import React from 'react';
import { withRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import {Helmet} from 'react-helmet';

import LandingLayout from 'component/layout/page/landing';

import Blogs from           'component/content/blog';
import Hero from            'component/content/hero';
import Logos from            'component/content/logos';
import USP from            'component/content/usp';
import Features from        'component/content/features';
import Testimonials from        'component/content/testimonials';
import CTA from        'component/content/cta';
import {PageCTA as SubscribePageCTA} from  'component/content/subscribe';


const PROFILE_MAP = {

}


class Page extends React.Component {
  render (){
    
    const { profile_key } = this.props.match.params;
    const is_mobile = window.innerWidth < 1200;

    const title = {
      'personal': 'Organize your bookmarks',
      'creator': 'Share collections and Grow your audience',
      'enterprise': "Build your team's knowledge base",
    }[profile_key]

    return (
      <LandingLayout id="landing-page">
        <Helmet>
          <title>Cloe | {title}</title>
        </Helmet>

        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <div className=" "  style={{
          minHeight: '100vh', 
          // backgroundImage: `url('/static/images/bg/07.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', 
        }}>
          <Hero profile_key={profile_key}/>
        </div>

        <CTA />
        

        {/* <div className="px-5"    style={{backgroundImage: `url('/static/images/bg/09.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <SubscribePageCTA category="early_access" view={(is_mobile)? 'split': 'inline'}  />
        </div> */}

        {/* <div className="my-5 pt-5">
          <USP />
        </div> */}

          <Features  profile_key={profile_key}/>
        {/* <div className="content-section bg-secondary"    style={{backgroundImage: `url('/static/images/bg/07.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}> */}
        {/* </div> */}

        {/* <Testimonials /> */}
        <div className="my-5 ">
          <Logos />
        </div>


        <div className="px-5"    style={{backgroundImage: `url('/static/images/bg/01.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <SubscribePageCTA category="early_access" view={(is_mobile)? 'split': 'inline'}  />
        </div>


        <div className=" py-5 " style={{backgroundImage: 'linear-gradient(300deg, #4954b6 0%, #9b23ea 100%)'}}>
          <Blogs />
        </div>


      </LandingLayout>
    )
  }
}

export default withRouter(Page);
