import React from 'react';
import { withRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/layout/page/landing';

import Splits from           'component/content/splits';
import Blogs from           'component/content/blog';
import Hero from            'component/content/hero';
import Logos from            'component/content/logos';
// import USP from            'component/content/usp';
// import Features from        'component/content/features';
// import Testimonials from        'component/content/testimonials';
// import CTA from        'component/content/cta';
import {PageCTA as SubscribePageCTA} from  'component/content/subscribe';


class Page extends React.Component {
  render (){
    const is_mobile = window.innerWidth < 1200;

    return (
      <LandingLayout id="landing-page">
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <div className=" "  style={{backgroundImage: `url('/static/images/bg/05.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <Hero />
        </div>

        <div className=" black-text-1  ">
          <Splits />
        </div>

        <div className="pt-1">
          <Logos />
        </div>


        <div className="px-5"    style={{backgroundImage: `url('/static/images/bg/04.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <SubscribePageCTA category="early_access" view={(is_mobile)? 'split': 'inline'} />
        </div>

        <div className=" py-5 " style={{backgroundImage: 'linear-gradient(300deg, #00bc70 0%, #60dca0 100%)'}}>
        {/* <div className="tertiary-bg-1 py-5 "> */}
          <Blogs />
        </div>


        {/* <div className="dark-highlight-1 py-5 ">
          <CTA />
        </div> */}

        {/* 

        <div className="my-5 ">
          <USP />
        </div>

        <div className="content-section bg-secondary"    style={{backgroundImage: `url('/static/images/bg/07.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <Features />
        </div>

        <Testimonials /> */}

      </LandingLayout>
    )
  }
}

export default withRouter(Page);
