import React, { useState } from 'react';


function ShareModal() {
  const [showModal, setShowModal] = useState(false);

  const handleShare = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const shareOnWhatsApp = () => {
    const currentUrl = window.location.href;
    const message = 'Check out this link: ' + currentUrl;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  const shareByEmail = () => {
    const currentUrl = window.location.href;
    const subject = 'Check out this link';
    const body = 'Hey, I wanted to share this link with you: ' + currentUrl;
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    const mailtoUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
    window.open(mailtoUrl);
  };

  const shareOnTwitter = () => {
    const currentUrl = window.location.href;
    const tweet = 'Check out this link: ' + currentUrl;
    const encodedTweet = encodeURIComponent(tweet);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedTweet}`;
    window.open(twitterUrl, '_blank');
  };

  const shareOnFacebook = () => {
    const currentUrl = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareOnLinkedIn = () => {
    const currentUrl = window.location.href;
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;
    window.open(linkedinUrl, '_blank');
  };

  return (
    <>
      <a href="" className="btn mr-2" onClick={handleShare} data-toggle="modal" data-target="#shareModal"><i className={" text-size-5 " + 'fa fa-share'}></i> </a>

      <div className="modal fade" data-backdrop="true" id="shareModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Share collection</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span className="close" onClick={handleModalClose}>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <button className="btn " onClick={shareOnWhatsApp}><i className="fab fa-whatsapp text-size-12" style={{color: '#25d366'}}></i></button>
              <button className="btn " onClick={shareByEmail}><i className="fa fa-envelope text-size-12" style={{color: '#a6b1b7'}}></i></button>
              <button className="btn " onClick={shareOnTwitter}><i className="fab fa-twitter text-size-12" style={{color: '#1da1f2'}}></i></button>
              <button className="btn " onClick={shareOnFacebook}><i className="fab fa-facebook text-size-12" style={{color: '#1877f2'}}></i></button>
              <button className="btn " onClick={shareOnLinkedIn}><i className="fab fa-linkedin text-size-12" style={{color: '#007bb5'}}></i></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShareModal;
