import React from 'react';
import { Link } from 'react-router-dom';


export default class Blogs extends React.Component {

  render (){
    const blogs = [
      {author: "Cloe", text: "Organize bookmarks and expand your knowledge base",},
      // {author: "Jack Welch", text: "An organization's ability to learn, and translate that learning into action rapidly, is the ultimate competitive advantage.",},
      // {author: "Simon Sinek, author best known for 'Start With Why.'", text: "When people are financially invested, they want a return. When people are emotionally invested, they want to contribute.",},
      // {author: "Doug Conant, CEO of Campbell's Soup", text: "To win in the marketplace you must first win in the workplace",},
      // {author: "Timothy R. Clark, executive coach and organizational consultant", text: "Highly engaged employees make the customer experience. Disengaged employees break it.",},
      // {author: "Anonymous", text: "Claiming your power is an inside job.",},
    ];

    const blog = blogs[0];
    return (
      <section id="blogs" className=" text-center ">
        <>
          <img src={"/static/images/splits/entrepreneur.svg"} height="200px" alt="" className="" />
          <p className="white-text-1 text-size-10">{blog.text}</p>
          {(blog.link)? (
            <div className="pt-5"><a href={blog.link} className="btn btn-white py-2 px-4 rounded text-size-10 black-text-1">View post <i className="ml-2 fa fa-arrow-right"></i></a></div>
          ): ('')}
        </>

        <div className="mt-5 mb-3 text-center ">
          <Link to="/discover" className=" text-size-6 dark-highlight-5 white-text-1 rounded p-3  mb-3">
            <i className="fa fa-eye mr-2 "></i>
            Discover Collections
          </Link>
        </div>

      </section>
    )
  }
}