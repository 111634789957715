import React from 'react';
import {Link} from 'react-router-dom';
import {TEXT} from 'component/global/constants';


export function FooterSectionTitle(props){
  return(<h5 className={"small text-uppercase mt-5 mb-3 "+ TEXT.read}>{props.title}</h5>)
}


export function FooterLink(props){
  return (
    <div className={" m-2 text-spacing-2 "}>
      <Link className="fancyline black-text-6" to={props.to}>{props.text}</Link>
    </div>  
  )
}

export function FooterExtLink(props){
  return (
    <div className={" m-2 text-spacing-2 "}>
      <a href={props.href} className="fancyline black-text-6">{props.text}</a>
    </div>  
  )
}
