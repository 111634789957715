import React from 'react';
import { withRouter } from 'react-router-dom';

import LandingLayout from 'component/layout/page/landing';
import PricingSection from 'component/content/pricing';


class Page extends React.Component {

  render (){
    return (
      <LandingLayout id="pricing-page">
        <PricingSection />
      </LandingLayout>
    )
  }
}

export default withRouter(Page);
