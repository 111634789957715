const cards = [
    {"card_xid": "27c513b6a0b5e42a909e025bb7e176ae5f861dc2926b84f7c0396188b0bad039", "card_link": "https://www.youtube.com/watch?v=zOjQaCWpDWY", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Uber vs Lyft - Which Pays Better? - Ridesharing Comparison - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/zOjQaCWpDWY/maxresdefault.jpg", "added_at": "2023-04-23T02:27:20.557486", "updated_at": "2023-07-07T20:33:12.847001"}, 
    {"card_xid": "9f2af4dedaed211244940a42e0b9c2fb3da9100204ddcdb23038dd79f6805ce6", "card_link": "https://www.youtube.com/watch?v=grlJb-3C8-U", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Marvel VS DC - Which is More Successful? Comic Company Comparison - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/grlJb-3C8-U/maxresdefault.jpg", "added_at": "2023-04-24T01:31:17.209903", "updated_at": "2023-07-07T20:33:13.114103"}, 
    {"card_xid": "3c5a90c2adb8d2375ca5cf3762e2fb6464f4164fc2806dcf8cedee441f37b4e6", "card_link": "https://www.youtube.com/watch?v=JV1NB70gLQI", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Most Powerful Corporations in the World? - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/JV1NB70gLQI/maxresdefault.jpg", "added_at": "2023-04-08T15:14:52.092396", "updated_at": "2023-07-07T20:33:13.209200"}, 
    {"card_xid": "6a467465bcf56e97de0d0be2bf69f2ff7e253f6b1814ed384a7cd4651d6b9923", "card_link": "https://www.youtube.com/watch?v=MiQYQQcG1HQ", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Walmart vs Amazon - Which Is More Successful - Company Comparison - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/MiQYQQcG1HQ/maxresdefault.jpg", "added_at": "2023-04-01T00:20:27.799099", "updated_at": "2023-07-07T20:33:13.288521"}, 
    {"card_xid": "e8f2d2d699a61ae8a2ff470bd3ba46ae52cd604a779d03c389a8d053626b218e", "card_link": "https://www.youtube.com/watch?v=P1siFAXZeaU", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Is Nike More Successful Than Adidas? Shoe / Apparel Company Comparison - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/P1siFAXZeaU/maxresdefault.jpg", "added_at": "2023-04-09T10:23:54.631876", "updated_at": "2023-07-07T20:33:13.381596"}, 
    {"card_xid": "64472e6b80fd7038079139bef02c3e266387edf8aee644aaa07dc69ff6e598fc", "card_link": "https://www.youtube.com/watch?v=uv40td4t6fM", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Apple vs Samsung - Which Is Bigger? - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/uv40td4t6fM/maxresdefault.jpg", "added_at": "2023-05-15T16:10:20.013983", "updated_at": "2023-07-07T20:33:13.479104"}, 
    {"card_xid": "de97e368d46ddd44b1079eb13e574c2194dcc27d943cb9b000b8717e43a9953f", "card_link": "https://www.youtube.com/watch?v=owM_ik5g8Qo", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Google vs Apple - Which Is More Successful? - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/owM_ik5g8Qo/maxresdefault.jpg", "added_at": "2023-04-21T15:38:53.076071", "updated_at": "2023-07-07T20:33:13.580910"}, 
    {"card_xid": "50191fc89451b2bdd2a4a16b85de4fa4ce00e80a0985029ae25cc5f884c6dfb2", "card_link": "https://www.youtube.com/watch?v=pxufNxPcO7A", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Boeing vs Airbus - How Do They Compare - Airplane Company Comparison - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/pxufNxPcO7A/maxresdefault.jpg", "added_at": "2023-05-18T22:16:53.845258", "updated_at": "2023-07-07T20:33:13.829448"}, 
    {"card_xid": "05f7b26d74593ff59512e36bf693cabf35bfd48c8504916aa228d8f4533c8e90", "card_link": "https://www.youtube.com/watch?v=ycv17I1uu08", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Google vs Facebook - How Do They Compare? - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/ycv17I1uu08/maxresdefault.jpg", "added_at": "2023-05-04T17:57:20.085805", "updated_at": "2023-07-07T20:33:14.122275"}, 
    {"card_xid": "b95360cf516a7113b3297716f6836cd68d607a4f43482568223af75417a66bf5", "card_link": "https://www.youtube.com/watch?v=bTzTNNYSicM", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Is Microsoft Actually More Successful Than Apple? Microsoft vs Apple - Tech Company Comparison - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/bTzTNNYSicM/maxresdefault.jpg", "added_at": "2023-04-19T03:28:55.304066", "updated_at": "2023-07-07T20:33:14.368595"}, 
    {"card_xid": "c54577ae36478e278a86bc462f12ddeacbe0754d58fb45277ac526a0691c9019", "card_link": "https://www.youtube.com/watch?v=tWSXm7CD6PM", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Sony vs Nintendo - How Do They Compare? (Video Game Company Comparison) - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/tWSXm7CD6PM/maxresdefault.jpg", "added_at": "2023-04-01T22:43:17.448725", "updated_at": "2023-07-07T20:33:14.589682"}, 
]
const profile = {
    xid: '2EF7_D9E9_4973',
    name_key: 'company_comparisons',
    theme_color: '#721160', 
    title: 'Company Comparisons',
    img_src: 'https://img.freepik.com/free-vector/versus-vs-screen-with-two-focus-light-effect_1017-26146.jpg?w=1380&t=st=1687425702~exp=1687426302~hmac=01913c074ceef40119b9165429274d732032ca2d099020f0a6f28238c21b930c',
    description: [
        "Comparisons presented in a fun way",
    ],
}
const author = {
    key: 'the_infographics_show',
    name: 'The Infographics Show ',
    img_src: 'https://yt3.googleusercontent.com/ytc/AGIKgqOkssb2Z_x4t6jYyJndF0SnVlRzwOmSz8kDAZqRew=s176-c-k-c0x00ffffff-no-rj',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
