import React from 'react';
import {Link} from 'react-router-dom';

import Logo from 'component/assets/logo';
import { SectionCTA as SubscribeSectionCTA } from 'component/content/subscribe';
import {TEXT} from 'component/global/constants';

// import {FooterLink, FooterExtLink} from './base';
import {CopyrightFooter, CompanyComponent, OtherProductsComponent, ReachComponent, CopyrightInternalLinksFooter} from './common';


export function ContentFooter(props){
  const is_mobile = window.innerWidth < 1200;
  const sticky = props.sticky || false;
  const css = sticky? {position: 'fixed', width: '100%', bottom: '0', zIndex: 9999, } : {};
  const internal_links = props.internal_links || false;
  
  return(
    <footer  style={css}>
      {(internal_links)? (<CopyrightInternalLinksFooter padding={1} />) : (<CopyrightFooter padding={1}/>)}
    </footer>
  )
}

export function LandingFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <div  className="p-1 pb-3 dark-bg-1 shadow text-black-9">
        <div className="row m-0">
          <div className="col-sm-5 px-1"><AboutComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-1"><CompanyComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-1"><OtherProductsComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-3 px-1"><ReachComponent is_mobile={is_mobile} /></div>
        </div>
      </div>
      <CopyrightFooter padding={5} />
    </footer>
  )
}


function AboutComponent(props){
  return (
    <div className="px-5">
      <div className="m-1">        
        <div className="mt-5 mb-2 ">
          <Link to="/" className="navbar-brand text-spacing-2 mr-2">
            <span className={"mx-2 primary-text-1" }><Logo name="logo" size={25} /></span>
            <span className={" white-text-1"}>C</span>
            <span className={" primary-text-1"}>10</span>
            <span className={" white-text-1"}>E</span>
          </Link>  
        </div>
        <div className={" black-text-9 family-code "}>
          <p className="">An extension of your knowledge base</p>
          <p className="">Organize your bookmarks</p>
          <p className="">Let your collections shine</p>
          <p className=""> 
            <span className="primary-text-1">♥ </span> 
            <span className="black-text-6">
            by small businesses, influencers, youtubers, artists, writers, gamers, athletes, fitness coaches, musicians, brands, ecommerce sellers, retailers, hobbyists, vloggers, health educators, developers, models, podcasters, creators, designers ...
            </span>
          </p>
        </div>
        
      </div>

      <div className="py-3">
        <SubscribeSectionCTA />
      </div>
    </div>
  )
}
