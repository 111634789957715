import React from 'react';
import { withRouter, Link, NavLink} from 'react-router-dom';
// 

import Logo from 'component/assets/logo';
import {SectionCTA as SubscribeSectionCTA} from 'component/content/subscribe';
import Announcement from 'component/content/announcement';


class Nav extends React.Component {
  render () {
    return(
      <section className={' fixed-top' }>
      {/* <Announcement /> */}
      <nav className={"navbar navbar-expand-lg white-highlight-5 p-0"} style={{backdropFilter: 'blur(25px)', }}>

        <div className="navbar-header">
          <Link to="/" className="navbar-brand text-spacing-2 mr-2">
            {/* <img style={{height: "40px"}} className="hero mx-2" src="/logo.svg" alt="logo svg" /> */}
            <span className="mx-2 primary-text-1"><Logo name="logo" size={30} /></span>
            <span className="black-text-2">C</span>
            <span className="primary-text-1">10</span>
            <span className="black-text-2">E</span>
          </Link>  

        </div>

        <button className="navbar-toggler btn btn-primary text-white mr-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <ul className="navbar-nav mr-auto">
            <li className="nav-item active"></li>
          </ul>

          <ul className="navbar-nav my-0 my-lg-0">
            <li className="nav-item primary-highlight-3 px-2">
              {/* <span className="black-text-6">profile</span> */}
              <Link to="/for/personal" className="btn btn-rounded text-spacing-2 m-2 uppercase">Personal</Link>
              <Link to="/for/creator" className="btn btn-rounded text-spacing-2 m-2 uppercase">Creator</Link>
              <Link to="/for/enterprise" className="btn btn-rounded text-spacing-2 m-2 uppercase">Company</Link>
            </li>
            <li className="nav-item">
              <Link to="/discover" className="btn btn-rounded text-spacing-2 m-2 uppercase">Discover</Link>
              <Link to="/pricing" className="btn btn-rounded text-spacing-2 m-2 uppercase">Pricing</Link>
              {/* <Link to="/login" className="btn btn-rounded text-spacing-2 m-2 uppercase">Login</Link> */}
            </li>
            <li className="nav-item">
              <NavLink to="/login" className="btn btn-secondary btn-rounded text-white text-spacing-2 m-2 uppercase"><i className="fa fa-paper-plane mr-2"></i>Start free</NavLink>
            </li>
          </ul>

        </div>
      </nav>
      </section>
    )
  }
}


export default withRouter(Nav);
