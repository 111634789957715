import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';


import ContentLayout from 'component/layout/page/content';

import {parseToHsla, hsla} from 'color2k';
// import {detectDeviceType} from 'utils/tracker';

import GenericCard from 'component/content/cards';
import ClickCopyButton from 'component/content/buttons/clickcopy';
import ShareButton from 'component/content/buttons/share';

// import {LISK} from './sample/cards';
// import {LISK} from './sample/motivation5min';
import {LISK_MAP, LISK_NAME_MAP, authors} from './sample/all';




function Page({match}) {

  var { user_name  } = match.params;

  // var author = authors[0];
  var author = null;
  authors.map(function(current_author, idx){
    if (current_author.key === user_name){
      author = current_author;
    }
  })

  if (author === null){return 'User not found';}
  const lisk_xid = 'temp'

  var lisk_data_list = [];
  Object.keys(LISK_MAP).map(function(lisk_xid, idx){
    const lisk_data = LISK_MAP[lisk_xid];
    if (lisk_data.author.key === user_name){
      lisk_data_list.push(lisk_data);
    }
  })

  // var lisk_data = {};
  // if (lisk_xid){lisk_data = LISK_MAP[lisk_xid];}
  // if (lisk_name && user_name === LISK_NAME_MAP[lisk_name].author.key){lisk_data = LISK_NAME_MAP[lisk_name];}

  // console.log(lisk_xid, user_name, lisk_name);
  // console.log(lisk_data);

  // const lisk = lisk_data.lisk;
  // const profile = lisk_data.profile;
  // const author = lisk_data.author;
  
  // console.log(profile);
  // if (!lisk_xid){lisk_xid = profile.xid};

  ///////////

  // const deviceType = detectDeviceType();

  const [h,s,l,_] = parseToHsla('#fff');

  const bg_gradient = 'linear-gradient( -45deg'
    +', hsl('+(h- 20)+', '+(0.9)*100+'%, '+(l*0.75)*100+'%)   0%'
    +', hsl('+(h+  0)+', '+(  s)*100+'%, '+(l     )*100+'%)  40%'
    +', hsl('+(h+  0)+', '+(  s)*100+'%, '+(l     )*100+'%)  60%'
    +', hsl('+(h+ 10)+', '+(0.9)*100+'%, '+(l*0.95)*100+'%) 100%'
    +')';

  const bg_light = (l > 0.5)? 'hsl('+(h- 0)+', '+(0.5)*100+'%, '+(0.96)*100+'%)': 'hsl('+(h- 0)+', '+(0.5)*100+'%, '+(0.96)*100+'%)';
  const bg_text     =  (l > 0.5)? '#333333': '#ffffff';
  const bg_light_desc = 'hsl('+(h- 0)+', '+(0.4)*100+'%, '+(0.4)*100+'%)';
  const bg_light_auth = 'hsl('+(h- 0)+', '+(0.5)*100+'%, '+(0.3)*100+'%)';
  const bg_text_desc     =  (l > 0.5)? '#ffffff':'#ffffff';


  const headerStyle = {
    // color: '#333333', 
    // background: bg_light, 
  };
  const descStyle = {
    color: bg_text_desc, background: bg_light_desc, fontSize: '0.8rem',
  }
  const authorStyle = {
    color: bg_text_desc, background: bg_light_auth, fontSize: '0.9rem', 
  }

  const imgStyle = {
    height: 'auto', 
    maxHeight: '10em', 
    width: 'auto', 
    maxWidth: '100%', 
    margin: '0.1em auto 0.1em auto', 
    display: 'block', 
    boxShadow: '0 0 8px 8px white inset',
  }

  const is_mobile = window.innerWidth < 1200;

  return (
    <ContentLayout id="page" >

      <div className="" style={{height: '100%'}}>

        <div className="row" style={{height: '100%'}}>
          <div className="col-md-3 m-0 p-0" style={{  color: bg_text, zIndex: 2000,  }}>
            <div className="card-block text-center" style={{position: 'sticky', top: 0 }}>
              
              <img src={author.img_src} className="img-radius my-3 " alt="User-Profile" style={{borderRadius: '50%', width: '40%', position: 'sticky', }} />
              
              <div className="py-3" style={headerStyle}>

                <h4 className="text-size-8 ">{author.name}</h4>

                <div className="my-3 black-text-6 ">
                  {author.description.map(function(desc, idx){
                    return(
                      <p className="m-0 p-0">{desc}</p>    
                    )
                  })}
                </div>

              </div>
              
              {/* DESCRIPTION */}
              {/* <div className="py-3 text-left family-code " style={descStyle}> */}
                {/* {profile.description.map(function(desc, idx){
                  return (<p key={idx} className="m-3" idx={idx}>{desc}</p>)
                })} */}
              {/* </div> */}

              {/* AUTHOR DETAILS */}
              {/* <div className="py-3 " style={authorStyle}>
                <a href={"/user/@"+author.key} className="white-text-5 m-0 p-0 ">
                  <img src={author.img_src} alt="" className="mr-2 " style={{height: '20px', width: '20px', borderRadius: '50%'}} />
                  {author.name}
                </a>
              </div> */}

              {/* ALERT */}
              {/* <div className="pt-3 pb-1 px-1 " style={{backgroundColor: '#fafafad0'}}>
                <Link to={"/lisk/"+lisk_xid+'/errors'} className="mx-3 black-text-1 ">
                  <i className="fa fa-bug text-size-6"></i>
                </Link>
                <Link to={"/lisk/"+lisk_xid+'/notifications'} className="mx-3 black-text-1 ">
                  <i className="fas fa-plus-circle text-size-6"></i>
                </Link>
                <Link to={"/lisk/"+lisk_xid+'/suggestions'} className="mx-3 danger-text-1 "  >
                  <i className="fas fa-triangle-exclamation text-size-6"></i>
                  <span className="text-size-3 " style={{ position: 'relative', top: '-1em', fontSize: '0.8em' }}>4</span>
                </Link>
              </div> */}

            </div>
          </div>
          <div 
            className="col-md-9 m-0 p-0"  
            style={{background: 'rgba(250,250,250,1)', height: '100%', overflowY: 'scroll'}}
          >


              <div className="my-5">

                <h4 className="my-3 ">Shared Collections:</h4>

              
          <div className=" row">
          {/* {Object.keys(LISK_MAP).map(function(lisk_key, idx){ */}
          {lisk_data_list.map(function(lisk_data, idx){
          
            // const lisk_data = LISK_MAP[lisk_key];
            const lisk_key = lisk_data.profile.name_key;
            const lisk_xid = lisk_data.profile.xid;

            const profile = lisk_data.profile;
            // const author = (lisk_data.author)? lisk_data.author: {"name": "Cloe Team", "img_src": "/logo.png", "key": "cloe_team"};
            const author = lisk_data.author;

            // console.log(lisk_data);

            return(
              <div key={"_"+idx} className="col-6 col-lg-3 col-md-3 col-sm-4 col-xs-12 p-1 ">
                <div 
                  className=" border  bg-white " 
                  style={{backgroundImage: '-webkit-linear-gradient(-45deg, #00000000 90%, '+profile.theme_color+' 90%)', }} 
                >
                  
                  <Link to={"/lisk/"+lisk_xid} className="">
                    <p className={" card-text text-center mt-3 black-text-3 " + ((is_mobile)? ' text-size-6 ': ' text-size-8 ')}>{profile.title}</p>
                  </Link>

                  <Link to={"/lisk/"+lisk_xid} className="">
                    <img className="card-img-top" src={profile.img_src} alt="Card image cap" style={imgStyle} />
                  </Link>

                  <div className="card-body m-2 p-2">

                      <span className="black-text-6 text-size-2 ">
                      {profile.description.map(function(desc, idx){
                        return(<p key={"_"+idx} className="m-1 p-0 ">{desc}</p>)
                      })}
                      </span>

                  </div>

                </div>
              </div>
            )
          })}
          </div>

              </div>

          </div>
        </div>

      </div>
    </ContentLayout>
  )
}



export default withRouter(Page);
