import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import qs from 'query-string';

import PageLayout from 'component/layout/page/content';
import {PageCTA as SubscribePageCTA} from  'component/content/subscribe';
// import {Loader} from 'component/assets/loader';
// import {DisclaimerLogin} from 'component/global/disclaimer';
// import GoogleButton from './social_login';

// import {saveToken, saveLoginMeta, hasStoredToken} from 'utils/authentication';
// import API, {handleError} from 'utils/xhr';


class Login extends React.Component {

  // state = {
  //   signin: false
  // }

  // componentDidMount(){
  //   if (hasStoredToken()){
  //     this.props.history.push('/simulations');
  //   }
  // }


  render (){
    return (
      <PageLayout id="login-page">

        <div className="login-form secondary-highlight-2 rounded p-2">

          <div className="avatar" align="center">
            <span className="fa-stack fa-4x align-items-center">
              <i className="fa fa-circle fa-stack-2x text-secondary"></i>
              <i className="fa fa-user fa-stack-1x fa-inverse"></i>
            </span>
          </div>

          <h3 className="text-center">Login</h3>

          <div className="m-4 mt-5">

            <div className="my-5 black-text-5 text-center small">
              <div className="">We're in early beta!</div>
              <div className="">Your login will only work if your email has been onboarded.</div>
              <div className="">Please request early access for login to work.</div>
            </div>

            <div className="text-center">
              <Link to="/login/email" className="btn btn-lg btn-block btn-dark"><i className="fa fa-envelope mr-2"></i>&nbsp;Login with E-Mail</Link>
            </div>


            <div className="m-2 mt-5 ">
              <a href="/" className="black-text-5"><i className="fa fa-home mr-2"></i>Back to Landing</a>
            </div>

          </div>

        </div>

        {/* <div className="content-section "    style={{backgroundImage: `url('/static/images/bg/06.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}> */}
        <div className="dark-bg-1 p-5 " style={{backgroundImage: `url('/static/images/bg/01.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
          <div className="mx-5 px-5">
            <SubscribePageCTA category="early_access" inverted />
          </div>
        </div>

      </PageLayout>
    )
  }
}

export default withRouter(Login);

