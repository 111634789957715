import React from 'react';
import { Link } from 'react-router-dom';

import SocialComponent from 'component/global/social';
import { PROFILE } from "component/global/constants";
import {TEXT} from 'component/global/constants';

import { FooterExtLink, FooterSectionTitle} from './base';


export function CopyrightInternalLinksFooter(props){
  const is_mobile = window.innerWidth < 1200;

  if (is_mobile){
    return (
      <div className={" family-code bg-dark black-text-6 px-1 py-" + props.padding}>
        <div className="text-right py-1">
          <Link to="/" className="fancyline black-text-6 mr-3 "><i className="fa fa-home mr-2 "></i>Home</Link>
          <Link to="/discover" className="fancyline black-text-6 mr-3 "><i className="fa fa-eye mr-2 "></i>Discover</Link>
          <Link to="/login" className="fancyline black-text-6 mr-3 "><i className="fa fa-paper-plane mr-2 "></i>Login</Link>
        </div>
        <div className={"text-left py-1"}>
          © { new Date().getFullYear() } - <a className=" text-spacing-2 white-text-1" href="https://bytebeacon.com">ByteBeacon, Inc</a> - All rights reserved
        </div>
      </div>
    )
  }
  return (
    <div className={" d-flex justify-content-between family-code bg-dark black-text-6 px-3 py-" + props.padding}>
      <div className={""}>
        © { new Date().getFullYear() } - <a className=" text-spacing-2 white-text-1" href="https://bytebeacon.com">ByteBeacon, Inc</a> - All rights reserved
      </div>
      <div className="">
        <Link to="/" className="fancyline black-text-6 mr-3 "><i className="fa fa-home mr-2 "></i>Home</Link>
        <Link to="/discover" className="fancyline black-text-6 mr-3 "><i className="fa fa-eye mr-2 "></i>Discover</Link>
        <Link to="/login" className="fancyline black-text-6 mr-3 "><i className="fa fa-paper-plane mr-2 "></i>Login</Link>
      </div>
    </div>
  )
}


export function CopyrightFooter(props){
  return (
    <div className={" text-center family-code bg-dark black-text-6 px-3 py-" + props.padding}>
      <div className={""}>
        © { new Date().getFullYear() } - <a className=" text-spacing-2 white-text-1" href="https://bytebeacon.com">ByteBeacon, Inc</a> - All rights reserved
      </div>
    </div>
  )
}



export function CompanyComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" " + ((is_mobile)? ' text-center mx-3 px-3': " m-1")}>
      <FooterSectionTitle title="Company"/>
      <div className="group-vertical">
        <FooterExtLink href="https://bytebeacon.com" text="Home" />
        <FooterExtLink href="https://blog.bytebeacon.com" text="Blog" />
        <FooterExtLink href="https://bytebeacon.com/legal/privacy" text="Privacy" />
        <FooterExtLink href="https://bytebeacon.com/legal/tos" text="Terms" />
        <FooterExtLink href="https://angel.co/company/bytebeacon/jobs" text="Jobs" />
      </div>
    </div>
  )
}

export function OtherProductsComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" " + (is_mobile)? ' text-center ': " m-1"}>
      <FooterSectionTitle title="Other products"/>
      <div className="">
        <FooterExtLink href="https://lightflare.bytebeacon.com" text="LightFlare" />
        <FooterExtLink href="https://bytetrack.bytebeacon.com" text="ByteTrack" />
        <FooterExtLink href="https://navigator.bytebeacon.com" text="Navigator" />
        <FooterExtLink href="https://divshow.bytebeacon.com" text="DivShow" />
      </div>
    </div>
  )
}


export function ReachComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className="text-center">
      {(is_mobile)? (<hr className="highlight-white-4 "/>) : ('')}


      <div className="my-2">
        <FooterSectionTitle title="Follow"/>
        <div className="group-vertical my-1 text-size-7">
        <SocialComponent contact={PROFILE.contact} />
        </div>
      </div>

      <hr className="highlight-white-4 white-bb-1-1"/>

      <div className="my-2">
        <FooterSectionTitle title="Contact"/>
        <div className="group-vertical my-1 text-size-7">
          <a className={"mx-2 " + TEXT.muted} href="mailto:info@bytebeacon.com"><i className="fa fa-envelope"></i></a>
          <a className={"mx-2 " + TEXT.muted} href="https://join.slack.com/t/bytebeacon/shared_invite/enQtODAxNTUwODM0MjU4LTU3NDFiMGYzMDgyMDY0OWM3YzAzOWMyODlhYjM4NGY4Zjc1N2U0MWM0N2UyMGVkNTIyY2IwZjkxMGM3ZDRmY2E"><i className="fab fa-slack-hash"></i></a>
          <a className={"mx-2 " + TEXT.muted} href="https://angel.co/company/bytebeacon"><i className="fab fa-angellist"></i></a>
        </div>
      </div>

    </div>
  )
}
