import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch, Redirect } from 'react-router-dom';

import { ParallaxProvider } from 'react-scroll-parallax';

// import {createBrowserHistory} from 'history';
import createBrowserHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

import {hasStoredToken} from './utils/authentication';

import LandingPage from './page/public/landing';
import ProfileLandingPage from './page/public/profile_landing';
import UserLandingPage from './page/public/user_landing';
import StoryPage from './page/public/story';
import LiskPage from './page/public/lisk';
import DiscoverPage from './page/public/discover';
import PricingPage from './page/public/pricing';
import SampleCardsPage from './page/public/sample_cards';

import LoginPage from './page/public/login';
import LoginEmailPage from './page/public/login/email';
import NoMatchPage from './page/generic/no_match';
import ComingSoonPage from './page/generic/coming_soon';

import LegalTOSPage from './page/legal/tos';
import PrivacyPolicyPage from './page/legal/privacy';

import * as serviceWorker from './serviceWorker';

import './styles/bootstrap.min.css';
import './styles/alertify.min.css';


const history = createBrowserHistory();

history.listen(location => {
  // const on_sites = (location.pathname.match(/sites/gi) || []).length;
  // const on_instructions = (location.pathname.match(/instructions/gi) || []).length;
  // const on_no_scroll_page = (on_sites > 0 || on_instructions > 0);
  const on_no_scroll_page = false;
  if (on_no_scroll_page){

  } else {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }  
});


const trackingId = process.env.REACT_APP_GA_CODE;  
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname);

// const target = document.querySelectorAll('.scroll');

// var index = 0, length = target.length;
// for (index; index < length; index++){
//   var pos = window.pageYOffset * target[index].dataset.rate;
//   if (target[index].dataset.direction === 'vertical'){
//     target[index].style.transform = 'translate3d(0px,'+pos+'px, -px)';
//   } else {
//     var posX = window.pageYOffset * target[index].dataset.ratex;
//     var posY = window.pageYOffset * target[index].dataset.ratey;

//     target[index].style.transform = 'translate3d('+posX+'px,'+posY+'px, -px)';
//   }
// }


ReactDOM.render(
  <ParallaxProvider>
  <Router history={history}>
    <Switch>
      
      <Route exact path='/sample_cards' component={SampleCardsPage} />

      <Route exact path='/for/:profile_key' component={ProfileLandingPage} />
      
      {/* protected */}<Route exact path='/lisk/:lisk_xid/subscribe' render={() => (hasStoredToken()? (<ComingSoonPage />) : (<Redirect to={"/login?dest=" + window.location.pathname}/>))}/>
      {/* protected */}<Route exact path='/lisk/:lisk_xid/report' render={() => (hasStoredToken()? (<ComingSoonPage />) : (<Redirect to={"/login?dest=" + window.location.pathname}/>))}/>
      {/* premium */}<Route exact path='/lisk/:lisk_xid/clone' render={() => (hasStoredToken()? (<ComingSoonPage />) : (<Redirect to={"/login?dest=" + window.location.pathname}/>))}/>

      <Route path='/legal/tos' component={LegalTOSPage} />
      <Route path='/legal/privacy' component={PrivacyPolicyPage} />

      <Route path='/login/email' component={LoginEmailPage} />
      <Route path='/login' component={LoginPage} />

      <Route exact path='/users/@:user_name/' component={UserLandingPage} /> 
      
      <Route exact path='/lisk/:lisk_xid' component={LiskPage} />
      {/* premium */}<Route exact path='/@:user_name/:lisk_name' component={LiskPage} /> 

      <Route exact path='/discover' component={DiscoverPage} />
      <Route exact path='/pricing' component={PricingPage} />
      <Route path='/story' component={StoryPage} />
      
      {/* premium */}<Route path='/analytics' component={ComingSoonPage} />
      <Route path='/' component={LandingPage} />
      
      <Route component={NoMatchPage} />

    </Switch>
  </Router>
  </ParallaxProvider>
  , document.getElementById('page')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
