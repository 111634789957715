const cards = [
    {"card_xid": "ce0d22edaeb89680b1e139f6d7079ac66a02687d3f21e787c1f9273fd8485c52", "card_link": "https://www.adoptapet.com/public/apis/pet_list.html", "card_title": "www.adoptapet.com", "card_theme": null, "card_type": "image", "card_description": "Adopt-a-Pet.com Pet List API Documentation for Shelter and Rescue Partners", "icon_img_src": "https://www.adoptapet.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-12T15:00:35.854470", "updated_at": "2023-07-07T20:32:17.534329"}, 
    {"card_xid": "946ce5dfb6ed58f323a16e9af462a5befe5d314267e8a12b8765aa579c97ba8e", "card_link": "https://theaxolotlapi.netlify.app/", "card_title": "theaxolotlapi.netlify.app", "card_theme": null, "card_type": "image", "card_description": "Site Not Found", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-01T02:51:45.237502", "updated_at": "2023-07-07T20:32:17.619040"}, 
    {"card_xid": "7f1c58f0bf99ace965fcbbe4130da6c1119a7edacf2220a4fc13f0a902098595", "card_link": "https://alexwohlbruck.github.io/cat-facts/", "card_title": "alexwohlbruck.github.io", "card_theme": null, "card_type": "image", "card_description": "cat-facts | Daily cat facts! \ud83d\udc31", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-14T06:59:42.264664", "updated_at": "2023-07-07T20:32:17.622025"}, 
    {"card_xid": "5e55a66ac66f47a378062e7fa237344c0c84516e36199fd97f560721f3736837", "card_link": "https://cataas.com/", "card_title": "cataas.com", "card_theme": null, "card_type": "image", "card_description": "Cat as a service (CATAAS)", "icon_img_src": "https://cataas.com/cat?width=32&height=32", "link_img_src": "https://cataas.com/cat/says/cataas.com?t=sq", "added_at": "2023-05-14T16:47:38.735573", "updated_at": "2023-07-07T20:32:17.626884"}, 
    {"card_xid": "4c179ee27a6cea265c9e7fa77aab5ee06db4aff43ed90942acb3d7643f163a5d", "card_link": "https://dukengn.github.io/Dog-facts-API/", "card_title": "dukengn.github.io", "card_theme": null, "card_type": "image", "card_description": "Dog Facts API | dukengn.github.io", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-08T02:00:09.056113", "updated_at": "2023-07-07T20:32:17.630227"}, 
    {"card_xid": "6eb18dc019c4487322bc27a30abadacfddf6655a6dd471c17c9f46bca9693a4f", "card_link": "https://kinduff.github.io/dog-api/", "card_title": "kinduff.github.io", "card_theme": null, "card_type": "image", "card_description": "Dog API by kinduff", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-21T05:02:16.513870", "updated_at": "2023-07-07T20:32:17.635057"}, 
    {"card_xid": "366ce0f1fed8a4a80a2bd5f8dc5d0a2726088d4e32ec31fe2b437e817de54c06", "card_link": "https://dog.ceo/dog-api/", "card_title": "dog.ceo", "card_theme": null, "card_type": "image", "card_description": "Dog API", "icon_img_src": "https://dog.ceo/img/favicon.png", "link_img_src": "https://dog.ceo/img/dog-api-fb.jpg", "added_at": "2023-04-12T22:04:56.329373", "updated_at": "2023-07-07T20:32:17.643026"}, 
    {"card_xid": "35cb0347002c1fcc4bb4e5061fe25dcd1290f1fd597f99f07f43f0a5fb5a79e4", "card_link": "https://documenter.getpostman.com/view/664302/S1ENwy59", "card_title": "documenter.getpostman.com", "card_theme": null, "card_type": "image", "card_description": "eBird API 2.0", "icon_img_src": "https://documenter-assets.pstmn.io/favicon.ico", "link_img_src": "https://res.cloudinary.com/postman/image/upload/t_team_logo_pubdoc/v1/team/6fd91fe3b32bfc9fa9f3324234a98f329add3960689cd06a54acef749d59b781", "added_at": "2023-05-09T14:22:36.005223", "updated_at": "2023-07-07T20:32:17.649035"}, 
    {"card_xid": "2a863e39e6e6d197a16e43b91bc7243740825f495bb967e101de6e755da07362", "card_link": "https://www.fishwatch.gov/developers", "card_title": "www.fishwatch.gov", "card_theme": null, "card_type": "image", "card_description": "Sustainable Seafood | NOAA Fisheries", "icon_img_src": "https://www.fishwatch.gov/themes/custom/noaa_components/favicon.ico", "link_img_src": "https://www.fisheries.noaa.gov/themes/custom/noaa_components/images/open-graph.jpg", "added_at": "2023-05-15T15:22:16.183527", "updated_at": "2023-07-07T20:32:17.669146"}, 
    {"card_xid": "0c4ccf774a263c4b344c181c74244eedff086660dfe7fea2a96c2e83c17bec9b", "card_link": "https://http.cat/", "card_title": "http.cat", "card_theme": null, "card_type": "image", "card_description": "HTTP Cats", "icon_img_src": "https://http.cat/apple-touch-icon.png", "link_img_src": "https://http.cat/100.jpg", "added_at": "2023-05-23T15:12:33.201356", "updated_at": "2023-07-07T20:32:17.883730"}, 
    {"card_xid": "3e256fb57408f3dedc925fe624456ebda1e3466f2af33513600d46d7be41dbce", "card_link": "https://http.dog/", "card_title": "http.dog", "card_theme": null, "card_type": "image", "card_description": "HTTP Status Dogs API - A dog image for every HTTP Status Code", "icon_img_src": "https://http.dog/static/icons/dog.svg", "link_img_src": "https://http.dog/200.jpg", "added_at": "2023-06-10T08:28:59.408505", "updated_at": "2023-07-07T20:32:17.908958"}, 
    {"card_xid": "9eeaa4e5830e1d0c20028c49c84f6fdc7459131db6afed461d636f29108c37b1", "card_link": "http://apiv3.iucnredlist.org/api/v3/docs", "card_title": "apiv3.iucnredlist.org", "card_theme": null, "card_type": "image", "card_description": "API - IUCN Red List of Threatened Species", "icon_img_src": "http://apiv3.iucnredlist.org/favicon.ico", "link_img_src": null, "added_at": "2023-04-21T10:50:32.282768", "updated_at": "2023-07-07T20:32:17.957785"}, 
    {"card_xid": "a9c12df34cd50e8a3d0e64a2e3df80c9b83d6c2156bb6065f12c72e8b5fcbb8f", "card_link": "https://github.com/wh-iterabb-it/meowfacts", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - wh-iterabb-it/meowfacts: \ud83d\udc08 a simple api which returns a catfact", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/09492e500eba6c205a92b05e56c92d343e2d93756c06232b1d37fcc5c4fe3e3a/wh-iterabb-it/meowfacts", "added_at": "2023-05-05T05:12:42.128891", "updated_at": "2023-07-07T20:32:18.025279"}, 
    {"card_xid": "1b66c09d619e272b4ceebae9a10b1fa7334a2e9a1f27798dcf9f818560cd764d", "card_link": "https://github.com/movebank/movebank-api-doc", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - movebank/movebank-api-doc: Description of download interface to build calls to the Movebank database using HTTP/CSV or JSON/JavaScript requests", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/f417d4da752020435fa3f29234cf73d0f064e8d1ee139f34d319254baa36dfd8/movebank/movebank-api-doc", "added_at": "2023-04-08T02:39:43.773885", "updated_at": "2023-07-07T20:32:18.129899"}, 
    {"card_xid": "adca6e66c55af3ff6da1274b32938f791b449997c81a1c9fa021161a954ffc7b", "card_link": "https://www.petfinder.com/developers/", "card_title": "www.petfinder.com", "card_theme": null, "card_type": "image", "card_description": "Petfinder\u2019s Free API for Developers | Petfinder", "icon_img_src": "https://www.petfinder.com/assets/www/media/favicon/apple-touch-icon-180x180.png", "link_img_src": null, "added_at": "2023-05-31T10:44:33.841529", "updated_at": "2023-07-07T20:32:18.206421"}, 
    {"card_xid": "fa9f6eee3e8e2b9b58a39f791bf681600150181aced6dcb5951d74976263f739", "card_link": "https://placebear.com/", "card_title": "placebear.com", "card_theme": null, "card_type": "image", "card_description": "{placebear}", "icon_img_src": "https://placebear.com/favicon.png", "link_img_src": "https://placebear.com/100/100.jpg", "added_at": "2023-06-14T05:08:26.543270", "updated_at": "2023-07-07T20:32:18.247000"}, 
    {"card_xid": "47ef29e79b5ea316cbced26db5cc870a30b56f66492642e8e8359d5c395a0654", "card_link": "https://place.dog", "card_title": "place.dog", "card_theme": null, "card_type": "image", "card_description": "place.dog | 502: Bad gateway", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-01T09:38:33.394471", "updated_at": "2023-07-07T20:32:18.250306"}, 
    {"card_xid": "af1ca19429a157a00a707e655f6db4f32572f0daa82c172374a162d4b9d00b43", "card_link": "https://placekitten.com/", "card_title": "placekitten.com", "card_theme": null, "card_type": "image", "card_description": "{placekitten} - Placeholder kitten images for developers", "icon_img_src": "https://placekitten.com/apple-touch-icon.png", "link_img_src": "http://placekitten.com/100/100", "added_at": "2023-04-13T07:18:49.520142", "updated_at": "2023-07-07T20:32:18.255676"}, 
    {"card_xid": "9e4ea4cad177f9ca17773e67663bf5807c175c961d8f9958b55473c77d0dbd3e", "card_link": "https://random.dog/woof.json", "card_title": "random.dog", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://random.dog/favicon.ico", "link_img_src": null, "added_at": "2023-06-02T20:32:14.784302", "updated_at": "2023-07-07T20:32:18.262285"}, 
    {"card_xid": "58bd6fdd43f4e149ce257e02f2fd86bb090155102d67e70617269ba17b88fb0b", "card_link": "https://random-d.uk/api", "card_title": "random-d.uk", "card_theme": null, "card_type": "image", "card_description": "Random-d.uk", "icon_img_src": "https://random-d.uk/favicon.ico", "link_img_src": null, "added_at": "2023-04-28T16:45:03.343310", "updated_at": "2023-07-07T20:32:18.263752"}, 
    {"card_xid": "be08db518505ecb25b40b7aa72e364feacc52598177d8d1091e70c414b0565d9", "card_link": "https://randomfox.ca/floof/", "card_title": "randomfox.ca", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://randomfox.ca/favicon.ico", "link_img_src": null, "added_at": "2023-05-19T03:12:52.837655", "updated_at": "2023-07-07T20:32:18.279630"}, 
    {"card_xid": "7f6b10836c541e9b5440dce74ee9c18bcf3454fcbadf7baa0499abd2dbb1a427", "card_link": "https://userguide.rescuegroups.org/display/APIDG/API+Developers+Guide+Home", "card_title": "userguide.rescuegroups.org", "card_theme": null, "card_type": "image", "card_description": "API Developers Guide Home - API Developers Guide - RescueGroups.org", "icon_img_src": "https://userguide.rescuegroups.org/s/-wrj3kg/8803/cfgz7q/45/_/favicon.ico", "link_img_src": null, "added_at": "2023-05-12T14:41:21.154914", "updated_at": "2023-07-07T20:32:18.281660"}, 
    {"card_xid": "7dc827bdeaaa22ab38daf4a74f5408759a39f22971f12f59b13bdce5b46dc1c9", "card_link": "http://shibe.online/", "card_title": "shibe.online", "card_theme": null, "card_type": "image", "card_description": "shibe.online - the shiba inu api", "icon_img_src": "https://shibe.online/assets/favicon.ico?v=1.1", "link_img_src": "https://cdn.shibe.online/shibes/fd18553e5fb5ac26170062e3f474bb9e626f656c.jpg", "added_at": "2023-04-14T21:08:46.456766", "updated_at": "2023-07-07T20:32:18.316348"}, 
    {"card_xid": "6666c3ef1031b32104bd782e8e9df7b1bc16e0375530ec954eaa312f606f3a2f", "card_link": "https://thedogapi.com/", "card_title": "thedogapi.com", "card_theme": null, "card_type": "image", "card_description": "The Dog API - Dogs as a Service", "icon_img_src": "https://thedogapi.com/favicon.ico", "link_img_src": "https://cdn2.thedogapi.com/logos/wave-square_256.png", "added_at": "2023-05-01T22:21:23.150194", "updated_at": "2023-07-07T20:32:18.320952"}, 
    {"card_xid": "c62fbeaa43eca7e3a46931a7f6c9dd8d45448e512e2f9fe4a64fc7865dcb998c", "card_link": "https://xeno-canto.org/explore/api", "card_title": "xeno-canto.org", "card_theme": null, "card_type": "image", "card_description": "API :: xeno-canto", "icon_img_src": "https://xeno-canto.org/static/img/favicon-120.png", "link_img_src": null, "added_at": "2023-05-22T09:50:38.349543", "updated_at": "2023-07-07T20:32:18.357660"}, 
    {"card_xid": "019e05d5d96ca7a6b3a7f8f655f00cb46075244088a6813590d393fce06e293a", "card_link": "https://zoo-animal-api.herokuapp.com/", "card_title": "zoo-animal-api.herokuapp.com", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-21T00:44:17.705049", "updated_at": "2023-07-07T20:32:18.379155"}, 
]
const profile = {
    xid: '6660_AE44_0C29',
    name_key: 'api_animals',
    theme_color: '#79f160', 
    title: 'API: Animals',
    img_src: 'https://img.freepik.com/free-vector/wild-animals-white-background_1308-2815.jpg?w=1380&t=st=1687440945~exp=1687441545~hmac=588793d065b8a9da6b5ea3e9288ebb89e78cfdcca64ca2f165e5208c2bd9f496',
    description: [
        "Animals API",
    ],
}
const author = {
    key: 'api_store',
    name: 'All APIs',
    img_src: 'https://img.freepik.com/free-vector/application-programming-interface-concept-illustration_114360-9135.jpg?w=1060&t=st=1687440423~exp=1687441023~hmac=12830d60883b3aac174f89be583e4fec8d45286c3dfe6b3f6988f8288e0d6ce1',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
