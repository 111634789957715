const cards = [
    {"card_xid": "b440922a487cb6d80c2c1aa36c3cad0ff94e6159f3fb409f842d79bb44a14280", "card_link": "https://www.boredpanda.com/", "card_title": "www.boredpanda.com", "card_theme": null, "card_type": "image", "card_description": "Bored Panda - The Only Magazine For Pandas", "icon_img_src": "https://assets.boredpanda.com/blog/wp-content/themes/boredpanda/images/favicons/apple-touch-icon.png", "link_img_src": "https://www.boredpanda.com/blog/wp-content/themes/boredpanda/images/fb.jpg", "added_at": "2023-05-04T05:31:30.436287", "updated_at": "2023-07-07T20:32:09.385818"}, 
    {"card_xid": "2df2e56cb73950842df9d8ad5833e8fd08918586980e57ec5f55b6eeaaa66ef3", "card_link": "https://sketchfab.com/", "card_title": "sketchfab.com", "card_theme": null, "card_type": "image", "card_description": "Sketchfab - The best 3D viewer on the web", "icon_img_src": "https://static.sketchfab.com/static/builds/web/dist/static/assets/images/favicon/e59d7f2ff579bbf9d20771b3d4d8c6a7-v2.png", "link_img_src": "https://static.sketchfab.com/static/builds/web/dist/static/assets/images/favicon/a81e1fd93fc053fed8a5f56640f886f8-v2.png", "added_at": "2023-05-21T23:33:13.256530", "updated_at": "2023-07-07T20:32:09.502658"}, 
    {"card_xid": "377055195e8f2ac3d748ecd66f25c3bf6d04d727aca60ede946b7bffc1b3d28a", "card_link": "https://kahoot.com/", "card_title": "kahoot.com", "card_theme": null, "card_type": "image", "card_description": "Kahoot! | Learning games | Make learning awesome!", "icon_img_src": "https://kahoot.com/wp-content/themes/kahoot2017/assets/img/favicon/favicon-blue.ico", "link_img_src": "https://kahoot.com/files/2017/06/img-social-default.jpg", "added_at": "2023-05-13T15:59:06.756242", "updated_at": "2023-07-07T20:32:09.583638"}, 
    {"card_xid": "df633cfde23896fc32e9915c307ff5a155559a0615fadde73a0223cadc65ea96", "card_link": "https://www.sporcle.com/", "card_title": "www.sporcle.com", "card_theme": null, "card_type": "image", "card_description": "Sporcle - A World of Quizzes", "icon_img_src": "https://www.sporcle.com/images/favicon-167x167.png", "link_img_src": "https://d31xsmoz1lk3y3.cloudfront.net/images/big/default.png?v=30001", "added_at": "2023-06-10T14:30:27.293058", "updated_at": "2023-07-07T20:32:09.634885"}, 
    {"card_xid": "2f2bfbf88da52c807b0ec450f56a4595aa30beee161a9a04c3ead165bf5e95c1", "card_link": "https://www.enamelpins.com/", "card_title": "www.enamelpins.com", "card_theme": null, "card_type": "image", "card_description": "Custom Enamel Pins [ Up to 30% Off & QTY No Minimum ]", "icon_img_src": "https://enamelpins-static.oss-accelerate.aliyuncs.com/u_file/1912/photo/718f5a39d9.png", "link_img_src": null, "added_at": "2023-05-06T15:31:56.173927", "updated_at": "2023-07-07T20:32:09.826514"}, 
    {"card_xid": "6e9334df52cba58324621e6ccdd1ce55c29a515ede4f7a78c21c65e649db7ec7", "card_link": "https://9gag.com/", "card_title": "9gag.com", "card_theme": null, "card_type": "image", "card_description": "9GAG - Best Funny Memes and Breaking News", "icon_img_src": "https://9gag.com/favicon.ico", "link_img_src": "https://images-cdn.9gag.com/img/9gag-og.png", "added_at": "2023-05-30T10:58:54.025250", "updated_at": "2023-07-07T20:32:10.011639"}, 
    {"card_xid": "05d708e59b4f19c22944b31668b66db3de2fc2b27d13d430c4a7492665cd6f18", "card_link": "https://www.geoguessr.com/", "card_title": "www.geoguessr.com", "card_theme": null, "card_type": "image", "card_description": "GeoGuessr - Let's explore the world!", "icon_img_src": "https://www.geoguessr.com/_next/static/images/geoguessr-57-e3554d29cf9a410853ee28063965a2ba.png", "link_img_src": "https://www.geoguessr.com/_next/static/images/default-231f6f91e41050bfcac84aa3009be3fe.png", "added_at": "2023-06-10T10:23:21.380870", "updated_at": "2023-07-07T20:32:10.028546"}, 
    {"card_xid": "b2fd455a263cd1e06e263c0c68d25fc3eaf01d139a6b33a6925d440085dc3e38", "card_link": "https://artsandculture.google.com/", "card_title": "artsandculture.google.com", "card_theme": null, "card_type": "image", "card_description": "Google Arts & Culture", "icon_img_src": "https://www.gstatic.com/culturalinstitute/stella/apple-touch-icon-180x180-v1.png", "link_img_src": "https://www.gstatic.com/culturalinstitute/images/gac-icon-200x200-v1.png", "added_at": "2023-05-18T03:25:46.778013", "updated_at": "2023-07-07T20:32:10.054421"}, 
    {"card_xid": "b2aeb96d5fed1ee7551865c03921cdd1fb96eeda8c1dbfedf7483e5f00576e1c", "card_link": "https://colorhunt.co/", "card_title": "colorhunt.co", "card_theme": null, "card_type": "image", "card_description": "Color Palettes for Designers and Artists - Color Hunt", "icon_img_src": "https://colorhunt.co/img/color-hunt-icon-ios.png", "link_img_src": "https://colorhunt.co/img/color-hunt-og.png", "added_at": "2023-06-07T06:01:51.770258", "updated_at": "2023-07-07T20:32:10.199258"}, 
    {"card_xid": "2208fd3036d4f1ffa4844ce2b2e55e47fb2abe4bf1025ea7968f3579df30cdd2", "card_link": "https://www.imdb.com/", "card_title": "www.imdb.com", "card_theme": null, "card_type": "image", "card_description": "IMDb: Ratings, Reviews, and Where to Watch the Best Movies & TV Shows", "icon_img_src": "https://m.media-amazon.com/images/G/01/imdb/images-ANDW73HA/apple-touch-icon-web-152x152._CB479963088_.png", "link_img_src": "https://m.media-amazon.com/images/G/01/imdb/images/social/imdb_logo.png", "added_at": "2023-05-02T19:35:54.349193", "updated_at": "2023-07-07T20:32:10.263796"}, 
    {"card_xid": "e1fc0f6e57daae9e228ee70e09a0107f3d1b45adea910e152305d95f84a09ce2", "card_link": "https://theoatmeal.com/", "card_title": "theoatmeal.com", "card_theme": null, "card_type": "image", "card_description": "The Oatmeal - Comics by Matthew Inman ", "icon_img_src": "https://s3.amazonaws.com/theoatmeal-img/favicons/apple-icon-57x57.png", "link_img_src": null, "added_at": "2023-04-14T02:25:27.814252", "updated_at": "2023-07-07T20:32:10.368816"}, 
    {"card_xid": "d4f905e687a21f965b6a7ce87c5aeef6dbf33fe0f63c0f0bb17998488e29828a", "card_link": "https://giphy.com/", "card_title": "giphy.com", "card_theme": null, "card_type": "image", "card_description": "GIPHY - Be Animated", "icon_img_src": "https://giphy.com/static/img/icons/apple-touch-icon-167px.png", "link_img_src": "https://giphy.com/static/img/giphy-be-animated-logo.gif", "added_at": "2023-04-12T15:35:37.661528", "updated_at": "2023-07-07T20:32:10.378794"}, 
    {"card_xid": "ac85b1adabcd451d2e09c37fb0fc9fe1c5f8e5c0cd24c695780b869eb2777d9e", "card_link": "https://www.window-swap.com/", "card_title": "www.window-swap.com", "card_theme": null, "card_type": "image", "card_description": "WindowSwap - Watch windows around the world", "icon_img_src": "https://www.window-swap.com/assets/images/windowswaplogo.png", "link_img_src": "https://www.window-swap.com/TwitterCardImg.png", "added_at": "2023-06-18T21:45:15.420989", "updated_at": "2023-07-07T20:32:10.462496"}, 
    {"card_xid": "ef930eed8bcfaff791e0e204b0399b5f7c19d2c3d9825bd3e647c332ad0c4466", "card_link": "https://dribbble.com/", "card_title": "dribbble.com", "card_theme": null, "card_type": "image", "card_description": "Dribbble - Discover the World\u2019s Top Designers & Creative Professionals", "icon_img_src": "https://cdn.dribbble.com/assets/apple-touch-icon-precomposed-1c6d9b0a173f5b2d5c392ea101bb206ee9a2a39bef19eb21513b857eeb3624d2.png", "link_img_src": null, "added_at": "2023-05-19T15:43:19.208399", "updated_at": "2023-07-07T20:32:10.476527"}, 
    {"card_xid": "8fc396d0e5016d9e5d81a8ad6680d1b86b57343b3845935caafd27126e07db84", "card_link": "https://mynoise.net/", "card_title": "mynoise.net", "card_theme": null, "card_type": "image", "card_description": "Background Noises \u2022 Ambient Sounds \u2022 Relaxing Music | myNoise \u00ae", "icon_img_src": "https://mynoise.net/apple-touch-icon.png", "link_img_src": "https://mynoise.net/Pix/FBdefault.jpg?2", "added_at": "2023-06-17T14:05:00.876695", "updated_at": "2023-07-07T20:32:10.582737"}, 
    {"card_xid": "82cbed81cbb179cd8da4e1f090064ce9c46e03fea5e3bd525ea1f36b8de1945a", "card_link": "https://www.chess.com/", "card_title": "www.chess.com", "card_theme": null, "card_type": "image", "card_description": "Chess.com - Play Chess Online - Free Games", "icon_img_src": "https://www.chess.com/bundles/web/favicons/apple-touch-icon.f72d3fd3.png", "link_img_src": "https://www.chess.com/bundles/web/images/social/share-home.a3e2cdbb.png", "added_at": "2023-04-02T21:55:04.678733", "updated_at": "2023-07-07T20:32:10.634690"}, 
    {"card_xid": "5bad55b908cafb8baf3ad841cb24e20466765672ec1ae1ccc937ac3cebd9bb74", "card_link": "https://listverse.com/", "card_title": "listverse.com", "card_theme": null, "card_type": "image", "card_description": "Top 10 Lists - Listverse", "icon_img_src": "https://listverse.com/wp-content/uploads/2020/10/cropped-Listverse-Facebook-Avatar-180x180.png", "link_img_src": null, "added_at": "2023-04-20T01:52:57.988881", "updated_at": "2023-07-07T20:32:10.668527"}, 
    {"card_xid": "55c16b0cb6e01a959179d332a847126672c41ec4757afd8b967b080080b96f28", "card_link": "https://www.playbuzz.com/quizzes?page=0", "card_title": "www.playbuzz.com", "card_theme": null, "card_type": "image", "card_description": "New Quizzes - Playbuzz", "icon_img_src": "https://static.ex.co/cdn/content/images/pwa/square/apple-touch-icon-new.png", "link_img_src": null, "added_at": "2023-06-12T16:51:27.413277", "updated_at": "2023-07-07T20:32:10.714520"}, 
    {"card_xid": "b7efa5e504ef19c037efcacd4cf5ba1ed25564c76a3b094d63af61547f30abf9", "card_link": "https://www.exploratorium.edu/", "card_title": "www.exploratorium.edu", "card_theme": null, "card_type": "image", "card_description": "Exploratorium: The Museum of Science, Art & Human Perception", "icon_img_src": "https://www.exploratorium.edu/themes/custom/oppenheimer/favicon.ico", "link_img_src": null, "added_at": "2023-04-11T07:55:02.270593", "updated_at": "2023-07-07T20:32:10.812985"}, 
    {"card_xid": "2970fcca17e70bc8087ad9d8bf32a14ab0a119dca92b86abb42a4a1107410891", "card_link": "https://thegreatdiscontent.com/", "card_title": "thegreatdiscontent.com", "card_theme": null, "card_type": "image", "card_description": "The Great Discontent (TGD)", "icon_img_src": "https://thegreatdiscontent.com/wp-content/uploads/2021/09/cropped-coyote-mark-180x180.png", "link_img_src": "https://thegreatdiscontent.com/wp-content/uploads/2022/01/tgd-social-sharing.jpg", "added_at": "2023-05-11T17:43:57.636154", "updated_at": "2023-07-07T20:32:10.886574"}, 
    {"card_xid": "2c6f50ce976728b58549fb273249e7873468feb4cdeb9e88b392bda071767542", "card_link": "https://www.jigsawplanet.com/", "card_title": "www.jigsawplanet.com", "card_theme": null, "card_type": "image", "card_description": "Free Online Jigsaw Puzzles", "icon_img_src": "https://www.jigsawplanet.com/img/jp-logo-128x128.png?v=3a44bd1", "link_img_src": "https://www.jigsawplanet.com/img/jigsaw-planet-logo.png", "added_at": "2023-06-16T13:24:34.977297", "updated_at": "2023-07-07T20:32:10.923021"}, 
    {"card_xid": "e798df070e52ee4e3e6a1ea6d4b63a7ac3afd51fa4236e05ba0ef841c58f174b", "card_link": "https://virtualpiano.net/", "card_title": "virtualpiano.net", "card_theme": null, "card_type": "image", "card_description": "Virtual Piano - Play The Best Online Piano Keyboard", "icon_img_src": "https://virtualpiano.net/wp-content/uploads/2021/02/cropped-android-chrome-512x512-1-180x180.png", "link_img_src": "https://virtualpiano.net/wp-content/uploads/2020/07/Online-Piano-Keyboard-Virtual-Piano.jpg", "added_at": "2023-04-12T04:02:00.827667", "updated_at": "2023-07-07T20:32:10.962414"}, 
    {"card_xid": "bf70961d950952b051671f1170a778b542b8e4a95a43b0db723a111693459c94", "card_link": "https://www.accuweather.com/", "card_title": "www.accuweather.com", "card_theme": null, "card_type": "image", "card_description": "Local, National, & Global Daily Weather Forecast | AccuWeather", "icon_img_src": "", "link_img_src": "https://www.accuweather.com/images/logos/aw-logo-og-meta.png", "added_at": "2023-05-19T00:42:02.348992", "updated_at": "2023-07-07T20:32:11.135857"}, 
    {"card_xid": "fba0b7d59eeabc5d0e81defc4f2a3667c5caf48eb23d846ed49b371096d0f6b6", "card_link": "https://musiclab.chromeexperiments.com/", "card_title": "musiclab.chromeexperiments.com", "card_theme": null, "card_type": "image", "card_description": "Chrome Music Lab", "icon_img_src": "https://musiclab.chromeexperiments.com/static/MusicBox_16_2.ico.png", "link_img_src": "https://musiclab.chromeexperiments.com/static/share.jpg", "added_at": "2023-06-10T03:25:41.282523", "updated_at": "2023-07-07T20:32:11.249925"}, 
    {"card_xid": "aea93247b62e957a44bf41bc1defabad71360670758c1b56c1fbd86a16fac8f0", "card_link": "https://www.classicfm.com/", "card_title": "www.classicfm.com", "card_theme": null, "card_type": "image", "card_description": "Classic FM - The World's Greatest Music", "icon_img_src": "https://www.classicfm.com/assets_v4r/classic/img/apple-touch-icon-120x120.png", "link_img_src": "https://assets.classicfm.com/2018/10/classic-fm-logo-large-square-1520936467-list-handheld-0.png", "added_at": "2023-06-03T05:34:42.398321", "updated_at": "2023-07-07T20:32:11.262734"}, 
    {"card_xid": "79ab8340254b4db008f876dd4b756ac7646d24c718ee679ad5aad0ba71f132dd", "card_link": "https://www.mentalfloss.com/", "card_title": "www.mentalfloss.com", "card_theme": null, "card_type": "image", "card_description": "Mental Floss", "icon_img_src": "https://images2.minutemediacdn.com/image/upload/c_fill,w_1440,ar_1:1,f_auto,q_auto,g_auto/frontier/favicons/MFFavicon_216ef394-60ef-4fac-9984-97a8819af760_400x.png", "link_img_src": "https://images2.minutemediacdn.com/image/upload/c_fill,w_1440,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/mf_logo_tag-bdd0919ede2935a33065bc4f61df2502.png", "added_at": "2023-05-13T04:20:24.225766", "updated_at": "2023-07-07T20:32:11.355218"}, 
    {"card_xid": "1b74aee280a74a7f0638aa5a51fbdf23b753d49b76f40b403d39df1ddbcc0dee", "card_link": "https://www.khanacademy.org/", "card_title": "www.khanacademy.org", "card_theme": null, "card_type": "image", "card_description": "Khan Academy | Free Online Courses, Lessons & Practice", "icon_img_src": "https://cdn.kastatic.org/images/apple-touch-icon-144x144-precomposed.new.png", "link_img_src": "https://cdn.kastatic.org/images/khan-logo-dark-background-2.png", "added_at": "2023-04-18T12:44:14.572008", "updated_at": "2023-07-07T20:32:11.405874"}, 
    {"card_xid": "495d1eec85626a50a3752c0bc4b738b806f59109325ea16f7253136b9a6bd409", "card_link": "https://www.flightradar24.com/", "card_title": "www.flightradar24.com", "card_theme": null, "card_type": "image", "card_description": "Flightradar24: Live Flight Tracker - Real-Time Flight Tracker Map", "icon_img_src": "https://www.flightradar24.com/static/favicons/apple-icon-72x72.png?t=1687765382", "link_img_src": "https://images.flightradar24.com/opengraph/fr24_logo_default.png", "added_at": "2023-04-06T20:17:38.182363", "updated_at": "2023-07-07T20:32:11.460833"}, 
    {"card_xid": "7f2bd7d56cb93f27084b345db0573f5848f4c7b0192416bd7c32f2ff4d8e8639", "card_link": "https://quickdraw.withgoogle.com/", "card_title": "quickdraw.withgoogle.com", "card_theme": null, "card_type": "image", "card_description": "Quick, Draw!", "icon_img_src": "https://quickdraw.withgoogle.com/static/favicon-196.png", "link_img_src": "https://quickdraw.withgoogle.com/static/shareimg.png", "added_at": "2023-04-18T02:53:48.064221", "updated_at": "2023-07-07T20:32:11.626078"}, 
    {"card_xid": "be70c7a5a476a658df832ff813bbda6505fda1d61c6271fabcf8639e4c7603a1", "card_link": "https://www.nytimes.com/crosswords", "card_title": "www.nytimes.com", "card_theme": null, "card_type": "image", "card_description": "The Crossword - The New York Times", "icon_img_src": "https://www.nytimes.com/games-assets/v2/metadata/nyt-apple-touch-icon.png?v=v2306261100", "link_img_src": "https://mwcm.nyt.com/dam/mkt_assets/img/games/hub.png", "added_at": "2023-05-20T22:26:45.729471", "updated_at": "2023-07-07T20:32:11.654317"}, 
    {"card_xid": "7912f28d3494f7f590465e904797e3e211151eaeaffa932c98fac29337bfffba", "card_link": "https://bigthink.com/", "card_title": "bigthink.com", "card_theme": null, "card_type": "image", "card_description": "Big Think - Smarter, Faster", "icon_img_src": "https://bigthink.com/wp-content/uploads/2023/06/cropped-bt-icon-512x512-1-1.png?w=180", "link_img_src": "https://bigthink.com/wp-content/uploads/2021/09/BigThink_Logo_Black.jpg?resize=1200,630", "added_at": "2023-04-20T22:04:41.093382", "updated_at": "2023-07-07T20:32:11.677619"}, 
    {"card_xid": "1adb5935700eec3fb67ba3e14afe1f60dd59dcbd6288e0d2d1132e45a12423d1", "card_link": "https://ed.ted.com/", "card_title": "ed.ted.com", "card_theme": null, "card_type": "image", "card_description": "Lessons Worth Sharing | TED-Ed", "icon_img_src": "https://ed.ted.com/favicon.ico", "link_img_src": "https://ed.ted.com/teded-logo-600-600.png", "added_at": "2023-05-25T17:06:01.774810", "updated_at": "2023-07-07T20:32:11.756475"}, 
    {"card_xid": "61dc9b2d49aec7feda6a1fe9499c557e63d05d4fe0d65ddaa1ec854547395bb2", "card_link": "https://www.nationalgeographic.com/", "card_title": "www.nationalgeographic.com", "card_theme": null, "card_type": "image", "card_description": "National Geographic", "icon_img_src": "https://assets-cdn.nationalgeographic.com/natgeo/static/icons/favicon-32x32.png", "link_img_src": "https://assets-cdn.nationalgeographic.com/natgeo/static/default.NG.logo.dark.jpg", "added_at": "2023-05-13T12:44:23.137467", "updated_at": "2023-07-07T20:32:11.781641"}, 
    {"card_xid": "00783553e54841a8d8c688fa881a0318ec0afc3e8ae31feae63d7ddba23b6280", "card_link": "https://futurism.com/", "card_title": "futurism.com", "card_theme": null, "card_type": "image", "card_description": "Futurism | Science and Technology News and Videos", "icon_img_src": "https://futurism.com/favicon.png", "link_img_src": "https://futurism.com/static/images/futurismOgImage.jpg", "added_at": "2023-04-21T08:27:22.161753", "updated_at": "2023-07-07T20:32:11.992477"}, 
    {"card_xid": "3f32654506d0bd346e47e9ec64030057753d9bc35a7cf43323d3321157390438", "card_link": "https://theuselessweb.com/", "card_title": "theuselessweb.com", "card_theme": null, "card_type": "image", "card_description": "The Useless Web", "icon_img_src": "https://theuselessweb.com/favicon.ico", "link_img_src": "https://theuselessweb.com/share-image-large.png", "added_at": "2023-04-20T18:07:45.112470", "updated_at": "2023-07-07T20:32:12.021257"}, 
]
const profile = {
    xid: '6CAB_98F6_3CC3',
    name_key: 'kill_time_sites',
    theme_color: '#a921f0', 
    title: 'Websites to kill time',
    img_src: 'https://img.freepik.com/free-vector/businessman-pushing-hands-clock-backward-pressure-deadline-business-tasks-late-tiny-man-flat-vector-illustration-time-management-concept-banner-website-design-landing-web-page_74855-22967.jpg?w=1380&t=st=1687804553~exp=1687805153~hmac=5701338ba2b3c85e38b50e803d8e7c8971b22a6cf71aae3808b8c91e31b7c3df',
    description: [
        "Websites for when you're bored online",
    ],
}
const author = {
    key: 'clay_owen',
    name: 'Clay Owen',
    img_src: 'https://img.freepik.com/free-photo/fun-3d-illustration-american-referee_183364-80776.jpg?w=1480&t=st=1686919604~exp=1686920204~hmac=3da499851855d8bc2c98a75ca9a3cca576d8214b83373ad0d19aae10438c0f8b',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
