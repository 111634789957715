const cards = [
    {"card_xid": "541c325330bbeb0b367fd0c088e6fbb01e952b642ada6ec96a1f6b3f84af684f", "card_link": "https://symphonychips.com/pages/about-us", "card_title": "symphonychips.com", "card_theme": null, "card_type": "image", "card_description": "\n    About Us\n    \n    \n    \n      \u2013 Symphony Chips\n    \n  ", "icon_img_src": "https://symphonychips.com/cdn/shop/files/SymphonyLogo_Update_32x32.png?v=1613182024", "link_img_src": "https://cdn.shopify.com/s/files/1/1962/7503/files/chips-logo.png?height=628&pad_color=fff&v=1613745103&width=1200", "added_at": "2023-04-01T20:09:28.489439", "updated_at": "2023-07-07T20:33:32.405518"}, 
    {"card_xid": "7b0c1e03a9efc6bf19266fc988b81eb48dfe535ea1868932fc2c55ce71e59011", "card_link": "https://www.mayascookies.com", "card_title": "www.mayascookies.com", "card_theme": null, "card_type": "image", "card_description": "\n  Maya's Cookies\n  ", "icon_img_src": "https://www.mayascookies.com/cdn/shop/files/Mayas-Cookies-heart-M_81fc480e-9a0a-43d8-92ee-0f2109a4cd5a_180x180_crop_center.png?v=1614345721", "link_img_src": "http://www.mayascookies.com/cdn/shop/files/2020-Maya_s-Cookies-logo_1200x1200.png?v=1614307940", "added_at": "2023-04-09T15:46:15.276899", "updated_at": "2023-07-07T20:33:38.507113"}, 
    {"card_xid": "a29ef6afe61e7a1446b1846d0bb15bc3c0379f86a9009c5938f53166246b4237", "card_link": "https://www.vegansmart.com", "card_title": "www.vegansmart.com", "card_theme": null, "card_type": "image", "card_description": "Home - Naturade", "icon_img_src": "https://www.naturade.com/wp-content/uploads/N-favicon-180x180.png", "link_img_src": "https://www.naturade.com/wp-content/uploads/Naturade-VeganSmart-Shake-Website-Banner-V01-Desktop.jpg", "added_at": "2023-06-16T06:35:40.465897", "updated_at": "2023-07-07T20:33:42.815658"}, 
    {"card_xid": "4be6d4e9fc511fed23cee13d87ede9a80fd312cc55ad785a9bfebd1e37eb403f", "card_link": "https://eatprojectpop.com", "card_title": "eatprojectpop.com", "card_theme": null, "card_type": "image", "card_description": "Natural Popcorn, Organic Ingredients | Major's Project Pop | U.S.", "icon_img_src": "", "link_img_src": "https://cdn.shopify.com/s/files/1/0523/0126/7144/files/Untitled_design-8_a60e1e46-31dd-4645-b51a-60e1332aec61.png?v=1623766583", "added_at": "2023-04-17T23:01:39.726031", "updated_at": "2023-07-07T20:33:50.795961"}, 
    {"card_xid": "980d5f4e93ab1e90aef9632e0c447914dfed5fdaebe0e4cc461bf491cdb4abb6", "card_link": "https://www.shopjacqs.com/pages/faq", "card_title": "www.shopjacqs.com", "card_theme": null, "card_type": "image", "card_description": "\n   JACQ's - JACQ's Skincare FAQs | shopjacqs.com\n \u2013 Jacq's ", "icon_img_src": "", "link_img_src": "http://www.shopjacqs.com/cdn/shop/files/JacqsRGB.png?v=1647699401", "added_at": "2023-05-07T14:12:31.120890", "updated_at": "2023-07-07T20:33:55.900707"}, 
    {"card_xid": "b34ff6adce2eb6ee9372ff17bf0703e549369b7849ed644f2a2b843682947683", "card_link": "https://www.mentedcosmetics.com", "card_title": "www.mentedcosmetics.com", "card_theme": null, "card_type": "image", "card_description": "Mented Cosmetics | Pigment is our Passion", "icon_img_src": "https://www.mentedcosmetics.com/cdn/shop/files/m-favicon_32x32.png?v=1663083946", "link_img_src": "http://www.mentedcosmetics.com/cdn/shop/files/mented-logo_1200x628_pad_fff.png?v=1654967754", "added_at": "2023-06-08T09:53:41.330495", "updated_at": "2023-07-07T20:34:04.392147"}, 
    {"card_xid": "0ebaa2f52a5f595e40072fb64ad7132587fadf8538621af33e6ac309e8fa5c95", "card_link": "https://www.shopkaike.com/", "card_title": "www.shopkaike.com", "card_theme": null, "card_type": "image", "card_description": "\n      Skincare to Celebrate your Melanin-Rich Skin | KAIKE\n", "icon_img_src": "https://www.shopkaike.com/cdn/shop/files/Product_Set_Images_1.png?crop=center&height=32&v=1666327138&width=32", "link_img_src": "http://www.shopkaike.com/cdn/shop/files/My_project-1_4.png?height=628&pad_color=fff&v=1661147359&width=1200", "added_at": "2023-05-22T18:48:15.862067", "updated_at": "2023-07-07T20:35:26.745308"}, 
    {"card_xid": "dc438975cc66cbc69442b882302287fad7267cbd0f586a38cd1ad4ac8aa61062", "card_link": "https://nopigneva.com/", "card_title": "nopigneva.com", "card_theme": null, "card_type": "image", "card_description": "NoPigNeva Vegan Grocery Store | Vegan Groceries Online | NoPigNeva", "icon_img_src": "https://nopigneva.com/wp-content/uploads/2021/11/favicon.webp", "link_img_src": "http://37089274-9106-43c5-8dda-c2bb34f07e34.fs03.conves.io/wp-content/uploads/2021/07/Section2-1024x116.png", "added_at": "2023-05-07T04:40:20.476976", "updated_at": "2023-07-07T20:35:32.258304"}, 
    {"card_xid": "874da930ff318c7a532370b3bf382d4931d23c7b8dc4c5ba77c49e17fe1079d4", "card_link": "https://10millionblackveganwomen.org/", "card_title": "10millionblackveganwomen.org", "card_theme": null, "card_type": "image", "card_description": "Home - Join the 10 Million Black Vegan Women Movement", "icon_img_src": "https://10millionblackveganwomen.org/wp-content/uploads/2021/12/10MBVW_greenbox-300x300.png", "link_img_src": "https://10millionblackveganwomen.org/wp-content/uploads/2022/02/10MBVW_Logo.png", "added_at": "2023-04-02T01:45:14.621637", "updated_at": "2023-07-07T20:35:51.448329"}, 
    {"card_xid": "c6e7ac69adf4c03859e0ec86f87e368b07e99b8ac680cf482d5ae873a31c95a6", "card_link": "https://www.alpro.com/nl/", "card_title": "www.alpro.com", "card_theme": null, "card_type": "image", "card_description": "Home | Alpro", "icon_img_src": "https://www.alpro.com/apple-touch-icon.png?v=2", "link_img_src": "https://www.alpro.com/open-graph.jpg", "added_at": "2023-04-12T22:04:12.845261", "updated_at": "2023-07-07T20:36:06.526677"}, 
    {"card_xid": "5a3055933d9f48a8efd7ecee5eba030df6377e93bd26e29613c8afc07fba9945", "card_link": "https://bedda-world.com/", "card_title": "bedda-world.com", "card_theme": null, "card_type": "image", "card_description": "Vegane K\u00e4sealternativen & mehr | 100% vegan 100% du - bedda world", "icon_img_src": "https://bedda-world.com/wp-content/uploads/2022/01/cropped-beddicon-2-180x180.png", "link_img_src": "https://bedda-world.com/wp-content/uploads/2022/05/bedda-tasche-vegane-produkte-1024x1024.png", "added_at": "2023-06-03T14:08:21.447243", "updated_at": "2023-07-07T20:36:06.563075"}, 
    {"card_xid": "d68c41f671c9d3d2efa77305b0237134f6713108ab4f769070f3c0651bcf1d2b", "card_link": "https://www.beyondmeat.com/", "card_title": "www.beyondmeat.com", "card_theme": null, "card_type": "image", "card_description": "Vegan Burger Meat, Crumbles, & Sausages | Beyond Meat", "icon_img_src": "https://www.beyondmeat.com/en-US/icons/icon-512x512.png?v=35d839b70ffe8c6a3a6e8d98c03fc3e8", "link_img_src": null, "added_at": "2023-05-21T16:45:32.334711", "updated_at": "2023-07-07T20:36:06.664642"}, 
    {"card_xid": "ebc9abeabb0126c4f75a025c427de59f1a5b0530eac543f6a0e6342854350302", "card_link": "https://dandies.com/", "card_title": "dandies.com", "card_theme": null, "card_type": "image", "card_description": "Privacy error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-12T18:20:57.886321", "updated_at": "2023-07-07T20:36:06.708862"}, 
    {"card_xid": "427e6a424dc7326e252107486f2cc4757a37f32dc6eeff1c62dffaf3ef522151", "card_link": "https://daiyafoods.com/", "card_title": "daiyafoods.com", "card_theme": null, "card_type": "image", "card_description": "Daiya Foods. Find Your Happy Plate. No Dairy, Soy Or Gluten", "icon_img_src": "https://daiyafoods.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-11T12:20:41.928325", "updated_at": "2023-07-07T20:36:06.718825"}, 
    {"card_xid": "5a56debb868a4ec95edfe4b406a14d2579921b759d805aaa405bd91548621a41", "card_link": "https://www.eatreal.co.uk/", "card_title": "www.eatreal.co.uk", "card_theme": null, "card_type": "image", "card_description": "Home | Eat Real Snacks", "icon_img_src": "https://static.parastorage.com/client/pfavico.ico", "link_img_src": null, "added_at": "2023-05-06T08:47:30.554164", "updated_at": "2023-07-07T20:36:06.775609"}, 
    {"card_xid": "3a41aad41ec969f821d1562992f5b222322a61c5c9c5717710cf8205f7fb22c9", "card_link": "https://happy-cheeze.com/", "card_title": "happy-cheeze.com", "card_theme": null, "card_type": "image", "card_description": "\n      DrMannahs Happy Cheeze GmbH - Vegane K\u00e4sealternativen aus Cashew\n \u2013 Dr. Mannahs - Vegane K\u00e4se Alternativen", "icon_img_src": "https://cdn.shopify.com/s/files/1/0416/8463/1704/files/Logo_300x_0c698baa-e6b5-470c-86c5-b18cbecb7f26.png?crop=center&height=32&v=1644922173&width=32", "link_img_src": "http://cdn.shopify.com/s/files/1/0416/8463/1704/files/Logo_300x_0c698baa-e6b5-470c-86c5-b18cbecb7f26.png?height=628&pad_color=fff&v=1644922173&width=1200", "added_at": "2023-05-10T01:57:46.391340", "updated_at": "2023-07-07T20:36:06.788663"}, 
    {"card_xid": "800865dd197d38490ec98bae197f94f2d8169f14451f981dec70c04ce7774d8c", "card_link": "https://nurishhplantbased.com/", "card_title": "nurishhplantbased.com", "card_theme": null, "card_type": "image", "card_description": "Tasty Vegan Cheese | Nurishh Plant-Based Cheese", "icon_img_src": "https://nurishhplantbased.com/wp-content/uploads/2021/04/favicon-1.ico", "link_img_src": null, "added_at": "2023-04-25T12:36:14.100304", "updated_at": "2023-07-07T20:36:06.883934"}, 
    {"card_xid": "ed3cfcd253bc5cc20589f9f803b94c9e2a6c52fadae587067d41a89d2488a359", "card_link": "https://www.oatly.com/int/", "card_title": "www.oatly.com", "card_theme": null, "card_type": "image", "card_description": "Oatly I the Original Oat Drink Company ", "icon_img_src": "https://www.oatly.com/favicon.ico", "link_img_src": "https://a.storyblok.com/f/107921/1000x958/f599753c0a/fallback_logo.png", "added_at": "2023-05-30T18:46:16.713251", "updated_at": "2023-07-07T20:36:06.930747"}, 
    {"card_xid": "d74b689be2f2cda70571e05231a0f2744bab195150f29f50da44aaeadf9596a5", "card_link": "https://peasofheaven.com/", "card_title": "peasofheaven.com", "card_theme": null, "card_type": "image", "card_description": "Peas of Heaven", "icon_img_src": "https://peasofheaven.com/wordpress/wp-content/uploads/2022/10/cropped-favicon-180x180.png", "link_img_src": null, "added_at": "2023-06-04T23:11:34.243278", "updated_at": "2023-07-07T20:36:07.064871"}, 
    {"card_xid": "90df5eeaf2ccc97d213a2bfb5078b3cf939f95e465484667b4bf490b1fb08e48", "card_link": "https://petit-veganne.com/", "card_title": "petit-veganne.com", "card_theme": null, "card_type": "image", "card_description": "Production et vente de Sp\u00e9cialit\u00e9s V\u00e9g\u00e9tales - Petit Veganne", "icon_img_src": "https://petit-veganne.com/wp-content/themes/petit-veganne/images/icons/favicon.ico", "link_img_src": "https://petit-veganne.com/wp-content/uploads/2022/03/logo-petit-veganne-profil.jpg", "added_at": "2023-05-18T09:13:02.994225", "updated_at": "2023-07-07T20:36:07.099994"}, 
    {"card_xid": "a7023720682be1a31f7cdb6ab14c594c9dd68a0024e2b97fb30fb84eb7c6f26d", "card_link": "https://www.provamel.com/", "card_title": "www.provamel.com", "card_theme": null, "card_type": "image", "card_description": "Provamel | Plant-based organic foods", "icon_img_src": "https://www.provamel.com/sites/default/files/favicon_0.ico", "link_img_src": null, "added_at": "2023-04-21T17:33:23.885885", "updated_at": "2023-07-07T20:36:07.171084"}, 
    {"card_xid": "37bf54f244e27ca1ac0ae5264362bff33194eb855363bf8f62e558052e028b25", "card_link": "https://www.tofuture.com/", "card_title": "www.tofuture.com", "card_theme": null, "card_type": "image", "card_description": "Tofuture - Transforming Tofu from the bland to the fabulous", "icon_img_src": "https://www.tofuture.com/wp-content/uploads/2023/01/cropped-316551421_2513249885492114_363205042968926221_n-180x180.jpg", "link_img_src": "https://www.tofuture.com/wp-content/uploads/2020/08/Tofu-15-scaled.jpg", "added_at": "2023-04-13T15:59:56.729771", "updated_at": "2023-07-07T20:36:07.179108"}, 
    {"card_xid": "ab9e9546b5179e4f2deae67cc5c494ea9d9c09e0ab1fb550bbc4624e08fef945", "card_link": "https://tofurky.com/", "card_title": "tofurky.com", "card_theme": null, "card_type": "image", "card_description": "Tofurky: plant-based proteins, more than 35 non-gmo products", "icon_img_src": "https://tofurky.com/apple-icon-120x120.png", "link_img_src": null, "added_at": "2023-05-05T18:14:31.975461", "updated_at": "2023-07-07T20:36:07.217896"}, 
    {"card_xid": "48cf3223726337a125d165d416b64385a8e0aca4b8929629bc86f034017e4327", "card_link": "https://www.valsoia.it/", "card_title": "www.valsoia.it", "card_theme": null, "card_type": "image", "card_description": "Valsoia Bont\u00e0 e Salute: Alimenti Vegetali ", "icon_img_src": "https://www.valsoia.it/apple-touch-icon-144x144.png", "link_img_src": null, "added_at": "2023-05-04T14:23:19.894337", "updated_at": "2023-07-07T20:36:07.266774"}, 
    {"card_xid": "f6af9c7680bf67b6c91bbe50514d62e6be7473c7f2bc3377505bd32bb8f6c4b7", "card_link": "https://www.vantastic-foods.com/", "card_title": "www.vantastic-foods.com", "card_theme": null, "card_type": "image", "card_description": "Deutschlands gr\u00f6\u00dfter veganer Online-Shop | velivery.com", "icon_img_src": "https://www.velivery.com/media/unknown/eb/5a/cb/faviconuAShCCOBSYlbG.ico", "link_img_src": "https://www.velivery.com/media/image/28/6e/e0/logo_small.png", "added_at": "2023-05-19T11:25:19.225490", "updated_at": "2023-07-07T20:36:07.321593"}, 
    {"card_xid": "bd61c89f2df85f55297b7fe4071d804a6d71c6532fe507d557689fa9720cd538", "card_link": "https://myvega.com/", "card_title": "myvega.com", "card_theme": null, "card_type": "image", "card_description": "Vega | Plant-Based Protein Powder\n\u2013 Vega (US)", "icon_img_src": "https://cdn.shopify.com/s/files/1/2590/5380/files/Favicon_fb6af347-fdec-4dab-b2d8-3b61105e83b8_32x32.png?v=1641412165", "link_img_src": "https://cdn.shopify.com/s/files/1/2590/5380/files/Vega-Logo-Green-Export.png?height=628&pad_color=f4faf5&v=1642461657&width=1200", "added_at": "2023-04-25T06:08:27.386776", "updated_at": "2023-07-07T20:36:07.388923"}, 
    {"card_xid": "d7eda129ea743f1ed808fd3e56feef883522bafea26db975a0bbbb3dd0d38529", "card_link": "https://vegafit.com/", "card_title": "vegafit.com", "card_theme": null, "card_type": "image", "card_description": "Home - Vegafit", "icon_img_src": "https://vegafit.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-26T00:49:14.118101", "updated_at": "2023-07-07T20:36:07.500167"}, 
    {"card_xid": "e2a37a5f1922a753bd215e16246996aa6bb76147b4bd4bbd067e2de0d1ab5bc2", "card_link": "https://www.vegusto.ch/", "card_title": "www.vegusto.ch", "card_theme": null, "card_type": "image", "card_description": "Vegusto.ch: Vegane Genussmanufaktur seit 1997", "icon_img_src": "https://www.vegusto.ch/assets/ico/apple-touch-icon-114-precomposed.png", "link_img_src": null, "added_at": "2023-05-25T11:41:37.245729", "updated_at": "2023-07-07T20:36:07.519102"}, 
    {"card_xid": "6d00096b97976be8f84013c3bb410a5d42e5b8b2f03b8acc56792f1ebfff1824", "card_link": "https://violifefoods.com/", "card_title": "violifefoods.com", "card_theme": null, "card_type": "image", "card_description": "Vegan Alternative to Cheese - Dairy Free | Violife Foods", "icon_img_src": "https://violifefoods.com/wp-content/uploads/2021/04/cropped-violife_icon-180x180.png", "link_img_src": "https://violifefoods.com/wp-content/uploads/2017/05/Violife100perVegan.jpg", "added_at": "2023-06-14T12:37:22.789421", "updated_at": "2023-07-07T20:36:07.625316"}, 
]
const profile = {
    xid: '3322_849C_688B',
    name_key: 'vegan_brands',
    theme_color: '#a8d453', 
    title: 'Vegan Brands',
    img_src: 'https://cdn-icons-png.flaticon.com/512/1031/1031521.png?w=1060&t=st=1686881788~exp=1686882388~hmac=493bdfc4a54b44750a23013d4aa4b4bf73fd544d983bc11d5f741f7fdd2c9c69',
    description: [
        "List of vegan brands to try",
    ],
}
const author = {
    key: 'clay_owen',
    name: 'Clay Owen',
    img_src: 'https://img.freepik.com/free-photo/fun-3d-illustration-american-referee_183364-80776.jpg?w=1480&t=st=1686919604~exp=1686920204~hmac=3da499851855d8bc2c98a75ca9a3cca576d8214b83373ad0d19aae10438c0f8b',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
