const cards = [
    {"card_xid": "ac8ca2211fdf257cc4a7b03b4812de6c17875678b7bd2564b53e4c87e27f9a69", "card_link": "https://www.nytimes.com/games/wordle/index.html", "card_title": "www.nytimes.com", "card_theme": null, "card_type": "image", "card_description": "Wordle - The New York Times", "icon_img_src": "https://www.nytimes.com/games-assets/v2/assets/wordle/wordle_logo_192x192.png", "link_img_src": "https://www.nytimes.com/games-assets/v2/assets/wordle/wordle_og_1200x630.png", "added_at": "2023-04-12T01:09:41.067378", "updated_at": "2023-07-07T20:33:14.907209"}, 
    {"card_xid": "290b99b6fc7de23cc4da59c4be956b26d9c0cc4d34b1c5f74fbdf3a0a672e37a", "card_link": "https://www.jetpunk.com/tags/logo", "card_title": "www.jetpunk.com", "card_theme": null, "card_type": "image", "card_description": "Logo Quizzes", "icon_img_src": "https://www.jetpunk.com/apple-touch-icon.png", "link_img_src": "https://www.jetpunk.com/img/logo-without-text-large.png", "added_at": "2023-04-25T17:57:43.233317", "updated_at": "2023-07-07T20:33:14.911742"}, 
    {"card_xid": "065cde7b9a23c988a963b0cfdcd0d02705f28155eac7e98340ab684b29f32d35", "card_link": "https://www.spotify.com/heardle/?type=auto&amp;message=no-local-storage", "card_title": "www.spotify.com", "card_theme": null, "card_type": "image", "card_description": "Page not found - Spotify", "icon_img_src": "https://www.spotify.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-29T01:00:36.757913", "updated_at": "2023-07-07T20:33:14.967799"}, 
    {"card_xid": "ddda2e13938d1b2cdb7cc3bcc738da836d4ab4e91fc574fcf80953e102a327e4", "card_link": "https://www.buzzfeed.com/uk/quizzes", "card_title": "www.buzzfeed.com", "card_theme": null, "card_type": "image", "card_description": "BuzzFeed Quizzes", "icon_img_src": "https://www.buzzfeed.com/static-assets/img/touch-icon-ios_152.9f7df7b0b9e9f4a1ac41d9f97c3e1a1e.png", "link_img_src": "https://www.buzzfeed.com/static-assets/img/buzzfeed_arrow.e86a786d9e5e2250e1ed3e0ec95ba42d.png", "added_at": "2023-05-01T10:12:11.740551", "updated_at": "2023-07-07T20:33:14.991183"}, 
    {"card_xid": "1ae92ac3b068f3d97042361eee4a7d246a53846e066ac76af824d21f87f93855", "card_link": "https://playtictactoe.org/", "card_title": "playtictactoe.org", "card_theme": null, "card_type": "image", "card_description": "Tic-Tac-Toe - Play retro Tic-Tac-Toe online for free", "icon_img_src": "https://playtictactoe.org/assets/images/icon-100.png", "link_img_src": "https://playtictactoe.org/assets/images/icon-200.png", "added_at": "2023-05-12T08:24:19.290177", "updated_at": "2023-07-07T20:33:15.177001"}, 
    {"card_xid": "d7e8a20a9072724c39cc5cb19d979da0540e09fb66f92a39bfecd4276e3c5925", "card_link": "https://www.factslides.com/p-2", "card_title": "www.factslides.com", "card_theme": null, "card_type": "image", "card_description": "\u2190 FACTSlides \u2192 Random Facts Page #2", "icon_img_src": "https://www.factslides.com/imgs/favicon.png", "link_img_src": "https://www.factslides.com/imgs/ISHOTS/10755.png", "added_at": "2023-04-01T04:27:24.690103", "updated_at": "2023-07-07T20:33:15.186249"}, 
    {"card_xid": "a6ef84c38c11fd6ac6b51487dfd15a8a095611944a94dd28c11c23ba78e2afac", "card_link": "https://lagged.com/more-games/1479?gclid=EAIaIQobChMIhZ-_sdri-QIVZmgVCB1nZQQnEAEYASACEgI69_D_BwE", "card_title": "lagged.com", "card_theme": null, "card_type": "image", "card_description": "Online Games - Fun Games to Play", "icon_img_src": "https://lagged.com/images/icon/touch-icon-iphone.png", "link_img_src": "https://imgs2.dab3games.com/share-site.jpg", "added_at": "2023-06-01T16:17:16.992066", "updated_at": "2023-07-07T20:33:15.259254"}, 
    {"card_xid": "a5e87be64080a20fe5232789c504e782534a4734452fbf5816c9936146a69070", "card_link": "https://www.flightradar24.com/62.19,-56.96/2", "card_title": "www.flightradar24.com", "card_theme": null, "card_type": "image", "card_description": "Flightradar24: Live Flight Tracker - Real-Time Flight Tracker Map", "icon_img_src": "https://www.flightradar24.com/static/favicons/apple-icon-72x72.png?t=1687765382", "link_img_src": "https://images.flightradar24.com/opengraph/fr24_logo_default.png", "added_at": "2023-06-12T07:12:11.263512", "updated_at": "2023-07-07T20:33:15.271601"}, 
    {"card_xid": "e017c8a3c35959c3aa3f42eef72adcb5c72bcb08b28b0cb2a5bcdabfe64f2230", "card_link": "https://en.akinator.com/game", "card_title": "en.akinator.com", "card_theme": null, "card_type": "image", "card_description": "    Akinator, the mind reading genie\n", "icon_img_src": "https://en.akinator.com/apple-touch-icon.png?v95", "link_img_src": null, "added_at": "2023-04-25T21:13:45.408111", "updated_at": "2023-07-07T20:33:15.477236"}, 
    {"card_xid": "506380b90df36e29a8922fa0e1836ee45e19892132465c15dbf0430cdf2f06e3", "card_link": "https://www.earthcam.com/", "card_title": "www.earthcam.com", "card_theme": null, "card_type": "image", "card_description": "EarthCam - Webcam Network", "icon_img_src": "https://static.earthcam.com/touch-icon-ipad-retina.png", "link_img_src": "https://static.earthcam.com/images/socialnetworking/social-media-thumbnail.jpg?2", "added_at": "2023-04-25T11:23:09.205621", "updated_at": "2023-07-07T20:33:15.507485"}, 
    {"card_xid": "dba0b41663fd1ba8bdb482ce6859fc074940937e577c769de708ca7244d8c74c", "card_link": "https://profiletree.com/what-is-tiktok/", "card_title": "profiletree.com", "card_theme": null, "card_type": "image", "card_description": "What is TikTok? | Social Media Marketing Agency | ProfileTree", "icon_img_src": "https://profiletree.com/wp-content/uploads/2022/09/cropped-ProfileTree-Favicon-180x180.png", "link_img_src": "https://profiletree.com/wp-content/uploads/2021/02/what-is-tiktok-logo.jpg", "added_at": "2023-05-11T11:04:23.401128", "updated_at": "2023-07-07T20:33:15.590085"}, 
    {"card_xid": "39848683527b2e897a0612ef2ae28c6794b986a5a86e6fe62f81e77866346a82", "card_link": "https://virtualpiano.net/?song-post-26693", "card_title": "virtualpiano.net", "card_theme": null, "card_type": "image", "card_description": "Virtual Piano - Play The Best Online Piano Keyboard", "icon_img_src": "https://virtualpiano.net/wp-content/uploads/2021/02/cropped-android-chrome-512x512-1-180x180.png", "link_img_src": "https://virtualpiano.net/wp-content/uploads/2020/07/Online-Piano-Keyboard-Virtual-Piano.jpg", "added_at": "2023-04-15T19:11:45.111542", "updated_at": "2023-07-07T20:33:15.620321"}, 
    {"card_xid": "653ad37812811db568f43996395c96e64c5bb8c4ec2cf0e2595d5e9ed1d1b737", "card_link": "https://www.thewikigame.com/group", "card_title": "www.thewikigame.com", "card_theme": null, "card_type": "image", "card_description": "The Wiki Game - Wikipedia Game - Explore Wikipedia!", "icon_img_src": "https://www.thewikigame.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-12T09:05:18.039968", "updated_at": "2023-07-07T20:33:15.833163"}, 
    {"card_xid": "38a78dc120ccacb86c910706204e40482ed1a05fa11646bbb1e0644e9ff7616a", "card_link": "https://www.boredapi.com/", "card_title": "www.boredapi.com", "card_theme": null, "card_type": "image", "card_description": "Bored API", "icon_img_src": "https://www.boredapi.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-10T16:45:03.626964", "updated_at": "2023-07-07T20:33:15.839922"}, 
    {"card_xid": "7f2bd7d56cb93f27084b345db0573f5848f4c7b0192416bd7c32f2ff4d8e8639", "card_link": "https://quickdraw.withgoogle.com/", "card_title": "quickdraw.withgoogle.com", "card_theme": null, "card_type": "image", "card_description": "Quick, Draw!", "icon_img_src": "https://quickdraw.withgoogle.com/static/favicon-196.png", "link_img_src": "https://quickdraw.withgoogle.com/static/shareimg.png", "added_at": "2023-04-18T02:53:48.064221", "updated_at": "2023-07-07T20:33:15.845003"}, 
    {"card_xid": "19009691168eae2700a194e49c591c1aa86fb743fedf845cdc6666717afe20c3", "card_link": "https://www.poptropica.com/", "card_title": "www.poptropica.com", "card_theme": null, "card_type": "image", "card_description": "Play Poptropica", "icon_img_src": "https://www.poptropica.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-11T06:39:00.212128", "updated_at": "2023-07-07T20:33:15.871240"}, 
    {"card_xid": "2e26bf347d07fdc9a815d1c775d5f5722ea2e9c66b9eed1fe0c961d08691c5b3", "card_link": "https://www.lego.com/en-ie/games", "card_title": "www.lego.com", "card_theme": null, "card_type": "image", "card_description": "LEGO\u00ae video games for PC and console | Official LEGO\u00ae Shop IE ", "icon_img_src": "https://www.lego.com/static/favicon/lego-logo-120.png", "link_img_src": null, "added_at": "2023-04-22T13:46:49.188184", "updated_at": "2023-07-07T20:33:15.880092"}, 
    {"card_xid": "e4e17ba45c279ebe054398c0f993386845fa2a4004dbdb5df5f56179b489037f", "card_link": "https://www.sesamestreet.org/", "card_title": "www.sesamestreet.org", "card_theme": null, "card_type": "image", "card_description": "Sesame Street | Preschool Games, Videos, & Coloring Pages to Help Kids Grow Smarter, Stronger & Kinder", "icon_img_src": "https://www.sesamestreet.org/favicon.ico", "link_img_src": null, "added_at": "2023-05-26T21:51:15.733511", "updated_at": "2023-07-07T20:33:15.934047"}, 
    {"card_xid": "8f5f80a57b01dd328f0c071259dc149ae2768dba0d48424402955db4e8ed2ef7", "card_link": "https://kids.nationalgeographic.com/games/", "card_title": "kids.nationalgeographic.com", "card_theme": null, "card_type": "image", "card_description": "Games", "icon_img_src": "https://assets-cdn.nationalgeographic.com/natgeo/static/icons/favicon-kids-32x32.png", "link_img_src": "https://i.natgeofe.com/k/8971d87d-2c3f-470a-aef3-8802c0a9ec79/gameshub_3_2_16x9.jpg?w=1200", "added_at": "2023-06-12T19:22:54.038806", "updated_at": "2023-07-07T20:33:15.974868"}, 
    {"card_xid": "546c9d60381cab75727ad0cf9e02ab051d006c75a378722d23c896403975da8e", "card_link": "https://poki.com/", "card_title": "poki.com", "card_theme": null, "card_type": "image", "card_description": "Online Games on Poki \u2014 Let's play", "icon_img_src": "https://a.poki.com/icons/fav-512.png", "link_img_src": "https://a.poki.com/icons/twitter2x.png", "added_at": "2023-04-26T14:17:39.584648", "updated_at": "2023-07-07T20:33:16.113291"}, 
    {"card_xid": "2a31954a22f6abb60c0ce504cbb608f5d6caa19b5e357dde3c9c60ca983fdd19", "card_link": "https://www.cookinggames.com/", "card_title": "www.cookinggames.com", "card_theme": null, "card_type": "image", "card_description": "Cooking Games - Play Free Cooking and Baking Games", "icon_img_src": "https://assets.cookinggames.com/images/favicon.svg", "link_img_src": "https://assets.cookinggames.com/images/ogImage.png", "added_at": "2023-04-13T22:35:16.671945", "updated_at": "2023-07-07T20:33:16.170627"}, 
    {"card_xid": "ee3d0e99338e235f63f76d05d5a54717f3a2720c4bbde2aed0b428f8c9dff054", "card_link": "https://classic.minecraft.net/?join=HJXm-wPSgKWpRFE-", "card_title": "classic.minecraft.net", "card_theme": null, "card_type": "image", "card_description": "Minecraft Classic", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-06T05:05:16.834692", "updated_at": "2023-07-07T20:33:16.222898"}, 
    {"card_xid": "03f4597ae5780dfa8df594d86fc99e89f47d40e0e3f1cb44d750bbd857c5e280", "card_link": "https://www.cartoonnetworkhq.com/games", "card_title": "www.cartoonnetworkhq.com", "card_theme": null, "card_type": "image", "card_description": "Cartoon Network Games | Free Kids Games | Online Games for Kids", "icon_img_src": "https://toon-int-images.akamaized.net/toonla/images/cnlatam/site/static-3.1.91.1/img/apple-touch-icon-60x60.png", "link_img_src": "https://tbsila.cdn.turner.com/toonla/images/cnlatam/site/static-3.1.91.1/img/cnlogo_social.png", "added_at": "2023-05-12T07:25:06.460150", "updated_at": "2023-07-07T20:33:16.225987"}, 
    {"card_xid": "03f4597ae5780dfa8df594d86fc99e89f47d40e0e3f1cb44d750bbd857c5e280", "card_link": "https://www.cartoonnetworkhq.com/games", "card_title": "www.cartoonnetworkhq.com", "card_theme": null, "card_type": "image", "card_description": "Cartoon Network Games | Free Kids Games | Online Games for Kids", "icon_img_src": "https://toon-int-images.akamaized.net/toonla/images/cnlatam/site/static-3.1.91.1/img/apple-touch-icon-60x60.png", "link_img_src": "https://tbsila.cdn.turner.com/toonla/images/cnlatam/site/static-3.1.91.1/img/cnlogo_social.png", "added_at": "2023-05-12T07:25:06.460150", "updated_at": "2023-07-07T20:33:16.274562"}, 
    {"card_xid": "0172ccd443d01db120da4af8f2bc37dcaf91c218ce48ac0000b4578c38ff8721", "card_link": "https://www.playphrase.me/#/search", "card_title": "www.playphrase.me", "card_theme": null, "card_type": "image", "card_description": "PlayPhrase.me: Site for cinema archaeologists.", "icon_img_src": "https://www.playphrase.me/img/app-icons/ios/152.png", "link_img_src": null, "added_at": "2023-05-15T21:28:04.167482", "updated_at": "2023-07-07T20:33:16.321270"}, 
    {"card_xid": "05d708e59b4f19c22944b31668b66db3de2fc2b27d13d430c4a7492665cd6f18", "card_link": "https://www.geoguessr.com/", "card_title": "www.geoguessr.com", "card_theme": null, "card_type": "image", "card_description": "GeoGuessr - Let's explore the world!", "icon_img_src": "https://www.geoguessr.com/_next/static/images/geoguessr-57-e3554d29cf9a410853ee28063965a2ba.png", "link_img_src": "https://www.geoguessr.com/_next/static/images/default-231f6f91e41050bfcac84aa3009be3fe.png", "added_at": "2023-06-10T10:23:21.380870", "updated_at": "2023-07-07T20:33:16.354404"}, 
    {"card_xid": "f7a8c4a4b8d8c8df7f3eb3603712d7108abc5fbbbc844594901b8b32330f251c", "card_link": "https://www.geo-fs.com/", "card_title": "www.geo-fs.com", "card_theme": null, "card_type": "image", "card_description": "GeoFS - Free Online Flight Simulator", "icon_img_src": "https://www.geo-fs.com/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-05-23T23:12:54.947777", "updated_at": "2023-07-07T20:33:16.375788"}, 
    {"card_xid": "773c7553ad910b27ebf45e94525da34e91f012ddf93f3f0415fe5dbefc8caf14", "card_link": "https://emupedia.net/beta/emuos/", "card_title": "emupedia.net", "card_theme": null, "card_type": "image", "card_description": "EmuOS v1.0", "icon_img_src": "https://emupedia.net/beta/emuos/assets/images/logos/emupedia/emupedia-logo-114.png", "link_img_src": "https://emupedia.net/beta/emuos/assets/images/logos/emupedia/emupedia-logo-256.png", "added_at": "2023-04-24T15:13:12.704278", "updated_at": "2023-07-07T20:33:16.396958"}, 
    {"card_xid": "9228997a5bc254d4472f8d10e839a775d0c2d7811b105ea2adbd843f1f66aa8c", "card_link": "https://neal.fun/lets-settle-this/", "card_title": "neal.fun", "card_theme": null, "card_type": "image", "card_description": "Let's Settle This", "icon_img_src": "https://neal.fun/favicons/lets-settle-this.png", "link_img_src": "https://neal.fun/share-cards/lets-settle-this.png", "added_at": "2023-04-23T04:24:48.991814", "updated_at": "2023-07-07T20:33:16.417857"}, 
    {"card_xid": "0223f3dbc9678486cbdae25e5f18105b2a124d16aa4dda007b405fca0c96454e", "card_link": "https://sandspiel.club/", "card_title": "sandspiel.club", "card_theme": null, "card_type": "image", "card_description": "sandspiel", "icon_img_src": "https://sandspiel.club/assets/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-09T08:18:50.834961", "updated_at": "2023-07-07T20:33:16.451798"}, 
    {"card_xid": "33a66d1379d5a760eb4582e1c01f50017d3cee558eaebe69a32ec4e3e5336913", "card_link": "https://kuku-kube.com/", "card_title": "kuku-kube.com", "card_theme": null, "card_type": "image", "card_description": "Kuku Kube", "icon_img_src": "https://kuku-kube.com/apple-icon-144x144.png", "link_img_src": "https://kuku-kube.com/kuku-kube-logo.jpg", "added_at": "2023-05-15T02:48:29.123456", "updated_at": "2023-07-07T20:33:16.463304"}, 
    {"card_xid": "549011403526d52ca95ba5f35e8a73ad240255e7f4fa2d631e8a37656b28d3ad", "card_link": "https://vole.wtf/perfect-circle/", "card_title": "vole.wtf", "card_theme": null, "card_type": "image", "card_description": "Draw a Perfect Circle \u2b55\ufe0f\ud83d\udcaf", "icon_img_src": "https://neal.fun/favicons/perfect-circle.png", "link_img_src": "https://neal.fun/share-cards/perfect-circle.png", "added_at": "2023-05-08T18:00:17.106244", "updated_at": "2023-07-07T20:33:16.472019"}, 
    {"card_xid": "d17851ddcf183ef3c38bd7ab35639f1e6d999f8d65298e93950735c3a160f4ef", "card_link": "https://www.cbc.ca/kids/games/play/color-pipes", "card_title": "www.cbc.ca", "card_theme": null, "card_type": "image", "card_description": "Play Color Pipes | Games | CBC Kids", "icon_img_src": "https://www.cbc.ca/favicon.ico", "link_img_src": "https://www.cbc.ca/kids/images/colorpipes_thumb.jpeg", "added_at": "2023-05-23T00:48:50.886436", "updated_at": "2023-07-07T20:33:16.481550"}, 
    {"card_xid": "a0eb679367e3d7f556b3cc9ed13f810deeda19b262aafa8a053bfbc33ec67d4a", "card_link": "https://www.connollycove.com/category/the-world/", "card_title": "www.connollycove.com", "card_theme": null, "card_type": "image", "card_description": "The World - ConnollyCove", "icon_img_src": "https://www.connollycove.com/wp-content/uploads/2022/01/ccFav.png", "link_img_src": "https://www.connollycove.com/wp-content/uploads/2022/10/Cushendun-Caves-ConnollyCove-scaled.jpg", "added_at": "2023-06-09T05:09:30.293458", "updated_at": "2023-07-07T20:33:16.490889"}, 
]
const profile = {
    xid: '8C28_85D3_9F00',
    name_key: 'cure_boredom_2',
    theme_color: '#13b1f2', 
    title: 'Cure Boredom',
    img_src: 'https://img.freepik.com/free-vector/hand-drawn-happy-black-woman-illustration_23-2149404964.jpg?w=1800&t=st=1687167942~exp=1687168542~hmac=5ce480c6f8d773c79d79d40e96b36a50d2977a901921dc6a70d3dba33ee89ff7',
    description: [
        "There's a lot of content out there about productivity",
        "What about those times when you're bored?",
    ],
}
const author = {
    key: 'greg_spiess',
    name: 'Greg Spiess',
    img_src: 'https://img.freepik.com/free-vector/cute-boy-standing-position-showing-thumb_96037-450.jpg?w=1480&t=st=1686919407~exp=1686920007~hmac=442c5a5ff6f4e931c2f0a97781c254de39ec48eb8731b211bb7024d9f34edc1b',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
