



let NAME_MAP = {
  facebook:  {name: 'Facebook', cta_name: 'Connect',},
  twitter:  {name: 'Twitter',  cta_name: 'Follow',},
  youtube:  {name: 'YouTube',  cta_name: 'Subscribe',},
  instagram:  {name: 'Instagram',  cta_name: 'Connect',},
  google:  {name: 'Google', cta_name: 'Connect',},
  pinterest:  {name: 'Pinterest',  cta_name: 'Connect',},
  linkedin:  {name: 'LinkedIn', cta_name: 'Connect',},
  vimeo:  {name: 'Vimeo',  cta_name: 'Connect',},
  tumblr:  {name: 'Tumblr', cta_name: 'Connect',},
  snapchat:  {name: 'SnapChat', cta_name: 'Connect',},
  whatsapp:  {name: 'WhatsApp', cta_name: 'Connect',},
  mastodon:  {name: 'Mastodon', cta_name: 'Connect',},
  apple:  {name: 'Apple',  cta_name: 'Connect',},
  amazon:  {name: 'Amazon', cta_name: 'Connect',},
  microsoft:  {name: 'Microsoft',  cta_name: 'Connect',},
  periscope:  {name: 'Periscope',  cta_name: 'Connect',},
  foursquare:  {name: 'FourSquare', cta_name: 'Connect',},
  swarm:  {name: 'Swarm',  cta_name: 'Connect',},
  medium:  {name: 'Medium', cta_name: 'Connect',},
  skype:  {name: 'Skype',  cta_name: 'Connect',},
  android:  {name: 'Android',  cta_name: 'Connect',},
  stumbleupon:  {name: 'StumbleUpon',  cta_name: 'Connect',},
  flickr:  {name: 'Flickr', cta_name: 'Connect',},
  yahoo:  {name: 'Yahoo',  cta_name: 'Connect',},
  twitch:  {name: 'Twitch', cta_name: 'Connect',},
  soundcloud:  {name: 'SoundCloud', cta_name: 'Connect',},
  spotify:  {name: 'Spotify',  cta_name: 'Connect',},
  dribbble:  {name: 'Dribbble', cta_name: 'Connect',},
  slack:  {name: 'Slack',  cta_name: 'Connect',},
  reddit:  {name: 'Reddit', cta_name: 'Connect',},
  deviantart:  {name: 'DeviantArt', cta_name: 'Connect',},
  pocket:  {name: 'Pocket', cta_name: 'Connect',},
  quora:  {name: 'Quora',  cta_name: 'Connect',},
  slideshare:  {name: 'SlideShare', cta_name: 'Connect',},
  fivehundredpx:  {name: 'FiveHundredPX',  cta_name: 'Connect',},
  vine:  {name: 'Vine', cta_name: 'Connect',},
  steam:  {name: 'Steam',  cta_name: 'Connect',},
  discord:  {name: 'Discord',  cta_name: 'Connect',},
  telegram:  {name: 'Telegram', cta_name: 'Connect',},
  clarity:  {name: 'Clarity',  cta_name: 'Connect',},
}




let THEME_MAP = {
  facebook: ['facebook', 'facebookold', ],
  twitter: ['twitter', ],
  youtube: ['youtube', ],
  instagram: ['instagram', 'instagrammagenta', 'instagramblue', 'instagrampurple', 'instagramorange', 'instagramyellow', ],
  google: ['googleblue', 'googlered', 'googleyellow', 'googlegreen', 'googleplus', ],
  pinterest: ['pinterest', ],
  linkedin: ['linkedin', ],
  vimeo: ['vimeoblue', ],
  tumblr: ['tumblr', ],
  snapchat: ['snapchat', ],
  whatsapp: ['whatsappgreen', 'whatsappteal1', 'whatsappteal2', ],
  mastodon: ['mastodon', ],
  apple: ['apple', ],
  amazon: ['amazon', ],
  microsoft: ['microsoftred', 'microsoftgreen', 'microsoftblue', 'microsoftyellow', ],
  periscope: ['periscope', ],
  foursquare: ['foursquarepink', 'foursquarenavy', 'foursquareblue', ],
  swarm: ['swarm', ],
  medium: ['medium', ],
  skype: ['skypeblue', 'skypedkblue', ],
  android: ['android', ],
  stumbleupon: ['stumbleupon', ],
  flickr: ['flickrpink', 'flickrblue', ],
  yahoo: ['yahoo', ],
  twitch: ['twitch', ],
  soundcloud: ['soundcloud', ],
  spotify: ['spotifygreen', 'spotifydarkgreen', ],
  dribbble: ['dribbble', ],
  slack: ['slackpurple', 'slackblue', 'slackgreen', 'slackred', 'slackyellow', ],
  reddit: ['reddit', ],
  deviantart: ['deviantart', ],
  pocket: ['pocket', ],
  quora: ['quora', 'quorablue', ],
  slideshare: ['slideshareorange', 'slideshareblue', ],
  fivehundredpx: ['fivehundredpx', ],
  vk: ['vk', ],
  listlyorange: ['listlyorange', 'listlyblue', ],
  vine: ['vine', ],
  steam: ['steam', ],
  discord: ['discord', ],
  telegram: ['telegram', ],
  clarity: ['clarity', ],
}

let REV_THEME_MAP = function(){
  let return_map = {};
  Object.keys(THEME_MAP).forEach(function(key, idx){
    let names = THEME_MAP[key];
    names.forEach((name, yidx) => return_map[name] = key);
  })
  return return_map;
}();


let THEME_ICON = {
  facebook: 'fab fa-facebook',
  twitter: 'fab fa-twitter',
  youtube: 'fab fa-youtube',
  instagram: 'fab fa-instagram',
  google: 'fab fa-google',
  pinterest: 'fab fa-pinterest',
  linkedin: 'fab fa-linkedin',
  vimeo: 'fab fa-vimeo',
  tumblr: 'fab fa-tumblr',
  snapchat: 'fab fa-snapchat',
  whatsapp: 'fab fa-whatsapp',
  apple: 'fab fa-apple',
  amazon: 'fab fa-amazon',
  microsoft: 'fab fa-microsoft',
  foursquare: 'fab fa-foursquare',
  medium: 'fab fa-medium',
  skype: 'fab fa-skype',
  android: 'fab fa-android',
  stumbleupon: 'fab fa-stumbleupon',
  flickr: 'fab fa-flickr',
  yahoo: 'fab fa-yahoo',
  twitch: 'fab fa-twitch',
  soundcloud: 'fab fa-soundcloud',
  spotify: 'fab fa-spotify',
  dribbble: 'fab fa-dribbble',
  slack: 'fab fa-slack',
  reddit: 'fab fa-reddit',
  deviantart: 'fab fa-deviantart',
  pocket: 'fab fa-pocket',
  quora: 'fab fa-quora',
  discord: 'fab fa-discord',
  telegram: 'fab fa-telegram',
}


let THEME_COLOR = {
  white:    '#fff',
  gray100: '#f8f9fa',
  gray200: '#ecf0f1',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#b4bcc2',
  gray600: '#95a5a6',
  gray700: '#7b8a8b',
  gray800: '#343a40',
  gray900: '#212529',
  black:    '#000',
  softblack: '#333333',

  blue:    '#2C3E50',
  indigo:  '#6610f2',
  purple:  '#6f42c1',
  pink:    '#e83e8c',
  red:     '#E74C3C',
  orange:  '#fd7e14',
  yellow:  '#F39C12',
  green:   '#18BC9C',
  teal:    '#20c997',
  cyan:    '#3498DB',

  facebook: '#1877f2',
  facebookold: '#3b5998',
  twitter: '#1da1f2',
  youtube: '#ff0000',
  instagram: '#c32aa3',
  instagrammagenta: '#c32aa3',
  instagramblue: '#4c5fd7',
  instagrampurple: '#7232bd',
  instagramorange: '#f46f30',
  instagramyellow: '#ffdc7d',
  googleblue: '#4285f4',
  googlered: '#ea4335',
  googleyellow: '#fbbc05',
  googlegreen: '#34a853',
  pinterest: '#bd081c',
  googleplus: '#db4437',
  linkedin: '#007bb5',
  vimeoblue: '#1ab7ea',
  tumblr: '#2c4762',
  snapchat: '#fffc00',
  whatsappgreen: '#25d366',
  whatsappteal1: '#075e54',
  whatsappteal2: '#128c7e',
  mastodon: '#2b90d9',
  apple: '#a6b1b7',
  amazon: '#ff9900',
  microsoftred: '#f35022',
  microsoftgreen: '#80bb03',
  microsoftblue: '#03a5f0',
  microsoftyellow: '#ffb903',
  periscope: '#40a4c4',
  foursquarepink: '#f94877',
  foursquarenavy: '#073282',
  foursquareblue: '#2d5be3',
  swarm: '#ffa633',
  medium: '#02b875',
  skypeblue: '#00aff0',
  skypedkblue: '#0078d7',
  android: '#a4c639',
  stumbleupon: '#e94826',
  flickrpink: '#f40083',
  flickrblue: '#006add',
  yahoo: '#430297',
  twitch: '#6441a4',
  soundcloud: '#ff5500',
  spotifygreen: '#1ed760',
  spotifydarkgreen: '#1db954',
  dribbble: '#ea4c89',
  slack: '#4a154b',
  slackpurple: '#4a154b',
  slackblue: '#36c5f0',
  slackgreen: '#2eb67d',
  slackred: '#e01e5a',
  slackyellow: '#ecb22e',
  reddit: '#ff5700',
  deviantart: '#05cc47',
  pocket: '#ee4056',
  quora: '#aa2200',
  quorablue: '#2b6dad',
  slideshareorange: '#e68523',
  slideshareblue: '#00a0dc',
  fivehundredpx: '#0099e5',
  vk: '#4a76a8',
  listlyorange: '#df6d46',
  listlyblue: '#52b1b3',
  vine: '#00b489',
  steam: '#171a21',
  discord: '#7289da',
  telegram: '#0088cc',
  clarity: '#61bed9',

}



export function getThemeDict(theme){

  const mapped_theme = REV_THEME_MAP[theme];
  const caption = NAME_MAP[mapped_theme];

  return {
    color: THEME_COLOR[theme] || THEME_COLOR[mapped_theme],
    icon: THEME_ICON[theme] || THEME_ICON[mapped_theme],
    caption: NAME_MAP[theme] || caption,
  }
}
