import {DATA  as _6CAB_98F6_3CC3} from './6CAB_98F6_3CC3';
import {DATA  as _7070_A736_3F2B} from './7070_A736_3F2B';
import {DATA  as _6660_AE44_0C29} from './6660_AE44_0C29';
import {DATA  as _5BD3_DF0A_BF2A} from './5BD3_DF0A_BF2A';
import {DATA  as _59D8_5F55_E010} from './59D8_5F55_E010';
import {DATA  as _AD55_5673_BF34} from './AD55_5673_BF34';
import {DATA  as _ADA6_A241_1AE1} from './ADA6_A241_1AE1';
import {DATA  as _956A_C812_DFE2} from './956A_C812_DFE2';
import {DATA  as _FF01_1365_D9C2} from './FF01_1365_D9C2';
import {DATA  as _EFFC_3AB0_7CB9} from './EFFC_3AB0_7CB9';
import {DATA  as _DDEF_9D1E_644E} from './DDEF_9D1E_644E';
import {DATA  as _2EF7_D9E9_4973} from './2EF7_D9E9_4973';
import {DATA  as _8C28_85D3_9F00} from './8C28_85D3_9F00';
import {DATA  as _19E0_B375_5E24} from './19E0_B375_5E24';
import {DATA  as _A310_8E89_CE52} from './A310_8E89_CE52';
import {DATA  as _6739_7132_D29B} from './6739_7132_D29B';
import {DATA  as _382D_2BD9_CA60} from './382D_2BD9_CA60';
import {DATA  as _1C2B_D203_0FA0} from './1C2B_D203_0FA0';
import {DATA  as _E07F_1AA1_0A9D} from './E07F_1AA1_0A9D';
import {DATA  as _3322_849C_688B} from './3322_849C_688B';
import {DATA  as _3D59_4C6F_4A25} from './3D59_4C6F_4A25';
import {DATA  as _B24C_8BBB_A403} from './B24C_8BBB_A403';
import {DATA  as _6179_B5AD_2201} from './6179_B5AD_2201';
import {DATA  as _AD15_5806_687B} from './AD15_5806_687B';
import {DATA  as _545F_1A0B_5B1F} from './545F_1A0B_5B1F';
import {DATA  as _C38E_93C4_B8AC} from './C38E_93C4_B8AC';
import {DATA  as cards} from './cards';

export const LISK_MAP = {
    'cards': cards,
    '6CAB_98F6_3CC3': _6CAB_98F6_3CC3,
    '7070_A736_3F2B': _7070_A736_3F2B,
    '6660_AE44_0C29': _6660_AE44_0C29,
    '5BD3_DF0A_BF2A': _5BD3_DF0A_BF2A,
    '59D8_5F55_E010': _59D8_5F55_E010,
    'AD55_5673_BF34': _AD55_5673_BF34,
    'ADA6_A241_1AE1': _ADA6_A241_1AE1,
    '956A_C812_DFE2': _956A_C812_DFE2,
    'FF01_1365_D9C2': _FF01_1365_D9C2,
    'EFFC_3AB0_7CB9': _EFFC_3AB0_7CB9,
    'DDEF_9D1E_644E': _DDEF_9D1E_644E,
    '2EF7_D9E9_4973': _2EF7_D9E9_4973,
    '8C28_85D3_9F00': _8C28_85D3_9F00,
    '19E0_B375_5E24': _19E0_B375_5E24,
    'A310_8E89_CE52': _A310_8E89_CE52,
    '6739_7132_D29B': _6739_7132_D29B,
    '382D_2BD9_CA60': _382D_2BD9_CA60,
    '1C2B_D203_0FA0': _1C2B_D203_0FA0,
    'E07F_1AA1_0A9D': _E07F_1AA1_0A9D,
    '3322_849C_688B': _3322_849C_688B,
    '3D59_4C6F_4A25': _3D59_4C6F_4A25,
    'B24C_8BBB_A403': _B24C_8BBB_A403,
    '6179_B5AD_2201': _6179_B5AD_2201,
    'AD15_5806_687B': _AD15_5806_687B,
    '545F_1A0B_5B1F': _545F_1A0B_5B1F,
    'C38E_93C4_B8AC': _C38E_93C4_B8AC,
}
export const LISK_NAME_MAP = {
    'cards': cards,
    'kill_time_sites': _6CAB_98F6_3CC3,
    'fun_websites': _7070_A736_3F2B,
    'api_animals': _6660_AE44_0C29,
    'api_books': _5BD3_DF0A_BF2A,
    'api_random': _59D8_5F55_E010,
    'api_games_comics': _AD55_5673_BF34,
    'popular_android_games': _ADA6_A241_1AE1,
    'popular_fonts': _956A_C812_DFE2,
    'marketing_tools': _FF01_1365_D9C2,
    'health_fitness_vids': _EFFC_3AB0_7CB9,
    'interesting_plays': _DDEF_9D1E_644E,
    'company_comparisons': _2EF7_D9E9_4973,
    'cure_boredom_2': _8C28_85D3_9F00,
    'cure_boredom': _19E0_B375_5E24,
    'awesome_websites_list': _A310_8E89_CE52,
    'kill_time_websites': _6739_7132_D29B,
    'design_inspirations': _382D_2BD9_CA60,
    'text_to_image': _1C2B_D203_0FA0,
    'motivation5min': _E07F_1AA1_0A9D,
    'vegan_brands': _3322_849C_688B,
    'programming_motivations': _3D59_4C6F_4A25,
    'service_architecture': _B24C_8BBB_A403,
    'moocs': _6179_B5AD_2201,
    'colour_tools': _AD15_5806_687B,
    'generated': _545F_1A0B_5B1F,
    'zen_habits': _C38E_93C4_B8AC,
}
export const authors = [
    {"name": "ByteBeacon - Ras", "description": ["building bytebeacon"], "img_src": "https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg", "key": "bb_ras"}, 
    {"name": "Greg Spiess", "description": ["Designer who loves AI, Nature & Wildlife.", "Living in San Francisco, CA"], "img_src": "https://img.freepik.com/free-vector/cute-boy-standing-position-showing-thumb_96037-450.jpg?w=1480&t=st=1686919407~exp=1686920007~hmac=442c5a5ff6f4e931c2f0a97781c254de39ec48eb8731b211bb7024d9f34edc1b", "key": "greg_spiess"}, 
    {"name": "Johnni Coutinho", "description": ["UIUX Designer", "Illustrator and Video creator."], "img_src": "https://img.freepik.com/premium-photo/cute-baby-cat_155807-11607.jpg?w=1060", "key": "johnni_coutinho"}, 
    {"name": "Clay Owen", "description": ["Portland, Oregon", "Product Designer & Software Engineer", "Canadian"], "img_src": "https://img.freepik.com/free-photo/fun-3d-illustration-american-referee_183364-80776.jpg?w=1480&t=st=1686919604~exp=1686920204~hmac=3da499851855d8bc2c98a75ca9a3cca576d8214b83373ad0d19aae10438c0f8b", "key": "clay_owen"}, 
    {"name": "Nitish Sundar", "description": ["generalist designer", "making beautiful things and helping others do the same."], "img_src": "https://img.freepik.com/free-photo/3d-cartoon-business-character_1048-16473.jpg?w=1060&t=st=1686919498~exp=1686920098~hmac=ff50423dcb7c4dbe4362c3e03f9d47f2ddf1da90e8a5f52a49b2a3c310144bf3", "key": "nitish_sundar"}, 
    {"name": "Amit Rajput", "description": ["Product Designer ", "Product designer and thoughtful collaborator", "San Francisco, CA"], "img_src": "https://img.freepik.com/free-photo/fun-3d-cartoon-illustration-indian-businessman_183364-114475.jpg?w=740&t=st=1686919588~exp=1686920188~hmac=e4fee9a9d3325b74960167c43886a3d9ca5bbe025d31b4c4766d37b75ea2bbdc", "key": "amit_rajput"}, 
    {"name": "Jared Feroldi", "description": ["Product designer (UX/UI/research) by day", "self-taught doodler and DIY lover by night."], "img_src": "https://img.freepik.com/free-vector/illustrator-designer-man-holding-digital-tablet_107791-12062.jpg?w=1060&t=st=1686919549~exp=1686920149~hmac=dcd3fa17081d31ea69e01cad76e468d5152040978fd7c32ac5f9379ed577fd74", "key": "jared_feroldi"}, 
    {"name": "Cloe Team", "description": ["Cloe team page"], "img_src": "/logo.png", "key": "cloe_team"}, 
    {"name": "All APIs", "description": ["Best Public APIs for Testing"], "img_src": "https://img.freepik.com/free-vector/application-programming-interface-concept-illustration_114360-9135.jpg?w=1060&t=st=1687440423~exp=1687441023~hmac=12830d60883b3aac174f89be583e4fec8d45286c3dfe6b3f6988f8288e0d6ce1", "key": "api_store"}, 
    {"name": "The Infographics Show ", "description": ["We focuses on making animated motion infographic videosFacts are fun, but most are presented in boring and badly edited videos."], "img_src": "https://yt3.googleusercontent.com/ytc/AGIKgqOkssb2Z_x4t6jYyJndF0SnVlRzwOmSz8kDAZqRew=s176-c-k-c0x00ffffff-no-rj", "key": "the_infographics_show"}, 
    {"name": "Zen Habits", "description": ["Life-changing live experiences"], "img_src": "https://pbs.twimg.com/profile_images/514865734816509952/k7puRwOr_400x400.jpeg", "key": "zen_habits"}, 
    {"name": "Non Stop Music", "description": ["Music sources"], "img_src": "https://img.freepik.com/free-psd/headphone-headset-icon-isolated-3d-render-illustration_439185-11402.jpg?w=1060&t=st=1686919131~exp=1686919731~hmac=43aede423fec3f057a2416dde8296cccd398732f4e91f8ac590202d3e3003596", "key": "non_stop_listening"}, 
    {"name": "Guitar tutorials", "description": ["Guitar lesson series that will teach you how to play"], "img_src": "https://img.freepik.com/free-vector/acoustic-guitar-concept-illustration_114360-12608.jpg?w=1060&t=st=1686919091~exp=1686919691~hmac=172eaade14202992d543fd7a796b6b2d5cae6012ba50c61d1d09cccff648916f", "key": "guitar_tuts"}, 
    {"name": "Chef Box", "description": ["Find thousands of recipes, tips, tricks and videos from the best chefs cooking"], "img_src": "https://img.freepik.com/premium-vector/cartoon-chef-holding-silver-platter_29190-8165.jpg?w=826", "key": "chef_box"}, 
    {"name": "Short Movies", "description": [], "img_src": "https://img.freepik.com/free-vector/flat-clapperboard-icon_1063-38.jpg?w=1060&t=st=1686918943~exp=1686919543~hmac=e2d66c480e99417fe7341d677f3a3aa17afd7294d10e01205d42edd7baf70eb2", "key": "pop_shorts"}, 
    {"name": "Socratica", "description": ["Learn More about Math, Science, and Programming "], "img_src": "https://img.freepik.com/free-photo/landscape-with-old-tree-silhouette-against-red-moonlit-sky_1048-11611.jpg?w=1480&t=st=1686918867~exp=1686919467~hmac=0c3922a0de37f8042f2f47a33f333b0e483e992b6836ce6278adf509546b135f", "key": "socratica"}, 
]
