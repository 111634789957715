export const PROFILE = {
  "contact": {
    "twitter": "https://twitter.com/bytebeacon",
    "linkedin": "https://www.linkedin.com/company/bytebeacon",
    "github": "https://github.com/bytebeacon",
    "facebook": "https://www.facebook.com/ByteBeacon-109146270805201",
// // "https://www.quora.com/profile/"+contact.quora
// // "https://www.instagram.com/"+contact.instagram
// // "https://hub.docker.com/r/"+contact.docker
  }
};



export const SITE = {
  url: "https://cloe.bytebeacon.com",
  title: "Cloe | Collect and showcase all your links",
  description: "The place to collect and share all your links with the world.",
  logo: "https://bytebeacon.com/logo.png",
}




export const PRODUCT = {
  general: 'primary',
  admin: 'quaternary',
}


const _CLASS = {
  theme: PRODUCT.general,
  notice: 'warning',
  important: 'danger',
  critical: 'danger',

  emphasis: 'primary',
  selection: PRODUCT.general,

  info: 'info',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  error: 'danger',
}



export const C_CLASS = {

  theme: _CLASS.theme,
  theme_blend: _CLASS.theme + '',
  theme_pop: _CLASS.theme + '',
  notice: _CLASS.notice,
  notice_blend: _CLASS.notice + '',
  notice_pop: _CLASS.notice + '',
  important: _CLASS.important,
  important_blend: _CLASS.important + '',
  important_pop: _CLASS.important + '',

  emphasis: _CLASS.emphasis,
  emphasis_blend: _CLASS.emphasis + '',
  emphasis_pop: _CLASS.emphasis + '',
  selection: _CLASS.selection,
  selection_blend: _CLASS.selection + '',
  selection_pop: _CLASS.selection + '',

  info: _CLASS.info,
  info_blend: _CLASS.info + '',
  info_pop: _CLASS.info + '',
  success: _CLASS.success,
  success_blend: _CLASS.success + '',
  success_pop: _CLASS.success + '',
  warning: _CLASS.warning,
  warning_blend: _CLASS.warning + '',
  warning_pop: _CLASS.warning + '',
  danger: _CLASS.danger,
  danger_blend: _CLASS.danger + '',
  danger_pop: _CLASS.danger + '',
  error: _CLASS.error,
  error_blend: _CLASS.error + '',
  error_pop: _CLASS.error + '',

  gray: _CLASS.gray,
  gray_blend: _CLASS.gray + '',
  gray_pop: _CLASS.gray + '',
  
}

export const TEXT = {
  emphasize: ' black-text-2 ',
  read: ' black-text-4 ',
  muted: ' black-text-7 ',
  blend: ' black-text-10 ',

  notice: ' warning-text-3  ',
  important: ' danger-text-3  ',
  critical: ' danger-text-3  ',

  emphasize_theme: ' ' + C_CLASS.theme + '-text-1 ',
  read_theme: ' ' + C_CLASS.theme + '-text-1 ',
  muted_theme: ' ' + C_CLASS.theme + '-text-4 ',
  blend_theme: ' ' + C_CLASS.theme + '-text-7 ',
}
