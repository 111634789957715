import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import {Helmet} from 'react-helmet';

import Moment from 'react-moment';
import {parseToHsla, hsla} from 'color2k';

import ContentLayout from 'component/layout/page/content';
import {RoundIcon} from'component/assets/icon';

// import {detectDeviceType} from 'utils/tracker';

import GenericCard from 'component/content/cards';
import ClickCopyButton from 'component/content/buttons/clickcopy';
import ShareButton from 'component/content/buttons/share';

// import {LISK} from './sample/cards';
// import {LISK} from './sample/motivation5min';
import {LISK_MAP, LISK_NAME_MAP, authors} from './sample/all';

import './lisk.css';

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};



function Page({match}) {

  var { lisk_xid, user_name, lisk_name } = match.params;


  var lisk_data = {};
  if (lisk_xid){lisk_data = LISK_MAP[lisk_xid];}
  if (lisk_name && user_name === LISK_NAME_MAP[lisk_name].author.key){lisk_data = LISK_NAME_MAP[lisk_name];}

  // console.log(lisk_xid, user_name, lisk_name);
  // console.log(lisk_data);

  const lisk = lisk_data.lisk;
  const profile = lisk_data.profile;
  const author = lisk_data.author;
  
  // console.log(profile);
  if (!lisk_xid){lisk_xid = profile.xid};

  ///////////

  const [btnOptions] = useState([
    { code: 'clone', icon_class: 'fa fa-copy', title: 'Clone', href: "/lisk/"+lisk_xid+'/clone', },
    { code: 'report', icon_class: 'fa fa-flag', title: 'Report', href: "/lisk/"+lisk_xid+'/report', },
    { code: 'home', icon_class: 'fa fa-home', title: 'Home', href: "/", },
  ]);
  const [selectedOption, setSelectedOption] = useState();
  
  const [expandedOptions, setExpandedOptions] = useState([]);
  const isExpandedOptionIncluded = (id) => {return expandedOptions.includes(id);};
  const toggleExpandedOptions = (id) => {
    if (isExpandedOptionIncluded(id)) {
      setExpandedOptions(expandedOptions.filter((itemId) => itemId !== id));
    } else {
      setExpandedOptions([...expandedOptions, id]);
    }
  };

  const [searchInputText, setSearchInputText] = useState('');
  const onSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInputText(e.target.value.toLowerCase());
  };
  
  const scrollDirection = useScrollDirection();
  // const deviceType = detectDeviceType();

  const [h,s,l,_] = parseToHsla(profile.theme_color);

  const bg_gradient = 'linear-gradient( -45deg'
    +', hsl('+(h- 20)+', '+(0.9)*100+'%, '+(l*0.75)*100+'%)   0%'
    +', hsl('+(h+  0)+', '+(  s)*100+'%, '+(l     )*100+'%)  40%'
    +', hsl('+(h+  0)+', '+(  s)*100+'%, '+(l     )*100+'%)  60%'
    +', hsl('+(h+ 10)+', '+(0.9)*100+'%, '+(l*0.95)*100+'%) 100%'
    +')';

  const bg_light = (l > 0.5)? 'hsl('+(h- 0)+', '+(0.5)*100+'%, '+(0.96)*100+'%)': 'hsl('+(h- 0)+', '+(0.5)*100+'%, '+(0.96)*100+'%)';
  const bg_text     =  (l > 0.5)? '#333333': '#ffffff';
  const bg_light_desc = 'hsl('+(h- 0)+', '+(0.4)*100+'%, '+(0.4)*100+'%)';
  const bg_light_auth = 'hsl('+(h- 0)+', '+(0.5)*100+'%, '+(0.3)*100+'%)';
  const bg_text_desc     =  (l > 0.5)? '#ffffff':'#ffffff';

  var count = 0;
  lisk.map(function(lisk_item, idx){
    count += lisk_item.cards.length;
    // console.log('lisk_item.cards.length, ', lisk_item.cards.length);
  })

  const headerStyle = (scrollDirection === "down")? 
    {
      color: '#333333', background: bg_light, 
      // zIndex: 9999,
      // position: 'sticky', top: 50
    } : {
      color: '#333333', background: bg_light, 
      // zIndex: 9999, 
      // position: 'sticky', top: 150
    };
  const descStyle = {
    color: bg_text_desc, background: bg_light_desc, fontSize: '0.8rem',
  }
  const authorStyle = {
    color: bg_text_desc, background: bg_light_auth, fontSize: '0.9rem', 
  }

  return (
    <ContentLayout id="page" >

      <Helmet className="">
        <title>{profile.title} | Cloe</title>
      </Helmet>

      <div className="" style={{height: '100%'}}>

        <div className="row" style={{height: '100%'}}>
          <div className="col-md-3 m-0 p-0" style={{  background: bg_gradient, color: bg_text, zIndex: 2000,  }}>
            <div className="card-block text-center" style={{position: 'sticky', top: 0 }}>
              <img src={profile.img_src} className="img-radius my-3 " alt="User-Profile" style={{borderRadius: '50%', width: '40%', position: 'sticky', }} />
              
              <div className="py-3" style={headerStyle}>

                <h4 className="text-size-8 ">{profile.title}</h4>

                <div className="">

                  <span className="">
                    <ClickCopyButton />
                    <ShareButton />
                    <a href={"/lisk/"+lisk_xid+'/subscribe'} className="btn mr-2" ><i className={" text-size-5 " + 'fa fa-bell'}></i> </a>
                  </span>  

                  <Dropdown as="span"
                    onSelect={eventKey => {
                      // const { code, title } = btnOptions.find(({ code }) => eventKey === code);
                      btnOptions.find(({ code }) => eventKey === code);
                      setSelectedOption(eventKey);
                      // console.log('eventKey=', eventKey);
                    }}
                  >
                    <Dropdown.Toggle  bsPrefix='btn mr-2' variant={null}>
                      <i className="text-size-5 fa fa-ellipsis-v"></i>
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu bsPrefix=''>
                      {btnOptions.map(({ code, icon_class, title, href }) => (
                        <Dropdown.Item key={code} eventKey={code} href={href} ><i className={"text-size-5 mr-2 " + icon_class}></i>{title}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                </div>
                
              </div>
              
              {/* DESCRIPTION */}
              <div className="py-3 text-left family-code " style={descStyle}>
                {profile.description.map(function(desc, idx){
                  return (<p key={idx} className="m-3" idx={idx}>{desc}</p>)
                })}
              </div>

              {/* AUTHOR DETAILS */}
              <div className="py-3 " style={authorStyle}>
                <a href={"/users/@"+author.key} className="white-text-5 m-0 p-0 ">
                  <img src={author.img_src} alt="" className="mr-2 " style={{height: '20px', width: '20px', borderRadius: '50%'}} />
                  {author.name}
                </a>
              </div>

              {/* ALERT */}
              {/* <div className="pt-3 pb-1 px-1 " style={{backgroundColor: '#fafafad0'}}>
                <Link to={"/lisk/"+lisk_xid+'/errors'} className="mx-3 black-text-1 ">
                  <i className="fa fa-bug text-size-6"></i>
                </Link>
                <Link to={"/lisk/"+lisk_xid+'/notifications'} className="mx-3 black-text-1 ">
                  <i className="fas fa-plus-circle text-size-6"></i>
                </Link>
                <Link to={"/lisk/"+lisk_xid+'/suggestions'} className="mx-3 danger-text-1 "  >
                  <i className="fas fa-triangle-exclamation text-size-6"></i>
                  <span className="text-size-3 " style={{ position: 'relative', top: '-1em', fontSize: '0.8em' }}>4</span>
                </Link>
              </div> */}

            </div>
          </div>
          <div className="col-md-9 m-0 p-0"  style={{background: 'rgba(243,243,243,1)', height: '100%', overflowY: 'scroll'}}>
            
              <div className="py-1 px-2 shadow black-text-3" 
              style={{background: bg_light, zIndex: 9999, position: 'sticky', top: 0, }}
              >

                <div className="p-1 rounded">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button id="button-addon2" type="submit" className="btn btn-link " style={{color: 'hsl('+(h- 0)+', '+(s)*100+'%, '+(0.36)*100+'%)'}}><i className="fa fa-search"></i></button>
                    </div>
                    <input onChange={onSearchInputChange } type="search" placeholder={'search ...'} aria-describedby="button-addon2" className="form-control border-0 bg-white" />
                  </div>
                </div>

              </div>

              <div id="accordion" className="mx-3" style={{  }}>

                {lisk.map(function(lisk_item, idx){
                  return(
                    <div key={idx} className="my-5">
                      <div className="row moodboarda" > 
                        <div className="" id={"heading_" + idx} style={{width: '100%'}}>
                            <a onClick={() => toggleExpandedOptions("heading_" + idx)} href="#" className="d-flex align-items-center justify-content-between black-text-3 collapsed" data-toggle="collapse" data-target={"#collapse_"+idx} aria-expanded="false" aria-controls={"collapse_" + idx}>
                              <h6 className="">{lisk_item.title}</h6>
                                <i className={"fas black-text-3 text-size-5 " + (isExpandedOptionIncluded("heading_" + idx) ? 'fa-plus' : 'fa-minus')}></i>
                            </a>
                        </div>
                        <div id={"collapse_"+idx} className="columnessa my-3 row show" aria-labelledby={"heading_" + idx}  style={{width: '100%'}} >

                          {/* <table className="table table-hover">
                          <tbody className=" ">
                          {lisk_item.cards.sort(compare_cards).map(function(card, cidx){
                            return(<TableItem key={cidx} profile={profile} card={card} search_text={searchInputText} />)
                          })}
                          </tbody>
                          </table> */}

                          {lisk_item.cards.sort(compare_cards).map(function(card, cidx){
                            // TODO .. mood board
                            // https://codepen.io/bexdin/pen/WNQRxoL?editors=1100
                            // return("")
                            return(<GenericCard key={cidx} profile={profile} card={card} search_text={searchInputText} />)
                          })}
                        </div>
                      </div>    
                      <hr className="" />
                    </div>    
                  )
                })}

              </div>

          </div>
        </div>

      </div>
    </ContentLayout>
  )
}



class TableItem extends React.Component {
  render (){

    const card = this.props.card;
    const search_text = this.props.search_text;

    const icon_img_src = card.icon_img_src;
    const icon_img_alt = card.icon_img_alt;
    const card_title = card.card_title;
    const card_description = card.card_description;
    const card_link = card.card_link;
    const link_img_src = card.link_img_src;
    const link_img_alt = card.link_img_alt;
    const added_at = card.added_at;

    const show = (search_text === null 
      || search_text === ''
      || (icon_img_alt && icon_img_alt.toLowerCase().includes(search_text)) 
      || (link_img_alt && link_img_alt.toLowerCase().includes(search_text) )
      || (card_title && card_title.toLowerCase().includes(search_text) )
      || (card_description && card_description.toLowerCase().includes(search_text) )
    );
    if (!show){return null;}

    const style = {
      height: 'auto', 
      maxHeight: '3em', 
      width: 'auto', 
      maxWidth: '100%', 
      margin: '0.1em auto 0.1em auto', 
      display: 'block', 
      boxShadow: '0 0 8px 8px white inset',
    }

    return (
      <tr className=" ">
          <th className=""  style={{verticalAlign: 'middle'}}>{(link_img_src)?
              <img 
                className="card-img-bottom rounded " 
                loading="lazy" 
                src={link_img_src} 
                alt={link_img_alt} 
                style={style} 
                onError={(event) => event.target.style.display = 'none'} 
              /> : ''}</th>
          <td className="black-text-6" style={{verticalAlign: 'middle'}}>
            <div className="">
              {(icon_img_src)? <RoundIcon size="15px" img_src={icon_img_src} img_alt={icon_img_alt} /> : <RoundIcon size="15px" img_src={'/logo.svg'} img_alt={'icon not found'} />}
            </div>
            <div className="text-size-2">
              {card_title}
            </div>
          </td>
          <td  style={{verticalAlign: 'middle'}}>{card_description}</td>
          <td  style={{verticalAlign: 'middle'}}><Moment fromNow>{added_at}</Moment></td>
        </tr>
    )
  }
}


function compare_cards( a, b ) {
  if ( a.added_at > b.added_at ){return -1;}
  if ( a.added_at < b.added_at ){return 1;}
  return 0;
}




export default withRouter(Page);
