import React from 'react';


export function RoundIcon(props){
    const img_src = props.img_src;
    const img_alt = props.img_alt || 'round icon';
    const size = props.size || '30px';
    return (
        <img 
            className="" 
            src={img_src} 
            alt={img_alt} 
            style={{borderRadius: '50%', width: size, height: size,}}
            onError={(event) => event.target.style.display = 'none'} 
        />
        )
}
