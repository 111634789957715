const cards = [
    {"card_xid": "1e5e412541f8ba73d69b587848e470ea88d50012c2431442858724618921d2ee", "card_link": "https://www.flyhyer.com/", "card_title": "www.flyhyer.com", "card_theme": null, "card_type": "image", "card_description": "HYER\u00ae - Personalised Aviation - Book or Share a Private Jet", "icon_img_src": "https://www.datocms-assets.com/40358/1611759777-hyer-favicon.jpg?auto=format&h=114&w=114", "link_img_src": "https://www.datocms-assets.com/40358/1611579548-hyer-social-fallback.jpg?auto=format&fit=max&w=1200", "added_at": "2023-05-21T19:05:16.681903", "updated_at": "2023-07-07T20:33:23.497824"}, 
    {"card_xid": "6f2a89bb0d9ff0cd597902103b960bbecdf17b98b72873a7c752a93f570af279", "card_link": "https://www.mubasic.com/", "card_title": "www.mubasic.com", "card_theme": null, "card_type": "image", "card_description": "www.mubasic.com", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-23T10:09:38.952897", "updated_at": "2023-07-07T20:33:23.571171"}, 
    {"card_xid": "e9edc2a3e74ef3c5684f1c68f5a3f068959b881e53b2f31279716834b3ff6e2f", "card_link": "https://digital-cover.com/", "card_title": "digital-cover.com", "card_theme": null, "card_type": "image", "card_description": "Digital Creative Agency in Malta, Paris & Lyon : Digital Cover", "icon_img_src": "https://digital-cover.com/wp-content/themes/digitalcover/dist/images/favicons/apple-icon-72x72.png", "link_img_src": null, "added_at": "2023-04-22T04:07:14.818820", "updated_at": "2023-07-07T20:33:23.579730"}, 
    {"card_xid": "6b4c5b489be81ba277f7f2e2fe78b6f08a26dcb31d293013ed0b5a354ad6e685", "card_link": "https://www.awwwards.com/sites/the-harmonic-state", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "The Harmonic State - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2021/06/60be414820642589758937.jpg", "added_at": "2023-06-01T22:36:28.091922", "updated_at": "2023-07-07T20:33:23.615432"}, 
    {"card_xid": "bf204d46f317da805c7ce0b38d4188cce7bc8bb89429b78fd44e79e4bd61107f", "card_link": "https://superlist.com/", "card_title": "superlist.com", "card_theme": null, "card_type": "image", "card_description": "Superlist", "icon_img_src": "https://global-uploads.webflow.com/625593a881b8ebd169835ca5/628d3c496176b4bcdac9355b_256.png", "link_img_src": null, "added_at": "2023-04-29T18:34:21.309522", "updated_at": "2023-07-07T20:33:23.716978"}, 
    {"card_xid": "9eef8b423daeb6a85471d95a2195bee021be1d9f95febe432d9a64aedfa0b675", "card_link": "https://swabtheworld.com/en/", "card_title": "swabtheworld.com", "card_theme": null, "card_type": "image", "card_description": "Swab The World | We're all created equal until blood cancer strikes", "icon_img_src": "https://swabtheworld.com/apple-touch-icon.png", "link_img_src": "https://swabtheworld.com/upload/images/FB_share_EN.png", "added_at": "2023-04-27T10:34:25.489441", "updated_at": "2023-07-07T20:33:23.768396"}, 
    {"card_xid": "f97755526d85e1af1407221376f709859008540dbc0ae81cab4cc33bcf4dfcdf", "card_link": "https://newestamericans.com/", "card_title": "newestamericans.com", "card_theme": null, "card_type": "image", "card_description": "Newest Americans | Newest Americans", "icon_img_src": "https://newestamericans.com/wp-content/uploads/2020/04/cropped-na-logo-180x180.png", "link_img_src": "https://newestamericans.com/wp-content/uploads/2019/10/NA_DesiGas_20160327_0137_darkened-1.jpg", "added_at": "2023-06-16T06:49:01.837256", "updated_at": "2023-07-07T20:33:23.837898"}, 
    {"card_xid": "8e9f47d8898a6d9887e2854d8a00fcf33a39cf8ba8313da6359b90fe755e7e77", "card_link": "https://spotify.design/", "card_title": "spotify.design", "card_theme": null, "card_type": "image", "card_description": "Home | Spotify Design", "icon_img_src": "https://spotify.design/favicons/apple-touch-icon.png", "link_img_src": "https://spotify.design/social/home.png", "added_at": "2023-05-19T23:38:40.779629", "updated_at": "2023-07-07T20:33:23.905237"}, 
    {"card_xid": "1fc6b51509106669b996540adfb70b67134518001ecb54716b7f1f6aba9cac05", "card_link": "http://waaarhol.com/hollywoodblonde-paintings/", "card_title": "waaarhol.com", "card_theme": null, "card_type": "image", "card_description": "Andy Warhol", "icon_img_src": "https://rm-content.s3.amazonaws.com/5c8040be4d65327bbf3b1a6e/upload-32334e7b-7145-4caa-8ec8-78daab09e014_114.png", "link_img_src": "https://d3n32ilufxuvd1.cloudfront.net/5c8040be4d65327bbf3b1a6e/1760347/screenshot-65e629e2-d9c7-4148-8cfa-4d39d03c14ca_readyscr_1024.jpg", "added_at": "2023-05-03T15:36:20.868982", "updated_at": "2023-07-07T20:33:24.020998"}, 
    {"card_xid": "5df11646eb3be1a7f5673f8efea121c2a1124a82d1fc78e4c7b1ebc6ed455255", "card_link": "https://www.humaninteraction.com/", "card_title": "www.humaninteraction.com", "card_theme": null, "card_type": "image", "card_description": "Human interaction company", "icon_img_src": "https://www.humaninteraction.com/static/apple-touch-icon.png", "link_img_src": "https://res.cloudinary.com/humaninteraction/image/upload/c_lfill,h_720,q_auto,w_1280/v1/info/facebook-social-homepage_p7lc5b.jpg", "added_at": "2023-04-29T02:05:29.060063", "updated_at": "2023-07-07T20:33:24.059410"}, 
    {"card_xid": "d625b537e7c4d4a3d64915f97305199e713b0023492dc10c487794750120991c", "card_link": "https://garoaskincare.com/", "card_title": "garoaskincare.com", "card_theme": null, "card_type": "image", "card_description": "garoaskincare.com", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-28T18:31:33.147546", "updated_at": "2023-07-07T20:33:24.088728"}, 
    {"card_xid": "cd5da5ad204143b7e05d987d0bf98ded23ba27fb8bf2b2a96375595a7aaa9d73", "card_link": "https://intothetrenches.1917.movie/", "card_title": "intothetrenches.1917.movie", "card_theme": null, "card_type": "image", "card_description": "1917 | 360 Trench Experience | In Theaters Now", "icon_img_src": "https://intothetrenches.1917.movie/favicon.ico", "link_img_src": "https://intothetrenches.1917.movie/images/common/site-share.jpg", "added_at": "2023-05-23T15:16:34.316418", "updated_at": "2023-07-07T20:33:24.096647"}, 
    {"card_xid": "d811eb25137bc80424f1500d044e9a9e7854eaefd158680cd375cb38f207747a", "card_link": "https://www.ideo.com/blog", "card_title": "www.ideo.com", "card_theme": null, "card_type": "image", "card_description": "IDEO Blog: The Octopus | ideo.com", "icon_img_src": "https://www.ideo.com/favicon.ico", "link_img_src": "https://www.datocms-assets.com/63952/1669032060-ideo_og.jpg", "added_at": "2023-04-16T08:55:20.817998", "updated_at": "2023-07-07T20:33:24.099450"}, 
    {"card_xid": "a5d5f670fe0bc96a8dd4e391241dc2a06ef218bbe4c7bbf2dc54eac2f90d0fc3", "card_link": "https://nomadictribe.com/", "card_title": "nomadictribe.com", "card_theme": null, "card_type": "image", "card_description": "Nomadic Tribe \u2014 Embark on a life changing journey!", "icon_img_src": "https://nomadictribe.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-18T15:14:50.526296", "updated_at": "2023-07-07T20:33:24.131774"}, 
    {"card_xid": "4d2223515c24783e48deaa97a0dbd87f63a876cc970182d09b357c735138b2e3", "card_link": "https://dianadanieli.com/", "card_title": "dianadanieli.com", "card_theme": null, "card_type": "image", "card_description": "Diana Danieli", "icon_img_src": "https://dianadanieli.com/templates/leviev/ico/76.png", "link_img_src": "https://dianadanieli.com/images/cms/thumbs/0157f9c605716e1376eb5dfd1babf37597604983/lev_1200_630_jpg_5_100.jpg", "added_at": "2023-05-14T11:46:08.591035", "updated_at": "2023-07-07T20:33:24.181831"}, 
    {"card_xid": "3ab092526a1f5e87faac23eb7421a3dad9392011d9f3f486c60a4c7202413ff5", "card_link": "https://nakashimawoodworkers.com/", "card_title": "nakashimawoodworkers.com", "card_theme": null, "card_type": "image", "card_description": "George Nakashima Woodworkers - New Hope, Pennsylvania", "icon_img_src": "https://nakashimawoodworkers.com/apple-touch-icon.png?a=1", "link_img_src": "https://nakashimawoodworkers.com/wp-content/uploads/2018/06/george-nakashima.jpg", "added_at": "2023-04-17T09:07:06.998740", "updated_at": "2023-07-07T20:33:24.214018"}, 
    {"card_xid": "837980b1fe9ac503b2b5788850005d92c79274df281494bd69aa1b423a2348f4", "card_link": "https://www.awwwards.com/sites/crypton-trading-bot", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "+_+ crypton.trading - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2018/03/5aba1b835d482.png", "added_at": "2023-05-08T12:15:12.711596", "updated_at": "2023-07-07T20:33:24.247367"}, 
    {"card_xid": "c5dd7ca514d04dd9f7d7024ba1cc55efe7b1e3cbeb91e141b9b63f5911fc56f5", "card_link": "https://www.webbyawards.com/winners/2018/websites/features-design/best-visual-design-aesthetic/heart-of-travel/", "card_title": "www.webbyawards.com", "card_theme": null, "card_type": "image", "card_description": "The Webby Awards", "icon_img_src": "https://winners.webbyawards.com/2018/websites-and-mobile-sites/features-design/best-data-visualization/42689/favicons/1.ico", "link_img_src": "https://www.webbyawards.com/wp-content/uploads/sites/4/2014/10/trophies1.jpg", "added_at": "2023-04-05T17:55:08.336149", "updated_at": "2023-07-07T20:33:24.368676"}, 
    {"card_xid": "379be736432353a133a75a04f07334becb39380cf30a4b085f0c865b6c2186bf", "card_link": "https://overflow.io/", "card_title": "overflow.io", "card_theme": null, "card_type": "image", "card_description": "User flow diagramming tool for design teams | Overflow", "icon_img_src": "https://overflow.io/favicon.ico", "link_img_src": "https://overflow.io/assets/images/social/homepage.png?v=1687772068089", "added_at": "2023-04-03T02:18:24.250229", "updated_at": "2023-07-07T20:33:24.390758"}, 
    {"card_xid": "52d23966c82a4a3e74d6a7fefb13ee4484e72f5f3288d12a87ee472ce6d60897", "card_link": "https://www.franshalsmuseum.nl/en/", "card_title": "www.franshalsmuseum.nl", "card_theme": null, "card_type": "image", "card_description": "Frans Hals Museum - Meet at Frans Hals Museum", "icon_img_src": "https://www.franshalsmuseum.nl/wp-content/themes/franshals/assets/images/icons/apple-icon-114x114.png", "link_img_src": "https://www.franshalsmuseum.nl/wp-content/uploads/2020/12/FH_nieuwjaar_2021_KORT-1-768x432.gif", "added_at": "2023-05-24T22:45:53.538052", "updated_at": "2023-07-07T20:33:24.427227"}, 
    {"card_xid": "e639c88ada9db150124ecd61ad1d7ece0aff31c1235ad3731d3767e88cad64a8", "card_link": "https://www.awwwards.com/sites/simply-chocolate", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "Simply Chocolate - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2017/09/59ca11ebdd968.jpg", "added_at": "2023-06-17T04:35:56.720915", "updated_at": "2023-07-07T20:33:24.533836"}, 
    {"card_xid": "509b35314e5fb06540b12f0937f05ea148361fcf10e3ac89e7ea4a628165f15e", "card_link": "https://www.webbyawards.com/winners/2017/websites/general-website/cultural-blogwebsite/nowness/", "card_title": "www.webbyawards.com", "card_theme": null, "card_type": "image", "card_description": "The Webby Awards", "icon_img_src": "https://winners.webbyawards.com/2017/websites-and-mobile-sites/general-websites-and-mobile-sites/cultural-blogwebsite/9948/favicons/1.ico", "link_img_src": "https://www.webbyawards.com/wp-content/uploads/sites/4/2014/10/trophies1.jpg", "added_at": "2023-06-03T10:19:31.928797", "updated_at": "2023-07-07T20:33:24.622436"}, 
    {"card_xid": "f4a80e78cb1de9afaee159b46aef8143e959be26dafeb9af8327e98c4e73a97f", "card_link": "https://www.webbyawards.com/winners/2016/websites/general-website/best-activism-websites/rainforest-guardians/", "card_title": "www.webbyawards.com", "card_theme": null, "card_type": "image", "card_description": "The Webby Awards", "icon_img_src": "https://winners.webbyawards.com/2016/websites-and-mobile-sites/general-websites-and-mobile-sites/activism/161395/favicons/1.ico", "link_img_src": "https://www.webbyawards.com/wp-content/uploads/sites/4/2014/10/trophies1.jpg", "added_at": "2023-04-29T18:41:15.446717", "updated_at": "2023-07-07T20:33:24.644739"}, 
    {"card_xid": "131fcd34eb311be14f9ef44d1a46cbac65e2a7965109a43b43f83708b971e847", "card_link": "https://www.awwwards.com/sites/protest-sportswear", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "Protest Sportswear - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2016/12/58416b997e31d.jpg", "added_at": "2023-05-08T20:06:08.617556", "updated_at": "2023-07-07T20:33:24.666264"}, 
    {"card_xid": "23435921ef8dc3b95c2d7389d69f9488a70282550dac8bb1ee8d212fee8352f3", "card_link": "https://www.webbyawards.com/winners/2016/websites/general-website/associations/the-teachers-guild/", "card_title": "www.webbyawards.com", "card_theme": null, "card_type": "image", "card_description": "The Webby Awards", "icon_img_src": "https://winners.webbyawards.com/2016/websites-and-mobile-sites/general-websites-and-mobile-sites/associations/161913/favicons/1.ico", "link_img_src": "https://www.webbyawards.com/wp-content/uploads/sites/4/2014/10/trophies1.jpg", "added_at": "2023-04-08T06:30:25.851799", "updated_at": "2023-07-07T20:33:24.740238"}, 
    {"card_xid": "72e51ab97df231333aff770aeb9199a68aa9ed2489b9ffeaad039d1117294c04", "card_link": "http://userexperienceawards.com/ux-awards-2014-winners/#work-co", "card_title": "userexperienceawards.com", "card_theme": null, "card_type": "image", "card_description": "2014 UX Awards | UX Awards", "icon_img_src": "http://userexperienceawards.com/wp-content/themes/uxa2013/assets/images/favicon.gif", "link_img_src": null, "added_at": "2023-05-28T12:49:37.642982", "updated_at": "2023-07-07T20:33:24.762507"}, 
    {"card_xid": "37d8d73e397955658be7d6c9695740ef0199a960ab0170fb23a6bb933dd80485", "card_link": "http://www.awwwards.com/web-design-awards/feed-2", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "Feed - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2015/05/55634a275a445.jpeg", "added_at": "2023-06-05T07:39:27.559150", "updated_at": "2023-07-07T20:33:24.864689"}, 
    {"card_xid": "803de2add7902613e3db026ac18b1fc8e59a9f527fef299a0afe64e3ac203743", "card_link": "http://www.awwwards.com/web-design-awards/etq", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "ETQ - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2015/05/55473f629fe83.jpeg", "added_at": "2023-05-14T01:35:00.014474", "updated_at": "2023-07-07T20:33:24.927057"}, 
    {"card_xid": "00b5393662e2043b542ed1f52c230d164b458e5a7e5a20d31aff67be27f5ab3b", "card_link": "http://www.awwwards.com/web-design-awards/mikiya-kobayashi", "card_title": "www.awwwards.com", "card_theme": null, "card_type": "image", "card_description": "MIKIYA KOBAYASHI - Awwwards SOTD", "icon_img_src": "https://www.awwwards.com/apple-touch-icon.png", "link_img_src": "https://assets.awwwards.com/awards/submissions/2015/06/55877333b2d0e.jpeg", "added_at": "2023-05-07T19:29:05.463608", "updated_at": "2023-07-07T20:33:25.024024"}, 
    {"card_xid": "ebb5b3655cd59a4ba7904351cd53213038006faf7b42ea351d7b53dd1ed06297", "card_link": "http://wovenmagazine.com/", "card_title": "wovenmagazine.com", "card_theme": null, "card_type": "image", "card_description": "wovenmagazine.com", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-04T12:00:14.063174", "updated_at": "2023-07-07T20:33:25.088665"}, 
    {"card_xid": "3098c4977f61e909e1a144c2e191b7887599a58e202c6c69096d549c9dad41ac", "card_link": "http://www.thefwa.com/site/follow-the-joho-s-bean", "card_title": "www.thefwa.com", "card_theme": null, "card_type": "image", "card_description": "Follow the JOHO's bean - The FWA", "icon_img_src": "https://thefwa.com/favicons/apple-touch-icon-144x144.png", "link_img_src": "https://thefwa.com/dyn/resources/Case_Model_Case/thumbnail/1/231/1457954663/fwa1-I0UJ.jpg", "added_at": "2023-04-14T11:29:59.081796", "updated_at": "2023-07-07T20:33:25.096747"}, 
    {"card_xid": "002d020c66ef461a2c562e1f971af77184d49e7b60f1cb2acc7d46de59fe4871", "card_link": "http://www.webbyawards.com/winners/2015/websites/website-features-and-design/best-user-interface/world-of-swiss/", "card_title": "www.webbyawards.com", "card_theme": null, "card_type": "image", "card_description": "World of SWISS | The Webby Awards", "icon_img_src": "https://winners.webbyawards.com/2015/websites-and-mobile-sites/features-design/best-user-interface/158582/favicons/1.ico", "link_img_src": "https://www.webbyawards.com/wp-content/uploads/sites/4/2014/10/trophies1.jpg", "added_at": "2023-05-15T18:26:20.416702", "updated_at": "2023-07-07T20:33:25.130891"}, 
    {"card_xid": "1032f191c7612673eb87f1c89e090f6a680e2d53d7a210473842b47cf5df57a4", "card_link": "https://www.guillaumetomasi.com/", "card_title": "www.guillaumetomasi.com", "card_theme": null, "card_type": "image", "card_description": "Guillaume Tomasi - Photographer", "icon_img_src": "https://www.guillaumetomasi.com/pwa-192x192.png", "link_img_src": "https://guillaumetomasi.b-cdn.net/a-bloom-in-the-eye-of-the-storm/jpg/01_arbre_bizarre.jpg", "added_at": "2023-05-26T14:32:20.075511", "updated_at": "2023-07-07T20:33:25.158444"}, 
    {"card_xid": "a2804ba05227f846eebaba3b7f70504c3472c776c0cfbadeb706441ca21b440a", "card_link": "https://www.thedistrict.co.uk/", "card_title": "www.thedistrict.co.uk", "card_theme": null, "card_type": "image", "card_description": "The District are a design practice", "icon_img_src": "https://www.thedistrict.co.uk/icons/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-05-27T05:23:16.819987", "updated_at": "2023-07-07T20:33:25.168664"}, 
    {"card_xid": "d49dfd99d8af4d45f4bf6d17afca4bf0b55d523ce4f50c52e6e8d9730311b0f2", "card_link": "https://tejchauhan.com/", "card_title": "tejchauhan.com", "card_theme": null, "card_type": "image", "card_description": "Tej Chauhan | Industrial design | Product design | London", "icon_img_src": "https://tejchauhan.com/wp-content/themes/tej/i/shared/favicon.png", "link_img_src": null, "added_at": "2023-05-22T03:57:14.338933", "updated_at": "2023-07-07T20:33:25.184518"}, 
    {"card_xid": "c81d213c68fab1d787109b739524e228e00bb6cbd5cd2ac3d57d2051f49e8e19", "card_link": "https://amandamartocchio.com/", "card_title": "amandamartocchio.com", "card_theme": null, "card_type": "image", "card_description": "Amanda Martocchio Architecture I Connecticut Residential Architect", "icon_img_src": "https://amandamartocchio.com/assets/images/apple-icon-144x144_preview.png", "link_img_src": "http://amandamartocchio.com/5_greenwich_ct_modern_architecture_01.84ecd9276dc7654ed6f2ecf2dfa0d051.jpg", "added_at": "2023-04-30T22:13:13.724763", "updated_at": "2023-07-07T20:33:25.195335"}, 
    {"card_xid": "a8031a719a1177683e61c6eddcd9523cf80102ee3c09e56517212c9bae2ba4ea", "card_link": "https://dribbble.com/shots/popular/web-design?__hstc=20629287.91f63b89ddbccf6274f597844b8e5a0b.1687014106158.1687014106158.1687014106158.1&amp;__hssc=20629287.1.1687014106158&amp;__hsfp=3965892091", "card_title": "dribbble.com", "card_theme": null, "card_type": "image", "card_description": "Trending Website, Landing Page and Splash Page Designs on Dribbble", "icon_img_src": "https://cdn.dribbble.com/assets/apple-touch-icon-precomposed-1c6d9b0a173f5b2d5c392ea101bb206ee9a2a39bef19eb21513b857eeb3624d2.png", "link_img_src": null, "added_at": "2023-05-04T17:55:11.318293", "updated_at": "2023-07-07T20:33:25.293567"}, 
    {"card_xid": "0a8c49ed17776e33172665298df79e66264cebe2d97277f77eb87397fcddb2f6", "card_link": "https://www.behance.net/search/projects?field=web%20design", "card_title": "www.behance.net", "card_theme": null, "card_type": "image", "card_description": "Behance", "icon_img_src": "https://www.behance.net/favicon.ico", "link_img_src": null, "added_at": "2023-04-25T09:17:19.934889", "updated_at": "2023-07-07T20:33:25.397484"}, 
    {"card_xid": "7ccc696ccbc4a0db9882920158ca1b6091107658bbd677e0e808598c3593ef1a", "card_link": "https://www.pttrns.com/", "card_title": "www.pttrns.com", "card_theme": null, "card_type": "image", "card_description": "Pttrns Homepage - Pttrns", "icon_img_src": "https://www.pttrns.com/wp-content/uploads/2022/07/favicon-300x300.png", "link_img_src": null, "added_at": "2023-05-09T12:53:51.447663", "updated_at": "2023-07-07T20:33:25.399794"}, 
]
const profile = {
    xid: '382D_2BD9_CA60',
    name_key: 'design_inspirations',
    theme_color: '#ffeb3b', 
    title: 'Websites for design inspirations',
    img_src: 'https://img.freepik.com/free-vector/flat-creativity-concept-illustration_52683-64279.jpg?w=1060&t=st=1687014588~exp=1687015188~hmac=e38671fa8beb72759efe906fafad84f27864d27231b18f3c818652eb3c698355',
    description: [
        "A list of website for design inspirations",
    ],
}
const author = {
    key: 'jared_feroldi',
    name: 'Jared Feroldi',
    img_src: 'https://img.freepik.com/free-vector/illustrator-designer-man-holding-digital-tablet_107791-12062.jpg?w=1060&t=st=1686919549~exp=1686920149~hmac=dcd3fa17081d31ea69e01cad76e468d5152040978fd7c32ac5f9379ed577fd74',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
