const cards = [
    {"card_xid": "36e1744d36bd9ad02bf5aa70b6d3b64518c933b12568022e1c381b2ff1637d7c", "card_link": "http://tristen.ca/hcl-picker/", "card_title": "tristen.ca", "card_theme": null, "card_type": "image", "card_description": "Colorpicker for data | tristen", "icon_img_src": "http://tristen.ca/hcl-picker/img/favicon.png", "link_img_src": "http://tristen.ca/hcl-picker/img/twitter-card.png", "added_at": "2023-05-07T12:21:20.763305", "updated_at": "2023-07-07T20:36:13.133477"}, 
    {"card_xid": "93b6f38013a20ca0e919dba9d4b52d99207f3ab206b6ba107547fd814906a081", "card_link": "http://colorbrewer2.org/", "card_title": "colorbrewer2.org", "card_theme": null, "card_type": "image", "card_description": "ColorBrewer: Color Advice for Maps", "icon_img_src": "http://colorbrewer2.org/favicon.ico", "link_img_src": null, "added_at": "2023-05-20T09:40:13.383080", "updated_at": "2023-07-07T20:36:13.140882"}, 
    {"card_xid": "79b90a44f8fcb00c0fda2ba99275a70dba5d6a5246bc614990a40a0dfdaddd03", "card_link": "https://coolors.co/", "card_title": "coolors.co", "card_theme": null, "card_type": "image", "card_description": "Coolors - The super fast color palettes generator!", "icon_img_src": "https://coolors.co/assets/img/favicon.png", "link_img_src": "https://coolors.co/assets/img/og_image.png", "added_at": "2023-05-10T16:35:24.190219", "updated_at": "2023-07-07T20:36:13.284765"}, 
    {"card_xid": "7969bee7003948ed5cb43a22fdb9fb0fd3ffc06946175b900f4439969c3da451", "card_link": "http://flatuicolors.com/", "card_title": "flatuicolors.com", "card_theme": null, "card_type": "image", "card_description": "Palettes | Flat UI Colors \ud83c\udfa8  280 handpicked colors ready for COPY & PASTE", "icon_img_src": "https://flatuicolors.com/static/favicon.ico", "link_img_src": "https://flatuicolors.com/static/facebook-share.png", "added_at": "2023-04-21T15:55:42.958563", "updated_at": "2023-07-07T20:36:13.530391"}, 
    {"card_xid": "f225834e786c14ffb3011e6ab394a7dac7daf4c922c20bf737e5a46dd84c4010", "card_link": "http://www.materialui.co/", "card_title": "www.materialui.co", "card_theme": null, "card_type": "image", "card_description": "Material Design Color, Flat Colors, Icons, Color Palette | Material UI", "icon_img_src": "https://materialui.co/_nuxt/icons/icon_512x512.6c0fb2.png", "link_img_src": "https://www.materialui.co/img/social.jpg", "added_at": "2023-05-10T15:52:28.578103", "updated_at": "2023-07-07T20:36:13.563174"}, 
    {"card_xid": "f209ad8204a14e144e89f45e951bbcb502c8b33134afe49c451603eab3234fec", "card_link": "http://palettab.com/", "card_title": "palettab.com", "card_theme": null, "card_type": "image", "card_description": " Palettab ", "icon_img_src": "https://palettab.com/favicon.ico", "link_img_src": "http://palettab.com/img/share.jpg", "added_at": "2023-05-07T21:49:22.525149", "updated_at": "2023-07-07T20:36:13.581112"}, 
    {"card_xid": "98c1fa1c426074fa8f30f5d90a16a30083117c140263cad9dec6d708a11b7188", "card_link": "http://paletton.com", "card_title": "paletton.com", "card_theme": null, "card_type": "image", "card_description": "Paletton - The Color Scheme Designer", "icon_img_src": "http://paletton.com/img/favicon-128.png", "link_img_src": null, "added_at": "2023-04-25T14:47:23.812061", "updated_at": "2023-07-07T20:36:13.596611"}, 
    {"card_xid": "fcd94c6f230ee7c1f10cbd49b6289f6f3a144e799470b4d1de2fd79cdc93e407", "card_link": "https://color.adobe.com/", "card_title": "color.adobe.com", "card_theme": null, "card_type": "image", "card_description": "Color wheel, a color palette generator | Adobe Color", "icon_img_src": "https://color.adobe.com/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-15T03:13:23.949121", "updated_at": "2023-07-07T20:36:13.635957"}, 
    {"card_xid": "2052142cda4a1d81166357875a86391a0bd23ec486e2a959431147852dc0554f", "card_link": "http://elrumordelaluz.github.io/draGGradients/", "card_title": "elrumordelaluz.github.io", "card_theme": null, "card_type": "image", "card_description": "draGGradients -- create custom css radial-gradients dragging your mouse", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-30T17:12:51.524587", "updated_at": "2023-07-07T20:36:13.662166"}, 
    {"card_xid": "57eb0a22c88fb46f4bcf972613f41344448502124b0d8b1eeb0027402e21f8ff", "card_link": "https://github.com/jimniels/sassme", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - jimniels/sassme: SassMe: Real-time manipulation and visualization of Sass color functions", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/3924a874c6369a6908c58e2b2d0845585be2711e1d59d302d6f10b82fc934a71/jimniels/sassme", "added_at": "2023-04-23T18:59:04.955546", "updated_at": "2023-07-07T20:36:13.678564"}, 
    {"card_xid": "18f35d15ac671bd8e0a8c2181dc90e6e0a15c01780f57efbdf475b889d2e79e5", "card_link": "http://jxnblk.com/shade/", "card_title": "jxnblk.com", "card_theme": null, "card_type": "image", "card_description": "Jxnblk", "icon_img_src": "https://jxnblk.com/favicon.png", "link_img_src": "https://jxnblk.com/avatar.png", "added_at": "2023-05-21T09:18:28.940537", "updated_at": "2023-07-07T20:36:13.766435"}, 
    {"card_xid": "61afd3bada31139f380c3f4846de904e3b755f21f06849b105c0301b47cee9a0", "card_link": "http://www.colourco.de/", "card_title": "www.colourco.de", "card_theme": null, "card_type": "image", "card_description": "ColourCode: Color Palette Generator | Toptal\u00ae", "icon_img_src": "https://www.toptal.com/designers/colourcode/apple-touch-icon.png", "link_img_src": "https://www.toptal.com/designers/colourcode//assets/images/opengraph.png", "added_at": "2023-05-22T01:04:56.241684", "updated_at": "2023-07-07T20:36:13.773587"}, 
    {"card_xid": "bfc2799e581b65f001810b22be64ecdfcdf73d7290907501df56b14520d4414b", "card_link": "http://hslpicker.com/", "card_title": "hslpicker.com", "card_theme": null, "card_type": "image", "card_description": "HSL Color Picker - by Brandon Mathis", "icon_img_src": "https://hslpicker.com/favicon.png", "link_img_src": null, "added_at": "2023-05-26T12:09:06.422407", "updated_at": "2023-07-07T20:36:13.792359"}, 
    {"card_xid": "de97de061f80edccf8bed6151884b504752ba5b8c8fd638facccf0574c02b66d", "card_link": "http://kazzkiq.github.io/svg-color-filter/", "card_title": "kazzkiq.github.io", "card_theme": null, "card_type": "image", "card_description": "SVG Color Filter Playground", "icon_img_src": "http://kazzkiq.github.io/svg-color-filter/icon-512.png", "link_img_src": "https://cdn.rawgit.com/kazzkiq/svg-color-filter/master/readme-screen.jpg", "added_at": "2023-05-03T14:54:33.778002", "updated_at": "2023-07-07T20:36:13.798013"}, 
    {"card_xid": "1faf540efeba7c8297216618de5aba0099ec12b9269399257ab8246aadd918d5", "card_link": "http://www.randoma11y.com", "card_title": "www.randoma11y.com", "card_theme": null, "card_type": "image", "card_description": "Randoma11y", "icon_img_src": "https://user-images.githubusercontent.com/1424573/63131613-9862b880-bf7b-11e9-99d8-09ef94854999.png", "link_img_src": null, "added_at": "2023-06-03T23:59:41.546450", "updated_at": "2023-07-07T20:36:13.805885"}, 
    {"card_xid": "06fddf3a9cfb11e4a8b2f3ce5cca61aa6a06e3f69d603f3856c4e240a09a6e67", "card_link": "http://savemypalette.com/", "card_title": "savemypalette.com", "card_theme": null, "card_type": "image", "card_description": "ww16.savemypalette.com", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-18T18:06:24.697567", "updated_at": "2023-07-07T20:36:13.811504"}, 
    {"card_xid": "04584a2d04ae4bb5e798831478e9a5f3d0c6bf3a055a7ad094e71c60e684fd02", "card_link": "https://color.hailpixel.com", "card_title": "color.hailpixel.com", "card_theme": null, "card_type": "image", "card_description": "Colordot - A color picker for humans", "icon_img_src": "https://color.hailpixel.com/static/favicon-16x16.png", "link_img_src": null, "added_at": "2023-04-12T23:07:47.876778", "updated_at": "2023-07-07T20:36:13.822134"}, 
    {"card_xid": "ba8947de279ef3cf76e1584e981be615b5a404b609d7620c26fe7ea355cc7c6a", "card_link": "http://www.materialpalette.com/", "card_title": "www.materialpalette.com", "card_theme": null, "card_type": "image", "card_description": "Material Design Color Palette Generator - Material Palette", "icon_img_src": "https://www.materialpalette.com/assets/favicon-0f3eb1b9f6d525e848b1dca6db1cabd2a3e9e843749cb9ad72222b80ab9fdf19.ico", "link_img_src": "http://www.materialpalette.com/assets/facebook-e97d3446eefa01933d14bf4aa46907c0d3fcd4c2c17396b4a4731414992104b3.png", "added_at": "2023-05-03T07:20:08.814753", "updated_at": "2023-07-07T20:36:13.827070"}, 
    {"card_xid": "dbcb14325c12dc5bcb100098998788a04a5de66742dbf1390fe13e260d19de6d", "card_link": "http://www.0to255.com/", "card_title": "www.0to255.com", "card_theme": null, "card_type": "image", "card_description": "A Most Useful Color Picker | 0to255", "icon_img_src": "https://0to255.com/favicon.svg", "link_img_src": null, "added_at": "2023-04-27T16:50:48.769732", "updated_at": "2023-07-07T20:36:13.843580"}, 
    {"card_xid": "d8ab352778d283c33b802c80aadb850e7858444a1bc45928a655850634160397", "card_link": "http://147colors.com/", "card_title": "147colors.com", "card_theme": null, "card_type": "image", "card_description": "147 Colors | CSS Color Names", "icon_img_src": "https://147colors.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-02T04:13:55.442792", "updated_at": "2023-07-07T20:36:13.877487"}, 
    {"card_xid": "18e6c552b15ad7e42ee6a290caf4ea2b601fbdc608f1bf9ff148ca49d4e665a8", "card_link": "http://www.shutterstock.com/labs/spectrum/", "card_title": "www.shutterstock.com", "card_theme": null, "card_type": "image", "card_description": "Embrace the future with Shutterstock.AI", "icon_img_src": "https://www.shutterstock.com/assets/images/shutterstock-favicon-114.png", "link_img_src": "https://images.ctfassets.net/hrltx12pl8hq/4waZAy9iA6YYiSdOxJN6eA/ff2240cf8e950d2681eefc7c0ed0bc61/gen_ai_hero.jpg?fit=fill&w=1200&h=630", "added_at": "2023-04-25T12:46:25.035616", "updated_at": "2023-07-07T20:36:13.904508"}, 
    {"card_xid": "a9e982e095a73c46cb03379cc3fc043384b85b254d2fd9525edccf32e4b4452d", "card_link": "http://colrd.com/", "card_title": "colrd.com", "card_theme": null, "card_type": "image", "card_description": "Discover \u2014 Art & Design Inspiration at ColRD.com", "icon_img_src": "http://colrd.com/inc/css/images/media/favicon.ico", "link_img_src": null, "added_at": "2023-04-24T19:36:45.082511", "updated_at": "2023-07-07T20:36:13.946374"}, 
    {"card_xid": "299e50f1ebd7cdcc8fb31433da66cd5a7ef4d7f6a150dc916800eb00528af5be", "card_link": "http://www.checkmycolours.com/", "card_title": "www.checkmycolours.com", "card_theme": null, "card_type": "image", "card_description": "Service discontinued", "icon_img_src": "http://www.checkmycolours.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-20T09:15:17.194372", "updated_at": "2023-07-07T20:36:14.028278"}, 
    {"card_xid": "1ca6c7ff2b440c45f10dec23c1b09e47fce11d99b1e429c70d9e4b2d66cee075", "card_link": "http://www.dasplankton.de/ContrastA/", "card_title": "www.dasplankton.de", "card_theme": null, "card_type": "image", "card_description": "500 Internal Server Error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-28T13:51:57.488907", "updated_at": "2023-07-07T20:36:14.031146"}, 
    {"card_xid": "1acafc8bfc4b6333f486097d12f6e0d606cead942a861d9154e2788358312e0f", "card_link": "http://www.palettable.io/", "card_title": "www.palettable.io", "card_theme": null, "card_type": "image", "card_description": "Palettable", "icon_img_src": "https://www.palettable.io/favicon.ico", "link_img_src": null, "added_at": "2023-05-06T09:25:26.897970", "updated_at": "2023-07-07T20:36:14.033383"}, 
    {"card_xid": "a5699fa00f7f00434c44724e2511560f54cb37711e82170fd3c4a0c924568c32", "card_link": "http://vrl.cs.brown.edu/color", "card_title": "vrl.cs.brown.edu", "card_theme": null, "card_type": "image", "card_description": "Colorgorical", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-04T17:08:43.108558", "updated_at": "2023-07-07T20:36:14.037190"}, 
    {"card_xid": "f36dd0d4aa919324652e3778658561e7e32ea2c8db4f868d0831f0983e11ae22", "card_link": "http://colorlibrary.ch/", "card_title": "colorlibrary.ch", "card_theme": null, "card_type": "image", "card_description": "Color Library", "icon_img_src": "https://colorlibrary.ch/wp-content/uploads/cropped-ColorLibraryIcon-1-180x180.png", "link_img_src": null, "added_at": "2023-05-17T00:38:15.506916", "updated_at": "2023-07-07T20:36:14.326780"}, 
    {"card_xid": "b2c0eb34ea28d8c727ce3f4de8688929886585194dff661c9556ed00b0fe02c8", "card_link": "http://colorsupplyyy.com/app/", "card_title": "colorsupplyyy.com", "card_theme": null, "card_type": "image", "card_description": "Color Picker \u2014 A handy design tool from Color Supply", "icon_img_src": "", "link_img_src": "https://colorsupplyyy.com/color-picker-wheel-hex-code.jpg", "added_at": "2023-06-07T18:38:54.082581", "updated_at": "2023-07-07T20:36:14.334724"}, 
    {"card_xid": "5a8d79157431733dc8f539e6bbdd479a41b98d4f6b671de8e45104cae558ed49", "card_link": "http://www.colorfavs.com/", "card_title": "www.colorfavs.com", "card_theme": null, "card_type": "image", "card_description": "Default Web Site Page", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-24T21:40:56.457154", "updated_at": "2023-07-07T20:36:14.368175"}, 
    {"card_xid": "7fb7efdfbe9c460ce137f7e4c6d522ddf4fa0c5b6700d32ed64bbb4b949d8539", "card_link": "http://www.colormind.io/", "card_title": "www.colormind.io", "card_theme": null, "card_type": "image", "card_description": "Colormind - the AI powered color palette generator", "icon_img_src": "http://colormind.io/favicon32.gif", "link_img_src": null, "added_at": "2023-06-01T16:01:24.002417", "updated_at": "2023-07-07T20:36:14.372377"}, 
    {"card_xid": "b492a08449ded18dc8b09cdbbbec0f0917687bdb9082dd8b7e5b1b31cc458169", "card_link": "https://icolorpalette.com", "card_title": "icolorpalette.com", "card_theme": null, "card_type": "image", "card_description": "Create, Inspire and Share awesome color schemes | iColorpalette", "icon_img_src": "https://icolorpalette.com/apple-touch-icon-114x114.png", "link_img_src": null, "added_at": "2023-04-21T02:48:29.189276", "updated_at": "2023-07-07T20:36:14.396992"}, 
    {"card_xid": "e2b2b47535ebb47c2f5bd09a125277f29098b9bbc8f6979c84bcbdd3fb7ca03c", "card_link": "https://geenes.app/", "card_title": "geenes.app", "card_theme": null, "card_type": "image", "card_description": "Geenes", "icon_img_src": "https://geenes.app/img/icons/apple-touch-icon-152x152.png", "link_img_src": null, "added_at": "2023-05-20T00:33:17.375966", "updated_at": "2023-07-07T20:36:14.625274"}, 
    {"card_xid": "9ef2e68a27b93e5b8f7743972268023529704189a93ce755ff205a709a21ace8", "card_link": "https://maketintsandshades.com/", "card_title": "maketintsandshades.com", "card_theme": null, "card_type": "image", "card_description": "Tint and Shade Generator", "icon_img_src": "https://maketintsandshades.com/apple-touch-icon.png", "link_img_src": "https://maketintsandshades.com/images/og-image.jpg", "added_at": "2023-06-08T01:25:58.340061", "updated_at": "2023-07-07T20:36:14.643650"}, 
    {"card_xid": "66ac3cafa8817b50fe0d3aba3c61c29ca74a3166afd8d4c473e96cb0f1129cf1", "card_link": "https://enes.in/sorted-colors/", "card_title": "enes.in", "card_theme": null, "card_type": "image", "card_description": "Sorted CSS Colors", "icon_img_src": "https://enes.in/sorted-colors/images/icon-152.png", "link_img_src": "https://github.com/scriptype/sorted-colors/raw/master/screenshot.png", "added_at": "2023-04-05T00:43:13.982085", "updated_at": "2023-07-07T20:36:14.652132"}, 
    {"card_xid": "aabf754605ec6ba0be44879e459df82b4fd5050f1088daaf75a7dd924107ece1", "card_link": "https://croma.app", "card_title": "croma.app", "card_theme": null, "card_type": "image", "card_description": "Croma - Palette Manager | Croma - Palette Manager(Now renamed as HueHive) - Buzzing with inspiration for your color palettes", "icon_img_src": "https://croma.app/assets/assets/apple-touch-icon-72x72.png", "link_img_src": "https://croma.app/assets/images/large-logo.png", "added_at": "2023-04-17T13:15:01.017959", "updated_at": "2023-07-07T20:36:14.701212"}, 
    {"card_xid": "459bd83d4c7dd08a59233a67ee9c153135883a7098bce68781e938cba0ffea57", "card_link": "https://hexcolorpedia.com/", "card_title": "hexcolorpedia.com", "card_theme": null, "card_type": "image", "card_description": "Hex Color Codes and Color Palettes", "icon_img_src": "https://hexcolorpedia.com/wp-content/themes/bootscore-5-child/img/favicon/apple-touch-icon.png", "link_img_src": "https://i0.wp.com/hexcolorpedia.com/wp-content/uploads/2021/05/hexicon-01.png?fit=400%2C400&ssl=1", "added_at": "2023-04-29T15:24:07.849023", "updated_at": "2023-07-07T20:36:14.720172"}, 
    {"card_xid": "7b87fcf8ca9f586470ee9b94630a6b139fca7cddd3167e3dd6f88a326660216d", "card_link": "https://atmos.style/", "card_title": "atmos.style", "card_theme": null, "card_type": "image", "card_description": "Atmos | Everything you need to create color palettes", "icon_img_src": "https://atmos.style/apple-touch-icon.png", "link_img_src": "https://atmos.style/meta-image.jpg", "added_at": "2023-06-16T12:57:31.087900", "updated_at": "2023-07-07T20:36:14.806125"}, 
    {"card_xid": "7daeaecd2a749617a5b395c3352b34518993c2ca4ed5c207bf6b2ced6e17a523", "card_link": "https://www.loopple.com/color-palette-generator", "card_title": "www.loopple.com", "card_theme": null, "card_type": "image", "card_description": "Loopple - Drag and drop dashboard builder", "icon_img_src": "https://www.loopple.com/img/favicon.png", "link_img_src": "https://raw.githubusercontent.com/Loopple/loopple-public-assets/main/ai/ai-color-palette-generator.png", "added_at": "2023-04-15T03:16:11.757382", "updated_at": "2023-07-07T20:36:14.867276"}, 
]
const profile = {
    xid: 'AD15_5806_687B',
    name_key: 'colour_tools',
    theme_color: '#4F302B', 
    title: 'Colour Tools',
    img_src: 'https://img.freepik.com/free-vector/colour-painting-plate-with-brush-isolated_1308-57589.jpg?w=1380&t=st=1686894879~exp=1686895479~hmac=263fc3509c3f18c07835ee7cd159136ce09ebcb659cbc83ecce1d8ea2c6922b5',
    description: [
        "Web colours made easy",
    ],
}
const author = {
    key: 'amit_rajput',
    name: 'Amit Rajput',
    img_src: 'https://img.freepik.com/free-photo/fun-3d-cartoon-illustration-indian-businessman_183364-114475.jpg?w=740&t=st=1686919588~exp=1686920188~hmac=e4fee9a9d3325b74960167c43886a3d9ca5bbe025d31b4c4766d37b75ea2bbdc',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
