const cards = [
    {"card_xid": "e6e83b610d6e7d09fdc1faebca5caf806009d8fe3e203728e08a8afeb5d94056", "card_link": "https://www.shopify.com/email-marketing", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Shopify Email: Drive sales and build strong customer relationships. - Shopify USA", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "https://cdn.shopify.com/shopifycloud/brochure/assets/email-marketing/share-image-large-53a004655fd474fa0ec7cb8e9bde7b56b87929f889f8ef5b2a96f280e0b6568c.jpg", "added_at": "2023-05-06T23:27:57.330859", "updated_at": "2023-07-07T20:32:36.500544"}, 
    {"card_xid": "0d3e379517270838f61181e4510abbfca0fd592c84cfefaad7d883b46df0432c", "card_link": "https://mailchimp.com/", "card_title": "mailchimp.com", "card_theme": null, "card_type": "image", "card_description": "Marketing, Automation & Email Platform | Mailchimp", "icon_img_src": "https://mailchimp.com/release/plums/cxp/images/apple-touch-icon-192.ce8f3e6d.1.png", "link_img_src": "https://eep.io/images/yzco4xsimv0y/24H6wd91AK6dsK0oZqflB6/002bed565a1a474d01ac6469bcc89cbb/D3_General.png", "added_at": "2023-04-24T13:28:50.038210", "updated_at": "2023-07-07T20:32:36.566141"}, 
    {"card_xid": "685c64ffde40e90c06851a2b75e4c7bd5621c057e5f2d5725a9cd65176b6b115", "card_link": "https://apps.shopify.com/mailchimp", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Mailchimp: Email Marketing - Mailchimp for Shopify - Email and Marketing Automation | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/5a5385cf8a756c3fc55c4fb80706f9f6/icon/CLa98_yf6PMCEAE=.png", "added_at": "2023-06-04T07:46:06.879684", "updated_at": "2023-07-07T20:32:36.769237"}, 
    {"card_xid": "2730ebdaa3e210e28c3c9f66b63f08e61bc6759e191d60e66fe306b60e521f96", "card_link": "https://www.klaviyo.com/", "card_title": "www.klaviyo.com", "card_theme": null, "card_type": "image", "card_description": "Marketing Automation Platform for Email, SMS & Push | Klaviyo", "icon_img_src": "https://marketinguiproduction.gatsbyjs.io/icons/icon-192x192.png?v=0fe6c9777057a06e76a2ec177be948b9", "link_img_src": "https://www.klaviyo.com/_gatsby/file/fe66c4b71e86ce64dfcc3ed0cf8854f8/klaviyo-social-share-image.jpg?u=https%3A%2F%2Fklaviyocms.wpengine.com%2Fwp-content%2Fuploads%2F2022%2F03%2Fklaviyo-social-share-image.jpg", "added_at": "2023-05-29T12:30:12.134566", "updated_at": "2023-07-07T20:32:36.838405"}, 
    {"card_xid": "2a1263784c0a9431ab02007c2608c20414d64cd0733b22d2ec98fb172c234bfc", "card_link": "https://apps.shopify.com/klaviyo-email-marketing", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Klaviyo: Email Marketing & SMS - Email, SMS, and more - a unified customer platform | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/5edd9000b933a8fa88c152d1e498531f/icon/CP6B2OOv3PYCEAE=.png", "added_at": "2023-04-24T14:43:59.207783", "updated_at": "2023-07-07T20:32:36.879622"}, 
    {"card_xid": "fedc993213096cfe95274ceb9bfb93a575d706d0950bef1c3c596265c7a078fe", "card_link": "https://www.automizely.com/marketing", "card_title": "www.automizely.com", "card_theme": null, "card_type": "image", "card_description": "AfterShip Email: Shopify Email Marketing Automation App", "icon_img_src": "https://www.aftership.com/apple-touch-icon-180x180.png", "link_img_src": null, "added_at": "2023-05-23T20:09:23.139700", "updated_at": "2023-07-07T20:32:36.946175"}, 
    {"card_xid": "82a38413931fb17347a985fe66d40549870a2a942ce7ff42b76f3eaca6839077", "card_link": "https://apps.shopify.com/automizely-messages", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Automizely Email Marketing,SMS - Email, Popup, SMS, Marketing Tool All-in-One | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/b8bc9d365792ea16185361099864182c/icon/COb3zP2Pwv4CEAE=.png", "added_at": "2023-05-14T14:10:22.172865", "updated_at": "2023-07-07T20:32:37.179774"}, 
    {"card_xid": "ea5198fea726ef724cf4ccfe7435fd7ce77447ac2eabdb34264a686481e6c63a", "card_link": "https://buffer.com/", "card_title": "buffer.com", "card_theme": null, "card_type": "image", "card_description": "Buffer: All-you-need social media toolkit for small businesses", "icon_img_src": "https://buffer.com/static/icons/apple-touch-icon.png", "link_img_src": "https://buffer.com/static/ogImages/homepage-anz-og.png", "added_at": "2023-05-22T05:45:25.420202", "updated_at": "2023-07-07T20:32:37.247788"}, 
    {"card_xid": "cb6fef23189b691e1d9b4599235981f50d75f7d438f7c2c249debe1bd95dd6b7", "card_link": "https://www.hootsuite.com/", "card_title": "www.hootsuite.com", "card_theme": null, "card_type": "image", "card_description": "Social Media Marketing and Management Tool", "icon_img_src": "https://www.hootsuite.com/images/apple-touch-icon.png", "link_img_src": "https://www.hootsuite.com/images/social-preview.jpg", "added_at": "2023-05-23T15:58:44.972224", "updated_at": "2023-07-07T20:32:37.305451"}, 
    {"card_xid": "e42d7159afdab754c7ba0cb3a9a0cbb07963f0e29decbf60261ed122d23a850f", "card_link": "https://followerwonk.com/", "card_title": "followerwonk.com", "card_theme": null, "card_type": "image", "card_description": "Followerwonk: Tools for Twitter Analytics, Bio Search and More", "icon_img_src": "https://followerwonk.com/images/fw_favicon.ico", "link_img_src": null, "added_at": "2023-04-05T19:44:58.048454", "updated_at": "2023-07-07T20:32:37.343376"}, 
    {"card_xid": "994a283fdb30d5685b94c35bbe7914a9156681abc6d5d88bc97b44611e9f59d0", "card_link": "https://www.shopify.com/tools/online-video-maker", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Free Business Tools - Online Tools for Small Businesses", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "http://cdn.shopify.com/assets/images/logos/shopify-bag.png?1341928631", "added_at": "2023-04-16T09:26:29.958853", "updated_at": "2023-07-07T20:32:37.351321"}, 
    {"card_xid": "88f6fc27027723cfc51d6fa1386069de5d341c5e3ab63c3df163cdd2b309c013", "card_link": "https://www.adroll.com/", "card_title": "www.adroll.com", "card_theme": null, "card_type": "image", "card_description": "\n              \n        Digital Marketing & Growth Marketing Platform | AdRoll\n  ", "icon_img_src": "https://www.adroll.com/favicon.png", "link_img_src": "https://www.adroll.com/assets/img/assets/img/globals/social-shares/fb/hp.png", "added_at": "2023-04-26T15:20:02.787027", "updated_at": "2023-07-07T20:32:37.431464"}, 
    {"card_xid": "aa5ea8416656091ea1b6a64c37387207de17e77e60c252a0f90a01e2ffb7204b", "card_link": "https://apps.shopify.com/adroll-retargeting", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "AdRoll Marketing & Advertising - AdRoll + Shopify: Run Ads on Google, TikTok, Facebook &... | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/6ebaf39a3592a11d95d4436f522e6bc0/icon/CI3h88L0lu8CEAE=.png", "added_at": "2023-04-02T05:50:59.790178", "updated_at": "2023-07-07T20:32:37.510768"}, 
    {"card_xid": "24cf4f2b064fd6a3a99d3ce616c82c4658732fc66c47f48bdf64538ed2951e51", "card_link": "https://vimeo.com/create", "card_title": "vimeo.com", "card_theme": null, "card_type": "image", "card_description": "Online video maker | Make a video in minutes with Vimeo", "icon_img_src": "https://f.vimeocdn.com/images_v6/favicon.ico?12efd54dde7bc4920c3a973b64f211b8461343b5", "link_img_src": "https://i.vimeocdn.com/custom_asset/2804.jpg", "added_at": "2023-04-03T20:53:46.000029", "updated_at": "2023-07-07T20:32:37.576257"}, 
    {"card_xid": "e47ddcb61fdebe3659d450198f79265c58bc562bd2a769bcba53ad017c6516fd", "card_link": "https://apps.shopify.com/vimeo_product_video_maker", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Vimeo Create \u2011 Video Maker - Turn your Product Photos into Stunning Videos that Sell! | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/c2fef160546fe3123ea86075e7b2b053/icon/CN7-07n0lu8CEAE=.png", "added_at": "2023-04-01T16:20:32.477082", "updated_at": "2023-07-07T20:32:37.635117"}, 
    {"card_xid": "ec5d93f2af7347040e301dd92fc5f9e55840fcf5cc482ebdae660c8a0beacfbd", "card_link": "https://hemingwayapp.com/", "card_title": "hemingwayapp.com", "card_theme": null, "card_type": "image", "card_description": "Hemingway Editor", "icon_img_src": "https://hemingwayapp.com/img/favicon/apple-touch-icon-120x120.png", "link_img_src": null, "added_at": "2023-04-09T19:16:29.289055", "updated_at": "2023-07-07T20:32:37.694152"}, 
    {"card_xid": "276aea38e46f064733c796c0b7573bf5080fd66bf46e2d21120e4e3c91c02115", "card_link": "https://simplecast.com/", "card_title": "simplecast.com", "card_theme": null, "card_type": "image", "card_description": "Podcast Hosting, Distribution & Analytics - Simplecast", "icon_img_src": "https://www.simplecast.com/hubfs/favicon.ico", "link_img_src": null, "added_at": "2023-04-27T08:52:55.771929", "updated_at": "2023-07-07T20:32:37.706846"}, 
    {"card_xid": "dfb7e6798c0adf7491c17d0c74377cf94949f846be8f1eb472450f52c2adab62", "card_link": "https://buzzsumo.com/", "card_title": "buzzsumo.com", "card_theme": null, "card_type": "image", "card_description": "\n          BuzzSumo | Media Mentions In Minutes. Content Ideas For Days.      ", "icon_img_src": "https://buzzsumo.com/wp-content/themes/brandwatch/src/site--buzzsumo.com/assets/img/favicon.ico", "link_img_src": "", "added_at": "2023-04-12T02:27:40.075558", "updated_at": "2023-07-07T20:32:37.765958"}, 
    {"card_xid": "7a9443acb93703f659899b10786a263ca783ece415ff4ccbfce3f2cfafc073e8", "card_link": "https://moz.com/", "card_title": "moz.com", "card_theme": null, "card_type": "image", "card_description": "Moz - SEO Software for Smarter Marketing", "icon_img_src": "https://moz.com/favicon.ico", "link_img_src": "https://moz.com/images/cms/moz-stairs-homepage-banner-v2.jpg?w=1200&h=630&q=82&auto=format&fit=crop&crop=focalpoint&fp-x=0.6423&fp-y=0.497&dm=1549300321&s=4ade1686bcefc80937fc976e3a22d6f4", "added_at": "2023-04-17T02:53:50.293320", "updated_at": "2023-07-07T20:32:37.843363"}, 
    {"card_xid": "eaf0818edd218820e696c6112512f9f2fafa3a8bd4ba93664c7fa0ebf9bb3d47", "card_link": "https://www.semrush.com/", "card_title": "www.semrush.com", "card_theme": null, "card_type": "image", "card_description": "\n    \n        Semrush - Online Marketing Can Be Easy\n    \n", "icon_img_src": "https://www.semrush.com/__static__/apple-touch-icon.png", "link_img_src": "https://static.semrush.com/wt-static/semrush-com.png", "added_at": "2023-05-06T11:08:15.003084", "updated_at": "2023-07-07T20:32:37.917603"}, 
    {"card_xid": "6b04b833e93f99eddb65f111e21bc8b834836b3096d6c5c3b1f2ba4d371f3793", "card_link": "https://apps.shopify.com/avada-seo-suite", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "SEO:Image Optimizer Page Speed - Shopify SEO App: SEO Checklist, Tools, Optimization, Guides | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/12c5f7113c40f4e4e5a57cad4ff6464f/icon/CMnGhqeTsPwCEAE=.jpeg", "added_at": "2023-05-23T20:37:53.011945", "updated_at": "2023-07-07T20:32:38.038464"}, 
    {"card_xid": "6b04b833e93f99eddb65f111e21bc8b834836b3096d6c5c3b1f2ba4d371f3793", "card_link": "https://apps.shopify.com/avada-seo-suite", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "SEO:Image Optimizer Page Speed - Shopify SEO App: SEO Checklist, Tools, Optimization, Guides | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/12c5f7113c40f4e4e5a57cad4ff6464f/icon/CMnGhqeTsPwCEAE=.jpeg", "added_at": "2023-05-23T20:37:53.011945", "updated_at": "2023-07-07T20:32:38.101575"}, 
    {"card_xid": "6b04b833e93f99eddb65f111e21bc8b834836b3096d6c5c3b1f2ba4d371f3793", "card_link": "https://apps.shopify.com/avada-seo-suite", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "SEO:Image Optimizer Page Speed - Shopify SEO App: SEO Checklist, Tools, Optimization, Guides | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/12c5f7113c40f4e4e5a57cad4ff6464f/icon/CMnGhqeTsPwCEAE=.jpeg", "added_at": "2023-05-23T20:37:53.011945", "updated_at": "2023-07-07T20:32:38.163773"}, 
    {"card_xid": "82ca0a39eb139d55451bcd738dd5bed376a57f4f023c6d93697c97082f807bbb", "card_link": "https://www.shopify.com/market", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Marketing on Shopify - Shopify USA", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "https://cdn.shopify.com/shopifycloud/brochure/assets/market/social-share-large-587a002d0ec1f051e731f1a9df6d3d35184e45b3b083488869a7bd5137e72079.png", "added_at": "2023-05-08T03:19:13.206359", "updated_at": "2023-07-07T20:32:38.264182"}, 
    {"card_xid": "7de031c87f7d8704886861c36ccde8c7be49e886f5a40621e1e4f260a39b6249", "card_link": "https://sendoso.com/", "card_title": "sendoso.com", "card_theme": null, "card_type": "image", "card_description": "403 Forbidden", "icon_img_src": "https://sendoso.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-12T23:30:03.132253", "updated_at": "2023-07-07T20:32:38.325968"}, 
    {"card_xid": "011d73249051da5fc32032545dec502b7fb95a36c1300656d79c75e43f8f6afc", "card_link": "https://postie.com/", "card_title": "postie.com", "card_theme": null, "card_type": "image", "card_description": "Postie", "icon_img_src": "https://postie.com/wp-content/uploads/2021/09/cropped-Favicon-1-180x180.png", "link_img_src": null, "added_at": "2023-04-23T12:45:30.312372", "updated_at": "2023-07-07T20:32:38.327947"}, 
    {"card_xid": "31d11f63e3c14cb861c52a1e531b81bf681b9aa79fbca7c61557c8319b51ec5f", "card_link": "https://www.hubspot.com/", "card_title": "www.hubspot.com", "card_theme": null, "card_type": "image", "card_description": "HubSpot | Software, Tools, Resources for Your Business", "icon_img_src": "https://www.hubspot.com/hubfs/HubSpot_Logos/HubSpot-Inversed-Favicon.png", "link_img_src": "https://53.fs1.hubspotusercontent-na1.net/hubfs/53/assets/hubspot.com/buzz/HubSpotOpenGraph.png#keepProtocol", "added_at": "2023-04-27T22:38:05.891340", "updated_at": "2023-07-07T20:32:38.385789"}, 
    {"card_xid": "efa0b5451b9be465bd16e87e952e5257d3211f76fc69a3cc9b506d8cc388eefc", "card_link": "https://apps.shopify.com/hubspot", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "HubSpot - Sync store data and run powerful sales and marketing campaigns | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/5fa2eaaedf917e16dd24503ecdfb940c/icon/CPTl6_vhpvoCEAE=.png", "added_at": "2023-04-30T06:55:07.246981", "updated_at": "2023-07-07T20:32:38.460633"}, 
    {"card_xid": "2121ccf290553590ddaa7ef65ca80ebd9809cc2ed57b10cfee4ea0f694189157", "card_link": "https://apps.shopify.com/one", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "ChatGPT\u2014Upsell\u2014Email Marketing - All\u2011in\u2011ONE Store | Apps Seamlessly Integrated to Boost Sales | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/5bdeb0a5ba2b3641097f008a67cc23eb/icon/CN_DqYjGq_UCEAE=.png", "added_at": "2023-04-18T08:04:35.134295", "updated_at": "2023-07-07T20:32:38.516856"}, 
    {"card_xid": "2121ccf290553590ddaa7ef65ca80ebd9809cc2ed57b10cfee4ea0f694189157", "card_link": "https://apps.shopify.com/one", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "ChatGPT\u2014Upsell\u2014Email Marketing - All\u2011in\u2011ONE Store | Apps Seamlessly Integrated to Boost Sales | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/5bdeb0a5ba2b3641097f008a67cc23eb/icon/CN_DqYjGq_UCEAE=.png", "added_at": "2023-04-18T08:04:35.134295", "updated_at": "2023-07-07T20:32:38.583143"}, 
    {"card_xid": "76085920b25b0b28c10558284e245ff99764574631c2fb1d75987eb1cacf4394", "card_link": "https://unbounce.com/", "card_title": "unbounce.com", "card_theme": null, "card_type": "image", "card_description": "Unbounce - The Landing Page Builder & Platform", "icon_img_src": "https://unbounce.com/photos/cropped-unbounce-favicon-2-180x180.png", "link_img_src": "https://unbounce.com/photos/opengraph_homepage.jpg", "added_at": "2023-04-26T19:17:24.978829", "updated_at": "2023-07-07T20:32:38.647847"}, 
    {"card_xid": "46dc85c0ce7cc92c35dce0a0d410186b88b1afbfa5c008b7ac55a77ba45facf1", "card_link": "https://apps.shopify.com/octane-ai-messenger-marketing", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Octane AI: Quiz & AI Insights - Quiz builder with AI, custom design, flow, logic. AI insights. | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/660d02b21dc203afa3147b8a84d27cf3/icon/CMC_7Z38gfQCEAE=.png", "added_at": "2023-04-27T23:09:05.155094", "updated_at": "2023-07-07T20:32:38.684055"}, 
    {"card_xid": "46dc85c0ce7cc92c35dce0a0d410186b88b1afbfa5c008b7ac55a77ba45facf1", "card_link": "https://apps.shopify.com/octane-ai-messenger-marketing", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Octane AI: Quiz & AI Insights - Quiz builder with AI, custom design, flow, logic. AI insights. | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/660d02b21dc203afa3147b8a84d27cf3/icon/CMC_7Z38gfQCEAE=.png", "added_at": "2023-04-27T23:09:05.155094", "updated_at": "2023-07-07T20:32:38.746057"}, 
    {"card_xid": "3cff2f3c2146ffe95214fe6b6493077ead44718e935c98820e6474990a3abf59", "card_link": "https://apps.shopify.com/recent-sales-popup-notifications-1", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Sales Pop\u2011up Conversion Pro - Sales Pop | Sales Notification | Free Shipping Bar App | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/b40b566cff7cff84be1b458d929b1642/icon/CKyEjcnFzvwCEAE=.png", "added_at": "2023-04-07T03:25:12.121593", "updated_at": "2023-07-07T20:32:38.806822"}, 
    {"card_xid": "3cff2f3c2146ffe95214fe6b6493077ead44718e935c98820e6474990a3abf59", "card_link": "https://apps.shopify.com/recent-sales-popup-notifications-1", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Sales Pop\u2011up Conversion Pro - Sales Pop | Sales Notification | Free Shipping Bar App | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/b40b566cff7cff84be1b458d929b1642/icon/CKyEjcnFzvwCEAE=.png", "added_at": "2023-04-07T03:25:12.121593", "updated_at": "2023-07-07T20:32:38.866230"}, 
    {"card_xid": "9c374f3b3dfb67c7274b33f6c58a9f7ee84d33b00fa29d2eabfb39d2f87af30f", "card_link": "https://apps.shopify.com/inbox", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Shopify Inbox - Connect with shoppers and drive sales with chat \u2013 for... | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/5c413ee331721e49374ce06d0a7edc1b/icon/CPrw4d6I5fECEAE=.png", "added_at": "2023-06-04T18:04:46.841127", "updated_at": "2023-07-07T20:32:38.963916"}, 
    {"card_xid": "960e953ccf6a0e95b8c74ca9bea3cd98905f6957e21ea7669b4eb0c43e293b48", "card_link": "https://www.gorgias.com/", "card_title": "www.gorgias.com", "card_theme": null, "card_type": "image", "card_description": "Customer Service Made Easy for Online Stores | Gorgias", "icon_img_src": "https://assets-global.website-files.com/5e4ff204e7b6f80e402d407a/620a804c565c1d36b8e7e92f_Gorgias%20Mark%20256x256.png", "link_img_src": "https://assets-global.website-files.com/5e4ff204e7b6f80e402d407a/61dea8322ebadeb00beb851c_Homepage_OG.png", "added_at": "2023-04-15T16:47:07.191470", "updated_at": "2023-07-07T20:32:39.021528"}, 
    {"card_xid": "f202c04de5b67fa80bfbcd9335c116ff812c0995b348ee364004f1b86e641da2", "card_link": "https://apps.shopify.com/helpdesk", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Gorgias \u2011 Helpdesk & Live Chat - Happier customers. Easier workflows. Faster revenue growth. | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/d783d0d0ded4ab7a13c20f47533819a3/icon/CNrzhbD8hfkCEAE=.png", "added_at": "2023-06-02T14:54:16.975893", "updated_at": "2023-07-07T20:32:39.096437"}, 
    {"card_xid": "9746621ad898f85616cb39109e31ead305826066f36030ef5e57b2ffbe194c46", "card_link": "https://www.tidio.com/", "card_title": "www.tidio.com", "card_theme": null, "card_type": "image", "card_description": "Customer Service Software To Grow Your Business | Tidio", "icon_img_src": "https://www.tidio.com/images/apple-touch-icon.png", "link_img_src": "https://www.tidio.com/wp-content/uploads/tidio-facebook-cover.jpg", "added_at": "2023-04-23T11:14:07.191263", "updated_at": "2023-07-07T20:32:39.162009"}, 
    {"card_xid": "dd3261165fc753080066cd81e9a7081193c04e996363b7653bdc3fd57951a7b0", "card_link": "https://apps.shopify.com/tidio-chat", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Tidio Live Chat & AI Chatbots - Boost your Shopify store with Tidio Live Chat & Chatbots | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/c9308d01d0e596807cd697bab16009a1/icon/CJj2z7GLi_sCEAE=.png", "added_at": "2023-04-15T10:52:03.860004", "updated_at": "2023-07-07T20:32:39.206853"}, 
    {"card_xid": "ee76fbba779289eb77a3c0b752cd1001a89602ced7ccb52407990528087bc43d", "card_link": "https://www.canva.com/", "card_title": "www.canva.com", "card_theme": null, "card_type": "image", "card_description": "Free Design Tool: Presentations, Video, Social Media | Canva", "icon_img_src": "https://static.canva.com/static/images/apple-touch-152x152-1.png", "link_img_src": "https://static.canva.com/anon_home/og-image-1200x630.jpg", "added_at": "2023-04-15T16:29:12.139657", "updated_at": "2023-07-07T20:32:39.275131"}, 
    {"card_xid": "1fefdc724db9cccd61a00d0884c26e2990a5d81fb4b0a6d36064845c490ec288", "card_link": "https://www.shopify.com/tools/business-card-maker", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Free Business Card Maker: Design Business Cards Online (2023)", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "http://cdn.shopify.com/assets/images/logos/shopify-bag.png?1341928631", "added_at": "2023-05-17T20:46:19.284919", "updated_at": "2023-07-07T20:32:39.393258"}, 
    {"card_xid": "e8e86a2860b5b6755dddf6b0df310c63321851605d10da0e1c1b322aea99a777", "card_link": "https://www.shopify.com/tools", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Free Business Tools - Online Tools for Small Businesses", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "http://cdn.shopify.com/assets/images/logos/shopify-bag.png?1341928631", "added_at": "2023-06-16T21:21:12.368297", "updated_at": "2023-07-07T20:32:39.510880"}, 
    {"card_xid": "1f10fa1a59182d5c2271bcb25b2abab1e1fa515984f2cc4e5e51b17120fcd08e", "card_link": "https://promo.com/", "card_title": "promo.com", "card_theme": null, "card_type": "image", "card_description": "Video Maker | Create Videos Online | Promo.com", "icon_img_src": "https://promo.com/favicons/favicon180x180.png", "link_img_src": "https://ak01-promo-cdn.slidely.com/images/promoVideos/default-og.jpg/1687261806", "added_at": "2023-04-08T07:01:44.946179", "updated_at": "2023-07-07T20:32:39.624437"}, 
    {"card_xid": "32d37d0c692af6fed19f9f612c0526ff50d5f22e4073b07b6bfaf4e521eae389", "card_link": "https://apps.shopify.com/promo-com-promo-video-maker", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Promo.com \u2011 Promo Video Maker - Promo.com for Shopify | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/9d39ab6a946cc7020f53c76a25b44bf8/icon/CJvLgs70lu8CEAE=.png", "added_at": "2023-04-27T04:26:51.888323", "updated_at": "2023-07-07T20:32:39.715184"}, 
    {"card_xid": "e8e8172ed6dca17c45c3e41dbeb1d11a04a76a73d695e71659d53dec6ef79437", "card_link": "https://www.arka.com/", "card_title": "www.arka.com", "card_theme": null, "card_type": "image", "card_description": "\n      Design Eco Friendly Custom Packaging & Sustainable Boxes Online\n\n        \u2013\n        Arka\n      ", "icon_img_src": "https://cdn.shopify.com/s/files/1/0641/3064/6253/files/Arka_icon_64741e87-17da-4fa3-917a-6b81fc7c6d6f.png?crop=center&height=32&v=1651778306&width=32", "link_img_src": "http://cdn.shopify.com/s/files/1/0641/3064/6253/files/logo2.png?v=1664316507", "added_at": "2023-05-12T15:26:48.467916", "updated_at": "2023-07-07T20:32:39.774497"}, 
    {"card_xid": "7b405ac55fc718a70fd96b6be6c761a80f72b4f91704287b431f16e60d8bd9f8", "card_link": "https://apps.shopify.com/arka-custom-product-packaging", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Oh no! That page doesn\u2019t exist.", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": null, "added_at": "2023-06-03T22:45:19.862869", "updated_at": "2023-07-07T20:32:39.832694"}, 
    {"card_xid": "e5b40c7fcd19cfe504e457d139bc52af155b4cb5d39853147e654dd8cfe77aac", "card_link": "https://asana.com/", "card_title": "asana.com", "card_theme": null, "card_type": "image", "card_description": "Manage your team\u2019s work, projects, & tasks online \u2022 Asana", "icon_img_src": "https://d1gwm4cf8hecp4.cloudfront.net/images/favicons/apple-touch-icon-76x76.png", "link_img_src": "https://assets.asana.biz/m/4aecf2c7c66aa51b/original/asana-meta-coral-1x.png", "added_at": "2023-04-22T03:53:15.193154", "updated_at": "2023-07-07T20:32:39.855577"}, 
    {"card_xid": "d5d7b93dce94f0f4169b6b842edcc9cb93ccb97a21738fb6bcf4c1c0133b0c32", "card_link": "https://workspace.google.com/", "card_title": "workspace.google.com", "card_theme": null, "card_type": "image", "card_description": "\n      Google Workspace | Business Apps & Collaboration Tools\n    ", "icon_img_src": "https://workspace.google.com/static/favicon.ico?cache=4926369", "link_img_src": "https://lh3.googleusercontent.com/wZQCaQrSWMtfsI1l8JGBb8293-fl3CgugMKV6jo_NNdxYKOzMi28G-qaQktF7CXN4wOd8jWtruu75Kqd_65GweDZ2_x5PRXfL3o8PQ", "added_at": "2023-05-14T10:49:44.148017", "updated_at": "2023-07-07T20:32:39.987298"}, 
    {"card_xid": "e5d304c1d5d0fd195f815dfd03c1dcc52db5640c8f4d5e118bdb6a8529f7d77d", "card_link": "https://slack.com/", "card_title": "slack.com", "card_theme": null, "card_type": "image", "card_description": "Slack is your productivity platform | Slack", "icon_img_src": "https://a.slack-edge.com/5f35cf0/img/icons/favicon-32-ua.png", "link_img_src": "https://a.slack-edge.com/8ce325c/marketing/img/homepage/e2e-prospects/unfurl/slack-e2e-homepage-unfurl.en-GB.jpg", "added_at": "2023-06-07T04:15:27.352075", "updated_at": "2023-07-07T20:32:40.096729"}, 
    {"card_xid": "23c7898f8bd428f31f74e925ff06ce780f4a1ac8050789454ce6dfa02b85149d", "card_link": "https://basecamp.com/", "card_title": "basecamp.com", "card_theme": null, "card_type": "image", "card_description": "Basecamp: Project management software, online collaboration", "icon_img_src": "https://basecamp.com/assets/general/apple-touch-icon-aa535b32a95bf807024f70929a6c30b13785da00686e07b5f5dbc794c3fc33bb.png", "link_img_src": "https://basecamp.com/assets/general/opengraph-182182e4c9a0a44b01adf12480e0fb7e4d92210e0c8176f28f9293f285252a02.png", "added_at": "2023-05-29T08:44:26.580329", "updated_at": "2023-07-07T20:32:40.140291"}, 
    {"card_xid": "9ed054b33d63a251c2001f82b824fe2fd83c2c8fbee68f45686d51ac30c04f2b", "card_link": "https://postscript.io/", "card_title": "postscript.io", "card_theme": null, "card_type": "image", "card_description": "Postscript - SMS Marketing and Sales for Shopify Brands", "icon_img_src": "https://postscript.io/favicon.ico", "link_img_src": "https://images.ctfassets.net/mpv16aisnpdx/4arZvAda0fA9OLvkPjUwhs/0388f87280ca1e18052bf11a10afb164/default_open-graph_seo.jpg?fit=fill&w=1200&h=630", "added_at": "2023-05-25T01:06:20.302449", "updated_at": "2023-07-07T20:32:40.162016"}, 
    {"card_xid": "911a5bf01aa14dcd51640a20835c5c9161cd76378471dfc54dee9aec450d6ccf", "card_link": "https://apps.shopify.com/postscript-sms-marketing", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Postscript SMS Marketing - Make SMS your #1 completely owned revenue channel | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/54fb9eb4e59e94fa0e3b1b173de78ca4/icon/CNnjpLa0g_sCEAE=.png", "added_at": "2023-04-05T12:14:09.740168", "updated_at": "2023-07-07T20:32:40.228983"}, 
    {"card_xid": "24b5690eb7aa367cf0d7a0065faa9ad9a060740634a2980e48c5106b77282e97", "card_link": "https://smsbump.com/", "card_title": "smsbump.com", "card_theme": null, "card_type": "image", "card_description": "\n                    SMSBump | SMS Marketing E-Commerce App for Shopify", "icon_img_src": "https://smsbump.com/img/website/apple-touch-icon-new.png", "link_img_src": "https://smsbump.com/img/og_images/homepage.png", "added_at": "2023-06-15T10:33:20.255057", "updated_at": "2023-07-07T20:32:40.290643"}, 
    {"card_xid": "55f6a543a648f0ca89c6658948f82e55751b816fbd6b4093c19e5d5565593e59", "card_link": "https://apps.shopify.com/smsbump", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "SMSBump: SMS Marketing & Email - Email & Text Marketing, SMS Automation and Abandoned Carts | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/a68804021383da136b6c1d18e7806937/icon/CNXv_Lbuz_gCEAE=.png", "added_at": "2023-06-15T10:44:46.896637", "updated_at": "2023-07-07T20:32:40.324157"}, 
    {"card_xid": "d4363e478be2fa95f166529ccf693c3c31e23f81aede5c1cd01c15e063e3eeef", "card_link": "https://www.attentivemobile.com/", "card_title": "www.attentivemobile.com", "card_theme": null, "card_type": "image", "card_description": "Attentive | Personalized Text Messaging for Innovative Brands", "icon_img_src": "https://assets-global.website-files.com/5f8dd331b45e61acc8ac985c/60257a5a028a1dd7cf9accb4_attentive_webclip_256x256.png", "link_img_src": "https://assets-global.website-files.com/5f8dd331b45e61acc8ac985c/606dedfeb8fb8b813ce79552_Homepage%20Sharing%20Image-01.png", "added_at": "2023-05-22T07:31:09.971986", "updated_at": "2023-07-07T20:32:40.434457"}, 
    {"card_xid": "8efc9658d595ad8152318ef7ca49919e8d1f3cfd8b66e9aaf786bded1118381d", "card_link": "https://analytics.google.com/analytics/web/", "card_title": "analytics.google.com", "card_theme": null, "card_type": "image", "card_description": "Google Analytics", "icon_img_src": "https://accounts.google.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-12T06:39:33.636643", "updated_at": "2023-07-07T20:32:40.604193"}, 
    {"card_xid": "27bcca6f146df3a598c6753fce2b43fda5196fa4ec95061b3083ac195bbee07a", "card_link": "https://www.luckyorange.com/", "card_title": "www.luckyorange.com", "card_theme": null, "card_type": "image", "card_description": "Lucky Orange Heatmaps, Recordings, Surveys | Conversion Funnel Tools", "icon_img_src": "https://www.luckyorange.com/images/icons/favicon-32x32.png", "link_img_src": "https://a.storyblok.com/f/110013/x/6b44b7c0b0/prod_survey.svg", "added_at": "2023-04-13T16:02:20.026616", "updated_at": "2023-07-07T20:32:40.636689"}, 
    {"card_xid": "5120909b95b20d4c14765fec754c0f38001c11142915adeaa70a5a21b49ff4c2", "card_link": "https://apps.shopify.com/lucky-orange", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Lucky Orange Heatmaps & Replay - Optimize your online store using Lucky Orange | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/6347e264c8d3e24d7b2e8687c58213a8/icon/CNWK6dflsPACEAE=.png", "added_at": "2023-04-28T12:31:58.697518", "updated_at": "2023-07-07T20:32:40.664283"}, 
    {"card_xid": "f124d66e30951bd837b69c0bd78f19ccd60752771a913d89c8531949a8aad6de", "card_link": "https://heap.io/", "card_title": "heap.io", "card_theme": null, "card_type": "image", "card_description": "Heap - Better Insights. Faster. | Heap", "icon_img_src": "https://www.heap.io/favicon/apple-touch-icon.png?v=1", "link_img_src": "https://images.ctfassets.net/jicu8fwm4fvs/57SlD3uXZskS7cHKEOwGEm/793173d449f782f05fad3531ee05a1a5/heap-logo-social-twitter-1200x675__2_.png?w=1200&h=627&fit=fill&q=60&fm=jpg&fl=progressive", "added_at": "2023-04-26T16:59:23.236362", "updated_at": "2023-07-07T20:32:40.727935"}, 
    {"card_xid": "bb94a3a6821c9a14fc2d7f7c8413f2e4c54371d406a6dc8e4974bcee61f3fc7f", "card_link": "https://www.shopify.com/tools/logo-maker", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Free Logo Maker - Create a Logo in Seconds - Shopify", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": null, "added_at": "2023-04-19T17:11:11.782428", "updated_at": "2023-07-07T20:32:40.800716"}, 
    {"card_xid": "4eafe9627d1e8ab8d634e7cf5b6de28fe0e81c812449b15025f214cc7d3e05a8", "card_link": "https://www.shopify.com/tools/business-name-generator", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "FREE Business Name Generator: Company Name Ideas (2023) - Shopify", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "http://cdn.shopify.com/assets/images/logos/shopify-bag.png?1341928631", "added_at": "2023-04-15T11:27:35.903838", "updated_at": "2023-07-07T20:32:40.823203"}, 
    {"card_xid": "48baef2759a670db4148d4083819cbf8bbe4b01bbc4f5932f0ef999fad20e97e", "card_link": "https://www.shopify.com/collabs/find-influencers", "card_title": "www.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Find Influencers & Increase Sales with Shopify Collabs \ud83d\udd25 - Shopify USA", "icon_img_src": "https://cdn.shopify.com/static/shopify-favicon.png", "link_img_src": "https://cdn.shopify.com/shopifycloud/brochure2/production/assets/find-influencers-social-share.fb3056b7.jpg", "added_at": "2023-05-05T08:11:34.972974", "updated_at": "2023-07-07T20:32:40.925101"}, 
    {"card_xid": "ef7157881129a88b950163deeea1053816174a53bd45d8305baed0a317d23aae", "card_link": "https://apps.shopify.com/dovetale", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Shopify Collabs - Shopify Collabs - Shopify's Influencer App | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/a48f805d99a4948755fcfd3bd15d1aec/icon/CO7CxvKy2fsCEAE=.png", "added_at": "2023-05-13T21:48:59.880439", "updated_at": "2023-07-07T20:32:40.981052"}, 
    {"card_xid": "fd90039ba58f7662380be7355dc5a077d11ddf481118958d306ee9597926a1b8", "card_link": "https://www.refersion.com/", "card_title": "www.refersion.com", "card_theme": null, "card_type": "image", "card_description": "Affiliate Marketing & Tracking Software | Refersion", "icon_img_src": "https://www.refersion.com/staticassets/icons/icon-256x256.png?v=33afbe9b74eeb677e6ef5ce604a4fb3a", "link_img_src": "https://cdn.refersion.com/wp-content/uploads/2022/08/18035151/Home-hero-new.webp", "added_at": "2023-05-03T00:47:53.895965", "updated_at": "2023-07-07T20:32:41.042338"}, 
    {"card_xid": "d530f15d254821cd347e9ce223f7cb6626c573ff6d263abe3bcd02bbab3715a0", "card_link": "https://apps.shopify.com/refersion", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Refersion: Affiliate Marketing - Affiliate Marketing & Tracking Software | Refersion | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/b021799cb411d24623b60612a6228f1b/icon/CPm-ltSQjvwCEAE=.jpeg", "added_at": "2023-04-10T06:44:32.247782", "updated_at": "2023-07-07T20:32:41.088846"}, 
    {"card_xid": "0fd2f940a59090f36d48f204da9bf2bccc323a440ea5e1187066470e4f08d4dc", "card_link": "https://smile.io/", "card_title": "smile.io", "card_theme": null, "card_type": "image", "card_description": "Smile.io - Easy-to-use loyalty programs for small businesses", "icon_img_src": "https://website-assets.smilecdn.co/assets/favicon/apple-touch-icon-27d4f55f34027a8d125d80f09b081d091e78db263ac5d81b7464e72664e58ba7.png", "link_img_src": "https://website-assets.smilecdn.co/assets/brand-community-179b124cd6cb5011461ba382b26bd92eff3f9bcbbc0bf094d781b4ba72a71cca.png", "added_at": "2023-06-02T17:14:01.052979", "updated_at": "2023-07-07T20:32:41.186976"}, 
    {"card_xid": "a76122417831b0f55dccd8ec3a1911bce5cb63a183a6ea1c15d05965a6979cca", "card_link": "https://apps.shopify.com/smile-io", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Smile: Loyalty & Rewards - Smile: Loyalty & Rewards - World's Most Trusted Loyalty App | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/439617d93d452f065b8cd6122493ccc3/icon/CPHVlb70lu8CEAE=.png", "added_at": "2023-05-22T23:41:22.248317", "updated_at": "2023-07-07T20:32:41.214508"}, 
    {"card_xid": "903cda90b841d107699289035f0ba58bfc4bb30952e5bcfd552189d125e5c03b", "card_link": "https://www.yotpo.com/", "card_title": "www.yotpo.com", "card_theme": null, "card_type": "image", "card_description": "Yotpo | eCommerce Retention Marketing Platform", "icon_img_src": "https://www.yotpo.com/wp-content/uploads/2021/03/cropped-yotpo-logo-lettermark-180x180.png", "link_img_src": "https://www.yotpo.com/wp-content/uploads/2023/05/Generic-social-home-page.png", "added_at": "2023-04-15T02:40:41.860934", "updated_at": "2023-07-07T20:32:41.284650"}, 
    {"card_xid": "3dc2a1dd26b8dc859dc1bb014601830925a7bde6e407695889ffe8d5ec0a4f30", "card_link": "https://apps.shopify.com/swell", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "Yotpo: Loyalty & Rewards - Loyalty, Referrals & Rewards by Yotpo (formerly Swell) | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/f693caaaf4c437600611eb42fc8da5d3/icon/CKz31_O0zfgCEAE=.png", "added_at": "2023-05-08T00:06:56.253944", "updated_at": "2023-07-07T20:32:41.450447"}, 
    {"card_xid": "0a9a4a6f37e986ae618901f0e1ffb96416f615973c492f5eb1c2721c7e1152e4", "card_link": "https://loyaltylion.com/", "card_title": "loyaltylion.com", "card_theme": null, "card_type": "image", "card_description": "Official: LoyaltyLion | Customer loyalty platform | Loyalty software", "icon_img_src": "https://loyaltylion.com/app/uploads/2019/01/cropped-favicon-180x180.png", "link_img_src": "https://loyaltylion.com/app/uploads/2020/12/LoyaltyLion-loyalty-platform.png", "added_at": "2023-04-15T15:33:16.179991", "updated_at": "2023-07-07T20:32:41.512915"}, 
    {"card_xid": "7400d93e572d4456c6a3f3ff0349d3babcdb863d377627c50c3c01e7066f145c", "card_link": "https://apps.shopify.com/loyaltylion", "card_title": "apps.shopify.com", "card_theme": null, "card_type": "image", "card_description": "LoyaltyLion Rewards & Referral - LoyaltyLion | Customer loyalty and retention platform | Shopify App Store", "icon_img_src": "https://apps.shopify.com/cdn/shopifycloud/shopify_app_store/assets/favicon-7264cd52115ee17f2d4ad8f30580c39ea5dd8171c08b25d6ca3d3f4862411d6c.png", "link_img_src": "https://cdn.shopify.com/app-store/listing_images/98fe3014a0ddc555aa03aef1fc14d5b2/icon/CPDO9d3KiP8CEAE=.png", "added_at": "2023-06-03T18:19:28.410953", "updated_at": "2023-07-07T20:32:41.578438"}, 
]
const profile = {
    xid: 'FF01_1365_D9C2',
    name_key: 'marketing_tools',
    theme_color: '#f28180', 
    title: 'Marketing tools',
    img_src: 'https://img.freepik.com/free-vector/mobile-marketing-concept-illustration_114360-1497.jpg?t=st=1687249504~exp=1687250104~hmac=9c4a6307a1324c1e7a3467aa00ee73d0f5705f7f828875a5a0fd095717c4b65e',
    description: [
        "Must have digital marketing tools to help you grow",
    ],
}
const author = {
    key: 'bb_ras',
    name: 'ByteBeacon - Ras',
    img_src: 'https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
