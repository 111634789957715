import React from 'react';
import {notify} from 'react-notify-toast';

import {TEXT} from 'component/global/constants';
import {Title} from 'component/global/title';

import {SubscriptionAPI, handleError} from 'utils/xhr';


const CONTENT = {
  enterprise: {
    title: {
      page: "Reach out for access!",
      btn: 'Get early access',
    },
    description: 'Work emails get prioritized!',
    placeholder: "Work email",
  },
  subscribe: {
    title: {
      page: "Keep yourself updated!",
      btn: 'Subscribe',
    },
    description: 'For new ByteBeacon updates',
    placeholder: "Email address",
  },
  early_access: {
    title: {
      page: "Signup for early access",
      btn: 'Get Early Access',
    },
    description: null,
    placeholder: "Email address",
  }
}


export class PageCTA extends React.Component {
  render (){
    const category = this.props.category || 'subscribe'
    var content = CONTENT[category];

    return (
      <section id="subscribe-page-section" className="py-5  ">
        <div className="">
          <Title type="page" className="white-text-1 text-center" >{content.title.page}</Title>
          <div className="my-5 ">
            <Form category={category} view={this.props.view}/>
          </div>
        </div>
      </section>
    )
  }
}

export class SectionCTA extends React.Component {
  render (){
    const category = this.props.category || 'subscribe'
    return (
      <section id="subscribe-section" className=" ">
        <Form category={category} view={this.props.view} />
      </section>
    )
  }
}

class Form extends React.Component {

  state = {
    email: '',
  }

  onSubscribe(e){
    e.preventDefault();

    if (!this.state.email){
      notify.show('Cannot submit a blank form!', "custom", 2000, { background: '#ff5b74', text: "#FFFFFF" });
      return;
    }

    const url = '/public/subscribe';
    const data = {'email': this.state.email}
    SubscriptionAPI.post(url, data).then(response =>{
      const state = this.state;
      state.email = '';
      notify.show('Subscribed!', "custom", 2000, { background: '#333333', text: "#FFFFFF" });
      this.setState(state);
    },(error) =>{
      notify.show('Error subscribing!', "custom", 2000, { background: '#ff5b74', text: "#FFFFFF" });
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onChange(e){
    const state = this.state;
    state.email = e.target.value;
    this.setState(state);
  }
  
  render (){
    const view = this.props.view || 'split';
    const category = this.props.category || 'subscribe';
    var content = CONTENT[category];

    return (
      <React.Fragment>

        {(view === 'inline')? (<>
          <div className="input-group mb-3">
            <input 
              type="text" 
              className="form-control text-size-7 " 
              placeholder={content.placeholder}
              onChange={this.onChange.bind(this)}
              value={this.state.email} 
            />
            <div className="input-group-append">
              <button 
                className=" text-size-7  white-text-1 px-3 " 
                type="button" 
                style={{border: '0px', backgroundImage: 'linear-gradient(12deg, #4954b6 0%, #9b23ea 100%)'}} 
              >
                {content.title.btn}
                <i className="fa fa-paper-plane ml-3"></i>
              </button>
            </div>
          </div>

        </>): (view === 'split')? (<>
          <div className="form-group">
            <input 
              className="form-control  h-100 m-1 text-size-7 " 
              type="email" 
              placeholder={content.placeholder}
              onChange={this.onChange.bind(this)}
              value={this.state.email} 
            />
          </div>

          <div className="row m-0 p-0 ">
            <div className="col m-0 p-0 ">
              <a 
                className={" btn-block text-size-7  white-text-1  black-bg-1 rounded m-1 form-control text-center "} 
                style={{border: '0px', backgroundImage: 'linear-gradient(12deg, #4954b6 0%, #9b23ea 100%)'}} 
                href='/' 
                onClick={this.onSubscribe.bind(this)} 
              >
                {content.title.btn}
                <i className="fa fa-paper-plane ml-3"></i>
              </a>
            </div>
          </div>

        </>): (<></>)}

        {(content.description)? (
          <p className={"my-1 text-center text-spacing-3 small family-code black-text-6 "}>{content.description}</p>
        ): ('')}

      </React.Fragment>
    )
  }
}
