import React from 'react';
import Notifications from 'react-notify-toast';


export default function PageBlankLayout(props) {
  return (
    <div id={props.id} className="page-wrapper" style={{position: 'relative'}}>
      <Notifications options={{zIndex: 200, top: '80px'}} />

      {props.children}
    </div>
  );
}
