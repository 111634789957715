const cards = [
    {"card_xid": "949b500ff077e1d82afb84fdd029f936342ea5908c2b6e09208791c369de96a0", "card_link": "https://www.classcentral.com", "card_title": "www.classcentral.com", "card_theme": null, "card_type": "image", "card_description": "Class Central \u2022 Find the best courses, wherever they exist.", "icon_img_src": "https://www.classcentral.com/apple-touch-icon.png", "link_img_src": "https://ccweb.imgix.net/https%3A%2F%2Fwww.classcentral.com%2Fimages%2Fmeta%2Fmeta-default.png?auto=format&ixlib=php-4.1.0&s=a980b3b7b5c83a15eac6da49bbbbc182", "added_at": "2023-05-18T06:17:39.653985", "updated_at": "2023-07-07T20:36:11.273539"}, 
    {"card_xid": "63f00916b80a4e872289f32adee31120dd631bab961de5c8bd4c1ce809aed363", "card_link": "https://www.codecademy.com", "card_title": "www.codecademy.com", "card_theme": null, "card_type": "image", "card_description": "Learn to Code - for Free | Codecademy", "icon_img_src": "https://www.codecademy.com/apple-touch-icon.png", "link_img_src": "https://images.codecademy.com/social/logo-codecademy-social.png", "added_at": "2023-05-15T07:26:50.874420", "updated_at": "2023-07-07T20:36:11.448698"}, 
    {"card_xid": "f213aa9d3b927b2c41db1444769ca73a58b83367c2231c4a20432e3aaa85c2fa", "card_link": "https://www.coursera.org", "card_title": "www.coursera.org", "card_theme": null, "card_type": "image", "card_description": "Coursera | Degrees, Certificates, & Free Online Courses ", "icon_img_src": "https://d3njjcbhbojbot.cloudfront.net/web/images/favicons/apple-touch-icon-v2-144x144.png", "link_img_src": "https://s3.amazonaws.com/coursera/media/Grid_Coursera_Partners_updated.png", "added_at": "2023-04-30T20:41:08.643764", "updated_at": "2023-07-07T20:36:11.590241"}, 
    {"card_xid": "11f97189d8588bb146def9dd5087a91c5f31ccb3986b55cc524ed8f20f33bfc4", "card_link": "https://www.datacamp.com", "card_title": "www.datacamp.com", "card_theme": null, "card_type": "image", "card_description": "Learn Data Science and AI Online | DataCamp", "icon_img_src": "https://www.datacamp.com/marketing-backgrounds/favicons/apple-touch-icon-114x114.png", "link_img_src": "https://www.datacamp.com/datacamp-socials-share.png", "added_at": "2023-06-09T13:39:33.618057", "updated_at": "2023-07-07T20:36:11.794993"}, 
    {"card_xid": "5b713837d490041c9fd7cddc1bfcf4ca3be65da59400267ef437d71aee0f0f8f", "card_link": "https://devdocs.io", "card_title": "devdocs.io", "card_theme": null, "card_type": "image", "card_description": "DevDocs API Documentation", "icon_img_src": "https://devdocs.io/images/apple-icon-120.png", "link_img_src": "https://devdocs.io/images/icon-320.png", "added_at": "2023-04-03T19:16:24.721430", "updated_at": "2023-07-07T20:36:11.973572"}, 
    {"card_xid": "b6b92f50b571271b5915159528356ac5557051ec7932a775c002171f62f9d7b6", "card_link": "https://www.edx.org", "card_title": "www.edx.org", "card_theme": null, "card_type": "image", "card_description": "edX | Build new skills. Advance your career. | edX", "icon_img_src": "https://www.edx.org/favicon.ico", "link_img_src": "https://edx.org/contentful/ii9ehdcj88bc/24nI9FEfxhYsu4JtOI8esA/6405f3a237472501ca21a3ffcfa1cdc4/edx-homepage-preview.png?w=1116&h=585&fm=webp", "added_at": "2023-05-04T18:34:26.138321", "updated_at": "2023-07-07T20:36:11.981874"}, 
    {"card_xid": "7d416474a141799ac8fa3a29859ca6dfefca291c99cc4106f81aa201623dd49e", "card_link": "https://www.freecodecamp.org", "card_title": "www.freecodecamp.org", "card_theme": null, "card_type": "image", "card_description": "freeCodeCamp.org", "icon_img_src": "https://www.freecodecamp.org/icons/icon-512x512.png?v=6cba562cbd10e31af925a976f3db73f7", "link_img_src": "https://cdn.freecodecamp.org/platform/universal/fcc_meta_1920X1080-indigo.png", "added_at": "2023-05-22T18:51:47.058324", "updated_at": "2023-07-07T20:36:12.063167"}, 
    {"card_xid": "5bf5a2eb24b82fff777e92ca4cbbfa881cb4f978128e33dc4a2e2feb7d4fbedd", "card_link": "https://www.futurelearn.com", "card_title": "www.futurelearn.com", "card_theme": null, "card_type": "image", "card_description": "FutureLearn: Online Courses and Degrees from Top Universities", "icon_img_src": "https://assets.futurelearn.com/packs/app/assets/images/apple-touch-icon-114x114-8004283dd99c6f7e10c9.png", "link_img_src": null, "added_at": "2023-05-01T07:14:54.936511", "updated_at": "2023-07-07T20:36:12.069738"}, 
    {"card_xid": "da943474efd6f9eb736f2948a733a5aabaf5291626a82831c6475ea0cba3f570", "card_link": "https://www.iitbombayx.in", "card_title": "www.iitbombayx.in", "card_theme": null, "card_type": "image", "card_description": "Home Page | IITBombayX", "icon_img_src": "https://www.iitbombayx.in/sites/default/files/favicon-32x32.png", "link_img_src": null, "added_at": "2023-06-02T19:56:05.121552", "updated_at": "2023-07-07T20:36:12.175195"}, 
    {"card_xid": "26480a141abfb9a973075c18f3aa3a841f40cb31b93f33518fba1544f963eb70", "card_link": "https://www.khanacademy.org", "card_title": "www.khanacademy.org", "card_theme": null, "card_type": "image", "card_description": "Khan Academy | Free Online Courses, Lessons & Practice", "icon_img_src": "https://cdn.kastatic.org/images/apple-touch-icon-144x144-precomposed.new.png", "link_img_src": "https://cdn.kastatic.org/images/khan-logo-dark-background-2.png", "added_at": "2023-06-08T21:49:53.963423", "updated_at": "2023-07-07T20:36:12.218777"}, 
    {"card_xid": "65e50b5bc254527ef7c244423691c51a0e4aed56b2112e52fbec7b0e6fb6d707", "card_link": "https://learnweb3.io", "card_title": "learnweb3.io", "card_theme": null, "card_type": "image", "card_description": "LearnWeb3 DAO - Become a Web3 Developer for free!", "icon_img_src": "https://learnweb3.io/apple-touch-icon.png?v=1", "link_img_src": "http://localhost:3000/api/og", "added_at": "2023-04-14T15:02:27.622920", "updated_at": "2023-07-07T20:36:12.272457"}, 
    {"card_xid": "95666bd889320093e9a0abafa44253fe40b3f66b983ff70076afb8164fa70c31", "card_link": "http://ocw.mit.edu", "card_title": "ocw.mit.edu", "card_theme": null, "card_type": "image", "card_description": "MIT OpenCourseWare | Free Online Course Materials", "icon_img_src": "http://ocw.mit.edu/favicon.ico", "link_img_src": "https://ocw.mit.edu/static_shared/images/ocw_logo_orange.png", "added_at": "2023-04-09T10:49:17.587034", "updated_at": "2023-07-07T20:36:12.305273"}, 
    {"card_xid": "c5fd64bab7269a6ac74a0f1f87804751b6e18da4e4e4b7986b77ce4451492205", "card_link": "https://www.mooc.fi/en/", "card_title": "www.mooc.fi", "card_theme": null, "card_type": "image", "card_description": "Home - MOOC.fi", "icon_img_src": "https://www.mooc.fi/favicon.ico", "link_img_src": null, "added_at": "2023-05-24T12:42:29.685160", "updated_at": "2023-07-07T20:36:12.510389"}, 
    {"card_xid": "d5ea22a67841afac1242d81720f8ba1962cd586cdf1c4b30d9a1ba263ce9f8ba", "card_link": "https://onlinecourses.nptel.ac.in", "card_title": "onlinecourses.nptel.ac.in", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://storage.googleapis.com/swayam2-node/assets/img/swayam_images/ico/152x152.png", "link_img_src": null, "added_at": "2023-04-30T12:02:12.374753", "updated_at": "2023-07-07T20:36:12.524055"}, 
    {"card_xid": "028e4b32d460fdbd62aeb3a2d6f3335ee4ae1247292a81a70df25d61d8b68547", "card_link": "https://open.hpi.de", "card_title": "open.hpi.de", "card_theme": null, "card_type": "image", "card_description": "MOOCs powered by HPI | openHPI", "icon_img_src": "https://open.hpi.de/assets/icons/openhpi-icon-1024x1024-f7cd7e8ca76389d8c5bd0852bcbc239caca20f729af345b28c2716a72f03cc9f.png", "link_img_src": null, "added_at": "2023-04-22T16:46:52.720819", "updated_at": "2023-07-07T20:36:12.908084"}, 
    {"card_xid": "1ef33c75960c3b0d04148d33bfa4dbfe952f1ec0db1db2743134579b70fafad4", "card_link": "https://open.sap.com", "card_title": "open.sap.com", "card_theme": null, "card_type": "image", "card_description": "Free SAP Training | openSAP", "icon_img_src": "https://open.sap.com/assets/icons/opensap-icon-96x96-e561b8a48e8395df6d54a5c2f468e5a6b98904897c4bf1e3806047e3041d7b4d.png", "link_img_src": null, "added_at": "2023-04-28T15:53:20.386205", "updated_at": "2023-07-07T20:36:12.948002"}, 
    {"card_xid": "69c2bdd5afb5c9d42eb728e387f179d6f0d428f30cbc16b2a90f1b76ef916b43", "card_link": "https://courses.platzi.com", "card_title": "courses.platzi.com", "card_theme": null, "card_type": "image", "card_description": "Platzi: Plataforma de aprendizaje profesional online", "icon_img_src": "https://static.platzi.com/media/favicons/platzi_favicon.png", "link_img_src": "https://static.platzi.com/ms-landings-admin/media/image_72f0665f4b.png", "added_at": "2023-06-05T03:21:22.853164", "updated_at": "2023-07-07T20:36:12.981420"}, 
    {"card_xid": "78eb5a6aa6764316644498374b37fbbc4c2ed67a26ece8739c258f84bbdf539b", "card_link": "https://www.udacity.com", "card_title": "www.udacity.com", "card_theme": null, "card_type": "image", "card_description": "Learn the Latest Tech Skills; Advance Your Career | Udacity", "icon_img_src": "https://www.udacity.com/images/touch-icon.png?v=2", "link_img_src": "https://www.udacity.com/www-proxy/contentful/assets/2y9b3o528xhq/2dmDLmWvCncVHcQ6lz9u5v/9ebc8c914fcf0e8b546bce78133b2a4a/OpenGraph_Udacity_Logo_Update__1_.png", "added_at": "2023-06-07T12:49:49.148942", "updated_at": "2023-07-07T20:36:13.073103"}, 
]
const profile = {
    xid: '6179_B5AD_2201',
    name_key: 'moocs',
    theme_color: '#AFDBCC', 
    title: 'MOOCs',
    img_src: 'https://img.freepik.com/free-vector/online-certification-illustration_23-2148575636.jpg?w=1060&t=st=1686894838~exp=1686895438~hmac=ea078f2071270833ac6011df7d3e4c8ffee0fe80f31354d98b1efa6271db324a',
    description: [
        "My collection of MOOC offerings",
    ],
}
const author = {
    key: 'amit_rajput',
    name: 'Amit Rajput',
    img_src: 'https://img.freepik.com/free-photo/fun-3d-cartoon-illustration-indian-businessman_183364-114475.jpg?w=740&t=st=1686919588~exp=1686920188~hmac=e4fee9a9d3325b74960167c43886a3d9ca5bbe025d31b4c4766d37b75ea2bbdc',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
