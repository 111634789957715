function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  
const cards_cta = [
{
    card_type: 'theme', 
    text:'bytebeacon', 
    link_src: 'https://www.facebook.com/catalyst.ic.98', 
    cta_link_src: 'https://www.facebook.com/catalyst.ic.98', 
    card_theme: 'facebook', 
},{
    card_type: 'theme', 
    text:'@bytebeacon', 
    link_src: 'https://twitter.com/bytebeacon', 
    cta_link_src: 'https://twitter.com/bytebeacon', 
    card_theme: 'twitter', 
},{
    card_type: 'theme', 
    text:'@bytebeaconteam3145', 
    link_src: 'https://www.youtube.com/@bytebeaconteam3145', 
    cta_link_src: 'https://www.youtube.com/@bytebeaconteam3145', 
    card_theme: 'youtube', 
},{
    card_type: 'theme', 
    text:'bytebeacon', 
    link_src: 'https://www.instagram.com/bytebeacon', 
    cta_link_src: 'https://www.instagram.com/bytebeacon', 
    card_theme: 'instagram', 
},{
    card_type: 'theme', 
    text:'bytebeacon', 
    link_src: 'https://app.slack.com/client/TNQUJ3PKR/CNGLF9GE5', 
    cta_link_src: 'https://app.slack.com/client/TNQUJ3PKR/CNGLF9GE5', 
    card_theme: 'slack', 
},{
    card_type: 'theme', 
    text:'bytebeacon', 
    link_src: 'https://www.linkedin.com/in/rasbb/', 
    cta_link_src: 'https://www.linkedin.com/in/rasbb/', 
    card_theme: 'linkedin', 
},{
    card_type: 'theme', 
    text:'bytebeacon', 
    link_src: 'https://www.linkedin.com/in/rasbb/', 
    cta_link_src: 'https://www.linkedin.com/in/rasbb/', 
    card_theme: 'linkedin', 
},{
    card_type: 'theme', 
    text:'bytebeacon', 
    link_src: '', 
    cta_link_src: '', 
    card_theme: 'facebookold', 
},
]

var randomNumber = (Math.random() * 1.0).toFixed(2);
const cards_image = [
{    
    card_type: 'overlayImage',
    link_src: '!#',
    card_title: 'Card title',
    card_description: 'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
    icon_img_src: 'https://storage.googleapis.com/creatorspace-public/sites%2Ftouchicons%2FaHR0cHM6Ly9jZG4yLmJ1c3RsZS5jb20vMjAyMy9pbnZlcnNlL2ljb24tN2JkN2IzMzg3OS5wbmc%3D.png',
    icon_img_alt: 'icon',
    img_src: "https://picsum.photos/seed/picsum"+randomNumber+"/900/400",
    img_caption: "Card image",
    link_img_alt: 'Bologna',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},{
    // no icon
    card_type: 'image',
    link_src: "#!",
    card_title: 'no icon.com',
    card_description: 'Arc is the best web browser to come out in the last decade',
    icon_img_src: null,
    icon_img_alt: 'icon',
    link_img_src: 'https://creatorspace.imgix.net/sites/ogimages/aHR0cHM6Ly9pbWdpeC5idXN0bGUuY29tL3VwbG9hZHMvaW1hZ2UvMjAyMi8xMi8yMi8wZDExYjQxZC1kN2VlLTQ5MmQtYWFkYS05YWU5ODM3ODgyOGYtYXJjLWJyb3dzZXItZmVhdHVyZS0wNi5qcGc/dz0xMjAwJmg9NjMwJmZpdD1jcm9wJmNyb3A9ZmFjZXMmZm09anBn.jpeg?width=600&height=600',
    link_img_alt: 'Bologna',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},{
    // no image
    card_type: 'image',
    card_title: 'no image.com',
    card_description: 'Arc is the best web browser to come out in the last decade',
    icon_img_src: 'https://storage.googleapis.com/creatorspace-public/sites%2Ftouchicons%2FaHR0cHM6Ly9jZG4yLmJ1c3RsZS5jb20vMjAyMy9pbnZlcnNlL2ljb24tN2JkN2IzMzg3OS5wbmc%3D.png',
    icon_img_alt: 'icon',
    link_src: "#!",
    link_img_src: null,
    link_img_alt: 'Bologna',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},{
    // no description
    card_type: 'image',
    icon_img_src: 'https://storage.googleapis.com/creatorspace-public/sites%2Ftouchicons%2FaHR0cHM6Ly9jZG4yLmJ1c3RsZS5jb20vMjAyMy9pbnZlcnNlL2ljb24tN2JkN2IzMzg3OS5wbmc%3D.png',
    icon_img_alt: 'icon',
    card_title: 'no description.com',
    card_description: null,
    link_src: "#!",
    link_img_src: 'https://creatorspace.imgix.net/sites/ogimages/aHR0cHM6Ly9pbWdpeC5idXN0bGUuY29tL3VwbG9hZHMvaW1hZ2UvMjAyMi8xMi8yMi8wZDExYjQxZC1kN2VlLTQ5MmQtYWFkYS05YWU5ODM3ODgyOGYtYXJjLWJyb3dzZXItZmVhdHVyZS0wNi5qcGc/dz0xMjAwJmg9NjMwJmZpdD1jcm9wJmNyb3A9ZmFjZXMmZm09anBn.jpeg?width=600&height=600',
    link_img_alt: 'Bologna',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},{
    card_type: 'image',
    icon_img_src: 'https://storage.googleapis.com/creatorspace-public/sites%2Ftouchicons%2FaHR0cHM6Ly9jZG4yLmJ1c3RsZS5jb20vMjAyMy9pbnZlcnNlL2ljb24tN2JkN2IzMzg3OS5wbmc%3D.png',
    icon_img_alt: 'icon',
    card_title: 'inverse.com',
    card_description: 'Arc is the best web browser to come out in the last decade',
    link_src: "#!",
    link_img_src: 'https://creatorspace.imgix.net/sites/ogimages/aHR0cHM6Ly9pbWdpeC5idXN0bGUuY29tL3VwbG9hZHMvaW1hZ2UvMjAyMi8xMi8yMi8wZDExYjQxZC1kN2VlLTQ5MmQtYWFkYS05YWU5ODM3ODgyOGYtYXJjLWJyb3dzZXItZmVhdHVyZS0wNi5qcGc/dz0xMjAwJmg9NjMwJmZpdD1jcm9wJmNyb3A9ZmFjZXMmZm09anBn.jpeg?width=600&height=600',
    link_img_alt: 'Bologna',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},{
    card_type: 'image',
    icon_img_src: 'https://storage.googleapis.com/creatorspace-public/sites%2Ftouchicons%2FaHR0cHM6Ly9jZG4yLmJ1c3RsZS5jb20vMjAyMy9pbnZlcnNlL2ljb24tN2JkN2IzMzg3OS5wbmc%3D.png',
    icon_img_alt: 'icon',
    card_title: 'inverse.com',
    card_description: 'Arc is the best web browser to come out in the last decade',
    link_src: "#!",
    link_img_src: "https://picsum.photos/seed/picsum"+randomNumber+"/900/400",
    link_img_alt: 'Bologna',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},     
]

const cards_video = [
{
    card_type: 'youtube',
    youtube_link: "https://www.youtube.com/embed/NAMvdbS4lk4",
    youtube_title: "YouTube video player",
    card_title: 'Title',
    card_description: 'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
    link_src: "https://www.youtube.com/embed/NAMvdbS4lk4",
    link_img_alt: 'Bologna',
    added_at: new Date(),
},{
    card_type: 'video',
    icon_img_src: 'https://storage.googleapis.com/creatorspace-public/sites%2Ftouchicons%2FaHR0cHM6Ly9jZG4yLmJ1c3RsZS5jb20vMjAyMy9pbnZlcnNlL2ljb24tN2JkN2IzMzg3OS5wbmc%3D.png',
    icon_img_alt: 'icon',
    video_src: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
    card_title: 'inverse.com',
    card_description: 'Arc is the best web browser to come out in the last decade',
    added_at: randomDate(new Date(2022, 0, 1), new Date()),
},
];

const LISK = [
    {title: 'Links', cards: [], },
    {title: 'Notes', cards: [], },
    {title: 'Image', cards: cards_image, },
    {title: 'Video', cards: cards_video, },
    {title: 'CTA', cards: cards_cta, },
]

const profile = {
    idx: 'ASD4_20SD_29GF',
    theme_color: '#f0a6fb', 
    title: "All cards samples",
    img_src: 'https://img.freepik.com/free-vector/hand-drawn-flat-design-recap-illustration_23-2149331618.jpg?w=1060&t=st=1686898250~exp=1686898850~hmac=a4adf830eaba703da0fac0e3dc936b4a7b1b72ba43ef820694903b7d9ac45dd6',
    description: [
        'All sample cards',
        // 'A long list of random links ',
    ],
}
const author = {
    key: 'cloe_team',
    name: 'Cloe Team',
    img_src: '/logo.png',
}

export const DATA = {
    lisk: LISK,
    profile: profile,
    author: author,
}
        