const cards = [
    {"card_xid": "2b2c64c8fae9e5b0386834cda09c572a3d711e051725e3f01e976fc278d77639", "card_link": "https://www.midjourney.com/", "card_title": "www.midjourney.com", "card_theme": null, "card_type": "image", "card_description": "Midjourney", "icon_img_src": "https://www.midjourney.com/apple-touch-icon.png?v=1", "link_img_src": null, "added_at": "2023-06-15T15:17:47.082633", "updated_at": "2023-07-07T20:33:25.428300"}, 
    {"card_xid": "b023389fda8b21501fe6bda9596b7018f01a1f71446981b61e008844840fae04", "card_link": "https://starryai.com/", "card_title": "starryai.com", "card_theme": null, "card_type": "image", "card_description": "starryai - AI Art Generator App - AI Art Maker", "icon_img_src": "https://assets.website-files.com/61554cf069663530fc823d21/61558887cc6fa930c78db0ca_256.png", "link_img_src": "https://assets.website-files.com/61554cf069663530fc823d21/616cf70e234cffb792c226db_2x2x2x-min.png", "added_at": "2023-05-17T03:12:31.300494", "updated_at": "2023-07-07T20:33:25.446779"}, 
    {"card_xid": "defe71776d0ac8354a0776fdf16b54deafc99245d8af7d6c589f9ac21bbfc95e", "card_link": "https://diffusionart.co/", "card_title": "diffusionart.co", "card_theme": null, "card_type": "image", "card_description": "DiffusionArt.co \u2013 100% FREE AI ART Generator \u2013 No Signup, No Upgrades, No CC reqd. Live access to 100s of Hosted Stable Diffusion Models.", "icon_img_src": "https://diffusionart.co/wp-content/uploads/2023/03/cropped-Favicons-180x180.png", "link_img_src": "https://diffusionart.co/wp-content/uploads/2023/04/DiffusionArt-The-Best-Midjourney-Alternative-intro.png", "added_at": "2023-05-27T22:21:54.395569", "updated_at": "2023-07-07T20:33:25.479041"}, 
    {"card_xid": "7ce8e275c12c337ca40dc9048e94ec4cbb570cbd8a95fe1fa85585175c4a9429", "card_link": "https://playgroundai.com/", "card_title": "playgroundai.com", "card_theme": null, "card_type": "image", "card_description": "Playground AI", "icon_img_src": "https://playgroundai.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-14T04:09:19.626636", "updated_at": "2023-07-07T20:33:25.592911"}, 
    {"card_xid": "9cb9986f71ed284dd6d3bfd32113fe680b8bb3541b0d7fe9de9609058925a526", "card_link": "https://www.prompthunt.com/", "card_title": "www.prompthunt.com", "card_theme": null, "card_type": "image", "card_description": "Prompt Hunt - Your home for exploring, creating, and sharing AI art", "icon_img_src": "https://www.prompthunt.com/favicon.png", "link_img_src": "https://www.prompthunt.com/cover.jpg", "added_at": "2023-04-18T11:57:37.098383", "updated_at": "2023-07-07T20:33:25.742580"}, 
    {"card_xid": "7b37785fdae947a864c52596fb887f1188f0c3775387563daebf541a8a199ba3", "card_link": "https://beta.dreamstudio.ai/generate", "card_title": "beta.dreamstudio.ai", "card_theme": null, "card_type": "image", "card_description": "DreamStudio", "icon_img_src": "https://beta.dreamstudio.ai/favicon.svg", "link_img_src": null, "added_at": "2023-05-08T01:54:48.579055", "updated_at": "2023-07-07T20:33:25.809229"}, 
    {"card_xid": "bf91f1caa69b626f1c9e53ae5612494cd17d73e29cddaea226e8f84874b50069", "card_link": "https://dreamlike.art/", "card_title": "dreamlike.art", "card_theme": null, "card_type": "image", "card_description": "Free AI Art Generator, AI Art Maker | Stable Diffusion Online", "icon_img_src": "https://framerusercontent.com/modules/6ScDhke80BkrerN7Yfji/OJeWQfexOX7Om5ZRMOYl/assets/QUbaKg7h1kTm0wLek7YB9vhNe1c.png", "link_img_src": "https://framerusercontent.com/images/ykg84wApW36KG4QC2buqbiKNzY.jpg", "added_at": "2023-06-16T14:32:17.293964", "updated_at": "2023-07-07T20:33:25.823104"}, 
    {"card_xid": "b7c16b6e0f857808494a97c25660c7bc1f4ff5c73968c0d934e2064a69118e3b", "card_link": "https://www.bing.com/create", "card_title": "www.bing.com", "card_theme": null, "card_type": "image", "card_description": "Image Creator from Microsoft Bing", "icon_img_src": "https://www.bing.com/sa/simg/favicon-trans-bg-blue-mg.ico", "link_img_src": "http://www.bing.com/sa/simg/facebook_sharing_5.png", "added_at": "2023-06-05T20:54:26.779679", "updated_at": "2023-07-07T20:33:25.842844"}, 
    {"card_xid": "7ce8e275c12c337ca40dc9048e94ec4cbb570cbd8a95fe1fa85585175c4a9429", "card_link": "https://playgroundai.com/", "card_title": "playgroundai.com", "card_theme": null, "card_type": "image", "card_description": "Playground AI", "icon_img_src": "https://playgroundai.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-14T04:09:19.626636", "updated_at": "2023-07-07T20:33:25.853904"}, 
    {"card_xid": "b2da9c85561327136cefc873f844f4a670ddffb8a655aee8d53ddbf6fc388630", "card_link": "https://lexica.art/aperture", "card_title": "lexica.art", "card_theme": null, "card_type": "image", "card_description": "Lexica Aperture", "icon_img_src": "", "link_img_src": "https://lexica.art/lexica-aperture-meta.png", "added_at": "2023-05-27T19:38:51.804209", "updated_at": "2023-07-07T20:33:25.977399"}, 
    {"card_xid": "d90967bae35ddcfcbbb84bfbd2481ede676678b42d8b8ee1b7ecf2dbf89b5b8d", "card_link": "https://creator.nightcafe.studio/studio", "card_title": "creator.nightcafe.studio", "card_theme": null, "card_type": "image", "card_description": "Create Something Amazing - NightCafe Creator", "icon_img_src": "https://creator.nightcafe.studio/apple-touch-icon.png", "link_img_src": "https://images.nightcafe.studio//assets/tdraw-girl.jpg?tr=w-1200,c-at_max", "added_at": "2023-04-03T01:29:51.427900", "updated_at": "2023-07-07T20:33:25.985353"}, 
    {"card_xid": "7aad23f34624d1d17b14351bcf761ddac8b2d91a901db068e38f10201b751c31", "card_link": "https://instantart.io/", "card_title": "instantart.io", "card_theme": null, "card_type": "image", "card_description": "InstantArt - Home", "icon_img_src": "https://instantart.io/favicon.ico", "link_img_src": null, "added_at": "2023-05-15T20:49:35.370693", "updated_at": "2023-07-07T20:33:25.997492"}, 
    {"card_xid": "f7fd7728d6485dbe7c394e8e6209457d54caacf54c4cc1f404b6ad7a72a18e5c", "card_link": "https://www.bluewillow.ai/", "card_title": "www.bluewillow.ai", "card_theme": null, "card_type": "image", "card_description": "BlueWillow | Free AI Art Generator", "icon_img_src": "https://uploads-ssl.webflow.com/63b84d768804709af30fbb6b/641a20bbb8c4027e0e140a81_blue%20willow%20logo%20dark%20256.png", "link_img_src": null, "added_at": "2023-04-14T03:31:48.210749", "updated_at": "2023-07-07T20:33:26.001184"}, 
    {"card_xid": "c435d127fd4029fabae48c9fb2ea2086c17ddcd2056ce90dfa376ceeb65c9fcf", "card_link": "https://firefly.adobe.com/generate/images", "card_title": "firefly.adobe.com", "card_theme": null, "card_type": "image", "card_description": "Adobe Firefly (Beta)", "icon_img_src": "https://firefly.adobe.com/generate/adobe_safari_touchbar.png", "link_img_src": null, "added_at": "2023-05-03T20:35:33.564278", "updated_at": "2023-07-07T20:33:26.015295"}, 
]
const profile = {
    xid: '1C2B_D203_0FA0',
    name_key: 'text_to_image',
    theme_color: '#13b1f2', 
    title: 'Text to Image services',
    img_src: 'https://img.freepik.com/free-vector/cute-artificial-intelligence-robot-isometric-icon_1284-63045.jpg?w=1060&t=st=1686936935~exp=1686937535~hmac=aa804c1f919d3fdba60e62217ae8828d71f69be253b81856501b6758c698ceee',
    description: [
        "A compile of recent text to image services",
    ],
}
const author = {
    key: 'bb_ras',
    name: 'ByteBeacon - Ras',
    img_src: 'https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
