import React from "react";
import { Link } from 'react-router-dom';

import {Title} from 'component/global/title';

export default function CTAComponent(props){

  const cta_type = props.type || 'try';

  if (cta_type === 'basic'){
    return <CTAComponentBasic />

  } else if (cta_type === 'try'){
    return <CTAComponentTry />

  } else if (cta_type === 'light'){
    return <CTAComponentLightBack />

  } else {
    return '';
  }
}

function CTAComponentTry(props){
  return (
    <div id="cta" className="content-container">
      <div className="container-fluid my-5 ">
      {/* Stay focused on your business.Let us handle the network. */}
        <Title type="page" className="text-center " >Try it yourself 👋</Title>

        <CTAControls bg_class="black" />

      </div>
    </div>
  )
}

function CTAControls(props){
  // const bg_class = props.bg_class || 'black';
  // const is_mobile = window.innerWidth < 1200;
  return(
    <div className={" my-5 "}>
      <div className="row">
        <span className="col-md flex-center my-3 px-2">
          <Link to="/login" className="btn btn-black white-text-1 px-5 py-3 shadow ">
            <span className="text-size-9"><i className="px-4">Get Started <i className="fa fa-paper-plane ml-2"></i></i></span>
          </Link>
          <div className="my-3 text-muted text-center small">Try it free. No credit card required. Instant setup.</div>
        </span>

        <span className="col-md flex-center my-3 px-2">
          <Link to="/discover" className="btn btn-black-outline white-bg-1 black-text-1 px-5 py-3  border shadow">
            <span className="text-size-9"><i className="px-4"><i className="fa fa-eye mr-2"></i>Discover </i></span>
          </Link>
          <div className="my-3 text-muted text-center small"></div>
        </span>

      </div>
      <div className="row d-flex justify-content-center">
        <div className="my-4 ">
        <a href="mailto:info@bytebeacon.com?subject=ByteBeacon: Cloe pilot request!" className="text-size-3 black-text-3 underline">Contact sales</a>
        </div>
      </div>
    </div>
  )
}

function CTAComponentLightBack(props){
  return (
    <div className="">
      <div className="slant slant-top-left-secondary-1"></div>

      <div id="cta" className="content-container highlight-secondary-1">
        <div className="container-fluid">
          <h3 className="family-h3 jumbo-title text-center mb-5 "><span className="text-secondary">Shows</span> on your site in {"<"} 5 minutes!</h3>
          <CTAControls bg_class="secondary" />
        </div>
      </div>

      <div className="slant slant-bottom-right-secondary-1"></div>
    </div>
  )
}


function CTAComponentBasic(props){
  return (
    <div id="cta" className="content-container container-fluid">
      <div className="container my-5">
        <div className="row">
          <div className="col-md-3 col-sm-4 flex-center slide wrapper">
            <img className="animated infinite pulse delay-2s img-fluid rounded" src="/static/images/megaphone.svg" alt=""/>
          </div>
          <div className="col-md-9 col-sm-8">
            <h2 className="family-h2 jumbo-sub-title text-center my-5 ">Create your own <span className="text-secondary">show</span> {"in < 5 minutes!"}</h2>

            <div className="lead">
              <Link to="/login" className="btn btn-block btn-secondary btn-lg btn-rounded text-white  uppercase text-spacing-2">
                <i className="fa fa-sign-in-alt mr-4"></i>
                Login / Signup
              </Link>
            </div>
            <div className="my-2 text-muted text-center">*Try it for Free! No credit card required.</div>
          </div>

        </div>
      </div>

      {/* <div className="highlight">
        <h3>Highlight</h3>
      {['primary', 'secondary', 'secondary', 'warning', 'danger', 'success', 'light', 'dark'].map(function(name, nidx){
        return(
          <div className="row">
          {[1,2,3,4,5,6,7,8,9,10].map(function(item, idx){
            return (
              <div className={"m-1 py-3 text-center col-sm-1 highlight-" +name+"-" + item}> </div>
            )
          })}
        </div>  
        )
      })}
      </div>

      <div className="text">
      <h3>Text</h3>
      {['primary', 'secondary', 'secondary', 'warning', 'danger', 'success', 'light', 'dark'].map(function(name, nidx){
        return(
          <div className="row">
          {[1,2,3,4,5,6,7,8,9,10].map(function(item, idx){
            return (
              <div className={"text-center col-sm-1 text-"+name+"-" + item}>Text </div>
            )
          })}
        </div>  
        )
      })}
      </div>
      <div className="gradient">
        <h3>Gradient</h3>
      {['primary', 'secondary', 'secondary', 'warning', 'danger', 'success', 'light', 'dark'].map(function(name, nidx){
        return(
          <div className="row">
            <div className={"m-1 py-3 text-center col-sm-12 gradient-" +name}> </div>
          </div>  
        )
      })}
      </div> */}


    </div>
  )
}


// export default function CTAComponent(props){
//     return (
//       <div id="cta" className="content-container">
//         <div className="container my-5">
//           <div className="row">
//             <div className="col-md-3 col-sm-4 flex-center slide wrapper">
//               <img className="animated infinite pulse delay-2s img-fluid rounded" src="/static/images/megaphone.svg" alt=""/>
//             </div>
//             <div className="col-md-9 col-sm-8">
//               <h2 className="family-h2 jumbo-sub-title text-center my-5 ">Signup now for <span className="text-secondary">early</span> access</h2>

//               <div className="lead">
//                 <Link to="/login" className="btn btn-block btn-secondary btn-lg btn-rounded text-white">
//                   <i className="fa fa-sign-in-alt mr-2"></i>
//                   Login
//                 </Link>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//     )
// }
