


export function getRandomItemsFromObject(obj, n) {
  var keys = Object.keys(obj);
  var randomItems = [];

  // Check if the desired number of items (n) is greater than the number of keys
  if (n > keys.length) {
    console.log("Desired number of items exceeds the object's size.");
    return randomItems;
  }

  // Randomly select n items from the object
  while (randomItems.length < n) {
    var randomKey = keys[Math.floor(Math.random() * keys.length)];

    // Check if the randomly selected key has already been added
    if (!randomItems.includes(obj[randomKey])) {
      randomItems.push(obj[randomKey]);
    }
  }

  return randomItems;
}
