import React, { useState, useEffect } from 'react';
import { withRouter, Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';


import ContentLayout from 'component/layout/page/content';
import LandingLayout from 'component/layout/page/landing';

import {parseToHsla, hsla} from 'color2k';
import {detectDeviceType} from 'utils/tracker';

import GenericCard from 'component/content/cards';
import ClickCopyButton from 'component/content/buttons/clickcopy';
import ShareButton from 'component/content/buttons/share';

import {LISK_MAP, authors} from './sample/all';

import {getRandomItemsFromObject} from 'utils/sorting';


function Page({match}) {

  const imgStyle = {
    height: 'auto', 
    maxHeight: '10em', 
    width: 'auto', 
    maxWidth: '100%', 
    margin: '0.1em auto 0.1em auto', 
    display: 'block', 
    boxShadow: '0 0 8px 8px white inset',
  }

  const filtered_lisk_map = Object.keys(LISK_MAP).filter(function(lisk_key) {
    const obj = LISK_MAP[lisk_key];
    return (!(obj.author.key === 'cloe_team'));
  }).reduce((res_map, key) => (res_map[key] = LISK_MAP[key], res_map), {});

  const lisk_data_list = getRandomItemsFromObject(filtered_lisk_map, 12);
  const is_mobile = window.innerWidth < 1200;

  return (
    <LandingLayout id="page" >

      <div className="py-5 px-1 bg-light" style={{height: '100%'}}>

        <h2 className="mt-1 mb-5 ">Discover: </h2>

        <div className="my-5 row">
          {/* {Object.keys(LISK_MAP).map(function(lisk_key, idx){ */}
          {lisk_data_list.map(function(lisk_data, idx){
          
            // const lisk_data = LISK_MAP[lisk_key];
            const lisk_key = lisk_data.profile.name_key;
            const lisk_xid = lisk_data.profile.xid;

            const profile = lisk_data.profile;
            // const author = (lisk_data.author)? lisk_data.author: {"name": "Cloe Team", "img_src": "/logo.png", "key": "cloe_team"};
            const author = lisk_data.author;

            // console.log(lisk_data);

            return(
              <div key={"_"+idx} className="col-6 col-lg-3 col-md-3 col-sm-4 col-xs-12 p-1 ">
                <div 
                  className=" border  bg-white " 
                  // style={{backgroundImage: '-webkit-linear-gradient(45deg, #00000000 90%, '+profile.theme_color+' 90%)', }} 
                >
                  
                  <Link to={"/lisk/"+lisk_xid} className="">
                    <p className={" card-text text-center mt-3 black-text-3 " + ((is_mobile)? ' text-size-6 ': ' text-size-8 ')}>{profile.title}</p>
                  </Link>

                  <Link to={"/lisk/"+lisk_xid} className="">
                    <img className="card-img-top" src={profile.img_src} alt="Card image cap" style={imgStyle} />
                  </Link>

                  <div className="card-body m-2 p-2">

                      <span className="black-text-6 text-size-2 ">
                      {profile.description.map(function(desc, idx){
                        return(<p key={"_"+idx} className="m-1 p-0 ">{desc}</p>)
                      })}
                      </span>

                  </div>

                  {/* <hr className="m-0 p-0 " /> */}
                  
                  <div 
                    className="family-code m-0 py-2  " 
                    // style={{backgroundColor: '#333333'}}
                    style={{backgroundColor: '#333333', backgroundImage: '-webkit-linear-gradient(45deg, #00000000 85%, '+profile.theme_color+' 0%)', }} 
                  >
                  
                    <div className="  p-2 " style={{}}>
                      <Link to={"/users/@"+author.key} className="white-text-5 m-0 p-0 ">
                        <img src={author.img_src} alt="" className="mr-2 " style={{height: '20px', width: '20px', borderRadius: '50%'}} />
                        {author.name}
                      </Link>
                    </div>

                    {/* <div className="px-2 d-flex justify-content-between align-items-center black-text-8" style={{fontSize: '0.9em',  }}>
                      <p className="m-0 p-0">#links</p>
                      <p className="m-0 p-0">#views</p>
                      <p className="m-0 p-0">last Updated</p>
                    </div> */}
                  
                  </div>

                </div>
              </div>
            )
          })}
        </div>

      </div>
    </LandingLayout>
  )
}




export default withRouter(Page);
