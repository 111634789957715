import React from 'react';
import { Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// import ReactHelmet from 'react-helmet';

// import {SectionTitle} from 'component/reuse/title';
// import {ModalFeedback} from 'component/reuse/modal';
// import {LoaderRipple} from 'component/loader';

// import { STARTER_TIER, HOBBY_TIER, GROWTH_TIER, SCALE_TIER, ENTERPRISE_TIER } from 'component/constant/pricing';

// import API, {handleError} from 'utils/xhr';
// import {getUserId} from 'utils/authentication';
// import Track from 'utils/tracker';

import './slider.css';


export const STARTER_TIER = {
  tier_name: "Starter",
  tier_icon: 'fa-battery-empty',
  img: {
    path: "/static/images/pricing/1.svg",
    alt: 'pricing starter tier ',
  },
  card_border: '',
  most_popular: false,
  price_details: {
    monthly: {
      plan_key: null,
      price: "0 / month",
      description_subs: [
        "no card required",
      ],
    },
    yearly: {
      plan_key: null,
      price: "0 / year",
      description_subs: [
        "no card required",
      ],
    }
  },
  description: [
    {"class": "text-size-3 black-text-6", "text": "For ", },
    {"class": "text-size-5 primary-text-1 text-size-5 ", "text": "organizing your links", },
  ],
  features: [
    {"text": "Unlimited bookmarks", "tip": ""}, 
    {"text": "Unlimited Public / Private collections", "tip": ""},
    {"text": "Personalized notes support", "tip": "Annotate bookmarks with custom notes"},
    {"text": "Import & Export data", "tip": ""},
    {"text": "Alerts on broken links", "tip": ""}, 
    {"text": "Search support", "tip": "Easily search through all saved content"},
  ],
  btn: {
    text: 'Try it now',
    class: 'btn-outline-quaternary',
    icon: "mr-2 fa fa-sign-in-alt",
    link: '/login',
  },
}

export const GROWTH_TIER = {
  tier_name: "Growth",
  tier_icon: 'fa-battery-half',
  img: {
    path: "/static/images/pricing/2.svg",
    alt: 'pricing growth tier ',
  },
  card_border: 'box-shadow',
  most_popular: false,
  price_details: {
    monthly: {
      plan_key: '20191118-startup-monthly',
      price: "20 / month",
      description_subs: [
        // "annual cost: $600",
      ],    
    },
    yearly: {
      plan_key: '20191118-startup-yearly',
      price: "192 / year",
      description_subs: [
        "$16 / month",
      ],
    }
  },
  description: [
    {"class": "text-size-3 black-text-6", "text": "For ", },
    {"class": "text-size-5 primary-text-1 text-size-5 ", "text": "growing your user base", },
  ],
  features: [
    {"text": "Named collections support", "tip": "Name your collections for easy to remember URLs"}, 
    {"text": "Subscription support", "tip": "Visitors can subscribe to your public collection pages"}, 
    {"text": "Analytics on collections", "tip": "View usage statistics to enhance visitor engagement"}, 
    {"text": "Customer support via email", "tip": ""}, 
    {"text": "Sync from sources", "tip": "New links added to a marked source get added to your bookmark collection automatically. <br/>Great for following new content on blogs / news sites, youtube playlists or a constantly changing source. <br/><br/>This feature is in early beta and costs extra."}, 
  ],
  btn: {
    text: 'Login',
    class: 'btn-outline-quaternary',
    icon: "mr-2 fa fa-sign-in-alt",
    // link: '/login?dest=/billing?tier=startup',
    link: '/login',
  },
}

export const ENTERPRISE_TIER = {
  tier_name: "Enterprise",
  tier_icon: 'fa-battery-full',
  img: {
    path: "/static/images/pricing/4.svg",
    alt: 'pricing enterprise tier ',
  },
  card_border: '',
  most_popular: false,
  price_details: {
    monthly: {
      plan_key: 'contact-us',
      price: "Custom",
      description_subs: [
        "contact us for details",
      ],
    },
    yearly: {
      plan_key: 'contact-us',
      price: "Custom",
      description_subs: [
        "contact us for details",
      ],
    }
  },
  description: [
    {"class": "text-size-3 black-text-6", "text": "For ", },
    {"class": "text-size-5 primary-text-1 text-size-5 ", "text": "building team's knowledge base", },
  ],
  features: [
    {"text": "Custom branding / domain", "tip": ""}, 
    {"text": "Hierarchical dashboards", "tip": "View usage stats from all your collections in one place or choose to slice and dice. <br/>Great for HR teams to see employee engagement"}, 
    {"text": "Advanced analytics", "tip": ""}, 
    {"text": "Single sign-on (SSO) support", "tip": ""}, 
    {"text": "On-Prem deployment available", "tip": ""}, 
    {"text": "Priority customer support", "tip": ""}, 
  ],
  btn: {
    text: 'Contact us',
    class: 'btn-outline-quaternary',
    icon: "mr-2 fa fa-users",
    href: 'mailto:info@bytebeacon.com?subject=Cloe for enterprise',
  },
}


function PricingTier(props){
  const pricing = props.pricing;
  const is_yearly = props.is_yearly;

  const purchased_tier_name = (props.tier)? props.tier.name: null;
  const current_tier_purchased = (purchased_tier_name === pricing.tier_name.toLowerCase());
  const offer = props.offer || null;

  const price_details = is_yearly? pricing.price_details.yearly: pricing.price_details.monthly;

  const price_class = ' primary-text-1 primary-highlight-1 btn-rounded ';

  return (
    <div className={"card " + (pricing.card_border) + ((current_tier_purchased)? ' primary-highlight-1': ' ')}>
      <h5 className={"card-header uppercase text-spacing-2 flex-center text-size-5 " + ((current_tier_purchased)? ' highlight-light-1': (' white-text-1 dark-bg-2'))}>
        {/* <i className={"fas mr-2 " + pricing.tier_icon}></i> */}
        {pricing.tier_name}
      </h5>

      <div className="mt-3 ">
        <img 
          style={{height: '200px', width: '100%', display: 'block'}} 
          src={pricing.img.path}
          alt={pricing.img.alt} />
      </div>

      {(pricing.most_popular)? (<span className="mx-1 py-2 text-spacing-3 uppercase badge badge-pill bg-primary text-white">Most popular</span>): ''}
      {(current_tier_purchased)? (<span className="mx-1 py-2 text-spacing-3 uppercase badge badge-pill bg-quaternary text-white">Current plan</span>): ''}
      {(offer !== null)? (<span className="mx-1 py-1 text-spacing-3 uppercase badge badge-pill bg-success text-white">{offer.details}</span>): ''}

      <div className="card-body">
        <h5 className={"card-title text-center " + price_class}><i className={'fa fa-dollar-sign mr-2 ' + price_class}></i>{price_details.price}</h5>
        {price_details.description_subs.map(function(sub, idx){
          return(
            <p key={"k_" + idx} className={"m-0 p-0 btn-rounded small text-center text-black-8 family-code uppercase text-spacing-1"} >{sub}</p>
          )
        })}
      </div>

      <div className="card-body family-code m-0 pt-0 pb-3 ">
        {pricing.description.map(function(desc, idx){
          return(<span key={"_"+idx} className={desc.class}>{desc.text}</span>)
        })}
      </div>

      {/* <div className=""> */}
      <ul className="list-unstyled black-text-1 text-size-3">
        {pricing.features.map(function(feature, idx){
          return (
            <li className={" pl-4 text-s-1" + ((current_tier_purchased)? ' ': ' text-muted')} key={"k_" + idx}>
              <span data-for="priceFeatureDescriptionDataTip" data-tip={feature.tip}>
                <i className="fa fa-check mr-2"></i>{feature.text}
                {(feature.tip)? (<i className="fa fa-circle-info ml-2"></i>): ('')}
              </span>
            </li>
          )
        })}
      </ul>
      {/* </div> */}

      <div className={"card-body "}>
        {(offer !== null)? (
          <a href={"/"} onClick={props.activateOffer} data-key={offer.id} className={"btn btn-rounded fa-pull-right btn-success"}>{"Activate"}</a>
        ) : (
          (purchased_tier_name === null)? (
            (pricing.btn.link === undefined)? (
              ((pricing.btn.alert !== undefined)? (
                <button onClick={() => alert(pricing.btn.alert)} className={"btn btn-outline-secondary btn-rounded fa-pull-right " + pricing.btn.class}><i className={pricing.btn.icon}></i>{pricing.btn.text}</button>
              ): (
                <a href={pricing.btn.href} className={"btn btn-outline-secondary btn-rounded fa-pull-right " + pricing.btn.class}><i className={pricing.btn.icon}></i>{pricing.btn.text}</a>
                ))
            ): (
              <Link to={pricing.btn.link} className={"btn btn-outline-secondary btn-rounded fa-pull-right " + pricing.btn.class}><i className={pricing.btn.icon}></i>{pricing.btn.text}</Link>
            )
          ):(
            (current_tier_purchased)? (
              (price_details.plan_key === null)? (
                '' //<Link to='/' className="disabled btn btn-light btn-rounded text-black fa-pull-right">Current plan</Link>
              ): (
                <button onClick={props.handleUnsubscribe} className={"btn btn-danger btn-rounded fa-pull-right"}>Unsubscribe</button>
              )
            ): (
              (price_details.plan_key === 'contact-us')? (
                <a href={pricing.btn.href} className={"btn btn-rounded fa-pull-right " + pricing.btn.class}>{pricing.btn.text}</a>
              ):(
                (price_details.plan_key === null)? (
                  <Link to='/' className="disabled btn btn-light btn-rounded text-black fa-pull-right"></Link>
                ): (
                  <button onClick={props.handleUpgrade} className={"btn btn-outline-primary btn-rounded fa-pull-right"}>
                    Subscribe {is_yearly? 'Yearly': 'Monthly'} 
                    {/* <br /> */}
                    {/* <i className={'fa fa-dollar-sign'}></i> {price_details.price} */}
                  </button>
                )
              )
            )
          )
        )}
        
      </div>
    </div>  
  )
}


export default class Pricing extends React.Component {

  render (){
    const pricing_tiers = [
      STARTER_TIER,
      GROWTH_TIER,
      ENTERPRISE_TIER,
    ]

    const tier = this.props.tier;

    if (tier === undefined) {
      return <LoggedOutPricing pricing_tiers={pricing_tiers} />
    }

    // const fetching_data = this.props.fetching_data;

    return (
      ''
      // <LoggedInPricing pricing_tiers={pricing_tiers} tier={tier} fetching_data={fetching_data} />
    )
  }
}


class LoggedOutPricing extends React.Component {
  state = {
    toggle_yearly: false,
  }

  handleToggleYearly = () => {
    const state = this.state;
    state.toggle_yearly = !state.toggle_yearly;
    // Track.billing("Public Toggle: " + state.toggle_yearly)
    this.setState(state);
  }

  render (){
    const pricing_tiers = this.props.pricing_tiers;

    return (
      <div className="container-fluid my-5">
        
        <ReactTooltip id={"priceFeatureDescriptionDataTip"} className={"  "} html={true}
        // backgroundColor='#333333ee' arrowColor='#ffffff' textColor='#ffffff'
        />

        <div className="m-3 content-section ">

          <div className="flex-center black-text-1">
            <h1 className="">Plans and Pricing</h1>
          </div>

          <BillingToggle toggle_yearly={this.state.toggle_yearly} handleToggleYearly={this.handleToggleYearly.bind(this)} />

          <div className="row justify-content-md-center">
            {pricing_tiers.map(function(pricing, idx){
              return (
                <div className="col-xl col-lg-4 col-md-4 col-sm-12 p-1" key={"k_" + idx}>
                  <PricingTier pricing={pricing} is_yearly={this.state.toggle_yearly} />
                </div>  
              )
            }, this)}
          </div>

          {/* <div className="fa-pull-right">
            <ModalFeedback modalLinkText={"Have questions on pricing?"} modalID={"pricing_feedback"} modalTitle={"Pricing"} />
          </div> */}

        </div>
      </div>
    )
  }
}

function BillingToggle(props){

  const toggle_yearly = props.toggle_yearly;
  const handleToggleYearly = props.handleToggleYearly;

  const selector_class = ' rounded text-white primary-bg-1 p-1 ';
  const non_selector_class = ' black-text-6 p-1 ';

  return (
    <div className="text-center p-2">
    <label className={"  mr-4 text-black-1" + (toggle_yearly? non_selector_class: selector_class)}>Monthly</label>
    <label className=" switch">
      <input
        type="checkbox"
        checked={toggle_yearly}
        onChange={handleToggleYearly}
      />
      <span className={(toggle_yearly? ' ': ' ') + " black-bg-1 slider btn-rounded"} style={{position: 'absolute'}}></span>
    </label>
    <label className={"  ml-4 text-black-1"+ (toggle_yearly? selector_class: non_selector_class)}>Yearly</label>
  </div>
  )
}



// class LoggedInPricing extends React.Component {

//   state = {
//     public_key: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
//     toggle_yearly: false,
//   }

//   handleUpgrade(plan_key){
//     Track.billing("Subscribe: " + plan_key, getUserId())
    
//     const url = '/payments/stripe/checkout_session';
//     const data = {
//       selected_plan_key: plan_key,
//     }
//     const public_key = this.state.public_key;

//     API.post(url, data).then(response =>{
//       const checkout_session_id = response.data.data.checkout_session_id;

//       const stripe = window.Stripe(public_key);
//       stripe.redirectToCheckout({
//         sessionId: checkout_session_id
//       }).then(function(result) {
//         console.log("error");
//         console.log(result.error.message);
//         // If `redirectToCheckout` fails due to a browser or network
//         // error, display the localized error message to your customer
//         // using `result.error.message`.

//       }).catch(function(err) {
//         console.log(err);
//       });
      
//     },(error) =>{
//       handleError(error, this.props.history, window.location.pathname);
//     })
//   }

//   handleUnsubscribe(plan_key){

//     Track.billing("UN-Subscribe: " + plan_key, getUserId())

//     const url = '/payments/stripe/unsubscribe';
//     const data = {}
//     API.post(url, data).then(response =>{
//       // TODO: fix this reload!
//       window.location.reload();
//     },(error) =>{
//       handleError(error, this.props.history, window.location.pathname);
//     })
//   }


//   handleToggleYearly = () => {
//     const state = this.state;
//     state.toggle_yearly = !state.toggle_yearly;
//     Track.billing("Login Toggle: " + state.toggle_yearly, getUserId())
//     this.setState(state);
//   }

//   render (){

//     const pricing_tiers = this.props.pricing_tiers;
//     const tier = this.props.tier;

//     return (
//       <div className="container-fluid">

//         <ReactHelmet>
//           <script src="https://js.stripe.com/v3/"></script>
//         </ReactHelmet>

//         <div className="m-3 content-section  ">

//           <div className="container-fluid">
//             <div className="m-3 content-section">
//               <div className="text-center">
//                 <span className="text-size-1 text-dark">You are currently on the</span>
//                 <span className="text-size-1 text-quaternary highlight-quaternary-1 uppercase btn-rounded mx-2"> {(tier === null)? ('...'): (tier.name)} </span>
//                 <span className="text-size-1 text-dark">plan</span>
//               </div>
//             </div>
//           </div>

//           <BillingToggle toggle_yearly={this.state.toggle_yearly} handleToggleYearly={this.handleToggleYearly.bind(this)} />

//           {(this.props.fetching_data)? (<LoaderRipple fetching_data={true} />) : (
//             <div className="row">
//             {pricing_tiers.map(function(pricing, idx){
//               const is_yearly = this.state.toggle_yearly;
//               const price_details = is_yearly? pricing.price_details.yearly: pricing.price_details.monthly;
//               const plan_key = price_details.plan_key

//               return (
//                 <div className="col-xl col-lg-4 col-md-4 col-sm-12 p-1" key={"k_" + idx}>
//                   <PricingTier 
//                     pricing={pricing} 
//                     tier={tier} 
//                     is_yearly={is_yearly} 
//                     handleUpgrade={this.handleUpgrade.bind(this, plan_key)} 
//                     handleUnsubscribe={this.handleUnsubscribe.bind(this, plan_key)} 
//                   />
//                 </div>  
//               )
//             }, this)}
//             </div>
//           )}

//           <div className="fa-pull-right">
//             <ModalFeedback modalLinkText={"Have questions on pricing?"} modalID={"pricing_feedback"} modalTitle={"Pricing"} />
//           </div>

//           <div className="flex-center my-5">
//           <div className="img img-fluid text-center animated heartBeat" style={{width: '250px'}}>
//             <img className="hero" src="/static/images/powered_by_stripe.svg" alt="" />
//           </div> 
//           </div>

//         </div>
//       </div>
//     )
//   }
// }



// export class OfferPricing extends React.Component {
//   state = {
//     toggle_yearly: false,
//   }

//   handleToggleYearly = () => {
//     const state = this.state;
//     state.toggle_yearly = !state.toggle_yearly;
//     Track.billing("Offer Toggle: " + state.toggle_yearly)
//     this.setState(state);
//   }

//   render (){
//     const offers = this.props.offers;
//     const activateOffer = this.props.activateOffer;

//     return (
//       <div className="container-fluid my-5">
//         <div className="m-3 content-section ">

//           <BillingToggle toggle_yearly={this.state.toggle_yearly} handleToggleYearly={this.handleToggleYearly.bind(this)} />

//           <div className="row justify-content-md-center">
//             {offers.map(function(offer, idx){
//               return (
//                 <div className="col col-xl-4 col-sm-12 p-1" key={"k_" + idx}>
//                   <PricingTier pricing={offer.pricing_details} is_yearly={this.state.toggle_yearly} offer={offer.offer_details} activateOffer={activateOffer}/>
//                 </div>  
//               )
//             }, this)}
//           </div>

//         </div>
//       </div>
//     )
//   }
// }
