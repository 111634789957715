import React from "react";
import { withRouter, Link} from 'react-router-dom';

import {parseToHsla, hsla} from 'color2k';
import {getThemeDict } from 'utils/constants';

import {RoundIcon} from'component/assets/icon';
// import {CardFooter} from'component/assets/footer';

import Moment from 'react-moment';


export function CardFooter(props){
    const added_at = props.added_at;
    const updated_at = props.updated_at;
    return (
      <>
        {/* <small className="mt-1 mb-3 float-right text-muted">updaed <Moment fromNow>{updated_at}</Moment></small> */}
        <small className="mt-1 mb-3 float-right text-muted">added <Moment fromNow>{added_at}</Moment></small>
      </>
    )
}




const text_css_title = ' black-text-8 ';
const text_css_content = ' black-text-3 ';



export default class GenericCard extends React.Component {
  render (){
    const card = this.props.card;
    const profile = this.props.profile;
    const search_text = this.props.search_text;
    
    if (card.card_type === 'theme'){
      return(<ThemeIconCard profile={profile} card={card} search_text={search_text} />)
    }
    if (card.card_type === 'overlayImage'){
      return(<OverlayImgCard profile={profile} card={card} search_text={search_text} />)
    }
    if (card.card_type === 'image'){
      return(<ImageCard profile={profile} card={card} search_text={search_text} />)
    }
    if (card.card_type === 'youtube'){
      return(<YoutubeCard profile={profile} card={card} search_text={search_text} />)
    }
    if (card.card_type === 'video'){
      return(<VideoCard profile={profile} card={card} search_text={search_text} />)
    }

    return (
      <div className="" ></div>
    )
  }
}



class ThemeIconCard extends React.Component {
  render (){
    const card = this.props.card;
    
    const theme_dict = getThemeDict(card.card_theme);
    // console.log('card', card);
    // console.log('theme_dict', theme_dict);
    const [h,s,l,a] = parseToHsla(theme_dict.color);

    const card_icon = theme_dict.icon;
    const card_color = theme_dict.color;
    const card_background = 'hsl('+h+', '+s*100+'%, 98%)';
    const card_description = card.text;
    const link_src = card.link_src;
    const card_title = theme_dict.caption.name;

    const cta_link = card.cta_link_src;
    const cta_name = theme_dict.caption.cta_name;

    return (
      <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 p-2" >
        <div className=" border " style={{borderRadius: '15px', backgroundColor: card_background}}>
          <div className="card-body p-3">

            <a href={link_src} className="">
              <div className="row d-flex align-items-center">

                <div className="col-3 col-lg-12 col-md-3 col-sm-3 col-xs-2 mb-2 m-0 p-0 ">
                  <div className="btn m-0 " style={{backgroundColor: card_color, color: 'white'}}>
                    <i className={"rounded " + card_icon}></i>
                  </div>
                </div>

                <div className="col-9 col-lg-12 col-md-9 col-sm-9 col-xs-10 m-0 p-0">
                  <div className="pl-2 row m-0 p-0">
                    <div className="col-4 col-sm-12 m-0 p-0">
                      <p className={" mt-0 mb-0 " + text_css_title}>{card_title}</p>
                    </div>
                    <div className="col-8 col-sm-12 m-0 p-0">
                      <p className={" mt-0 mb-2 " + text_css_content}>{card_description}</p>
                    </div>
                  </div>
                </div>

              </div>
            </a>

            <a href={cta_link} className="btn float-right" style={{borderRadius: '15px', backgroundColor: card_color, color: 'white'}}>
              {cta_name}
            </a>

          </div>
        </div>
      </div>
    )
  }
}


class OverlayImgCard extends React.Component {
  render (){

    const card = this.props.card;

    const icon_img_src = card.icon_img_src;
    const icon_img_alt = card.icon_img_alt;
    const img_src = card.img_src;
    const img_caption = card.img_caption;
    const link_src = card.link_src;
    const link_img_alt = card.link_img_alt;
    const card_title = card.card_title;
    const card_description = card.card_description;
    const added_at = card.added_at;

    return (
      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <a href={link_src} alt={link_img_alt} className="">
          <div className="m-0 p-0 card text-white rounded" style={{backgroundColor: 'transparent'}}>
            <div className="rounded" style={{background: 'rgba(0,0,0,0.5)', padding: '10px', }}>
              <img className="card-img-overlay card-img" src={img_src} alt={img_caption} style={{zIndex: '-9999', height: '100%', padding: '0px'}}/>
              <RoundIcon img_src={icon_img_src} img_alt={icon_img_alt} />
              <h5 className={"card-title"}>{card_title}</h5>
              <p className={"card-text"}>{card_description}</p>

            </div>
          </div>
        </a>
        <CardFooter added_at={added_at} />
      </div>
    )
  }
}


class ImageCard extends React.Component {
  render (){

    const card = this.props.card;
    const search_text = this.props.search_text;

    const icon_img_src = card.icon_img_src;
    const icon_img_alt = card.icon_img_alt;
    const card_title = card.card_title;
    const card_description = card.card_description;
    const card_link = card.card_link;
    const link_img_src = card.link_img_src;
    const link_img_alt = card.link_img_alt;
    const added_at = card.added_at;

    const show = (search_text === null 
      || search_text === ''
      || (icon_img_alt && icon_img_alt.toLowerCase().includes(search_text)) 
      || (link_img_alt && link_img_alt.toLowerCase().includes(search_text) )
      || (card_title && card_title.toLowerCase().includes(search_text) )
      || (card_description && card_description.toLowerCase().includes(search_text) )
    );
    if (!show){return null;}


    const style = {
      height: 'auto', 
      maxHeight: '10em', 
      width: 'auto', 
      maxWidth: '100%', 
      margin: '1em auto 1em auto', 
      display: 'block', 
      boxShadow: '0 0 8px 8px white inset',
    }

    return (
      <div className="pinticka col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <div className="m-0 p-0 bg-white rounded" >
          <a href={card_link} className="" target="_blank" rel="noreferrer">
            <div className="card-body" style={{overflow: 'scroll'}}>
              {(icon_img_src)? <RoundIcon img_src={icon_img_src} img_alt={icon_img_alt} /> : <RoundIcon img_src={'/logo.svg'} img_alt={'icon not found'} />}
              <h6 className={"family-code text-size-3 mb-2 mt-2" +text_css_title}>{card_title}</h6>
              <p className={"card-text"+text_css_content}>{card_description} </p>
            </div>
            {(link_img_src)?
              <img 
                className="card-img-bottom rounded " 
                loading="lazy" 
                src={link_img_src} 
                alt={link_img_alt} 
                style={style} 
                onError={(event) => event.target.style.display = 'none'} 
              /> : ''}
          </a>
        </div>
        <CardFooter added_at={added_at} />
      </div>
    )
  }
}


class YoutubeCard extends React.Component {
  render (){

    const card = this.props.card;
    
    const youtube_link = card.youtube_link;
    const youtube_title = card.youtube_title;
    const card_title = card.card_title;
    const card_description = card.card_description;
    const link_src = card.link_src;
    const link_img_alt = card.link_img_alt;
    const added_at = card.added_at;

    return (
      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
        <a href={link_src} alt={link_img_alt} className="" target="_blank" rel="noreferrer">
          <div className="card rounded m-0 p-0" >
          <div className="ratio ratio-16x9">
            <iframe className="rounded" width="100%" height="100%" src={youtube_link} title={youtube_title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <div className="card-body">
            <h5 className={"card-title"+text_css_title}>{card_title}</h5>
            <p className={"card-text"+text_css_content}>{card_description}</p>
          </div>
        </div>
      </a>
      <CardFooter added_at={added_at} />
      </div>
    )
  }
}


class VideoCard extends React.Component {
  render (){

    const card = this.props.card;

    const icon_img_src = card.icon_img_src;
    const icon_img_alt = card.icon_img_alt;
    const video_src = card.video_src;
    const card_title = card.card_title;
    const card_description = card.card_description;
    const added_at = card.added_at;

    return (
      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div className="card rounded  m-0 p-0">
          <video src={video_src} className="card-img-top" muted controls></video>
          <a href={video_src} className="" target="_blank" rel="noreferrer">
            <div className="card-body">
              <RoundIcon img_src={icon_img_src} img_alt={icon_img_alt} />
              <h5 className={"card-title"+text_css_title}>{card_title}</h5>
              <p className={"card-text"+text_css_content}>{card_description}</p>
            </div>
          </a>
        </div>

        <CardFooter added_at={added_at} />
      </div>
    )
  }
}
