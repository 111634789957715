const cards = [
    {"card_xid": "a2cb5c9cfddde91db66f73fabfc323662ffe80e5eee85e9fe8647f3e2c39c4c4", "card_link": "https://play.google.com/store/apps/details?id=com.scopely.monopolygo", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "MONOPOLY GO! - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/7M9FYlUnscfJQZqHE4Yz2HNEzESl3QOUEVAOD3YzC32HuMsbropV8h_tPpaNdRmqrA", "added_at": "2023-04-27T14:10:53.814438", "updated_at": "2023-07-07T20:32:31.149612"}, 
    {"card_xid": "efff06a986edf621d2c0e743e9012a829708570f2d0fe127ec603aaedc20830f", "card_link": "https://play.google.com/store/apps/details?id=com.roblox.client", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Roblox - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/Oirqdrw3CsCN1M2QBrGdOqONlue6XxX0oA0hEjeEZ1EufDJKSSECwqkhd_VAdSmcns7w", "added_at": "2023-05-17T03:13:48.956366", "updated_at": "2023-07-07T20:32:31.224039"}, 
    {"card_xid": "d47f72d39441e51eb57c4cbabb888407863aac1e97422e1844c347d7f1d0cc4d", "card_link": "https://play.google.com/store/apps/details?id=com.crescivegames.twistedtangle", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Twisted Tangle - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/OTQxmAJPLtV6wWY2CUr104lX44D-YT5M_g1MwuQOufIzZzr_BYExFB5jpmxvnS-hfE2q", "added_at": "2023-06-09T06:09:36.996047", "updated_at": "2023-07-07T20:32:31.289613"}, 
    {"card_xid": "ae292aaed775809539b2aa7fd0e56f9531ee4f7a7761dca9ba3fc7016c07afea", "card_link": "https://play.google.com/store/apps/details?id=com.dreamgames.royalmatch", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Royal Match - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/qBdVfwRCsI4KM7qewhJ0AKZKQjyD-DdxPDcdDbsRMhNO9zrwbefggn1vGqRIDZA3fg", "added_at": "2023-06-14T21:17:45.894879", "updated_at": "2023-07-07T20:32:31.356702"}, 
    {"card_xid": "fb0a94a0bdf03af53b6d69d1de51d3ea09a507a3194d880d4f517ffa6a17d1b5", "card_link": "https://play.google.com/store/apps/details?id=com.adengames.weaponcraftrun", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Weapon Craft Run - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/TdtSYiDOMYY8PLYu6Eje4gMu6C1QT1rRXg4uMOhD4BM4NxXQtfis4zjqVXS7TqpDxSw", "added_at": "2023-06-10T11:05:17.980477", "updated_at": "2023-07-07T20:32:31.423965"}, 
    {"card_xid": "167f0c896a840e24200407f66a2dc0b3cb2426fea0266259c82f4e605c110b53", "card_link": "https://play.google.com/store/apps/details?id=com.amanotes.gs.g06", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Duet Cats: Cute Popcat Music - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/Wz2CC-A3KsvaPgLgLYBZBpzMpHr_3aKdJND9u8LuP1x0ffskdmaZOz8f8EH7FSuxxe8", "added_at": "2023-04-15T14:45:57.506604", "updated_at": "2023-07-07T20:32:31.497161"}, 
    {"card_xid": "ef36b54432c3a1ff18aa0546342853ba1175273d70d2973ac7e24848f43cd6a2", "card_link": "https://play.google.com/store/apps/details?id=com.block.juggle", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Block Blast Adventure Master - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/R0qgNDYYHbRhw6JFsdEbDMqONplEvJx0m0W9wzYVvY3eNF1c2rfBWYjQxW0sLEzFe1E", "added_at": "2023-05-15T18:56:46.573750", "updated_at": "2023-07-07T20:32:31.576274"}, 
    {"card_xid": "98306cc67d4e060f18b6ab946128d35aba8ce09e5983e2d12a0c2f7e2ecaeb20", "card_link": "https://play.google.com/store/apps/details?id=com.kiloo.subwaysurf", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Subway Surfers - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/mDhB9ehWSRe0ms9FtLTSdgch4vmbmCCsBEr8ojLbf6Tg62QHF1mPyAwHx1lYBlZbtkY", "added_at": "2023-05-02T17:13:24.553422", "updated_at": "2023-07-07T20:32:31.644487"}, 
    {"card_xid": "be5931681ade17696309d9b80e4b6a2d1a4360e5af5f09b30118fe7786e08f50", "card_link": "https://play.google.com/store/apps/details?id=com.youmusic.magictiles", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Magic Tiles 3 - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/roGkhpU3GfmHjgGr3xfbyEInDmUNYZloBgLdfA9gOCAnlOejqWozqPUaEY26nGlzVxE", "added_at": "2023-05-12T18:02:58.895460", "updated_at": "2023-07-07T20:32:31.714518"}, 
    {"card_xid": "702c9c0a1325b173a3cb0d3832c5cea7a30bb9a2021f631c87644e6ab53b54bd", "card_link": "https://play.google.com/store/apps/details?id=com.playgendary.tom", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Tomb of the Mask - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/gTd127I81O2i2Q6kfCJoN-M0OSWmgsdjB47orUFpLwANW7VZLJYrOxMVt-OpilDXszg", "added_at": "2023-06-11T18:42:47.507138", "updated_at": "2023-07-07T20:32:31.785247"}, 
    {"card_xid": "3ce63f38c35c227810a805d1100c81b3fda2406a445c1d0ee6da0ce3ac7d6b0b", "card_link": "https://play.google.com/store/apps/details?id=com.inspiredsquare.jupiter", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "2248 - Numbers Game 2048 - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/-785A77wxW3shA2bdIHLrxHVnVv2jyzoZD6V01FMkMSDIHX2KfZ4S9YQGasLmB0Im5DS", "added_at": "2023-04-28T20:49:50.510370", "updated_at": "2023-07-07T20:32:31.900380"}, 
    {"card_xid": "3e9abcf96290df3aa93fb4f5bba089d30642f54614e8af75027f38b22ab3be7f", "card_link": "https://play.google.com/store/apps/details?id=com.htt.baby.hunt.hn", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Where is He: Hide and Seek - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/JHCfc662aSRGnA0bVV1fpLr0mqq4Dk9ZGWxi-cF8e0puuAWQksOR2BjMr9UX70Mncsc", "added_at": "2023-04-27T07:38:19.650890", "updated_at": "2023-07-07T20:32:31.977445"}, 
    {"card_xid": "4105043a3305d2972304e853711bff94089e82a18ea6ef7588b65ec77c82459a", "card_link": "https://play.google.com/store/apps/details?id=com.redlinegames.attackhole", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Attack Hole - Black Hole Games - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/r8TsKF6VY6FC74IDuX8qAe0iOoQpsIY2cPzm-6PIvk3qv7rtpZleZqZ2b7t9N5EYHwjY", "added_at": "2023-04-12T08:08:42.852661", "updated_at": "2023-07-07T20:32:32.044117"}, 
    {"card_xid": "df3b5ebfc29b8eff912ee4592eaed47a6bc87d3577bab7f5d7a22460324bff5d", "card_link": "https://play.google.com/store/apps/details?id=com.king.candycrushsaga", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Candy Crush Saga - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/gU9NKwpgLDYA6LIYK4dnkAkVyqNHUfTIqklEiNuO4oZ2OCpWQhQdqhnDh8Yb9B8SWIM", "added_at": "2023-05-09T01:36:12.715478", "updated_at": "2023-07-07T20:32:32.114209"}, 
    {"card_xid": "c5d7b1a1e5a63d3dee211817008530938b78d0d6ea45c0f6b5ddfc43dfdc60a6", "card_link": "https://play.google.com/store/apps/details?id=com.easygames.race", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Race Master 3D - Car Racing - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/7x4TWM86ezxXupWVUj8Ta93eqzJt6eYUWkmbTgdSx5IDCPBstDN-59C1ewyLGMoxNno", "added_at": "2023-04-01T14:11:18.165699", "updated_at": "2023-07-07T20:32:32.186179"}, 
    {"card_xid": "e0bb9dbce54fdef9892890e11611323e19d0d2e782027fbbfb602fbaa156850e", "card_link": "https://play.google.com/store/apps/details?id=com.pronetis.ironball2", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Going Balls - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/ks07h0XiT08jo1deG5A3CDNjRZ5KGlVu0Jr133GRZyXkCC4j6VJ6x-IL_cffxoGMViM", "added_at": "2023-04-26T03:03:39.186306", "updated_at": "2023-07-07T20:32:32.260462"}, 
    {"card_xid": "5ef80e458f834b7a33fa682d4bf0970840c6d726d6cc8a8cbdb580682ff6ca81", "card_link": "https://play.google.com/store/apps/details?id=com.vincentb.MobControl", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Mob Control - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/WG9LoxydQHtU2S7iUvlLAdt1_fc5edPzT0Q_pJ7QJLhriQlz4G-ToaG6UJDiNJoBSAoA", "added_at": "2023-06-12T23:11:30.092979", "updated_at": "2023-07-07T20:32:32.327967"}, 
    {"card_xid": "64c1a07c668715b69cb92ff498c11f66dc3f30b1e34dbee7f23392f1e112d3e9", "card_link": "https://play.google.com/store/apps/details?id=com.fungames.sniper3d", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Sniper 3D\uff1aGun Shooting Games - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/McSve6acOo6AMtrZ3vmPcRgReC4QD-rHjZpXK1tDp6vUVQkYXtxYFn_ILA8Hj2qwVw", "added_at": "2023-04-21T02:50:32.730175", "updated_at": "2023-07-07T20:32:32.398820"}, 
    {"card_xid": "4d3a8b9c6cd42c4da902751408b4bc084e32dea66b1f6270e9e6ea4de8e6cced", "card_link": "https://play.google.com/store/apps/details?id=in.playsimple.wordsearch", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Word Search Explorer - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/mCfgwBA4i2DlZlcAYvghu2XMShRbDFrLRt0x7hLJKLd5dW3WnwkF8sHI2H220c1oae4", "added_at": "2023-06-13T13:51:14.039128", "updated_at": "2023-07-07T20:32:32.475307"}, 
    {"card_xid": "1911df3eb2efecfbffd601410c65083a8aa489522d8ece392c438fe6c1285ad5", "card_link": "https://play.google.com/store/apps/details?id=com.StudioGamebit.WildWestCowboy", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Wild West Cowboy Redemption - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/ZJNZYnvW-GbMxB2ynaBqZtglk-NhwayggDTGxqaYB_dXvtp0DGHj29vHRemmUiV2-aPf", "added_at": "2023-04-12T03:21:13.814210", "updated_at": "2023-07-07T20:32:32.548570"}, 
    {"card_xid": "573a730b08dc7a76528a3849527278a9ad4611b230374fe8d5fcc8f70e6af0f9", "card_link": "https://play.google.com/store/apps/details?id=com.draw.to.pee.bo", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Toilet Rush Race: Draw Puzzle - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/c240yqpF4BzB8XE4UF0yY4UTOzp9zVslIWHxsdEzVSg3v0BYKGcrTH5ZIcxED1z94Dcb", "added_at": "2023-04-16T00:21:06.745357", "updated_at": "2023-07-07T20:32:32.612938"}, 
    {"card_xid": "12af8caa50543b22b9a32bacf85a0280d2817174944ad33b9280d48bb9a1e482", "card_link": "https://play.google.com/store/apps/details?id=com.robtopx.geometryjumplite", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Geometry Dash Lite - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/p7kWbWBFxQIrzolEqiV5uSvctLeyssZvBV7UBOX29wzZI52IdcSs4qCB_zsQJL1mt9A", "added_at": "2023-05-25T15:26:16.044795", "updated_at": "2023-07-07T20:32:32.721883"}, 
    {"card_xid": "0d1e625339f9fb650264c9d0ea18ff1c755e3e7d2d19e7656cf2a29d5f3eb271", "card_link": "https://play.google.com/store/apps/details?id=com.Garawell.BridgeRace", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Bridge Race - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/nj_GuOTr8TTNXtSN0SaS5yY_i84C3u-wZ-er_F3YcFR-XMBFVhnmrPVZZPnL5F1Aaw", "added_at": "2023-05-01T06:34:40.508592", "updated_at": "2023-07-07T20:32:32.791115"}, 
    {"card_xid": "4f86d59f11af50ee497f4fe8ab25c5a8a46f124f0944addd085a9cdc1f48eb6a", "card_link": "https://play.google.com/store/apps/details?id=com.draw.to.home.bo", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Not Found", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": null, "added_at": "2023-06-09T15:04:40.605877", "updated_at": "2023-07-07T20:32:32.856621"}, 
    {"card_xid": "4d18b85567c4d8ff240866bcd13fecf52c2ac7d97c2cab0cc7ea96db80d09379", "card_link": "https://play.google.com/store/apps/details?id=com.tocaboca.tocalifeworld", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Toca Life World: Build a Story - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/L6dGUMljVL7OS5W21yZspPtI0IZirF9pNzZFs0ZRyuPIfWeyuSlGIRRAyJDnE6uaELg", "added_at": "2023-06-06T23:03:50.643141", "updated_at": "2023-07-07T20:32:32.858799"}, 
    {"card_xid": "35e5e46b4aeb5029581fe4ee4b064102c3010ba50c6b8d6101e3c9c76cda9672", "card_link": "https://play.google.com/store/apps/details?id=com.moonactive.coinmaster", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Coin Master - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/Gpp87vMy7HMftEnl8GkTJrWPf2g6lIbNR0asWPtFBNSduXxfdzuky2fK1itlx4pfjaU", "added_at": "2023-04-03T06:09:29.953298", "updated_at": "2023-07-07T20:32:32.931601"}, 
    {"card_xid": "3879b4537bcfe7987dec1caeab3fb5610ed0b5b3d9da964398cc3df1c2664699", "card_link": "https://play.google.com/store/apps/details?id=com.ht2.stick.hero", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Stick Hero: Tower Defense - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/Xu5aUXW2-VDcPUxxJxU7jkFQLUPQpplDeY8x5JVY-nq0-CBbjPFrVdjiB9vN-O6kHw", "added_at": "2023-06-15T09:16:55.473635", "updated_at": "2023-07-07T20:32:33.004554"}, 
    {"card_xid": "2f839c77bc996292eb29aca9a81dbb81c42f1a6110fcda0062bf06a194490a50", "card_link": "https://play.google.com/store/apps/details?id=com.peoplefun.wordcross", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Wordscapes - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/2TOwtdo3fcRQVnMo3Y-lqpK514490ZfztM-9J5XkbZ9E1hmxrfahQQawgH38Ojf7U9g", "added_at": "2023-06-08T10:52:10.591037", "updated_at": "2023-07-07T20:32:33.073271"}, 
    {"card_xid": "86ce6ff6858a29e9edc8db0baa4a29977a078d75f79e1a7b82e36b1dd46e1672", "card_link": "https://play.google.com/store/apps/details?id=com.unicostudio.braintest", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Brain Test: Tricky Puzzles - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/L4-Oa6O8GV4I23JTmo-xKSjfyjowc0d2uGGfdScYkCTMk6ftxTwGowdAjgh2nOrJlEmI", "added_at": "2023-05-25T04:55:02.941728", "updated_at": "2023-07-07T20:32:33.144882"}, 
    {"card_xid": "6a837a84b03c29ef21698b4484123ee98b882def1087782afabf30fa4827e4b7", "card_link": "https://play.google.com/store/apps/details?id=com.ione.robot.car.transformation", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Robot War: Robot Transform - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/zF4lXLXJrLZq3nek3nkn4hYTyBzAfH2IJa-0zllygv4C97sIwHKtghqL-lqjXacrKe0", "added_at": "2023-05-08T17:01:19.937643", "updated_at": "2023-07-07T20:32:33.218186"}, 
    {"card_xid": "04ba1945ca0f74c1bcbae42ef3bba65f9998460bab2687c328bdab0a3bb8e06b", "card_link": "https://play.google.com/store/apps/details?id=com.snakeattack.game", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Snake Run Race\u30fb3D Running Game - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/MZ4gG1DCdRSrVm6D8GBEBAVNKKMUfNKYmQg1CDRraIz6YQqqUlh4RO3h0N7Oy5oBflM", "added_at": "2023-05-04T12:34:44.712326", "updated_at": "2023-07-07T20:32:33.283644"}, 
    {"card_xid": "6eb0a475987706c0a64708bf9f9726a3004351f7d3c3672b53b96a5854acebf0", "card_link": "https://play.google.com/store/apps/details?id=com.maroieqrwlk.unpin", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Pull the Pin - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/WJ00mzxTYOfpL9S8i8T41VvciLM6QpHSotS3WtnFNNkK7JBnYtxtfb_s_fKLNC2Dru8", "added_at": "2023-04-29T03:14:31.061098", "updated_at": "2023-07-07T20:32:33.356752"}, 
    {"card_xid": "56d9f1082a9ac5b43ed24aa0f6c7299bac6c4dffea4680a8419a394d4104d54d", "card_link": "https://play.google.com/store/apps/details?id=com.os.space.force.galaxy.alien", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Galaxiga Arcade Shooting Game - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/XOiiMECalPlJJToUId6GWDZzjgIzMjppIN2Ej1ckjEIQ650oZy6BA6aNTb4t5LstJQo", "added_at": "2023-04-13T10:39:13.995045", "updated_at": "2023-07-07T20:32:33.425915"}, 
    {"card_xid": "fd3e14debf2723123aa8ec7194fe8c8d727920baf1a0fa94d8e273de30099bda", "card_link": "https://play.google.com/store/apps/details?id=tr.com.apps.fashion.battle", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Fashion Battle - Dress up game - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/LItARuBWfW7W0fIuDJ_DGQPs0n0RYgmkCoJGiGNhFFBUD918L8B8UF6Rwr0qL3-B6xc", "added_at": "2023-05-03T12:21:55.444486", "updated_at": "2023-07-07T20:32:33.500568"}, 
    {"card_xid": "e50c5f59230fdeacdac8b90c39db92e5eeeaac980f64186a9998f37d84ba888c", "card_link": "https://play.google.com/store/apps/details?id=io.voodoo.paper2", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Paper.io 2 - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/KxcjUrs8S75iOPnKBR2b-s7eGtqbREwLgzttVxaM9Znl5ZxsDCl6qEZHny4rBkMeYA", "added_at": "2023-05-15T19:25:17.750618", "updated_at": "2023-07-07T20:32:33.613219"}, 
    {"card_xid": "5a7e5f5683b6241c4259c96650554d254fad8de19626c7ceb4f919f546e8fd07", "card_link": "https://play.google.com/store/apps/details?id=com.playrix.gardenscapes", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Gardenscapes - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/bBcTnHOQz3yim_TVw-M21BDvUECT1fZWJHryApg5QXpBS6gw0YE6vjqkXw6wJdA7DIE", "added_at": "2023-04-03T08:12:23.726395", "updated_at": "2023-07-07T20:32:33.683561"}, 
    {"card_xid": "220ed3945d0e769ab3ae29143515e40a96e4e86a19bbae82a6739c2d4005878b", "card_link": "https://play.google.com/store/apps/details?id=com.miniclip.eightballpool", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "8 Ball Pool - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/m7UzuFoXqeQUzdI0FRjI4peJo901tFPDgHJpnFEXHgywmgPJMYa55g6Yr-ZrmQ6DcPg", "added_at": "2023-06-18T04:05:54.843188", "updated_at": "2023-07-07T20:32:33.756042"}, 
    {"card_xid": "0234331895b1f4a76bf4223870799f4f72955511eb5632b2968a48060f633e37", "card_link": "https://play.google.com/store/apps/details?id=com.freeplay.twerk", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Twerk Race 3D \u2014 Running Game - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/y0-nS7p0zwME3wAOLAendKlOQx8vjpImD0WylIw81Gw0Uqsbd7uGYq49wiRXEwNrHQ", "added_at": "2023-04-22T01:27:26.363747", "updated_at": "2023-07-07T20:32:33.829780"}, 
    {"card_xid": "391e4078e6b10fcf1c917c68bf2303c26447f81887a09e0dbe8628f1a894cab8", "card_link": "https://play.google.com/store/apps/details?id=com.nt.games.tangledsnakes", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Tangled Snakes - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/D3yOP9D_Ov_9MmKmm8WM42kKUkoNdj8HIRupvra1WQhlYGUrfLcsYm8HzEUyWR25GA", "added_at": "2023-06-08T00:50:12.061801", "updated_at": "2023-07-07T20:32:33.901916"}, 
    {"card_xid": "7c5d941b475a91e13dea0fe09eff4c28b01f875c2e3e67243dcee7ad322e1bb5", "card_link": "https://play.google.com/store/apps/details?id=com.ea.gp.fifamobile", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "FIFA Soccer - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/MUc561H_AeA4lUfUzyo0-6ZgikC6xrblMMPcbOTNC61iXb9hbfV9guj5IKiXAMfpumJw", "added_at": "2023-04-09T21:48:30.869376", "updated_at": "2023-07-07T20:32:33.973690"}, 
    {"card_xid": "441314ac89241f87eab9edacb8bb97450979f18c36fbf1ba6251be83990ee737", "card_link": "https://play.google.com/store/apps/details?id=com.pixel.art.coloring.color.number", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Happy Color\u00ae: Coloring Book - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/OKIUc3vQq9bemwOMHYURdp7sD2_arP63f11lHYwzfTM45pAVsQIdYjlZaaygkW5LP4U", "added_at": "2023-06-13T09:35:43.749025", "updated_at": "2023-07-07T20:32:34.047208"}, 
    {"card_xid": "ce2ca4fd477ae19f5b320bbb9bed0fa61921900548dc12ef8564d6ec991806e0", "card_link": "https://play.google.com/store/apps/details?id=com.my.lifting", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Lifting Hero - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/B9WZWOVUr-DTO2JgCsCcBqFfliQg4iS95YmB4dfufP3p0CCxi7jfhqCK3yKqSi0OADw", "added_at": "2023-05-26T15:44:08.286559", "updated_at": "2023-07-07T20:32:34.115313"}, 
    {"card_xid": "4f6444aba4ddee385e6ebd4c8245d48a1f7e26b03a52a6158340a3fe9bc84c66", "card_link": "https://play.google.com/store/apps/details?id=com.miracle.savethedoge.an", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Save the Doge - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/A2YGjwOpSv28KjtWmwZQ6Jl9CTawBL3NPqSf6i7i9CFZAIIy-k6qLgPHAMtPwLVJvbGA", "added_at": "2023-05-13T17:34:45.524006", "updated_at": "2023-07-07T20:32:34.184306"}, 
    {"card_xid": "96ddf9e071e988a874be7d4b705f93889b13662d4276b1fdf52988a0163a2eed", "card_link": "https://play.google.com/store/apps/details?id=com.playgendary.kickthebuddy", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Kick the Buddy - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/Pp_E-QbhIEbD4suP7XphQELzp85ef5nh7DoxFQgB9Rxfs2ZMFn0BCCgyNntBV50Mhrqh", "added_at": "2023-04-13T07:11:07.551829", "updated_at": "2023-07-07T20:32:34.253806"}, 
    {"card_xid": "81b69abc1130431a5ea2d7f2725c6fb4bd18c8f02170a907e928a3c602be0306", "card_link": "https://play.google.com/store/apps/details?id=io.supercent.burgeridle", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Burger Please! - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/cxcH6MnNpSWQDY9cBszYBq55NbJ7HnEityMQugLSiIZ_He-jWIz65fNR0v6KV2O6dA4", "added_at": "2023-06-18T12:07:23.796833", "updated_at": "2023-07-07T20:32:34.322639"}, 
    {"card_xid": "c82f48d6c1a7d0fe42f0dbf1265ac8e7463fd66074e628bb69bbdcf90b4b1e46", "card_link": "https://play.google.com/store/apps/details?id=com.pbgames.parkingorder", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Parking Order! - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/p7KP_uhoBSu8f7SnBoVT7Ju8UYnxCAPBl8D3unagp_VIwzxlQArKgK7cZh3njhaWaA", "added_at": "2023-05-26T03:44:29.653556", "updated_at": "2023-07-07T20:32:34.436131"}, 
    {"card_xid": "932f723fcc78bce15dc266d5671489c61fd567f164d53f8bcec04b7f4f3ea7ab", "card_link": "https://play.google.com/store/apps/details?id=com.superplaystudios.dicedreams", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Dice Dreams\u2122\ufe0f - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/IIGsWtdZWnn2-_ykp04KeAWsxHNJzLDaU-dxGc92cW9eUoNL5Yev5aR_yg-s4yBcM1dg", "added_at": "2023-06-15T10:32:26.680757", "updated_at": "2023-07-07T20:32:34.504988"}, 
    {"card_xid": "ed82ee6d128f34ee6b674dd73271c002cdb2389a7139e288a7b04f214f6e8971", "card_link": "https://play.google.com/store/apps/details?id=com.artlife.jigsaw.puzzle", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Jigsaw Puzzles: HD Puzzle Game - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/nBCwojtvlRwAuKUJdPHWQhcpvTgGzWBGXPQE6ReWk6Rh2rpP9Nhw1vdIPWn0iEfDRGM", "added_at": "2023-06-18T06:49:40.252302", "updated_at": "2023-07-07T20:32:34.571184"}, 
    {"card_xid": "9f743392278982145b4385c8a7aba6b84b1d8ac73d0f569b9240f66fc5d02866", "card_link": "https://play.google.com/store/apps/details?id=com.lszenlamzr.parkingjam", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Parking Jam 3D - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/xr-ptzXroMQW2SKkTiZ27qIXEMOcHIXgHg69juUxdg3jAk2ov1RYdPR0fkk0fZVWmlST", "added_at": "2023-04-08T22:58:56.044438", "updated_at": "2023-07-07T20:32:34.639213"}, 
    {"card_xid": "64f8cc6eb342e375a9ce77ea5d89cd5af9ea167476cec719ca6917f47b0fa5da", "card_link": "https://play.google.com/store/apps/details?id=com.dancing.smash.hop.game.tiles.beat.piano.surfer", "card_title": "play.google.com", "card_theme": null, "card_type": "image", "card_description": "Cyber Surfer: Beat&Skateboard - Apps on Google Play", "icon_img_src": "https://www.gstatic.com/android/market_images/web/favicon_v3.ico", "link_img_src": "https://play-lh.googleusercontent.com/sgyED2FhnMm-2RGCCO1UiucfCS6g8XHrbnNuDsTp7z-MypD82xXsdnekStqweJ2lfGo", "added_at": "2023-05-22T17:39:57.239949", "updated_at": "2023-07-07T20:32:34.711941"}, 
]
const profile = {
    xid: 'ADA6_A241_1AE1',
    name_key: 'popular_android_games',
    theme_color: '#39d1f0', 
    title: 'Must try games',
    img_src: 'https://img.freepik.com/free-vector/game-streamer-concept-elements-illustrated_23-2148932487.jpg?w=1060&t=st=1687260075~exp=1687260675~hmac=3957ffa9236c0628f4853fa439e3407bf20476c874f150d0346147b387b52f5b',
    description: [
        "Most Popular Games Apps in United States",
    ],
}
const author = {
    key: 'nitish_sundar',
    name: 'Nitish Sundar',
    img_src: 'https://img.freepik.com/free-photo/3d-cartoon-business-character_1048-16473.jpg?w=1060&t=st=1686919498~exp=1686920098~hmac=ff50423dcb7c4dbe4362c3e03f9d47f2ddf1da90e8a5f52a49b2a3c310144bf3',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
