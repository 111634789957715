import React from "react";
import { Link } from 'react-router-dom';


import {getRandomItemsFromObject} from 'utils/sorting';


const items = [
  // {link: "https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg", },
  // {link: "https://img.freepik.com/free-vector/cute-boy-standing-position-showing-thumb_96037-450.jpg?w=1480&t=st=1686919407~exp=1686920007~hmac=442c5a5ff6f4e931c2f0a97781c254de39ec48eb8731b211bb7024d9f34edc1b", },
  // {link: "https://img.freepik.com/premium-photo/cute-baby-cat_155807-11607.jpg?w=1060", },
  // {link: "https://img.freepik.com/free-photo/fun-3d-illustration-american-referee_183364-80776.jpg?w=1480&t=st=1686919604~exp=1686920204~hmac=3da499851855d8bc2c98a75ca9a3cca576d8214b83373ad0d19aae10438c0f8b", },
  // {link: "https://img.freepik.com/free-photo/3d-cartoon-business-character_1048-16473.jpg?w=1060&t=st=1686919498~exp=1686920098~hmac=ff50423dcb7c4dbe4362c3e03f9d47f2ddf1da90e8a5f52a49b2a3c310144bf3", },
  // {link: "https://img.freepik.com/free-photo/fun-3d-cartoon-illustration-indian-businessman_183364-114475.jpg?w=740&t=st=1686919588~exp=1686920188~hmac=e4fee9a9d3325b74960167c43886a3d9ca5bbe025d31b4c4766d37b75ea2bbdc", },
  // {link: "https://img.freepik.com/free-vector/illustrator-designer-man-holding-digital-tablet_107791-12062.jpg?w=1060&t=st=1686919549~exp=1686920149~hmac=dcd3fa17081d31ea69e01cad76e468d5152040978fd7c32ac5f9379ed577fd74", },
  // {link: "https://img.freepik.com/free-vector/application-programming-interface-concept-illustration_114360-9135.jpg?w=1060&t=st=1687440423~exp=1687441023~hmac=12830d60883b3aac174f89be583e4fec8d45286c3dfe6b3f6988f8288e0d6ce1", },
  // // {link: "https://yt3.googleusercontent.com/ytc/AGIKgqOkssb2Z_x4t6jYyJndF0SnVlRzwOmSz8kDAZqRew=s176-c-k-c0x00ffffff-no-rj", },
  // // {link: "https://pbs.twimg.com/profile_images/514865734816509952/k7puRwOr_400x400.jpeg", },
  // {link: "https://img.freepik.com/free-psd/headphone-headset-icon-isolated-3d-render-illustration_439185-11402.jpg?w=1060&t=st=1686919131~exp=1686919731~hmac=43aede423fec3f057a2416dde8296cccd398732f4e91f8ac590202d3e3003596", },
  // {link: "https://img.freepik.com/free-vector/acoustic-guitar-concept-illustration_114360-12608.jpg?w=1060&t=st=1686919091~exp=1686919691~hmac=172eaade14202992d543fd7a796b6b2d5cae6012ba50c61d1d09cccff648916f", },
  // {link: "https://img.freepik.com/premium-vector/cartoon-chef-holding-silver-platter_29190-8165.jpg?w=826", },
  // {link: "https://img.freepik.com/free-vector/flat-clapperboard-icon_1063-38.jpg?w=1060&t=st=1686918943~exp=1686919543~hmac=e2d66c480e99417fe7341d677f3a3aa17afd7294d10e01205d42edd7baf70eb2", },
  // {link: "https://img.freepik.com/free-photo/landscape-with-old-tree-silhouette-against-red-moonlit-sky_1048-11611.jpg?w=1480&t=st=1686918867~exp=1686919467~hmac=0c3922a0de37f8042f2f47a33f333b0e483e992b6836ce6278adf509546b135f", },

  {'url_key': '/@api_store/api_animals', 'title': "API: Animals", link: 'https://img.freepik.com/free-vector/wild-animals-white-background_1308-2815.jpg?w=1380&t=st=1687440945~exp=1687441545~hmac=588793d065b8a9da6b5ea3e9288ebb89e78cfdcca64ca2f165e5208c2bd9f496',},
  {'url_key': '/@api_store/api_books', 'title': "API: Books", link: 'https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books_23-2149334862.jpg?w=1060&t=st=1687440800~exp=1687441400~hmac=a95b9386a8e6d05d802631bf6afc525b4f36606c1d87b49d4f945184f750f71a',},
  {'url_key': '/@api_store/api_random', 'title': "API: Entertaintment", link: 'https://img.freepik.com/free-photo/colored-squares-falling_1048-2449.jpg?w=1060&t=st=1687440838~exp=1687441438~hmac=93457f6dcbe93fd005e480dee6c0375d44029726213cbb237302f16f08b06a00',},
  {'url_key': '/@api_store/api_games_comics', 'title': "API: Games & Comics", link: 'https://img.freepik.com/free-vector/gaming-disorder-abstract-concept-vector-illustration-video-game-addict-decreased-attention-span-gaming-addiction-behavioral-disorder-mental-health-medical-condition-abstract-metaphor_335657-2264.jpg?w=1060&t=st=1687440773~exp=1687441373~hmac=419ab45ce31fbe996a4f645a6003f08cf0bbad9c888ceaf23845576036ff3ab1',},
  {'url_key': '/@nitish_sundar/popular_android_games', 'title': "Must try games", link: 'https://img.freepik.com/free-vector/game-streamer-concept-elements-illustrated_23-2148932487.jpg?w=1060&t=st=1687260075~exp=1687260675~hmac=3957ffa9236c0628f4853fa439e3407bf20476c874f150d0346147b387b52f5b',},
  {'url_key': '/@nitish_sundar/popular_fonts', 'title': "Best Fonts", link: 'https://img.freepik.com/free-vector/coloured-best-signal_1114-6.jpg?w=1060&t=st=1687259697~exp=1687260297~hmac=01e31ef956aabd1f77009dbd7248da022f1fc66733dea9db9376f11c82f983c3',},
  {'url_key': '/@bb_ras/marketing_tools', 'title': "Marketing tools", link: 'https://img.freepik.com/free-vector/mobile-marketing-concept-illustration_114360-1497.jpg?t=st=1687249504~exp=1687250104~hmac=9c4a6307a1324c1e7a3467aa00ee73d0f5705f7f828875a5a0fd095717c4b65e',},
  {'url_key': '/@the_infographics_show/health_fitness_vids', 'title': "Health videos", link: 'https://img.freepik.com/free-vector/human-figure-dots-illustration_23-2147490478.jpg?w=740&t=st=1687173746~exp=1687174346~hmac=8807533dcd2d3831b1fd91e93b78bc2bd65664947faa80e3cced722746f0cc12',},
  {'url_key': '/@the_infographics_show/interesting_plays', 'title': "Interesting videos", link: 'https://img.freepik.com/free-vector/landscape-mode-concept-illustration_114360-8356.jpg?w=740&t=st=1687173529~exp=1687174129~hmac=dbd38f8c4fc556e4c2272308d9c692e180efb37e627440f545043d77e7725ebf',},
  {'url_key': '/@the_infographics_show/company_comparisons', 'title': "Company Comparisons", link: 'https://img.freepik.com/free-vector/versus-vs-screen-with-two-focus-light-effect_1017-26146.jpg?w=1380&t=st=1687425702~exp=1687426302~hmac=01913c074ceef40119b9165429274d732032ca2d099020f0a6f28238c21b930c',},
  {'url_key': '/@greg_spiess/cure_boredom_2', 'title': "Cure Boredom", link: 'https://img.freepik.com/free-vector/hand-drawn-happy-black-woman-illustration_23-2149404964.jpg?w=1800&t=st=1687167942~exp=1687168542~hmac=5ce480c6f8d773c79d79d40e96b36a50d2977a901921dc6a70d3dba33ee89ff7',},
  {'url_key': '/@johnni_coutinho/cure_boredom', 'title': "Cure Your Boredom", link: 'https://img.freepik.com/free-vector/stress-concept-illustration_114360-2031.jpg?w=1060&t=st=1687167904~exp=1687168504~hmac=c822008b2612e384142c753274774945bf94d40bcf8c89e4f4811cb4c5c7ec2a',},
  {'url_key': '/@johnni_coutinho/awesome_websites_list', 'title': "Awesome Websites", link: 'https://img.freepik.com/free-vector/think-outside-box-concept-illustration_114360-15044.jpg?w=1060&t=st=1687058293~exp=1687058893~hmac=d7e9f530cc94b42d59de5af9acbb6d288459e404a78b1772a6ddf0a732aaa291',},
  {'url_key': '/@jared_feroldi/kill_time_websites', 'title': "Kill time Websites", link: 'https://img.freepik.com/free-vector/deadline-concept-illustration_114360-6003.jpg?w=1060&t=st=1687057919~exp=1687058519~hmac=c12283f3178bcb33f2cbbdbd56d43c6687162bd9155b80133c1be83691ca5672',},
  {'url_key': '/@jared_feroldi/design_inspirations', 'title': "Websites for design inspirations", link: 'https://img.freepik.com/free-vector/flat-creativity-concept-illustration_52683-64279.jpg?w=1060&t=st=1687014588~exp=1687015188~hmac=e38671fa8beb72759efe906fafad84f27864d27231b18f3c818652eb3c698355',},
  {'url_key': '/@bb_ras/text_to_image', 'title': "Text to Image services", link: 'https://img.freepik.com/free-vector/cute-artificial-intelligence-robot-isometric-icon_1284-63045.jpg?w=1060&t=st=1686936935~exp=1686937535~hmac=aa804c1f919d3fdba60e62217ae8828d71f69be253b81856501b6758c698ceee',},
  {'url_key': '/@bb_ras/motivation5min', 'title': "5 Minute Motivations", link: 'https://img.icons8.com/?size=512&id=YJZ8teK9DFg7&format=png',},
  {'url_key': '/@clay_owen/vegan_brands', 'title': 'Vegan Brands', link: 'https://cdn-icons-png.flaticon.com/512/1031/1031521.png?w=1060&t=st=1686881788~exp=1686882388~hmac=493bdfc4a54b44750a23013d4aa4b4bf73fd544d983bc11d5f741f7fdd2c9c69',},
  {'url_key': '/@bb_ras/programming_motivations', 'title': 'Programming Motivations', link: 'https://img.freepik.com/free-vector/hacker-operating-laptop-cartoon-icon-illustration-technology-icon-concept-isolated-flat-cartoon-style_138676-2387.jpg?w=1060&t=st=1686898170~exp=1686898770~hmac=c5188fb627ffd1d6224f9b6ccd0be607cc93ae3999035d8711415d7b7217b5aa',},
  {'url_key': '/@bb_ras/service_architecture', 'title': 'Service Architecture', link: 'https://img.freepik.com/free-vector/desktop-smartphone-app-development_23-2148683810.jpg?w=1060&t=st=1686898138~exp=1686898738~hmac=dfbe66b49abee36c3b3dd1eaf200d8d131152064ae793299d43b17bd1d896be2',},
  {'url_key': '/@amit_rajput/moocs', 'title': 'MOOCs', link: 'https://img.freepik.com/free-vector/online-certification-illustration_23-2148575636.jpg?w=1060&t=st=1686894838~exp=1686895438~hmac=ea078f2071270833ac6011df7d3e4c8ffee0fe80f31354d98b1efa6271db324a',},
  {'url_key': '/@amit_rajput/colour_tools', 'title': 'Colour Tools', link: 'https://img.freepik.com/free-vector/colour-painting-plate-with-brush-isolated_1308-57589.jpg?w=1380&t=st=1686894879~exp=1686895479~hmac=263fc3509c3f18c07835ee7cd159136ce09ebcb659cbc83ecce1d8ea2c6922b5',},
  {'url_key': '/@zen_habits/zen_habits', 'title': "Zen Habits", link: 'https://images.convertbox.com/users/984/81d556bcf5f2c7d219be50615b0f6453.png',},

]

const logos = [

  // { link: 'https://startx.com/system/companies/logos/000/000/003/medium/stemcell.png', },
  // {link: 'https://bytebeacon.com/logo.png',},
  // {link :'https://bytebeacon.com/static/images/divshow/sign.png'},
  // {link :'https://bytebeacon.com/static/images/divshow/logo.svg'},
  // {link :'https://bytebeacon.com/static/images/lightflare/sign.png'},
  // {link :'https://bytebeacon.com/static/images/lightflare/logo.svg'},
  // {link :'https://bytebeacon.com/static/images/navigator/sign.png'},
  // {link :'https://bytebeacon.com/static/images/softcomm/sign.png'},
  // {link :'https://bytebeacon.com/static/images/bytetrack/sign.png'},
  // {link :'https://bytebeacon.com/static/images/brandcode/sign.png'},
  // {link :'https://bytebeacon.com/static/images/bytepost/sign.png'},
  // { link: 'https://startx.com/system/companies/logos/000/000/010/medium/Modewalk.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/030/medium/Think.Bulbs.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/136/medium/gauss-logo-dark.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/156/medium/Legalio.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/181/medium/Remedly_Logo_400.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/192/medium/2rblogo_2x.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/195/medium/eargo_logo_main.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/200/medium/breaker.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/217/medium/Epinomics.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/228/medium/heap.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/245/medium/nearby.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/267/medium/stack-green_4x.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/271/medium/Treats_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/273/medium/Carmenta_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/275/medium/SB_banner.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/276/medium/Infinigene.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/277/medium/amigocloud.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/280/medium/Bolt.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/296/medium/Logo_RentMethod.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/301/medium/payjoy_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/313/medium/Novoed.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/315/medium/Breathometer.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/314/medium/periscope_logo.png', },
  
  // { link: 'https://startx.com/system/companies/logos/000/000/264/medium/studynotes_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/270/medium/Frankly_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/282/medium/Clef_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/306/medium/Ziva.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/316/medium/omg_logo_%281%29.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/320/medium/edcast.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/212/medium/doxiq.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/281/medium/daric.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/286/medium/halo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/305/medium/logo-color.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/326/medium/Citrine.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/332/medium/ARIO_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/333/medium/spry-logo-horz-1.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/335/medium/FARM_HILL_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/344/medium/pillclub.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/348/medium/VERSAME_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/353/medium/DTL_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/354/medium/magpie_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/361/medium/Chesscademy_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/362/medium/Civil_Maps_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/363/medium/Copycat_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/393/medium/ZingBox_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/414/medium/QuantiacsLogo.png', },
  
  { link: 'https://startx.com/system/companies/logos/000/000/318/medium/chobo_large.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/292/medium/naya.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/295/medium/Polarr_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/452/medium/lomotif.png', },

  // { link: 'https://startx.com/system/companies/logos/000/000/338/medium/LINC_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/339/medium/LUCKY_DAY_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/415/medium/Elsa.PNG', },
  

  // { link: 'https://startx.com/system/companies/logos/000/000/327/medium/Globavir_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/328/medium/nearpod.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/337/medium/GITSTAR_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/340/medium/LUMA_HEALTH_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/341/medium/LUNA_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/343/medium/Metamixis_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/349/medium/WALDO_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/364/medium/playposit.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/356/medium/aromyxlogo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/366/medium/FuelX.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/380/medium/Samegoal_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/371/medium/Maderight_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/372/medium/yup-logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/373/medium/moxxly_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/367/medium/gfycatblue_2x.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/368/medium/Gruv_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/377/medium/Realstax_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/381/medium/Sonar_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/383/medium/Tackk_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/384/medium/teapot_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/389/medium/UnifyID_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/391/medium/Worklife_Logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/392/medium/workr-logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/397/medium/peercdn.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/399/medium/Fictiv.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/511/medium/eero.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/401/medium/booster.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/402/medium/dfxmachina.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/403/medium/SeaUrchin.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/406/medium/Military_Mobile.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/407/medium/Ampervue.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/410/medium/sentryai.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/417/medium/Blendoor.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/422/medium/Wefuel.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/413/medium/Forward_Networks.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/416/medium/RenovoRx.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/434/medium/Caligenics.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/427/medium/Tinfoil_Security.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/429/medium/Sourcegraph.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/439/medium/workhood.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/440/medium/apptimize.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/444/medium/mattermost.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/445/medium/zendy_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/447/medium/argo-logo.png', },

  // { link: 'https://startx.com/system/companies/logos/000/000/411/medium/Cooler.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/424/medium/Tripcipe_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/426/medium/Epika_Bio.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/430/medium/Bluecrew.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/432/medium/Levo.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/438/medium/bodygram_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/448/medium/copilots.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/451/medium/seadrone.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/453/medium/datatron.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/454/medium/true.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/456/medium/littlelane.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/459/medium/brilian.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/461/medium/multera_bio.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/462/medium/madorra.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/463/medium/innovein.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/464/medium/fourleaf.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/466/medium/general_radars.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/467/medium/olakai.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/468/medium/dripcapital.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/469/medium/foldscope.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/470/medium/sizzle.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/473/medium/ought.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/476/medium/orca.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/478/medium/hepatx.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/480/medium/servers.global.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/481/medium/bullet_bio.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/482/medium/neighborly-logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/483/medium/freenome.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/484/medium/sleepbus.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/486/medium/AIFI_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/488/medium/tombo.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/489/medium/sail_internet.PNG', },
  // { link: 'https://startx.com/system/companies/logos/000/000/490/medium/slyce_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/491/medium/hingeto.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/492/medium/tankwallet_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/493/medium/shelterluv.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/496/medium/heartvista.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/502/medium/ravel.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/504/medium/ayasdi.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/505/medium/Loli_and_pops.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/494/medium/miroculus_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/495/medium/EMBRACE_LOGO.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/499/medium/exactuals_logo.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/500/medium/fem_inc.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/501/medium/digital_grid.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/506/medium/strivelabs.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/508/medium/tachyus.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/510/medium/logo-gridspace.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/512/medium/VIDA.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/513/medium/karius.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/515/medium/pixterity.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/517/medium/SVTP.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/519/medium/finvoice.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/521/medium/ShaperBlack.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/522/medium/lets_maybe.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/523/medium/eden.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/525/medium/vocate_.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/529/medium/axiom_eery_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/530/medium/chimera.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/534/medium/logo-transparent.png', },
  // { link: 'https://startx.com/system/companies/logos/000/000/537/medium/vynca.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/540/medium/Jengu_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/541/medium/unnamed.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/545/medium/Augmedix_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/546/medium/profile_pic.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/548/medium/NameCoach_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/549/medium/fibralign.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/550/medium/Lucid_VR_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/552/medium/Black_SMS_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/553/medium/sem.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/555/medium/Awair_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/556/medium/VideoSlick_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/557/medium/Deep_Vision_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/562/medium/Long_Game_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/563/medium/Kofa_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/564/medium/Sandstone_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/565/medium/250x70_wordmark.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/566/medium/nova.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/568/medium/mocacare.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/569/medium/proframa.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/571/medium/AgriWebb.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/574/medium/bioserenity.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/576/medium/ethos.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/578/medium/Inflammatix.PNG', },
  { link: 'https://startx.com/system/companies/logos/000/000/580/medium/piper_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/584/medium/Qurasense_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/588/medium/sowo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/589/medium/tipster.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/590/medium/Untrodden.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/591/medium/vimolabs.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/597/medium/ContractSafe.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/605/medium/levitas.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/606/medium/Copy_of_LP_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/623/medium/Pinpoint_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/629/medium/circle_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/646/medium/blue.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/661/medium/probius.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/664/medium/nephrogen.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/670/medium/logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/680/medium/logo-color.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/691/medium/Oloid_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/695/medium/logo-1024.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/698/medium/Tonic-logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/703/medium/leo-logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/706/medium/Spect-v.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/708/medium/ondaka_Logo-vert.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/709/medium/image016.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/712/medium/unnamed.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/713/medium/logo_png_black.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/716/medium/diagnoseearly.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/724/medium/respirix-logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/726/medium/cover_400_light.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/727/medium/tagbio.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/728/medium/GEn1E_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/745/medium/logo-280px.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/746/medium/Factor_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/748/medium/Ferrum_Logo_Dark.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/749/medium/Alertive_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/750/medium/Brand_2x.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/754/medium/ticket_dm.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/796/medium/Primary_Wordmark.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/800/medium/lime-logo-green.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/806/medium/500x500_logo_TnT.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/808/medium/liberio.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/810/medium/nanowear.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/815/medium/logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/826/medium/apstem.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/845/medium/nimblr-logo-01.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/863/medium/Sandbloom_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/865/medium/serviceMob_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/870/medium/logo_cortexxus.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/873/medium/logo_black.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/879/medium/GWAPPS_Logo-04.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/881/medium/prenuvo-logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/882/medium/fl_logo_blue_800.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/894/medium/BuildOps-BG.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/896/medium/Komaza_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/897/medium/dark-transparent.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/916/medium/Logo-04.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/922/medium/Frosty_Logo_2.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/924/medium/omiq_logo_black.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/930/medium/enya-logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/933/medium/Ladera_Logo_TM.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/936/medium/Logo-V-V2.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/937/medium/Raydiant_Logo_2.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/942/medium/findigs_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/948/medium/61997705.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/952/medium/Buzz_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/953/medium/bluecord_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/980/medium/logo_dark.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/983/medium/Control_logo_4x.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/984/medium/Decentriq_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/995/medium/plentina_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/000/997/medium/Flowly_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/002/medium/logo-inverted_2x.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/005/medium/logotype_black.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/008/medium/Logo_PNG_3x.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/012/medium/MH-Logo-1200px.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/021/medium/LifoIcon.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/025/medium/bach_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/029/medium/cropped_AB_no_R.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/030/medium/Text_black.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/034/medium/I-Stem_logo-min.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/038/medium/tikr-logo-green.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/042/medium/MODULUS_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/047/medium/index_%2815%29.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/050/medium/Citadel.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/051/medium/cover.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/055/medium/Kibbo-Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/064/medium/gable_purple.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/068/medium/nsitegradient.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/082/medium/bundle_logo_png.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/085/medium/logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/086/medium/aurie_logo-01.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/094/medium/blue.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/095/medium/Glyphic3.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/098/medium/image_%286%29.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/105/medium/Ramani_New_Logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/109/medium/Blue_Full_Name.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/113/medium/herologotangible.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/117/medium/aether_logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/122/medium/logo.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/125/medium/logo1.png', },
  { link: 'https://startx.com/system/companies/logos/000/001/127/medium/logo_transparent.png', },
]


export default function LogosComponent(props){
  const bg_class = props.bg_class || 'primary';

  const fetchSection = function(multiplier){
    // const count = 15; // count hould idealy be 12
    const count = items.length;
    const range = [count*multiplier, count*multiplier+(count-1)];

    const result = getRandomItemsFromObject(items, items.length).reduce(function(accumulator, currentValue, currentIndex, array) {
      if (currentIndex>range[0] && currentIndex<range[1]){
        accumulator.push(currentValue);
      } 
      return accumulator;
    }, []);

    return result;
  }

  const logos_first = fetchSection(0).map(function(item, idx){return(
    <a key={"_" + idx} href={item.url_key}>
      <img src={item.link} height="120" width="120" style={{borderRadius: '50%'}} />
      <p className="text-center black-text-6 text-size-2">{item.title}</p>
    </a>
  )})
  // const logos_second = fetchSection(1).map(function(item, idx){return(<img key={"" + idx} src={item.link} height="50" />)})
  
  const is_mobile = window.innerWidth < 1200;

  return (
    <div id="logos" className="content-container">
      <div className="">

        <h1 className={" px-3 black-text-1 " + ((is_mobile)? " text-size-9 ": " text-size-12 ")}>Sample collections</h1>
          
        <div className="slider py-5 ">
          <div style={{  filter: 'grayscale(99%)'}} className="slide-track slide-track-right" >{logos_first}{logos_first}</div>
        </div>

        <div className="mb-5 mt-3 text-center ">
          <Link to="/discover" className=" text-size-8 black-text-1 p-3  mb-3" style={{fontFamily: 'Caveat'}}>
            Explore more 
            <i className="fa fa-plane ml-3 "></i>
          </Link>
        </div>
      </div>
    </div>
  )

}
