import React from 'react';
import { withRouter} from 'react-router-dom';

import Notifications, {notify} from 'react-notify-toast';

// import qs from 'query-string';

// import {Loader} from 'component/assets/loader';
import PageLayout from 'component/layout/page/content';
import {PageCTA as SubscribePageCTA} from  'component/content/subscribe';
import {saveToken, saveLoginMeta, hasStoredToken} from 'utils/authentication';
// import API, {handleError} from 'utils/xhr';


class LoginEmail extends React.Component {
  state = {
    loading: false,
    form_data: {},
    show_code: false,
  }

  componentDidMount(){
    if (hasStoredToken()){
      this.props.history.push('/');
    }
  }

  onChange(e){
    const state = this.state;
    state.form_data[e.target.name] = e.target.value;
    this.setState(state);
  }

  sendEmailCode(){

    var data = this.state.form_data;
    const state = this.state;
    state.loading = true;
    this.setState(state);

    let myColor = { background: '#ff6666', text: "#FFFFFF" };
    if (data.work_email === null || data.work_email === undefined || data.work_email === ''){
      notify.show('Login Failed: Email cannot be empty!', "custom", 2000, myColor);
    } else {
      notify.show('Login Failed: Email not onboarded!', "custom", 2000, myColor);
    }

    // TODO: record email
  }

  render (){
    return (
      <PageLayout id="login-invite">

        <div className="login-form dark-highlight-1 rounded p-2">

          <div className="avatar" align="center">
            <span className="fa-stack fa-4x align-items-center">
              <i className="fa fa-circle fa-stack-2x text-secondary"></i>
              <i className="fa fa-at fa-stack-1x fa-inverse"></i>
            </span>
          </div>


          <h2 className="text-center">Email login</h2>

            <div className="my-5 black-text-5 text-center small">
              <div className="">We're in early beta!</div>
              <div className="">Your login will only work if your email has been onboarded.</div>
              <div className="">Please request early access for login to work.</div>
            </div>

          <div className="m-4">

            <div className="form-group">
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    name="work_email"
                    onChange={this.onChange.bind(this)}
                    value={this.state.work_email}
                    placeholder="user@email.com"/>
                </div>
                {(this.state.show_code)? (
                  <div className="">
                    <label className="col-form-label">Invite Code: </label>
                    <input
                      type="text"
                      className="form-control"
                      name="invite_code"
                      onChange={this.onChange.bind(this)}
                      value={this.state.invite_code}
                      placeholder="Invite code from your email"/>

                  </div>
                ): ('')}
              </div>

              <div className="form-group">

                {(this.state.show_code)? (
                  <button
                    onClick={this.runLogin.bind(this)}
                    className="btn btn-primary btn-lg">
                    <span className="">Login <i className="fa fa-sign-in"></i></span>
                  </button>
                ): (
                  <button
                    onClick={this.sendEmailCode.bind(this)}
                    className="btn btn-secondary btn-lg">
                      <span className="">Generate Login code <i className="fa fa-cog fa-spin fa-lg ml-1"></i></span>
                  </button>
                )}

                <p className="m-2 lead small">Trouble logging in? Contact us <a href="mailto:info@bytebeacon.com" className="black-text-5">here</a></p>

                <div className="m-2 mt-5 ">
                  <a href="/login" className="black-text-5"><i className="fa fa-right-to-bracket mr-2"></i>Back to login options</a>
                </div>

              </div>

              {/* {(this.state.loading)? (<Loader />): ''} */}


          </div>

        </div>


        {/* <div className="content-section "    style={{backgroundImage: `url('/static/images/bg/06.svg')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}> */}
        <div className="dark-bg-2">
          <div className="mx-5 px-5">
            <SubscribePageCTA category="early_access" inverted />
          </div>
        </div>

      </PageLayout>
    )
  }
}

export default withRouter(LoginEmail);
