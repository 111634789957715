import React from 'react';

import PageBlankLayout from 'component/layout/page/blank';

import {ContentFooter} from 'component/layout/footer';
import SEOComponent from 'component/global/seo';



export default function ContentLayout(props) {
  const context = props.context || {};
  return (
    <PageBlankLayout>
      <SEOComponent seo={context.seo}/>
      {props.children}
      <ContentFooter sticky internal_links />
    </PageBlankLayout>
  );
}
