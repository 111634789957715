import React from "react";
// import Slide from 'react-reveal/Slide';
import Rotate from 'react-reveal/Rotate';



const SECTIONS = [
  {
    'title': "Individuals",
    "src": '/for/personal',
    "description": [
      "Organize your bookmarks,",
      "Get alerted on broken links,",
      "Search through saved content",
    ], 
    "img_src": "/static/images/splits/personal.svg", 
  },
  {
    'title': "Creators", 
    "src": '/for/creator',
    "description": [
      "Grow followers with shared collections,",
      "View usage analytics,",
      "Sync bookmarks from predefined sources",
    ], 
    "img_src": "/static/images/splits/creator.svg", 
  },
  {
    'title': "Companies", 
    "src": '/for/enterprise',
    "description": [
      "Accelerate employee on-boarding,",
      "Keep the team engaged with links to announcements / industry related news",
      "Help the team build and maintain relevent knowledge base",
    ], 
    "img_src": "/static/images/splits/enterprise.svg", 
  },
]


function ComponentItem(props){
  var item = props.item;
  return (
    <div className="family-p mx-4 my-4 " >
      <div className="text-center ">
        <a href={item.src} className="">
          <img src={item.img_src} height="200px" width="" alt={item.title} className="" />
          <p className=" text-size-10 mt-3 p-2 black-text-1" style={{fontFamily: 'Caveat'}}> {item.title}</p>
        </a>
        <div className="py-3 black-text-4 text-size-4">
          {item.description.map(function(desc, idx){
            return(<p key={""+idx} className="m-0 p-0" >{desc}</p>)
          })}
        </div>
      </div>
    </div>
  );
}


export default function Component(props) {

    return (
      <section id="split" className="content-section my-5 ">
        
        <h6 className="text-center text-size-15 my-5">{"Used by ... "}</h6>

        <div className="container-fluid text-black-4">
          <div className="row">
              {SECTIONS.map(function(item, idx){
                return (
                  <React.Fragment key={'_' + idx}>
                  <Rotate bottom left>
                    <div className={"col-lg-4 col-md-4 col-sm-4 col-xs-12 "}>
                        <ComponentItem item={item} />
                    </div>
                  </Rotate>
                  </React.Fragment>
                )
              })}

          </div>              
        </div>
        
      </section>
    )
}