const cards = [
    {"card_xid": "22d9c4e7dec859bca6b2f647785bb99dc16cf46b7336053d3140627ae0bb679e", "card_link": "https://www.abibliadigital.com.br/en", "card_title": "www.abibliadigital.com.br", "card_theme": null, "card_type": "image", "card_description": "AB\u00edbliaDigital | A RESTful API for Bible", "icon_img_src": "https://www.abibliadigital.com.br/theme-v2/images/favicon/apple-icon-120x120.png", "link_img_src": null, "added_at": "2023-04-04T18:00:16.940894", "updated_at": "2023-07-07T20:32:18.381389"}, 
    {"card_xid": "8977f61b29f4116b676fbeaf227c0bdcdaffc16a113ab659ef4cccec7470e8fa", "card_link": "https://docs.bhagavadgitaapi.in", "card_title": "docs.bhagavadgitaapi.in", "card_theme": null, "card_type": "image", "card_description": "Site not found \u00b7 GitHub Pages", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-08T05:09:09.128808", "updated_at": "2023-07-07T20:32:18.393531"}, 
    {"card_xid": "1f55cafeae459fcc43ee04924ca9462c890773264599359c96e05764e15302a1", "card_link": "https://bhagavadgita.io/api", "card_title": "bhagavadgita.io", "card_theme": null, "card_type": "image", "card_description": "Bhagavad Gita API - BhagavadGita.io", "icon_img_src": "https://bhagavadgita.io/apple-touch-icon.png", "link_img_src": "https://bhagavadgita.io/static/images/sribhagavadgita.jpg", "added_at": "2023-04-06T12:13:35.693715", "updated_at": "2023-07-07T20:32:18.398434"}, 
    {"card_xid": "2722127aa19e0b4a7f55ac2a3761cf9a65230c98b04d1acb5731b7f6e1041531", "card_link": "https://gita-api.vercel.app", "card_title": "gita-api.vercel.app", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-13T03:09:31.495862", "updated_at": "2023-07-07T20:32:18.417719"}, 
    {"card_xid": "0e85dd3add700e04dc62db0dfcd8f0d2ad83f087b2b782426b3e0a0f3629b0a7", "card_link": "https://bible-api.com/", "card_title": "bible-api.com", "card_theme": null, "card_type": "image", "card_description": "bible-api.com", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-29T03:01:27.279665", "updated_at": "2023-07-07T20:32:18.419342"}, 
    {"card_xid": "94c4dd044d0d86de369e8284e675ebce4cb03f3e993a1358cdf7d6efd38c013c", "card_link": "http://bnb.data.bl.uk/", "card_title": "bnb.data.bl.uk", "card_theme": null, "card_type": "image", "card_description": "Linked Open BNB  - The British Library", "icon_img_src": "https://www.bl.uk/britishlibrary/resources/global/images/favicon.ico", "link_img_src": "https://www.bl.uk/britishlibrary/~/media/bl/global/services/collection%20metadata/getty-images-1139968862.jpg", "added_at": "2023-05-29T12:15:58.213561", "updated_at": "2023-07-07T20:32:18.425939"}, 
    {"card_xid": "49f8b53110d3f24efd07b2b233a39726b39d0a95eaa7c50357189ab7b076d639", "card_link": "https://github.com/CrossRef/rest-api-doc", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - CrossRef/rest-api-doc: Documentation for Crossref's REST API. For questions or suggestions, see https://community.crossref.org/", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/6bbedd4937d4d7e3b022f4e85d654ff4a82aa9f2a8e9e38708589ea63ccba111/CrossRef/rest-api-doc", "added_at": "2023-05-02T12:44:55.806520", "updated_at": "2023-07-07T20:32:18.470647"}, 
    {"card_xid": "a1669f04d9e83c52d28e4a83552625e80d70c31a38a50c61e870e3d336a7017f", "card_link": "https://api.ganjoor.net", "card_title": "api.ganjoor.net", "card_theme": null, "card_type": "image", "card_description": "Swagger UI", "icon_img_src": "https://api.ganjoor.net/favicon-32x32.png", "link_img_src": null, "added_at": "2023-05-01T09:34:25.148606", "updated_at": "2023-07-07T20:32:18.674901"}, 
    {"card_xid": "855e4a1110af38b45b8042ab07513712089f2ee598fc5d5c233a8d68ebf58c44", "card_link": "https://developers.google.com/books/", "card_title": "developers.google.com", "card_theme": null, "card_type": "image", "card_description": "Google Books APIs \u00a0|\u00a0 Google for Developers", "icon_img_src": "https://www.gstatic.com/devrel-devsite/prod/v530e2b4758c915c59675435df4424a8b4929cfd382db2f39325a64003950cf58/developers/images/touchicon-180-new.png", "link_img_src": "https://www.gstatic.com/devrel-devsite/prod/v530e2b4758c915c59675435df4424a8b4929cfd382db2f39325a64003950cf58/developers/images/opengraph/google-blue.png", "added_at": "2023-06-12T22:48:34.193276", "updated_at": "2023-07-07T20:32:18.678085"}, 
    {"card_xid": "a62f4cf7df17bd2967d29826050a30c5acec656a12aa619a5103c35251fb614b", "card_link": "https://github.com/GurbaniNow/api", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "Page not found \u00b7 GitHub \u00b7 GitHub", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://github.githubassets.com/images/modules/open_graph/github-octocat.png", "added_at": "2023-05-18T23:25:40.566931", "updated_at": "2023-07-07T20:32:18.704786"}, 
    {"card_xid": "6419d8b57feddfa499d96641259c6fbbf3b818a082a73632a0fce18e17396c19", "card_link": "https://gutendex.com/", "card_title": "gutendex.com", "card_theme": null, "card_type": "image", "card_description": "Gutendex", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-01T14:41:15.083686", "updated_at": "2023-07-07T20:32:18.750494"}, 
    {"card_xid": "55018b4206677592e2313a572a5a1bcc709d5706beccd52eae27c1fe3067ed05", "card_link": "https://openlibrary.org/developers/api", "card_title": "openlibrary.org", "card_theme": null, "card_type": "image", "card_description": "Developer Center / APIs | Open Library", "icon_img_src": "https://openlibrary.org/static/images/openlibrary-180x180.png", "link_img_src": null, "added_at": "2023-04-15T03:30:55.578617", "updated_at": "2023-07-07T20:32:18.757304"}, 
    {"card_xid": "472a214e7b7422b18b24b7bb858e9d727a7cfef0ae1ccce1924f322bed65b240", "card_link": "http://www.penguinrandomhouse.biz/webservices/rest/", "card_title": "www.penguinrandomhouse.biz", "card_theme": null, "card_type": "image", "card_description": "penguinrandomhouse.biz - Penguin Random House Rest Services API", "icon_img_src": "http://www.penguinrandomhouse.biz/favicon.ico", "link_img_src": null, "added_at": "2023-04-22T06:28:32.231591", "updated_at": "2023-07-07T20:32:18.797283"}, 
    {"card_xid": "0beb6ed19111c8d9423baa0d06615af873a44255f6dbde9a711ba64d55a1eef0", "card_link": "https://github.com/thundercomb/poetrydb#readme", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - thundercomb/poetrydb: The Internet's first Poetry API", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/67ecd8ee6f588705d79a089acd16326c2eaf71adef1f82aea574e00f36b6645d/thundercomb/poetrydb", "added_at": "2023-04-28T23:07:18.988095", "updated_at": "2023-07-07T20:32:18.903566"}, 
    {"card_xid": "bf0b15a286b0b13f28b94227209b782efcc47d7c1b7385bc8076507af6acde41", "card_link": "https://quran.api-docs.io/", "card_title": "quran.api-docs.io", "card_theme": null, "card_type": "image", "card_description": "API Docs", "icon_img_src": "https://quran.api-docs.io/favicon.ico", "link_img_src": null, "added_at": "2023-05-11T23:17:25.006006", "updated_at": "2023-07-07T20:32:19.082183"}, 
    {"card_xid": "af49c62c3a838b98ffbf4e2077d3a374d264f5c6d46a8442d95c78a86f995f50", "card_link": "https://alquran.cloud/api", "card_title": "alquran.cloud", "card_theme": null, "card_type": "image", "card_description": "Quran API", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-01T03:21:14.312291", "updated_at": "2023-07-07T20:32:19.086224"}, 
    {"card_xid": "13f0dcd261365f5ca2b395e41ebce5b4756be1c5cd28573eed300cd9fdfc639b", "card_link": "https://github.com/fawazahmed0/quran-api#readme", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - fawazahmed0/quran-api: Free Quran API Service with 90+ different languages and 400+ translations", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://repository-images.githubusercontent.com/285650585/36f9cf80-2d10-11eb-8a4d-5b11f742ca02", "added_at": "2023-05-28T18:15:56.790446", "updated_at": "2023-07-07T20:32:19.115470"}, 
    {"card_xid": "563dae45e19c020b02cd45dc58095035ebc43ad26b1ea6b7becdab5be4439b65", "card_link": "https://aninditabasu.github.io/indica/html/rv.html", "card_title": "aninditabasu.github.io", "card_theme": null, "card_type": "image", "card_description": "Broken link", "icon_img_src": "https://aninditabasu.github.io/indica/html/assets/images/favicon.ico", "link_img_src": null, "added_at": "2023-04-22T03:46:37.463590", "updated_at": "2023-07-07T20:32:19.222539"}, 
    {"card_xid": "a87b5e12b539759b3027153abb95615e7ac6d83c54336365f73d12346ff46347", "card_link": "https://docs.api.bible", "card_title": "docs.api.bible", "card_theme": null, "card_type": "image", "card_description": "Welcome | API.bible Documentation", "icon_img_src": "https://docs.api.bible/icons/icon-512x512.png?v=482887b1a5791c4a501134cd8f5c8aaa", "link_img_src": "https://docs.api.bible/docs/banner.png", "added_at": "2023-04-19T18:15:05.631179", "updated_at": "2023-07-07T20:32:19.224910"}, 
    {"card_xid": "448b4c8596f5b285d3ab59579689ab764967f14bde44f00d38e80906264319f7", "card_link": "https://api-thirukkural.web.app/", "card_title": "api-thirukkural.web.app", "card_theme": null, "card_type": "image", "card_description": "API THIRUKKURAL", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-09T06:50:26.170648", "updated_at": "2023-07-07T20:32:19.244637"}, 
    {"card_xid": "3260de435e0aef51233f5c93a0c7a9ee829dc779794acad518c0ae2424b44a59", "card_link": "https://aninditabasu.github.io/indica/html/vs.html", "card_title": "aninditabasu.github.io", "card_theme": null, "card_type": "image", "card_description": "Broken link", "icon_img_src": "https://aninditabasu.github.io/indica/html/assets/images/favicon.ico", "link_img_src": null, "added_at": "2023-05-21T10:38:24.355400", "updated_at": "2023-07-07T20:32:19.252960"}, 
    {"card_xid": "0b2052f1b6112679aa10528c397c56378d00af0c5a08273a045a76bcc3de3c6c", "card_link": "https://wizard-world-api.herokuapp.com/swagger/index.html", "card_title": "wizard-world-api.herokuapp.com", "card_theme": null, "card_type": "image", "card_description": "Swagger UI", "icon_img_src": "https://wizard-world-api.herokuapp.com/swagger/favicon-32x32.png", "link_img_src": null, "added_at": "2023-05-31T16:00:45.427809", "updated_at": "2023-07-07T20:32:19.255093"}, 
    {"card_xid": "ab7b47e869dd1d8014f2b80c2e31eaf6b47c468ad902a1771eb835499c976670", "card_link": "https://wolnelektury.pl/api/", "card_title": "wolnelektury.pl", "card_theme": null, "card_type": "image", "card_description": "API serwisu WolneLektury.pl | WolneLektury.pl", "icon_img_src": "https://wolnelektury.pl/api/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-14T21:12:01.382793", "updated_at": "2023-07-07T20:32:19.258527"}, 
]
const profile = {
    xid: '5BD3_DF0A_BF2A',
    name_key: 'api_books',
    theme_color: '#d96160', 
    title: 'API: Books',
    img_src: 'https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books_23-2149334862.jpg?w=1060&t=st=1687440800~exp=1687441400~hmac=a95b9386a8e6d05d802631bf6afc525b4f36606c1d87b49d4f945184f750f71a',
    description: [
        "API for books",
    ],
}
const author = {
    key: 'api_store',
    name: 'All APIs',
    img_src: 'https://img.freepik.com/free-vector/application-programming-interface-concept-illustration_114360-9135.jpg?w=1060&t=st=1687440423~exp=1687441023~hmac=12830d60883b3aac174f89be583e4fec8d45286c3dfe6b3f6988f8288e0d6ce1',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
