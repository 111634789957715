import React from 'react';
import { withRouter} from 'react-router-dom';

import ContentLayout from 'component/layout/page/content';
// import LandingLayout from 'component/layout/page/landing';
// import About from         'component/content/about';


class Page extends React.Component {
  render (){
    return (
      <ContentLayout id="page">

        <div className="my-5">
          <SampleDash />
          <hr className="my-5" />
          <div className="row">
            <div className="col"><SampleURLCard /></div>
            <div className="col"><SampleURLCard /></div>
            <div className="col"><SampleURLCard /></div>
          </div>

          <hr className="" />
          <SampleOverlay />

        </div>
      </ContentLayout>
    )
  }
}

class SampleBadges extends React.Component {
  render(){
    return (
      <>
        <span className=" mr-2 badge badge-pill badge-primary">Primary</span>
        <span className=" mr-2 badge badge-pill badge-secondary">Secondary</span>
        <span className=" mr-2 badge badge-pill badge-success">Success</span>
        <span className=" mr-2 badge badge-pill badge-danger">Danger</span>
        <span className=" mr-2 badge badge-pill badge-warning">Warning</span>
        <span className=" mr-2 badge badge-pill badge-info">Info</span>
        <span className=" mr-2 badge badge-pill badge-light">Light</span>
        <span className=" mr-2 badge badge-pill badge-dark">Dark</span>
    </>
    )
  }
}

class SampleOverlay extends React.Component {
  render(){
    return(
      <div className="my-5">
        <h1>Image overlay card</h1>
        <div className="row ">
          
        <div className="col">
          <div className="card bg-dark text-white rounded">
            <img className="card-img" src="https://picsum.photos/seed/picsum/900/400" alt="Card " style={{height: '100%'}}/>
            <div className="card-img-overlay rounded" style={{background: 'rgba(0,0,0,0.4)'}}>
              <h2 className="card-title">Card title</h2>
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>

              <SampleBadges />

              <p className="card-text">Last updated 3 mins ago</p>
            </div>
          </div>
          </div>
          <div className="col">
          <div className="card bg-dark text-white rounded">
            <img className="card-img" src="https://picsum.photos/seed/picsum/900/400" alt="Card " style={{height: '100%'}}/>
            <div className="card-img-overlay rounded" style={{background: 'rgba(0,0,0,0.4)'}}>
              <h2 className="card-title">Card title</h2>
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              <p className="card-text">Last updated 3 mins ago</p>
            </div>
          </div>
          </div>
          <div className="col">
          <div className="card bg-dark text-white rounded">
            <img className="card-img" src="https://picsum.photos/seed/picsum/900/400" alt="Card " style={{height: '100%'}}/>
            <div className="card-img-overlay rounded" style={{background: 'rgba(0,0,0,0.4)'}}>
              <h2 className="card-title">Card title</h2>
              <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              <p className="card-text">Last updated 3 mins ago</p>
            </div>
          </div>
          </div>

        </div>
      </div>
    )
  }
}

class SampleDash extends React.Component {
  render(){
    return(
      <div className="grey-bg container-fluid">
  <section id="minimal-statistics">
    
    <div className="row my-5">
      <div className="col-12 mt-3 mb-1">
        <h4 className="text-uppercase">Minimal Statistics Cards</h4>
        <p>Statistics on minimal cards.</p>
      </div>
    </div>

    <div className="row my-5">
      <div className="col-xl-3 col-sm-6 col-12"> 
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="align-self-center">
                  <i className="fa fa-edit danger-text-1 text-size-10 float-left"></i>
                </div>
                <div className="media-body text-right">
                  <h3>278</h3>
                  <span>New Posts</span>
                </div>
              </div>
              <SampleBadges />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="align-self-center">
                  <i className="icon-speech warning text-size-10 float-left"></i>
                </div>
                <div className="media-body text-right">
                  <h3>156</h3>
                  <span>New Comments</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="align-self-center">
                  <i className="icon-graph success text-size-10 float-left"></i>
                </div>
                <div className="media-body text-right">
                  <h3>64.89 %</h3>
                  <span>Bounce Rate</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="align-self-center">
                  <i className="icon-pointer danger text-size-10 float-left"></i>
                </div>
                <div className="media-body text-right">
                  <h3>423</h3>
                  <span>Total Visits</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div className="row my-5">
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="info-text-1">278</h3>
                  <span>New Projects</span>
                </div>
                <div className="align-self-center">
                  <i className="fa fa-rocket info-text-1 text-size-10 float-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="success">156</h3>
                  <span>New Clients</span>
                </div>
                <div className="align-self-center">
                  <i className="fa fa-user success-text-1 text-size-10 float-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="warning">64.89 %</h3>
                  <span>Conversion Rate</span>
                </div>
                <div className="align-self-center">
                  <i className="icon-pie-chart warning text-size-10 float-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="primary">423</h3>
                  <span>Support Tickets</span>
                </div>
                <div className="align-self-center">
                  <i className="icon-support primary text-size-10 float-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div className="row my-5">
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="primary">278</h3>
                  <span>New Posts</span>
                </div>
                <div className="align-self-center">
                  <i className="fa fa-book-open primary-text-1 text-size-10 float-right"></i>
                </div>
              </div>
              <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                <div className="progress-bar bg-primary" role="progressbar" style={{width: "80%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="warning-text-1">156</h3>
                  <span>New Comments</span>
                </div>
                <div className="align-self-center">
                  <i className="fa fa-users warning-text-1 text-size-10 float-right"></i>
                </div>
              </div>
              <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                <div className="progress-bar bg-warning" role="progressbar" style={{width: "35%"}} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="success">64.89 %</h3>
                  <span>Bounce Rate</span>
                </div>
                <div className="align-self-center">
                  <i className="fa fa-cup success-text-1 text-size-10 float-right"></i>
                </div>
              </div>
              <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                <div className="progress-bar bg-success" role="progressbar" style={{width: "60%"}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 col-12">
        <div className="card rounded">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left">
                  <h3 className="danger">423</h3>
                  <span>Total Visits</span>
                </div>
                <div className="align-self-center">
                  <i className="fa fa-arrow-right danger-text-1 text-size-10 float-right"></i>
                </div>
              </div>
              <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                <div className="progress-bar bg-danger" role="progressbar" style={{width: "40%"}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section id="stats-subtitle">
  <div className="row my-5">
    <div className="col-12 mt-3 mb-1">
      <h4 className="text-uppercase">Statistics With Subtitle</h4>
      <p>Statistics on minimal cards with Title &amp; Sub Title.</p>
    </div>
  </div>

  <div className="row my-5">
    <div className="col-xl-6 col-md-12">
      <div className="card overflow-hidden">
        <div className="card-content">
          <div className="card-body cleartfix">
            <div className="media align-items-stretch">
              <div className="align-self-center">
                <i className="fa fa-user primary-text-1 text-size-14 mr-3"></i>
              </div>
              <div className="media-body">
                <h4>Total Posts</h4>
                <span>Monthly blog posts</span>
              </div>
              <div className="align-self-center">
                <h1>18,000</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-xl-6 col-md-12">
      <div className="card rounded">
        <div className="card-content">
          <div className="card-body cleartfix">
            <div className="media align-items-stretch">
              <div className="align-self-center">
              <i className="fa fa-user info-text-1 text-size-14 mr-3"></i>
              </div>
              <div className="media-body">
                <h4>Total Comments</h4>
                <span>Monthly blog comments</span>
              </div>
              <div className="align-self-center"> 
                <h1>84,695</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="row my-5">
    <div className="col-xl-6 col-md-12">
      <div className="card rounded">
        <div className="card-content">
          <div className="card-body cleartfix">
            <div className="media align-items-stretch">
              <div className="align-self-center">
                <h1 className="mr-2">$76,456.00</h1>
              </div>
              <div className="media-body">
                <h4>Total Sales</h4>
                <span>Monthly Sales Amount</span>
              </div>
              <div className="align-self-center">
              <i className="fa fa-user danger-text-1 text-size-14 mr-3"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-xl-6 col-md-12">
      <div className="card rounded">
        <div className="card-content">
          <div className="card-body cleartfix">
            <div className="media align-items-stretch">
              <div className="align-self-center">
                <h1 className="mr-2">$36,000.00</h1>
              </div>
              <div className="media-body">
                <h4>Total Cost</h4>
                <span>Monthly Cost</span>
              </div>
              <div className="align-self-center">
              <i className="fa fa-user success-text-1 text-size-14 mr-3"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
    )
  }
}

class SampleURLCard extends React.Component {
  render(){
    return(
    <div className="card rounded">
    <div className="card-body">
      <h4 className="card-title">Card title</h4>
      <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
      <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
      <a href="/" className="card-link">Card link</a>
      <a href="/" className="card-link">Another link</a>
      <div className="my-3">
      <SampleBadges />
      </div>
    </div>
    </div>
    )
  }
}

export default withRouter(Page);
