const cards = [
    {"card_xid": "c34d6b18a7610f34b138bcbeb672b3c927382525832793aada37d81056e167b4", "card_link": "https://www.youtube.com/watch?v=WO4tIrjBDkk", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Al Pacino's Inspirational Speech - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/WO4tIrjBDkk/hqdefault.jpg", "added_at": "2023-04-18T13:28:31.352703", "updated_at": "2023-07-07T20:33:26.019159"}, 
    {"card_xid": "5fdd94590b2523fcf18070d3ca088d5d50ea9f43c186e7c4c3916cfba7fd9f7e", "card_link": "https://www.youtube.com/watch?v=BOksW_NabEk", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "The Time You Have (In JellyBeans) - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/BOksW_NabEk/maxresdefault.jpg", "added_at": "2023-05-31T01:59:39.993406", "updated_at": "2023-07-07T20:33:26.123789"}, 
    {"card_xid": "9bc6ca6436abd9714636ed3eeb3f907c791cbdce91a330d4daf6bd07cb38c494", "card_link": "https://www.youtube.com/watch?v=qM-gZintWDc", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "[Great Movie Scenes] Good Will Hunting - Park Scene - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/qM-gZintWDc/hqdefault.jpg", "added_at": "2023-06-12T10:48:43.668050", "updated_at": "2023-07-07T20:33:26.542997"}, 
    {"card_xid": "ccead1526f14eba1fb2fc35615070d327b51898e5a3432179a7d498ec10f2d3e", "card_link": "https://www.youtube.com/watch?v=qX9FSZJu448", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Never, Ever Give Up. Arthur's Inspirational Transformation! - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/qX9FSZJu448/maxresdefault.jpg", "added_at": "2023-06-07T16:01:30.843642", "updated_at": "2023-07-07T20:33:26.767905"}, 
    {"card_xid": "85ad84f17498f94e7b606731d6ae9a0ec3e3de72418df0b5f01ac534ef9365be", "card_link": "https://www.youtube.com/watch?v=l-gQLqv9f4o", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "A Pep Talk from Kid President to You - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/l-gQLqv9f4o/maxresdefault.jpg", "added_at": "2023-05-03T13:38:43.451591", "updated_at": "2023-07-07T20:33:26.860022"}, 
    {"card_xid": "ca6628af879753ce79abb7aff1652bab723649841ed735f5f6f0946409b03294", "card_link": "https://www.youtube.com/watch?v=Wgi0t2ap-us", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "The Power of Words - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/Wgi0t2ap-us/hqdefault.jpg", "added_at": "2023-04-14T10:38:20.364322", "updated_at": "2023-07-07T20:33:26.965937"}, 
    {"card_xid": "23fcb1885181820692aeb849cdb5429a9dcdab1c0b038a560440e68b429d33fa", "card_link": "https://www.youtube.com/watch?v=w8fu-hq3S7A", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Dead Poet's Society Robin Williams Speech Seize The Day - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/w8fu-hq3S7A/hqdefault.jpg", "added_at": "2023-06-09T12:25:30.802828", "updated_at": "2023-07-07T20:33:27.244828"}, 
    {"card_xid": "4217d7af208b539a757c7d1f05f948c29ce87807aa08302834b6b706c743418f", "card_link": "https://www.youtube.com/watch?v=c0ZzN6hxdzo", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Sacrifice - Motivational Video - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/c0ZzN6hxdzo/maxresdefault.jpg", "added_at": "2023-05-05T01:02:05.444972", "updated_at": "2023-07-07T20:33:27.528925"}, 
    {"card_xid": "f20c0fdacff7ea8b7224622a7deb2b01fa066c4729258bb20335d0de088b645b", "card_link": "https://www.youtube.com/watch?v=_Z5OookwOoY", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Rocky Balboa's inspirational speech to his son. - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/_Z5OookwOoY/hqdefault.jpg", "added_at": "2023-05-21T02:26:28.775258", "updated_at": "2023-07-07T20:33:27.750767"}, 
    {"card_xid": "022e813c42949fb351933bba2f35b3a9e106ba63277896d6cab30a719dc49095", "card_link": "https://www.youtube.com/watch?v=Pgb0MsCwKyI", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Entrepreneurs can change the world - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/Pgb0MsCwKyI/hqdefault.jpg", "added_at": "2023-04-25T19:06:40.504932", "updated_at": "2023-07-07T20:33:28.044495"}, 
    {"card_xid": "c5398be4df8a52d744ff9480bd90442585409062ab5d3037b073d4e4cbfabf93", "card_link": "https://www.youtube.com/watch?v=0LfM9ZPGmVY", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "How Incredibly Successful People THINK - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/0LfM9ZPGmVY/maxresdefault.jpg", "added_at": "2023-05-18T23:19:57.415558", "updated_at": "2023-07-07T20:33:28.315698"}, 
    {"card_xid": "b3287e4fbacec2d81b82816d344bd80b92aad456382a713bdd1af212e4c1fab4", "card_link": "https://www.youtube.com/watch?v=rrkrvAUbU9Y", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "The puzzle of motivation | Dan Pink - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/rrkrvAUbU9Y/maxresdefault.jpg", "added_at": "2023-04-22T04:08:55.565068", "updated_at": "2023-07-07T20:33:28.408433"}, 
    {"card_xid": "73dd8ca4b0e84acf8d1753c8bcdcaf8783082b671878a415092af040e47c39de", "card_link": "https://www.youtube.com/watch?v=mgmVOuLgFB0", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Why Do We Fall - Motivational Video - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/mgmVOuLgFB0/maxresdefault.jpg", "added_at": "2023-04-29T08:15:59.106860", "updated_at": "2023-07-07T20:33:28.645908"}, 
    {"card_xid": "00ca479c1292916deae237c3fee4581aeb99fb7f872396756aecf9aa7b4b95a4", "card_link": "https://www.youtube.com/watch?v=HiyYEVcU1tI", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "The Greatness Within \u25ba Motivational Video ft Les Brown, Eric Thomas, Jim Carrey and Ashton Cutcher - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/HiyYEVcU1tI/maxresdefault.jpg", "added_at": "2023-04-22T15:55:33.093869", "updated_at": "2023-07-07T20:33:28.877660"}, 
    {"card_xid": "4023f3d22cbc8abe086e43a0697615d3d356294a4e83b3cdf0eff11bdff08c17", "card_link": "https://www.youtube.com/watch?v=GXy__kBVq1M", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "TEDxBloomington - Shawn Achor - \"The Happiness Advantage: Linking Positive Brains to Performance\" - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/GXy__kBVq1M/maxresdefault.jpg", "added_at": "2023-05-22T12:07:59.829263", "updated_at": "2023-07-07T20:33:29.027010"}, 
    {"card_xid": "d688b08694f9b214f63f8289b57fbfeed134a6ea1a785f663f2e7b959b2dc6dd", "card_link": "https://www.youtube.com/watch?v=RQRfnexHJmk", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "PROVE THEM WRONG -  A Motivational Video To Change Your Life - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/RQRfnexHJmk/maxresdefault.jpg", "added_at": "2023-06-13T13:15:10.120177", "updated_at": "2023-07-07T20:33:29.120749"}, 
    {"card_xid": "85ad84f17498f94e7b606731d6ae9a0ec3e3de72418df0b5f01ac534ef9365be", "card_link": "https://www.youtube.com/watch?v=l-gQLqv9f4o", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "A Pep Talk from Kid President to You - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/l-gQLqv9f4o/maxresdefault.jpg", "added_at": "2023-05-03T13:38:43.451591", "updated_at": "2023-07-07T20:33:29.408607"}, 
    {"card_xid": "91ab2842bc835c6b29556f1f4fea15cbeabe0e0ed1af9bb3601fa601b1cfa4ae", "card_link": "https://www.youtube.com/watch?v=lsSC2vx7zFQ", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "How Bad Do You Want It? (Success) HD - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/lsSC2vx7zFQ/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGF4gXiheMA8=&rs=AOn4CLASpGfLO_KUnGugGn5UYrw2yUDWtg", "added_at": "2023-06-18T23:47:04.548043", "updated_at": "2023-07-07T20:33:29.500394"}, 
    {"card_xid": "11184cc19dd6c48851a664adfe455d2a14c014e694e19bd541e7ae1604454aa1", "card_link": "https://www.youtube.com/watch?v=g-jwWYX7Jlo", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Dream - Motivational Video - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/g-jwWYX7Jlo/maxresdefault.jpg", "added_at": "2023-05-16T10:56:22.968689", "updated_at": "2023-07-07T20:33:29.597836"}, 
    {"card_xid": "a58d920bcfa49f0f1c58c2c486363cba411a1f2367b3c117b35cbb9a650b94fa", "card_link": "https://www.youtube.com/watch?v=WhbYBb0huMs", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "How To Stay Focused - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/WhbYBb0huMs/maxresdefault.jpg", "added_at": "2023-04-07T21:52:31.093865", "updated_at": "2023-07-07T20:33:29.868713"}, 
    {"card_xid": "49894a7e4c71a097af59ee4368aff891b5badf70b734b79ab2c43212c857b293", "card_link": "https://www.youtube.com/watch?v=u4ZoJKF_VuA", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Start with why -- how great leaders inspire action | Simon Sinek | TEDxPugetSound - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/u4ZoJKF_VuA/maxresdefault.jpg", "added_at": "2023-05-27T22:57:45.329278", "updated_at": "2023-07-07T20:33:29.963955"}, 
    {"card_xid": "4185127a0d8015f2dfc685310817d24f4cf9b9e2d171263f75812cb06bacb2e9", "card_link": "https://www.youtube.com/watch?v=AVq5lc6sVVo", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "IMPOSE YOUR WILL - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/AVq5lc6sVVo/hqdefault.jpg", "added_at": "2023-04-07T07:16:21.506550", "updated_at": "2023-07-07T20:33:30.053649"}, 
    {"card_xid": "69548c183d4330f87f27dd4fd1f4142421252c4a65e2d8354362305325cc2e79", "card_link": "https://www.youtube.com/watch?v=JV5qaN-x340", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Hall of Fame - Disney Princesses | Dream Big, Princess! - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/JV5qaN-x340/hqdefault.jpg", "added_at": "2023-06-06T20:20:52.894487", "updated_at": "2023-07-07T20:33:30.282990"}, 
    {"card_xid": "49f28611729171fa5ff4600edfb631b419e00335863334e303fb61b8159b4eae", "card_link": "https://www.youtube.com/watch?v=uaWA2GbcnJU", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Unsung Hero | \u0e44\u0e17\u0e22\u0e1b\u0e23\u0e30\u0e01\u0e31\u0e19\u0e0a\u0e35\u0e27\u0e34\u0e15 (TLI) - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/uaWA2GbcnJU/maxresdefault.jpg", "added_at": "2023-05-12T17:51:29.445187", "updated_at": "2023-07-07T20:33:30.527084"}, 
    {"card_xid": "00659117d1a6dd642ec7b8b833827f656097a6b71f85de77a928096570cfff8e", "card_link": "https://www.youtube.com/watch?v=nHUjjBdikgs", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "The Boy Who Learned to Fly RIO 2016 | Usain Bolt - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/nHUjjBdikgs/maxresdefault.jpg", "added_at": "2023-04-12T23:07:36.126141", "updated_at": "2023-07-07T20:33:30.820491"}, 
    {"card_xid": "aa94dc98f03008acedc7fc8eadc61fef59a1d62c3684416e37560f3ac9bb267f", "card_link": "https://www.youtube.com/watch?v=0ruHOaHrGnQ", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "P&G - Thank You Mama - Best Job 2012 HD 2M - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/0ruHOaHrGnQ/maxresdefault.jpg", "added_at": "2023-04-19T23:58:04.149650", "updated_at": "2023-07-07T20:33:31.047636"}, 
    {"card_xid": "6ad55dad9fd56a2dbc6984dd552acadd7e274fe53e2540c453cf135d48a9bcba", "card_link": "https://www.youtube.com/watch?v=2G88zqPxJ00", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Coco \u2013 Inside CHANEL - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/2G88zqPxJ00/maxresdefault.jpg", "added_at": "2023-04-12T10:03:57.833405", "updated_at": "2023-07-07T20:33:31.271794"}, 
    {"card_xid": "e31ea42571c1afc139a1e5bae1bfbc7caec25b0e071d4141498f32c88e00351c", "card_link": "https://www.youtube.com/watch?v=P-DbXbPz8RA", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Give Blood TV Advert - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/P-DbXbPz8RA/maxresdefault.jpg", "added_at": "2023-04-21T19:53:09.889855", "updated_at": "2023-07-07T20:33:31.398987"}, 
    {"card_xid": "85ad84f17498f94e7b606731d6ae9a0ec3e3de72418df0b5f01ac534ef9365be", "card_link": "https://www.youtube.com/watch?v=l-gQLqv9f4o", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "A Pep Talk from Kid President to You - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/l-gQLqv9f4o/maxresdefault.jpg", "added_at": "2023-05-03T13:38:43.451591", "updated_at": "2023-07-07T20:33:31.631676"}, 
    {"card_xid": "a35be56c0ba884da4d50faf45fa972c35c8a103f24b0f3a0425ec57b11968e40", "card_link": "https://www.youtube.com/watch?v=v8nyGzOLsdw", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "June: Life is Better When You Share the Ride - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/v8nyGzOLsdw/maxresdefault.jpg", "added_at": "2023-04-11T13:19:14.068934", "updated_at": "2023-07-07T20:33:31.722113"}, 
    {"card_xid": "00e614f3544da57959d4b187147359cffab37019722741fdbc0693412fa637d6", "card_link": "https://www.youtube.com/watch?v=7Sha5XB3NpI", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "WILL FINDS A WAY | Dwayne Johnson Under Armour Campaign - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/7Sha5XB3NpI/maxresdefault.jpg", "added_at": "2023-04-20T07:02:04.590125", "updated_at": "2023-07-07T20:33:31.937719"}, 
    {"card_xid": "033bbec6ccd28a0b44248df581097920f6312fe0ac326bfe2f1221b35638aaf5", "card_link": "https://player.vimeo.com/video/110940290?h=013afcdaf9&dnt=1&app_id=122963", "card_title": "player.vimeo.com", "card_theme": null, "card_type": "image", "card_description": "California Inspires Me: Thao Nguyen on Vimeo", "icon_img_src": "https://player.vimeo.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-10T22:19:12.194469", "updated_at": "2023-07-07T20:33:32.196790"}, 
    {"card_xid": "05286b702ff7a9e92f94489198afd965ca9652ceb8afc14d31991cba30663cc0", "card_link": "https://player.vimeo.com/video/157082327?h=2a47e83e39&dnt=1&app_id=122963", "card_title": "player.vimeo.com", "card_theme": null, "card_type": "image", "card_description": "Cadillac - Don't You Dare on Vimeo", "icon_img_src": "https://player.vimeo.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-15T06:45:32.846224", "updated_at": "2023-07-07T20:33:32.208685"}, 
    {"card_xid": "2eaf0d58edaa0a0b8b5d55050f9b62e3a38f42e55678c8017e7b4ffa5a7021e5", "card_link": "https://www.youtube.com/watch?v=2nK5CTo5tVg", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Always #LikeAGirl | Olympian Alex Morgan - Keep Playing #LikeAGirl - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/2nK5CTo5tVg/maxresdefault.jpg", "added_at": "2023-06-16T10:45:20.410657", "updated_at": "2023-07-07T20:33:32.215311"}, 
    {"card_xid": "27b5bf9ac1d4b6250a9359cbc360b2bcc2fba52d6ed303ee774b3ed844f08cd6", "card_link": "https://www.youtube.com/watch?v=WW2yKSt2C_A", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Nike / Dream Crazy (United States) - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/WW2yKSt2C_A/hqdefault.jpg", "added_at": "2023-06-14T19:23:28.710935", "updated_at": "2023-07-07T20:33:32.311027"}, 
]
const profile = {
    xid: 'E07F_1AA1_0A9D',
    name_key: 'motivation5min',
    theme_color: '#ff0000', 
    title: '5 Minute Motivations',
    img_src: 'https://img.icons8.com/?size=512&id=YJZ8teK9DFg7&format=png',
    description: [
        "Short inspirational videos with tremendous impact",
        "Use these videos in retreats, keynotes, trainings, and strategic planning sessions",
    ],
}
const author = {
    key: 'bb_ras',
    name: 'ByteBeacon - Ras',
    img_src: 'https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
