const cards = [
    {"card_xid": "ac8ca2211fdf257cc4a7b03b4812de6c17875678b7bd2564b53e4c87e27f9a69", "card_link": "https://www.nytimes.com/games/wordle/index.html", "card_title": "www.nytimes.com", "card_theme": null, "card_type": "image", "card_description": "Wordle - The New York Times", "icon_img_src": "https://www.nytimes.com/games-assets/v2/assets/wordle/wordle_logo_192x192.png", "link_img_src": "https://www.nytimes.com/games-assets/v2/assets/wordle/wordle_og_1200x630.png", "added_at": "2023-04-12T01:09:41.067378", "updated_at": "2023-07-07T20:33:16.538233"}, 
    {"card_xid": "7433bfc332f7ef627bc6506e0a8151642de912a20d51d6c11478ec9e44e1a9f9", "card_link": "https://www.spotify.com/heardle/?", "card_title": "www.spotify.com", "card_theme": null, "card_type": "image", "card_description": "Page not found - Spotify", "icon_img_src": "https://www.spotify.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-11T16:43:40.444796", "updated_at": "2023-07-07T20:33:16.541853"}, 
    {"card_xid": "05d708e59b4f19c22944b31668b66db3de2fc2b27d13d430c4a7492665cd6f18", "card_link": "https://www.geoguessr.com/", "card_title": "www.geoguessr.com", "card_theme": null, "card_type": "image", "card_description": "GeoGuessr - Let's explore the world!", "icon_img_src": "https://www.geoguessr.com/_next/static/images/geoguessr-57-e3554d29cf9a410853ee28063965a2ba.png", "link_img_src": "https://www.geoguessr.com/_next/static/images/default-231f6f91e41050bfcac84aa3009be3fe.png", "added_at": "2023-06-10T10:23:21.380870", "updated_at": "2023-07-07T20:33:16.565194"}, 
    {"card_xid": "7a1be270a00ac3edd392fdccbabad4b2e8902f5546065c3e885c336d3d4d27ad", "card_link": "https://knowyourmeme.com/memes/memes", "card_title": "knowyourmeme.com", "card_theme": null, "card_type": "image", "card_description": "Memes | Know Your Meme", "icon_img_src": "https://a.kym-cdn.com/assets/favicon/apple-touch-icon-0d864d6f2001bee0c4d81b753bf3a85f.png", "link_img_src": "https://i.kym-cdn.com/entries/icons/original/000/002/058/meme.jpg", "added_at": "2023-05-23T06:56:58.970436", "updated_at": "2023-07-07T20:33:16.585896"}, 
    {"card_xid": "e1fc0f6e57daae9e228ee70e09a0107f3d1b45adea910e152305d95f84a09ce2", "card_link": "https://theoatmeal.com/", "card_title": "theoatmeal.com", "card_theme": null, "card_type": "image", "card_description": "The Oatmeal - Comics by Matthew Inman ", "icon_img_src": "https://s3.amazonaws.com/theoatmeal-img/favicons/apple-icon-57x57.png", "link_img_src": null, "added_at": "2023-04-14T02:25:27.814252", "updated_at": "2023-07-07T20:33:16.666213"}, 
    {"card_xid": "9cc4431ff808b365553e494267d27a13acfb59d5dc487593462312e29c0b3343", "card_link": "https://www.supercook.com/#/desktop", "card_title": "www.supercook.com", "card_theme": null, "card_type": "image", "card_description": "SuperCook - Zero Waste Recipe Generator", "icon_img_src": "https://www.supercook.com/favicon.ico", "link_img_src": "https://www.supercook.com/images/002.3cc1abc7.png", "added_at": "2023-06-16T00:44:13.439337", "updated_at": "2023-07-07T20:33:16.675744"}, 
    {"card_xid": "1a48921051a15f2fd96ae75479b42bdcb8d8e94530b74a5e2d61864f96ddec72", "card_link": "https://www.ocearch.org/tracker/", "card_title": "www.ocearch.org", "card_theme": null, "card_type": "image", "card_description": "OCEARCH Shark Tracker", "icon_img_src": "https://www.ocearch.org/tracker/assets/favicon/apple-touch-icon.png", "link_img_src": "https://www.ocearch.org/tracker/assets/images/og/ocearch_shark2.jpg", "added_at": "2023-05-27T04:14:07.622747", "updated_at": "2023-07-07T20:33:16.689699"}, 
    {"card_xid": "69c6ce326edd6ddff3137283d6e067ce08c856548fee45e00bb6a122402ae9d9", "card_link": "https://archive.org/details/msdos_Oregon_Trail_The_1990", "card_title": "archive.org", "card_theme": null, "card_type": "image", "card_description": "Oregon Trail, The : MECC : Free Borrow & Streaming : Internet Archive", "icon_img_src": "https://archive.org/favicon.ico", "link_img_src": "https://archive.org/services/img/msdos_Oregon_Trail_The_1990", "added_at": "2023-06-01T09:46:02.088468", "updated_at": "2023-07-07T20:33:16.745642"}, 
    {"card_xid": "d4f905e687a21f965b6a7ce87c5aeef6dbf33fe0f63c0f0bb17998488e29828a", "card_link": "https://giphy.com/", "card_title": "giphy.com", "card_theme": null, "card_type": "image", "card_description": "GIPHY - Be Animated", "icon_img_src": "https://giphy.com/static/img/icons/apple-touch-icon-167px.png", "link_img_src": "https://giphy.com/static/img/giphy-be-animated-logo.gif", "added_at": "2023-04-12T15:35:37.661528", "updated_at": "2023-07-07T20:33:17.355342"}, 
    {"card_xid": "802bdd65302972489ff289258a7a9b6239ed8fec21da027420bb34ce9f8418be", "card_link": "https://archive.org/web/", "card_title": "archive.org", "card_theme": null, "card_type": "image", "card_description": "Internet Archive: Wayback Machine", "icon_img_src": "https://archive.org/favicon.ico", "link_img_src": null, "added_at": "2023-06-13T13:59:56.113039", "updated_at": "2023-07-07T20:33:17.403203"}, 
    {"card_xid": "6cf49d12d57510da0812626d4b41cb41709974ab7aeb81629317564bfbbc45df", "card_link": "https://www.apartmenttherapy.com/", "card_title": "www.apartmenttherapy.com", "card_theme": null, "card_type": "image", "card_description": "Apartment Therapy | Saving the world, one room at a time", "icon_img_src": "https://www.apartmenttherapy.com/static/icons/apple-touch-icon.png", "link_img_src": "https://cdn.apartmenttherapy.info/image/fetch/f_auto,q_auto:eco,c_fill,g_auto,w_1500,ar_3:2/https%3A%2F%2Fs3.amazonaws.com%2Fpixtruder%2Foriginal_images%2Fb27c3c4c25f0038644ed1615071b00d52bf2025f", "added_at": "2023-05-20T03:00:08.761967", "updated_at": "2023-07-07T20:33:17.457294"}, 
    {"card_xid": "bc703e474c86c6f71f43e414699d49ab8d5e64909688740aaff5b3cc69341ce8", "card_link": "https://imgur.com/", "card_title": "imgur.com", "card_theme": null, "card_type": "image", "card_description": "Imgur: The magic of the Internet", "icon_img_src": "https://s.imgur.com/images/favicon-16x16.png", "link_img_src": "https://s.imgur.com/images/logo-1200-630.jpg?2", "added_at": "2023-04-17T06:08:40.939352", "updated_at": "2023-07-07T20:33:17.554612"}, 
    {"card_xid": "f55cf8710115adfabe7219393f58070fb2c4f267d9540eadea1595665e1d0d64", "card_link": "https://codepen.io/akm2/full/AGgarW", "card_title": "codepen.io", "card_theme": null, "card_type": "image", "card_description": "Gravity Points", "icon_img_src": "https://cpwebassets.codepen.io/assets/favicon/apple-touch-icon-5ae1a0698dcc2402e9712f7d01ed509a57814f994c660df9f7a952f3060705ee.png", "link_img_src": "https://shots.codepen.io/username/pen/AGgarW-800.jpg?version=1496627466", "added_at": "2023-04-12T17:15:49.373741", "updated_at": "2023-07-07T20:33:17.561186"}, 
    {"card_xid": "e7b9b6be6451b802d9cf0667c88d544be0f40d9bad25d1e9d704c4e515bcacab", "card_link": "https://www.wizardingworld.com/", "card_title": "www.wizardingworld.com", "card_theme": null, "card_type": "image", "card_description": "Wizarding World: The Official Home of Harry Potter", "icon_img_src": "https://www.wizardingworld.com/favicon.ico", "link_img_src": "", "added_at": "2023-05-03T00:48:12.795008", "updated_at": "2023-07-07T20:33:17.566869"}, 
    {"card_xid": "87105ad6c9ca687915ca3d737a794f7080415f1e377453c82097b6ff0b0271f4", "card_link": "https://the-toast.net/", "card_title": "the-toast.net", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://thetoast.wpenginepowered.com/wp-content/themes/toaster/ico/apple-touch-icon-144x144.png", "link_img_src": null, "added_at": "2023-06-16T01:01:54.917920", "updated_at": "2023-07-07T20:33:17.614549"}, 
    {"card_xid": "f61493befa6b8f6c4eec863faf5ea94de0ad8bf09313c18b0a8d579d6baf0011", "card_link": "https://www.theonion.com/", "card_title": "www.theonion.com", "card_theme": null, "card_type": "image", "card_description": "The Onion | America's Finest News Source.", "icon_img_src": "https://i.kinja-img.com/gawker-media/image/upload/c_fill,f_auto,fl_progressive,g_center,h_200,q_80,w_200/eti2h1r4wg0bqxctxenl.png", "link_img_src": "https://i.kinja-img.com/gawker-media/image/upload/c_fill,f_auto,fl_progressive,g_center,h_200,q_80,w_200/eti2h1r4wg0bqxctxenl.png", "added_at": "2023-06-16T18:00:12.060246", "updated_at": "2023-07-07T20:33:17.651922"}, 
    {"card_xid": "d76263709abaffff64ea5c57798cfbc16394f484ad6c0393549b0481f2af807c", "card_link": "https://www.cracked.com/", "card_title": "www.cracked.com", "card_theme": null, "card_type": "image", "card_description": "Cracked.com - America's Only Humor Site | Cracked.com", "icon_img_src": "https://ui-seo.crackedcdn.com/images/favicon/apple-touch-icon.png?v=b7029bae79fc422aacbd2d26398f1d2c", "link_img_src": null, "added_at": "2023-05-16T00:53:01.291576", "updated_at": "2023-07-07T20:33:17.719625"}, 
    {"card_xid": "79ab8340254b4db008f876dd4b756ac7646d24c718ee679ad5aad0ba71f132dd", "card_link": "https://www.mentalfloss.com/", "card_title": "www.mentalfloss.com", "card_theme": null, "card_type": "image", "card_description": "Mental Floss", "icon_img_src": "https://images2.minutemediacdn.com/image/upload/c_fill,w_1440,ar_1:1,f_auto,q_auto,g_auto/frontier/favicons/MFFavicon_216ef394-60ef-4fac-9984-97a8819af760_400x.png", "link_img_src": "https://images2.minutemediacdn.com/image/upload/c_fill,w_1440,ar_16:9,f_auto,q_auto,g_auto/shape/cover/sport/mf_logo_tag-bdd0919ede2935a33065bc4f61df2502.png", "added_at": "2023-05-13T04:20:24.225766", "updated_at": "2023-07-07T20:33:17.780020"}, 
    {"card_xid": "4b12dcd79f15c3a92d65f98b4fca2eae386f0a1a381020401fd7cc481aa462b1", "card_link": "https://www.howstuffworks.com/", "card_title": "www.howstuffworks.com", "card_theme": null, "card_type": "image", "card_description": "HowStuffWorks - Learn How Everything Works!", "icon_img_src": "https://cdn.hswstatic.com/en-us/hsw/img/icons/apple-touch.png", "link_img_src": "https://cdn.hswstatic.com/gif/hsw-logo-120x90.jpg", "added_at": "2023-06-14T12:02:23.117004", "updated_at": "2023-07-07T20:33:17.825793"}, 
    {"card_xid": "7afce7cd6d5ea3483e4d72246644ac34d80311ff9c88661275460daa5ebc5c71", "card_link": "https://lifehacker.com/", "card_title": "lifehacker.com", "card_theme": null, "card_type": "image", "card_description": "Lifehacker | Do everything better", "icon_img_src": "https://i.kinja-img.com/gawker-media/image/upload/c_fill,f_auto,fl_progressive,g_center,h_200,q_80,w_200/u0939doeuioaqhspkjyc.png", "link_img_src": "https://i.kinja-img.com/gawker-media/image/upload/c_fill,f_auto,fl_progressive,g_center,h_200,q_80,w_200/u0939doeuioaqhspkjyc.png", "added_at": "2023-04-24T10:59:20.767321", "updated_at": "2023-07-07T20:33:17.886507"}, 
    {"card_xid": "9efdc7a55e190253c791fa1589bb35d748bc295da7224fa919b26dba23abb72c", "card_link": "https://mix.com/", "card_title": "mix.com", "card_theme": null, "card_type": "image", "card_description": "Mix \u2014 Expand your mind", "icon_img_src": "https://mix.com/favicon.ico", "link_img_src": "https://assets.mix.com/assets/img/mix-dot-com-cad689f34536e269215f9e95e3973593.jpg", "added_at": "2023-05-17T04:43:42.346260", "updated_at": "2023-07-07T20:33:17.955515"}, 
    {"card_xid": "37891912e86462b53b70d8fa95f519525ade00fa4c5070a8f143adede64fbfdd", "card_link": "https://www.space.com/", "card_title": "www.space.com", "card_theme": null, "card_type": "image", "card_description": "Space.com: NASA, Space Exploration and Astronomy News", "icon_img_src": "https://vanilla.futurecdn.net/space/710494/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-24T19:15:36.604673", "updated_at": "2023-07-07T20:33:17.963169"}, 
    {"card_xid": "d800ba000408e29d748bc7a9701ef5b64c851dd0f7214a81dc87eac29a6beee7", "card_link": "https://www.zillow.com/", "card_title": "www.zillow.com", "card_theme": null, "card_type": "image", "card_description": "Zillow: Real Estate, Apartments, Mortgages & Home Values", "icon_img_src": "https://www.zillow.com/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-21T00:47:46.441972", "updated_at": "2023-07-07T20:33:18.032700"}, 
    {"card_xid": "9a9d50ec8631eafde241ce5b6991ebb9ba74c8543397179e1b589e700bedefd4", "card_link": "https://www.wikipedia.org/", "card_title": "www.wikipedia.org", "card_theme": null, "card_type": "image", "card_description": "Wikipedia", "icon_img_src": "https://www.wikipedia.org/static/apple-touch/wikipedia.png", "link_img_src": null, "added_at": "2023-04-19T06:31:22.140039", "updated_at": "2023-07-07T20:33:18.092042"}, 
    {"card_xid": "38a78dc120ccacb86c910706204e40482ed1a05fa11646bbb1e0644e9ff7616a", "card_link": "https://www.boredapi.com/", "card_title": "www.boredapi.com", "card_theme": null, "card_type": "image", "card_description": "Bored API", "icon_img_src": "https://www.boredapi.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-10T16:45:03.626964", "updated_at": "2023-07-07T20:33:18.140753"}, 
]
const profile = {
    xid: '19E0_B375_5E24',
    name_key: 'cure_boredom',
    theme_color: '#d2d274', 
    title: 'Cure Your Boredom',
    img_src: 'https://img.freepik.com/free-vector/stress-concept-illustration_114360-2031.jpg?w=1060&t=st=1687167904~exp=1687168504~hmac=c822008b2612e384142c753274774945bf94d40bcf8c89e4f4811cb4c5c7ec2a',
    description: [
        "Next time you find yourself getting bored, don’t worry",
        "This list has you covered",
    ],
}
const author = {
    key: 'johnni_coutinho',
    name: 'Johnni Coutinho',
    img_src: 'https://img.freepik.com/premium-photo/cute-baby-cat_155807-11607.jpg?w=1060',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
