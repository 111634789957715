const cards = [
    {"card_xid": "ba19f945cb135c4771939f091a56d74e1787387ccd88ebcce712bfd32e3b0dd3", "card_link": "https://www.youtube.com/watch?v=0SARbwvhupQ", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Google I/O 2009 - The Myth of the Genius Programmer - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/0SARbwvhupQ/hqdefault.jpg", "added_at": "2023-04-13T02:16:00.494901", "updated_at": "2023-07-07T20:36:07.639814"}, 
    {"card_xid": "18b5113818ac24db4c2d90a2ce32caadd337a522da36580b1698ef6b4e995e37", "card_link": "https://www.youtube.com/watch?v=T0qAjgQFR4c", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": null, "added_at": "2023-05-17T08:28:47.094402", "updated_at": "2023-07-07T20:36:07.925829"}, 
    {"card_xid": "0b0b3d9fcd2677b12ce8e505c84b10a4f18a6d355ae8042ff051181c1606fd21", "card_link": "https://www.youtube.com/watch?v=g3dkRsTqdDA", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": null, "added_at": "2023-05-18T03:12:29.716504", "updated_at": "2023-07-07T20:36:08.042552"}, 
    {"card_xid": "c08dbe7713f688d3b82a66a8314d66f04676354a50ba875ce2246e95e4ad1cd6", "card_link": "https://www.youtube.com/watch?v=neI_Pj558CY", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": " - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": null, "added_at": "2023-06-15T11:22:14.531175", "updated_at": "2023-07-07T20:36:08.287020"}, 
    {"card_xid": "5b4543f578fb9fdd97e9c00b85adb3ac85af607a7bc455e2b5f59176692409b6", "card_link": "https://www.youtube.com/watch?v=w1-bDwNtG-I", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "DjangoCon EU 2013: Zed Shaw - The Imaginative Programmer - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/w1-bDwNtG-I/maxresdefault.jpg", "added_at": "2023-04-06T22:42:20.794284", "updated_at": "2023-07-07T20:36:08.501247"}, 
    {"card_xid": "4e30c855b627d83d33429b7c2f2f6f93c255a2df504a33140e69c5322d2cbc1c", "card_link": "https://www.youtube.com/watch?v=hN63FOa_Gp4", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Impossible Programs \u2022 Tom Stuart \u2022 GOTO 2015 - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/hN63FOa_Gp4/maxresdefault.jpg", "added_at": "2023-05-12T10:02:41.009569", "updated_at": "2023-07-07T20:36:08.765717"}, 
    {"card_xid": "dcdb60835ae94f112571fdded55d7c955e983f4ad8d73822fbb905569bc1d07d", "card_link": "https://www.youtube.com/watch?v=9LfmrkyP81M", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "RailsConf 2014 - Keynote: Writing Software by David Heinemeier Hansson - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/9LfmrkyP81M/maxresdefault.jpg", "added_at": "2023-04-10T13:46:14.665551", "updated_at": "2023-07-07T20:36:09.027210"}, 
    {"card_xid": "f90e4f2b83ee56e9d59a9c3b9d5668c678145894b541b5196ebfc9e9af2aa9a8", "card_link": "https://www.youtube.com/watch?v=sLAvSgcrgZM", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "LoneStarRuby 2015 - Code Club by Saron Yitbarek - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/sLAvSgcrgZM/maxresdefault.jpg", "added_at": "2023-05-14T15:18:03.639165", "updated_at": "2023-07-07T20:36:09.129006"}, 
    {"card_xid": "5f0f602fe9ef617e6cabe8f5baf7b564d0c3475533793b63ae7bc0261a91ae5b", "card_link": "https://www.youtube.com/watch?v=yCBUsd52a3s", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "LoneStarRuby 2015 - My Dog Taught Me to Code by Dave Thomas - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/yCBUsd52a3s/maxresdefault.jpg", "added_at": "2023-04-13T11:54:06.669836", "updated_at": "2023-07-07T20:36:09.341893"}, 
    {"card_xid": "1f78f512bc57e6d9eb758c42765988842670610b50a72fd6ef235861b57e7d18", "card_link": "https://www.youtube.com/watch?v=cidchWg74Y4", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Simplicity Ain't Easy - Stuart Halloway - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/cidchWg74Y4/hqdefault.jpg", "added_at": "2023-05-17T22:13:58.722598", "updated_at": "2023-07-07T20:36:09.611568"}, 
]
const profile = {
    xid: '3D59_4C6F_4A25',
    name_key: 'programming_motivations',
    theme_color: '#FE36C2', 
    title: 'Programming Motivations',
    img_src: 'https://img.freepik.com/free-vector/hacker-operating-laptop-cartoon-icon-illustration-technology-icon-concept-isolated-flat-cartoon-style_138676-2387.jpg?w=1060&t=st=1686898170~exp=1686898770~hmac=c5188fb627ffd1d6224f9b6ccd0be607cc93ae3999035d8711415d7b7217b5aa',
    description: [
        "Getting started with software engineering",
    ],
}
const author = {
    key: 'bb_ras',
    name: 'ByteBeacon - Ras',
    img_src: 'https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
