import React from "react";

import Slide from 'react-reveal/Slide';


export function SectionTitle(props){
  return (
    <Slide bottom>
      <h1 className="text-center py-3">
        <span className={""}>{props.first}</span> 
        <span className={""}>{" " + props.second}</span>
      </h1>
    </Slide>
  )
}


export function ParaTitle(props){
  var bgclass = (props.background)? props.background: 'secondary';
  bgclass = "text-" + bgclass;
  
  const firstbg = (props.flip)? "text-black-2": bgclass;
  const secondbg = (props.flip)? bgclass: "text-black-3";

  return (
    <Slide bottom>
      <h4 className="text-center uppercase text-spacing-2 m-0">
        <span className={firstbg}>{props.first}</span>
        <span className={secondbg}>{props.second}</span>
      </h4>
    </Slide>
  )
}


export function Title (props){
  const type = props.type;
  const underline = props.underline || false;
  let className = props.className;
  const is_mobile = window.innerWidth < 1200;

  if (type === 'page'){
    className = className + (" text-wide-3 ") + ((is_mobile)? " text-size-11 ": " text-size-15 ");
  } else if (type === 'section'){
    className = className + ("  text-wide-1") + ((is_mobile)? " text-size-9 ": " text-size-11 ");
  } else if (type === 'strong'){
    className = className + (" text-wide-1") + ((is_mobile)? " text-size-6 ": " text-size-8 ");
  } else {
    return <p>{props.children}</p>
  }

  return (
    <React.Fragment>
      <h6 className={className}>{props.children}</h6>
      {(underline)? (<hr className="" />): ('')}
    </React.Fragment>

  )
}
