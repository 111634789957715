const cards = [
    {"card_xid": "49ae513f357379c94003db1116cc28a39bdd7901ed861bd3ae83b02546f215b6", "card_link": "https://www.youtube.com/watch?v=xyR4G2XgcHU", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Felipe Ribeiro - JavaScript @Spotify - BrazilJS 2015 - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/xyR4G2XgcHU/maxresdefault.jpg", "added_at": "2023-04-26T22:02:04.505553", "updated_at": "2023-07-07T20:36:09.701550"}, 
    {"card_xid": "85b26e802f232f5e7d4aaf9beea5049696309de7c0729e9c4459cb8e6f510011", "card_link": "https://www.youtube.com/watch?v=2yko4TbC8cI", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Martin Fowler  \u2013  Microservices - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/2yko4TbC8cI/hqdefault.jpg", "added_at": "2023-05-11T04:31:02.533689", "updated_at": "2023-07-07T20:36:09.796856"}, 
    {"card_xid": "2bad6f2d07f61bc83484fe138c253c6fa71d841fa148de515f89b2a87c21b54f", "card_link": "https://www.youtube.com/watch?v=r2bIhTO5FcM", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Cria\u00e7\u00e3o de APIs lightweight com harvester.js ~ Diogo Lucas - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/r2bIhTO5FcM/maxresdefault.jpg", "added_at": "2023-05-08T08:00:04.907156", "updated_at": "2023-07-07T20:36:09.897574"}, 
    {"card_xid": "d33835fc5b38fc598273e384d3f2d2ba6487cbdaee78aa2ec25a420e9591e587", "card_link": "https://www.youtube.com/watch?v=asLUTiJJqdE", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Robert C  Martin(Uncle Bob) -Clean Architecture and Design-2012 COHAA The Path to Agility Conference - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/asLUTiJJqdE/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGFIgTyhlMA8=&rs=AOn4CLAz--COJyMUzvI-uFrRPhzzd5hhRw", "added_at": "2023-06-08T06:14:52.496530", "updated_at": "2023-07-07T20:36:09.990546"}, 
    {"card_xid": "c9111a3f184c475ce8be09ea130646877dd8a64fb66e05cc70e3531f9fd75aef", "card_link": "https://www.youtube.com/watch?v=cN_DpYBzKso", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": null, "added_at": "2023-04-14T02:58:34.109811", "updated_at": "2023-07-07T20:36:10.250808"}, 
    {"card_xid": "58e1bb901a67fe5ce156a5bb706c8aa419f3ff59bcb398ba9f0c0a7e31ca62a7", "card_link": "https://www.youtube.com/watch?v=I56HzTKvZKc", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Microservices Anti-Patterns - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/I56HzTKvZKc/maxresdefault.jpg", "added_at": "2023-05-20T11:59:04.991164", "updated_at": "2023-07-07T20:36:10.350669"}, 
    {"card_xid": "7304b0e717a2587789ca9bc38806d33ddc6e7d6c2c40325e1ba185f4903310a9", "card_link": "https://www.youtube.com/watch?v=UH7wkvcf0ys", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Facebook and memcached - Tech Talk - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/UH7wkvcf0ys/hqdefault.jpg", "added_at": "2023-05-15T06:02:15.698433", "updated_at": "2023-07-07T20:36:10.439350"}, 
    {"card_xid": "bc6b3ec1ebf796f5a5ce8644d3a1c246e099f2f1c9627fa59accf1a2358144a1", "card_link": "https://www.youtube.com/watch?v=z8LU0Cj6BOU", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "LISA '10 - Operations at Twitter: Scaling Beyond 100 Million Users - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/z8LU0Cj6BOU/hqdefault.jpg", "added_at": "2023-06-02T15:30:21.578187", "updated_at": "2023-07-07T20:36:10.662687"}, 
    {"card_xid": "6d8d111daecfb98256c9be42db7229a6f0bbe2926e39186e0d71b53734f9338a", "card_link": "https://www.youtube.com/watch?v=-yxQIRl6Qic", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/-yxQIRl6Qic/maxresdefault.jpg", "added_at": "2023-05-27T05:13:46.626525", "updated_at": "2023-07-07T20:36:10.761811"}, 
    {"card_xid": "fb3f0a043b351bafac1c2250ae6d57d093f5c73c6a4fc178c9ca2b59ba69811e", "card_link": "https://www.youtube.com/watch?v=nNfaxNdyCgI", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "Say Hello to Offline First \u2022 Ola Gasidlo \u2022 GOTO 2015 - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/nNfaxNdyCgI/maxresdefault.jpg", "added_at": "2023-05-27T04:37:25.629215", "updated_at": "2023-07-07T20:36:10.881783"}, 
    {"card_xid": "0c81c7dcdf0aca0ebf6e1e86a69f7888db3a286cac8210150a31230383e57630", "card_link": "https://www.youtube.com/watch?v=VkTCL6Nqm6Y", "card_title": "www.youtube.com", "card_theme": null, "card_type": "image", "card_description": "OSCON 2014: How Instagram.com Works; Pete Hunt - YouTube", "icon_img_src": "https://www.youtube.com/s/desktop/0e9d1cf9/img/favicon_144x144.png", "link_img_src": "https://i.ytimg.com/vi/VkTCL6Nqm6Y/maxresdefault.jpg", "added_at": "2023-04-08T04:48:29.861402", "updated_at": "2023-07-07T20:36:11.176306"}, 
]
const profile = {
    xid: 'B24C_8BBB_A403',
    name_key: 'service_architecture',
    theme_color: '#A29234', 
    title: 'Service Architecture',
    img_src: 'https://img.freepik.com/free-vector/desktop-smartphone-app-development_23-2148683810.jpg?w=1060&t=st=1686898138~exp=1686898738~hmac=dfbe66b49abee36c3b3dd1eaf200d8d131152064ae793299d43b17bd1d896be2',
    description: [
        "Become a better web software engineer",
    ],
}
const author = {
    key: 'bb_ras',
    name: 'ByteBeacon - Ras',
    img_src: 'https://pbs.twimg.com/profile_images/1599995297178214406/dMsW-HWY_400x400.jpg',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
