const cards = [
    {"card_xid": "99ce400c6a21535219413111220acbc8f540e7e17daa125c5d06bdd5f5751d32", "card_link": "https://api.chucknorris.io", "card_title": "api.chucknorris.io", "card_theme": null, "card_type": "image", "card_description": "Chuck Norris Jokes Api", "icon_img_src": "https://api.chucknorris.io/img/favicon.ico", "link_img_src": null, "added_at": "2023-04-27T11:11:17.472977", "updated_at": "2023-07-07T20:32:19.276524"}, 
    {"card_xid": "5424d9d215276ece204f344836fc859a2c87d8ae839e65a43a3963bbb11741ee", "card_link": "https://github.com/sameerkumar18/corporate-bs-generator-api", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - sameerkumar18/corporate-bs-generator-api: Corporate Bullshit(BuzzWord) Generator API", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/2911af8fae3fe4bd61275b6b28491498b69d64475f1b9b58bcfc6a4a183e7b95/sameerkumar18/corporate-bs-generator-api", "added_at": "2023-06-14T06:13:51.685690", "updated_at": "2023-07-07T20:32:19.285175"}, 
    {"card_xid": "fe167eb43d3d92069554001ff4fc75b950537415972cfe8f6fb48a2a80d03e0e", "card_link": "https://excuser.herokuapp.com/", "card_title": "excuser.herokuapp.com", "card_theme": null, "card_type": "image", "card_description": "Heroku | Application Error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-12T03:01:11.244918", "updated_at": "2023-07-07T20:32:19.397771"}, 
    {"card_xid": "d32349fcbf7cf905d16be1f58038db221381c5e88058621599e8b8c097224fb2", "card_link": "https://api.aakhilv.me", "card_title": "api.aakhilv.me", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-12T09:25:27.038373", "updated_at": "2023-07-07T20:32:19.400197"}, 
    {"card_xid": "482068ee8df06381c66de960503c096e12a91acef31ed0aa1fcc76ff1493b096", "card_link": "https://imgflip.com/api", "card_title": "imgflip.com", "card_theme": null, "card_type": "image", "card_description": "Meme Generator API - Imgflip", "icon_img_src": "https://imgflip.com/apple-touch-icon.png?c", "link_img_src": null, "added_at": "2023-05-29T05:27:14.749187", "updated_at": "2023-07-07T20:32:19.402497"}, 
    {"card_xid": "d63b1a9fe124aa75172c66b7977da80d787911bc081273e1bd6aa7af846c92cc", "card_link": "https://mememaker.github.io/API/", "card_title": "mememaker.github.io", "card_theme": null, "card_type": "image", "card_description": "Meme Maker API", "icon_img_src": "https://mememaker.github.io/API/static/icons/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-11T00:20:24.392935", "updated_at": "2023-07-07T20:32:19.424000"}, 
    {"card_xid": "67d970ec18d59b37905016a2956f7afb3a0ce65225ae6c4bbbf815ab870d66eb", "card_link": "https://github.com/theIYD/NaMoMemes", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - theIYD/NaMoMemes: Is a description required ? Hail NaMo !", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/bd6b3840417022c6289e5283c46fa30748b0db2493305dea2e67915cad5c095e/theIYD/NaMoMemes", "added_at": "2023-05-31T06:02:40.563188", "updated_at": "2023-07-07T20:32:19.439861"}, 
    {"card_xid": "5994b7c3113bdd4440c0c7419661fdc302e419d60b866267833555f3a3a1227d", "card_link": "https://uselessfacts.jsph.pl/", "card_title": "uselessfacts.jsph.pl", "card_theme": null, "card_type": "image", "card_description": "Random Useless Facts", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-15T00:02:43.202411", "updated_at": "2023-07-07T20:32:19.522599"}, 
    {"card_xid": "030f89d1ebb374a5ba796dd7b612e72734f299f640d7557c7426bcedd57a7331", "card_link": "https://techy-api.vercel.app/", "card_title": "techy-api.vercel.app", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-11T06:50:33.476615", "updated_at": "2023-07-07T20:32:19.526877"}, 
    {"card_xid": "0d2c174972c036c67b97bac4fff059bf8a4c694e5f7d791b7f4a038a67a6a125", "card_link": "https://github.com/beanboi7/yomomma-apiv2", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - beanboi7/yomomma-apiv2: REST-API using FastAPI for Python that gives a 'yo momma' joke", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/bc2d179181ee553a770a0835163fbd1b7381d4c9a1b942bac080c3b72d22c486/beanboi7/yomomma-apiv2", "added_at": "2023-06-01T20:26:54.356930", "updated_at": "2023-07-07T20:32:19.529661"}, 
]
const profile = {
    xid: '59D8_5F55_E010',
    name_key: 'api_random',
    theme_color: '#896160', 
    title: 'API: Entertaintment',
    img_src: 'https://img.freepik.com/free-photo/colored-squares-falling_1048-2449.jpg?w=1060&t=st=1687440838~exp=1687441438~hmac=93457f6dcbe93fd005e480dee6c0375d44029726213cbb237302f16f08b06a00',
    description: [
        "Random entertaintment API",
    ],
}
const author = {
    key: 'api_store',
    name: 'All APIs',
    img_src: 'https://img.freepik.com/free-vector/application-programming-interface-concept-illustration_114360-9135.jpg?w=1060&t=st=1687440423~exp=1687441023~hmac=12830d60883b3aac174f89be583e4fec8d45286c3dfe6b3f6988f8288e0d6ce1',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
