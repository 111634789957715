import React from 'react';
import {notify} from 'react-notify-toast';



const clipCopyCurrentURL = (e) => {
  e.preventDefault();

  const currentUrl = window.location.href;
  navigator.clipboard.writeText(currentUrl);
  let myColor = { background: '#0E1717', text: "#FFFFFF" };
  notify.show('URL copied!', "custom", 2000, myColor);
};


function Button() {
  
  return (
    <>
      <a href="" className="btn mr-2" onClick={clipCopyCurrentURL}><i className={" text-size-5 " + 'fa fa-link'}></i> </a>
    </>
  );
}

export default Button;
