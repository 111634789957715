const cards = [
    {"card_xid": "ac165b9c44e1e4f87f8a37f88697eb12846a58c5d74c7c8cd7de8691c5d7b7d5", "card_link": "https://www.dnd5eapi.co/docs/", "card_title": "www.dnd5eapi.co", "card_theme": null, "card_type": "image", "card_description": "D&D 5th Edition API", "icon_img_src": "", "link_img_src": "https://www.dnd5eapi.co/public/DnD-5e-meta-4k.png", "added_at": "2023-06-16T13:12:11.585684", "updated_at": "2023-07-07T20:32:19.614428"}, 
    {"card_xid": "122eed8a1bf1edf887713c587bcdb0ed3a1ad8bd334e60adcd3dbfb08451a7aa", "card_link": "https://universalis.app/docs/index.html", "card_title": "universalis.app", "card_theme": null, "card_type": "image", "card_description": "Universalis Documentation", "icon_img_src": "https://docs.universalis.app/universalis.png", "link_img_src": null, "added_at": "2023-06-11T20:06:09.193865", "updated_at": "2023-07-07T20:32:19.617326"}, 
    {"card_xid": "05b037e413741f0be0e1720e7d036944f9dee37aba033e91b95fb6f338729455", "card_link": "https://docs.forza-api.tk", "card_title": "docs.forza-api.tk", "card_theme": null, "card_type": "image", "card_description": "docs.forza-api.tk", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-07T01:40:08.836945", "updated_at": "2023-07-07T20:32:19.770358"}, 
    {"card_xid": "74f08b96879b6a1ab659cdc759f14936d161c78c7c50086ed7f6ee688467a239", "card_link": "http://magicthegathering.io/", "card_title": "magicthegathering.io", "card_theme": null, "card_type": "image", "card_description": "MTG Developers", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-02T22:39:20.589992", "updated_at": "2023-07-07T20:32:19.779529"}, 
    {"card_xid": "5dc7cba60c0b9e46fcddbe229b067b8fea56f7a386d6a7b247d23c89bea1487c", "card_link": "https://www.bugsnaxapi.com/", "card_title": "www.bugsnaxapi.com", "card_theme": null, "card_type": "image", "card_description": "Onlinescustomersurvey.com", "icon_img_src": "http://ww1.onlinescustomersurvey.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-02T22:25:48.577876", "updated_at": "2023-07-07T20:32:19.786324"}, 
    {"card_xid": "6503d7a772c26bba82437cccb64f406bc5a6961f3fd233801dc70a68565a125f", "card_link": "https://api-docs.igdb.com", "card_title": "api-docs.igdb.com", "card_theme": null, "card_type": "image", "card_description": "Getting Started \u2013 IGDB API docs", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-14T23:02:15.567908", "updated_at": "2023-07-07T20:32:19.789668"}, 
    {"card_xid": "c14e3514220a994ad66a2191029891ee4f58e7eddbe6d5181f7ad4ce41e60644", "card_link": "https://playerdb.co/", "card_title": "playerdb.co", "card_theme": null, "card_type": "image", "card_description": "PlayerDB", "icon_img_src": "https://playerdb.co/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-06-16T10:13:27.551872", "updated_at": "2023-07-07T20:32:20.713722"}, 
    {"card_xid": "36ca8f929c2b0fb0515d9487a55d824ed7a577a60867a1fcf32a64aa1a9bfc3d", "card_link": "https://age-of-empires-2-api.herokuapp.com", "card_title": "age-of-empires-2-api.herokuapp.com", "card_theme": null, "card_type": "image", "card_description": "Heroku | Application Error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-24T19:53:53.868382", "updated_at": "2023-07-07T20:32:20.718317"}, 
    {"card_xid": "de04eba51d8f04d200e94d9a6094f63e7762a4e605426494574c3661989d297b", "card_link": "https://amiiboapi.com/", "card_title": "amiiboapi.com", "card_theme": null, "card_type": "image", "card_description": "\n        AmiiboAPI - A RESTful API for Amiibo\n    ", "icon_img_src": "https://amiiboapi.com/static/favicon/apple-icon-152x152.png", "link_img_src": null, "added_at": "2023-06-04T17:56:59.976852", "updated_at": "2023-07-07T20:32:20.720004"}, 
    {"card_xid": "baf166e02681eb959f1c5ba3d3ad47ab702f7061b2f43b16eeb54d82cc1f5746", "card_link": "http://acnhapi.com/", "card_title": "acnhapi.com", "card_theme": null, "card_type": "image", "card_description": "ACNH API", "icon_img_src": "http://acnhapi.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-23T12:47:21.940557", "updated_at": "2023-07-07T20:32:20.727287"}, 
    {"card_xid": "aa82a1c9c02419e16fef8b83fdb69ecafeb3dec101f3dac84d8537ba0870e55c", "card_link": "https://github.com/didadadida93/autochess-vng-api", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - didadadida93/autochess-vng-api: Unofficial Autochess VNG API", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/3940d6e7150822518dad02a051ee09583c94b80b873099ad728d72154439201a/didadadida93/autochess-vng-api", "added_at": "2023-06-17T21:49:58.365153", "updated_at": "2023-07-07T20:32:20.734357"}, 
    {"card_xid": "79918fe8251fec5d3808801121a500e464d74faf4af254179cb92f2e071e83e0", "card_link": "https://github.com/bartervg/barter.vg/wiki", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "Home \u00b7 bartervg/barter.vg Wiki \u00b7 GitHub", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/c81a6019ee594b399007c7fc04f794977f6a1a3e3b61eca47fe6e5f3f5fba091/bartervg/barter.vg", "added_at": "2023-06-05T05:10:21.140901", "updated_at": "2023-07-07T20:32:20.828928"}, 
    {"card_xid": "93036f5ab99c13a8495938010c2e8ea9e69b4973dffbb231b79cea8f209c7b8e", "card_link": "https://develop.battle.net/documentation/guides/getting-started", "card_title": "develop.battle.net", "card_theme": null, "card_type": "image", "card_description": "Blizzard Battle.net Developer Portal", "icon_img_src": "https://develop.battle.net/documentation/guides/favicon.ico", "link_img_src": null, "added_at": "2023-04-01T00:45:18.358803", "updated_at": "2023-07-07T20:32:20.903521"}, 
    {"card_xid": "003882203cb517a3874b7692c6a03af57d317a3b8baa7d45c69cb7c12045501e", "card_link": "https://boardgamegeek.com/wiki/page/BGG_XML_API2", "card_title": "boardgamegeek.com", "card_theme": null, "card_type": "image", "card_description": "BGG XML API2 | Wiki  | BoardGameGeek", "icon_img_src": "https://cf.geekdo-static.com/icons/touch-icon180.png", "link_img_src": "https://cf.geekdo-static.com/images/opengraph/bgg_opengraph.png", "added_at": "2023-05-03T00:00:19.371463", "updated_at": "2023-07-07T20:32:20.909539"}, 
    {"card_xid": "acc7b3678ad3b7356db457d13bd6aac4a27c667b73389bcfbe3a8c03bc868f9b", "card_link": "https://developer.brawlstars.com", "card_title": "developer.brawlstars.com", "card_theme": null, "card_type": "image", "card_description": "Brawl Stars API", "icon_img_src": "https://developer.brawlstars.com/favicon-192x192.eb44d3.png", "link_img_src": null, "added_at": "2023-05-03T16:05:08.332529", "updated_at": "2023-07-07T20:32:21.052695"}, 
    {"card_xid": "bc4a38f2aeb538a6ee2202cde8d06415190f8bc81506686b09072db1abe21811", "card_link": "https://www.cheapshark.com/api", "card_title": "www.cheapshark.com", "card_theme": null, "card_type": "image", "card_description": "CheapShark API", "icon_img_src": "https://res.cloudinary.com/postman/image/upload/v1576964494/team/x10mxpl4nldkyuz7mfvo.ico", "link_img_src": "https://res.cloudinary.com/postman/image/upload/t_team_logo_pubdoc/v1/team/39704a3323a205f1a9d5eed22402f553ba31406d71ed414edf9c51e625d27009", "added_at": "2023-04-07T16:23:27.485548", "updated_at": "2023-07-07T20:32:21.057121"}, 
    {"card_xid": "7777c6473b962fb8122c3923b7fb907822dcf7a8df8f4b9a52bcccb53f430cf7", "card_link": "https://www.chess.com/news/view/published-data-api", "card_title": "www.chess.com", "card_theme": null, "card_type": "image", "card_description": "Published-Data API - Chess.com", "icon_img_src": "https://www.chess.com/bundles/web/favicons/apple-touch-icon.f72d3fd3.png", "link_img_src": "https://images.chesscomfiles.com/uploads/v1/news/398544.e72c42cb.5000x5000o.b00f75910b25.png", "added_at": "2023-05-20T15:47:21.274382", "updated_at": "2023-07-07T20:32:21.071696"}, 
    {"card_xid": "1e4f2396f2affe0b0277aa257b35c036278be6837aaf4a8faef94add3b9ba673", "card_link": "http://www.icndb.com/api/", "card_title": "www.icndb.com", "card_theme": null, "card_type": "image", "card_description": "404 Not Found", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-12T05:22:50.366712", "updated_at": "2023-07-07T20:32:21.143724"}, 
    {"card_xid": "60d44736c00ac4be556ea4558c98f632ee12d2fa22a2d6cf8ff4dcd4edbb95fd", "card_link": "https://developer.clashofclans.com", "card_title": "developer.clashofclans.com", "card_theme": null, "card_type": "image", "card_description": "Clash of Clans API", "icon_img_src": "https://developer.clashofclans.com/favicon-192x192.44935f.png", "link_img_src": "http://cdn.supercell.com/gameapi/website/og_coc_share_img.jpg", "added_at": "2023-04-28T08:01:42.461523", "updated_at": "2023-07-07T20:32:21.145262"}, 
    {"card_xid": "2ee81d665f898de781ebd86ccfe38e61d70642a74e3fc870d29063ea3a215972", "card_link": "https://developer.clashroyale.com", "card_title": "developer.clashroyale.com", "card_theme": null, "card_type": "image", "card_description": "Clash Royale API", "icon_img_src": "https://developer.clashroyale.com/favicon-192x192.6f82ec.png", "link_img_src": "http://cdn.supercell.com/gameapi/website/og_cr_share_img.jpg", "added_at": "2023-05-31T05:07:35.440686", "updated_at": "2023-07-07T20:32:21.150170"}, 
    {"card_xid": "cd2641055392698186d2fe417ed122a909b89e06f7898782d2a35023eac54f82", "card_link": "https://comicvine.gamespot.com/api/documentation", "card_title": "comicvine.gamespot.com", "card_theme": null, "card_type": "image", "card_description": "Free Comic Database and API - Comic Vine", "icon_img_src": "https://comicvine.gamespot.com/a/bundles/phoenixsite/images/core/loose/apple-touch-icon-precomposed-cv.png", "link_img_src": "https://comicvine.gamespot.com/a/bundles/comicvinesite/images/logo.png", "added_at": "2023-04-09T00:49:00.301772", "updated_at": "2023-07-07T20:32:21.154345"}, 
    {"card_xid": "c35b744276a13324bca97c693c365841e172fbb8a6d4868657a1396d67611e64", "card_link": "https://crafatar.com", "card_title": "crafatar.com", "card_theme": null, "card_type": "image", "card_description": "Crafatar \u2013 A blazing fast API for Minecraft faces!", "icon_img_src": "https://crafatar.com/favicon.png", "link_img_src": "https://crafatar.com/logo.png", "added_at": "2023-06-13T10:43:06.933009", "updated_at": "2023-07-07T20:32:21.417141"}, 
    {"card_xid": "7f5c023b9b92a6a512c3942948d882abcf1aa5e065f8772fc1aabe0fb6f9ab34", "card_link": "https://crossuniverse.psychpsyo.com/apiDocs.html", "card_title": "crossuniverse.psychpsyo.com", "card_theme": null, "card_type": "image", "card_description": "CUA00001 Documentation", "icon_img_src": "https://crossuniverse.net/apiDocs/images/favicon.png", "link_img_src": null, "added_at": "2023-06-08T11:26:10.429002", "updated_at": "2023-07-07T20:32:21.433235"}, 
    {"card_xid": "ba79cce0d293d00d34ac53f0738bd674266e74b92fddbf260342711ed9621b0d", "card_link": "http://deckofcardsapi.com/", "card_title": "deckofcardsapi.com", "card_theme": null, "card_type": "image", "card_description": "Deck of Cards API", "icon_img_src": "https://deckofcardsapi.com/static/img/favicon/apple-touch-icon-120x120.png", "link_img_src": "https://deckofcardsapi.com/static/img/cards.png", "added_at": "2023-06-01T02:16:52.190635", "updated_at": "2023-07-07T20:32:21.525436"}, 
    {"card_xid": "14993098c30037cc41000e9fa657e51c26d77d5a38b3e9e089d60c154cde04f3", "card_link": "https://bungie-net.github.io/multi/index.html", "card_title": "bungie-net.github.io", "card_theme": null, "card_type": "image", "card_description": "Bungie.Net API", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-01T11:11:36.889498", "updated_at": "2023-07-07T20:32:21.537349"}, 
    {"card_xid": "707e598e67cc8e9b92b3dd77abc33d59a34bceb70f85ec1f4d59b2dde461d2bf", "card_link": "https://digimon-api.vercel.app/", "card_title": "digimon-api.vercel.app", "card_theme": null, "card_type": "image", "card_description": "Digimon API", "icon_img_src": "https://digimon-api.vercel.app/Favicon.x-ico", "link_img_src": null, "added_at": "2023-05-09T21:30:09.311679", "updated_at": "2023-07-07T20:32:21.712838"}, 
    {"card_xid": "c6f92b2b61c510f80fbb55d3396cc2458f8523f452cde7281f838d963630e8fe", "card_link": "https://documenter.getpostman.com/view/14059948/TzecB4fH", "card_title": "documenter.getpostman.com", "card_theme": null, "card_type": "image", "card_description": "Digimon TCG API", "icon_img_src": "https://documenter-assets.pstmn.io/favicon.ico", "link_img_src": "https://res.cloudinary.com/postman/image/upload/t_team_logo_pubdoc/v1/team/a5e4ebfdbe5036c1a012ff113d27c14ee2e99b4c6be957e2b34c1953b0f5a513", "added_at": "2023-05-30T19:55:57.152450", "updated_at": "2023-07-07T20:32:21.718984"}, 
    {"card_xid": "67412843c323d0f5f616f050a0bbc67fb9b430e855cbff4dffff90bc06e7019a", "card_link": "https://disneyapi.dev", "card_title": "disneyapi.dev", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://disneyapi.dev/icons/icon-384x384.png?v=496a51d19182578899ddd1e460de9a12", "link_img_src": null, "added_at": "2023-05-09T05:24:29.895468", "updated_at": "2023-07-07T20:32:21.744583"}, 
    {"card_xid": "669ebb6bad92d848b10a1599bc68bea847f50e3d19a66ee3fac90e24b0cb224e", "card_link": "https://docs.opendota.com/", "card_title": "docs.opendota.com", "card_theme": null, "card_type": "image", "card_description": "OpenDota API", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-30T06:17:56.601380", "updated_at": "2023-07-07T20:32:21.751660"}, 
    {"card_xid": "316ab63d5b8a2319313df5c872557aa497f492532db8f51bff0f255efb09a2a1", "card_link": "https://open5e.com/", "card_title": "open5e.com", "card_theme": null, "card_type": "image", "card_description": "Open5e", "icon_img_src": "https://open5e.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-07T19:10:26.702867", "updated_at": "2023-07-07T20:32:21.755614"}, 
    {"card_xid": "0483aa5e66399eab3f3d24d8a4e6bd9b94f87c50bdb1e6d21b77318411a1f93c", "card_link": "https://esi.evetech.net/ui", "card_title": "esi.evetech.net", "card_theme": null, "card_type": "image", "card_description": "EVE Swagger Interface", "icon_img_src": "https://esi.evetech.net/favicon.ico", "link_img_src": null, "added_at": "2023-06-10T19:17:59.951166", "updated_at": "2023-07-07T20:32:21.767987"}, 
    {"card_xid": "2ff64f13937805f489a9a45199478120c89406f147c545f64a51916bd8eeab2f", "card_link": "https://ffxivcollect.com/", "card_title": "ffxivcollect.com", "card_theme": null, "card_type": "image", "card_description": "FFXIV Collect", "icon_img_src": "https://ffxivcollect.com/favicon.ico", "link_img_src": "https://ffxivcollect.com/images/logo.png", "added_at": "2023-06-14T06:38:13.848300", "updated_at": "2023-07-07T20:32:21.776846"}, 
    {"card_xid": "8724787e4ee68f63f46a305a90f40d1c1be67ece791b51015cf07f2607f20ed8", "card_link": "https://www.easports.com/fifa/ultimate-team/api/fut/item", "card_title": "www.easports.com", "card_theme": null, "card_type": "image", "card_description": "An Error Occurred: Not Found", "icon_img_src": "https://www.easports.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-02T10:03:14.921560", "updated_at": "2023-07-07T20:32:21.792593"}, 
    {"card_xid": "9ee97975a62809ee67f17c44b9c986602488e56b4d95bb9346008832b776bb14", "card_link": "https://xivapi.com/", "card_title": "xivapi.com", "card_theme": null, "card_type": "image", "card_description": "XIVAPI - A FINAL FANTASY XIV: Online REST API", "icon_img_src": "https://xivapi.com/favicon.png", "link_img_src": "https://xivapi.com/logo.png?v=6cd16074c6f1a052ed6bedd12a639f12712d60c9", "added_at": "2023-06-12T11:32:16.440152", "updated_at": "2023-07-07T20:32:21.794342"}, 
    {"card_xid": "5353e1733cdcad0f685bd24f7fa9380da0d1556ebe2ff79727058da1d455ad93", "card_link": "https://fortnitetracker.com/site-api", "card_title": "fortnitetracker.com", "card_theme": null, "card_type": "image", "card_description": "\n    Fortnite Tracker - API\n", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-03T10:30:26.691418", "updated_at": "2023-07-07T20:32:21.801558"}, 
    {"card_xid": "7a62f2a1228fccf9e1edde78806647aac90acc47ef1f102cc8717d5323d360d0", "card_link": "https://www.freetogame.com/api-doc", "card_title": "www.freetogame.com", "card_theme": null, "card_type": "image", "card_description": "Free-To-Play Games Database API - FreeToGame", "icon_img_src": "https://www.freetogame.com/favicon/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-05-04T22:41:20.245940", "updated_at": "2023-07-07T20:32:21.830362"}, 
    {"card_xid": "cddd3634e698285adaeb8f546120be5414eb253ab544e983e8fdea2d463ed619", "card_link": "https://asli-fun-fact-api.herokuapp.com/", "card_title": "asli-fun-fact-api.herokuapp.com", "card_theme": null, "card_type": "image", "card_description": "Heroku | Application Error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-04T05:36:59.300687", "updated_at": "2023-07-07T20:32:21.858812"}, 
    {"card_xid": "85458c5953d0af73f2169acd2299dee6db8432ff87a2c13115412c5f779a3389", "card_link": "https://api.funtranslations.com/", "card_title": "api.funtranslations.com", "card_theme": null, "card_type": "image", "card_description": "Funtranslations API", "icon_img_src": "https://api.funtranslations.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-01T23:34:14.757979", "updated_at": "2023-07-07T20:32:21.861971"}, 
    {"card_xid": "123f839c761994146b0a67d559f016cca66ba69261194b6dc64ba0ee8b6cfb03", "card_link": "https://www.gamerpower.com/api-read", "card_title": "www.gamerpower.com", "card_theme": null, "card_type": "image", "card_description": "Free Games, Keys & Giveaways Tracker API - GamerPower", "icon_img_src": "https://www.gamerpower.com/favicon/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-30T22:09:01.286651", "updated_at": "2023-07-07T20:32:21.873141"}, 
    {"card_xid": "4bafe4c8f654b54403cf448591afd188978f2edabd5aa64a8eca43fbd7fe2950", "card_link": "https://gdbrowser.com/api", "card_title": "gdbrowser.com", "card_theme": null, "card_type": "image", "card_description": "GD Level Browser API", "icon_img_src": "https://gdbrowser.com/assets/difficulties/auto.png", "link_img_src": "https://gdbrowser.com/assets/difficulties/auto.png", "added_at": "2023-05-03T00:03:23.199630", "updated_at": "2023-07-07T20:32:21.899388"}, 
    {"card_xid": "9047b9529681852e60a51ea6c118d4ff25c299e886b7f3bb4545d4be5ddc9f96", "card_link": "https://github.com/sameerkumar18/geek-joke-api", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - sameerkumar18/geek-joke-api: Random Geek Jokes REST API", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/fa5dae02431d3fb92a18f3bbe8800d8c4facb73df40f3a8df29d7ba48b19b314/sameerkumar18/geek-joke-api", "added_at": "2023-06-10T16:38:19.917793", "updated_at": "2023-07-07T20:32:21.902894"}, 
    {"card_xid": "11fec469195c12ca27ccf40f6184ade4860a0939d9be1da0269b55367f01874b", "card_link": "https://genshin.dev", "card_title": "genshin.dev", "card_theme": null, "card_type": "image", "card_description": "genshin.dev - Fan-Made Genshin Impact API", "icon_img_src": "https://genshin.dev/favicon.ico", "link_img_src": null, "added_at": "2023-04-29T22:11:15.454481", "updated_at": "2023-07-07T20:32:22.014525"}, 
    {"card_xid": "6a5610df467dad2fa4b7e115765974efbe75b2551b6c0b7a51f53ff70cfa6b65", "card_link": "https://www.giantbomb.com/api/documentation", "card_title": "www.giantbomb.com", "card_theme": null, "card_type": "image", "card_description": "Free Video Game Database and API - Giant Bomb", "icon_img_src": "https://www.giantbomb.com/a/bundles/phoenixsite/images/core/loose/apple-touch-icon-precomposed-gb.png", "link_img_src": "https://www.giantbomb.com/a/bundles/giantbombsite/images/logo.png", "added_at": "2023-04-21T20:10:35.145522", "updated_at": "2023-07-07T20:32:22.016620"}, 
    {"card_xid": "4e57e651ad7417d1fd7cdcd8e18621d1c71a9822d46ed984d97fb5916bcf4e72", "card_link": "https://github.com/favware/graphql-pokemon", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - favware/graphql-pokemon: Extensive Pok\u00e9mon GraphQL API", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/d9364c2f6556042176676cc2adb343489846efce6be1ab5603ac080a84a85dd0/favware/graphql-pokemon", "added_at": "2023-06-13T15:20:12.542485", "updated_at": "2023-07-07T20:32:22.389735"}, 
    {"card_xid": "303d4140eb389bb47135a83c2e07705a96033a8af67a391fc56c6457190cae85", "card_link": "https://wiki.guildwars2.com/wiki/API:Main", "card_title": "wiki.guildwars2.com", "card_theme": null, "card_type": "image", "card_description": "API:Main - Guild Wars 2 Wiki (GW2W)", "icon_img_src": "https://wiki.guildwars2.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-10T13:15:06.692252", "updated_at": "2023-07-07T20:32:22.560845"}, 
    {"card_xid": "2ba6ebe0b4cf513182982284e2fdb1170bd12fbed42bbcb3a6f47e3cc5bac77c", "card_link": "https://github.com/rubensayshi/gw2spidy/wiki", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "Home \u00b7 rubensayshi/gw2spidy Wiki \u00b7 GitHub", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/76225ab249807f8e34f084c1c8b9f1653e4fb60ce29b8094f64c467fc0563ced/rubensayshi/gw2spidy", "added_at": "2023-05-11T19:45:31.047716", "updated_at": "2023-07-07T20:32:22.615782"}, 
    {"card_xid": "fd9a40f81f99e673f17d61a151a61b377c030bd55f23bc5c29c1719544b67a06", "card_link": "https://developer.haloapi.com/", "card_title": "developer.haloapi.com", "card_theme": null, "card_type": "image", "card_description": "<343 Industries> developer portal", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-14T20:08:05.194846", "updated_at": "2023-07-07T20:32:22.662576"}, 
    {"card_xid": "c0d5a1efff40a1bd6990a66fa255f398d44747925197ba7ca280c6a63f2bea02", "card_link": "http://hearthstoneapi.com/", "card_title": "hearthstoneapi.com", "card_theme": null, "card_type": "image", "card_description": "Hearthstone API", "icon_img_src": "https://hearthstoneapi.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-22T10:58:03.401741", "updated_at": "2023-07-07T20:32:22.669248"}, 
    {"card_xid": "4c1c4d4bfe112392d17d75a931bf109ddebff95a6eb4daec9166aa15ae0dccae", "card_link": "https://rapidapi.com/Ziggoto/api/humble-bundle", "card_title": "rapidapi.com", "card_theme": null, "card_type": "image", "card_description": "Humble Bundle API Documentation (Ziggoto) | RapidAPI", "icon_img_src": "https://rapidapi.com/static-assets/default/favicon-8e7d522e-653f-4edd-ac27-3f6ed950e45d.png", "link_img_src": "https://rapidapi.com/static-assets/default/favicon-8e7d522e-653f-4edd-ac27-3f6ed950e45d.png", "added_at": "2023-06-15T02:59:57.487578", "updated_at": "2023-07-07T20:32:22.722612"}, 
    {"card_xid": "7bf8362d368ce8819d901b3b5a9ecce7c421d86fe0aa9d1a13f0f69f81de31ed", "card_link": "https://humorapi.com", "card_title": "humorapi.com", "card_theme": null, "card_type": "image", "card_description": " Humor API: Jokes, Puns, Gifs, and Memes Galore ", "icon_img_src": "https://humorapi.com/img/logo-square.svg", "link_img_src": null, "added_at": "2023-06-14T10:24:10.851064", "updated_at": "2023-07-07T20:32:22.751909"}, 
    {"card_xid": "667b111152310e355d0c4d2e1cdb71773b6e0297501d006615a4a47c586958b8", "card_link": "https://api.hypixel.net/", "card_title": "api.hypixel.net", "card_theme": null, "card_type": "image", "card_description": "Hypixel Public API", "icon_img_src": "https://api.hypixel.net/favicon.ico", "link_img_src": null, "added_at": "2023-05-12T23:00:39.027708", "updated_at": "2023-07-07T20:32:22.813363"}, 
    {"card_xid": "88b771fd32cd0003e479b399da57752658e82cd3d7106490c7812da16b455c28", "card_link": "https://github.com/gadhagod/Hyrule-Compendium-API", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - gadhagod/Hyrule-Compendium-API: An API serving data on all creatures, monsters, materials, equipment, and treasure in The Legend of Zelda: Breath of the Wild.", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/8ae530b83801770557c2d4249d8d8105ea7ec7e7764e02ef869e0337a863bd52/gadhagod/Hyrule-Compendium-API", "added_at": "2023-04-28T04:30:18.049223", "updated_at": "2023-07-07T20:32:23.011239"}, 
    {"card_xid": "0ba26916e8be74c7f84b304fc4ad61e9cdabec6c93b40cebe052b3700fda2647", "card_link": "https://hytale-api.com/", "card_title": "hytale-api.com", "card_theme": null, "card_type": "image", "card_description": "hytale-api.com", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-28T04:35:30.752117", "updated_at": "2023-07-07T20:32:23.101063"}, 
    {"card_xid": "8567b06ea914781ff696416d393933bc989f09827385c5699201392499750f5e", "card_link": "https://sv443.net/jokeapi/v2/", "card_title": "sv443.net", "card_theme": null, "card_type": "image", "card_description": "JokeAPI - Documentation", "icon_img_src": "https://sv443.net/jokeapi/v2/favicon.ico", "link_img_src": "https://sv443.net/jokeapi/v2/favicon.ico", "added_at": "2023-04-11T12:34:38.360721", "updated_at": "2023-07-07T20:32:23.108994"}, 
    {"card_xid": "d0e9e1c6267a4b46052015c53b359327e883206fe9ed75bbacd5186825cfe38c", "card_link": "https://jokes.one/api/joke/", "card_title": "jokes.one", "card_theme": null, "card_type": "image", "card_description": "Jokes API", "icon_img_src": "https://jokes.one/favicon.ico", "link_img_src": null, "added_at": "2023-05-17T22:04:24.807457", "updated_at": "2023-07-07T20:32:23.277216"}, 
    {"card_xid": "9fd0a68bec2893e93c8445b471db06868b063632284166644b81dccd49d368f2", "card_link": "http://jservice.io", "card_title": "jservice.io", "card_theme": null, "card_type": "image", "card_description": "    jService - 221,510 trivia questions\n", "icon_img_src": "http://jservice.io/favicon.ico", "link_img_src": null, "added_at": "2023-04-08T22:22:17.540044", "updated_at": "2023-07-07T20:32:23.460840"}, 
    {"card_xid": "4dc2cbca6dca25250f6b9c243fb27e890f8c8a2ef14832960279f44d701df0eb", "card_link": "https://lichess.org/api", "card_title": "lichess.org", "card_theme": null, "card_type": "image", "card_description": "Lichess.org API reference", "icon_img_src": "https://lichess.org/favicon.ico", "link_img_src": null, "added_at": "2023-06-07T19:38:25.477265", "updated_at": "2023-07-07T20:32:23.468572"}, 
    {"card_xid": "113d0fa818f75e2fd18352d2567f5c2ac19a9cf4820127181d9b87531a1d6984", "card_link": "https://mario-kart-tour-api.herokuapp.com/", "card_title": "mario-kart-tour-api.herokuapp.com", "card_theme": null, "card_type": "image", "card_description": "Heroku | Application Error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-20T11:59:39.681831", "updated_at": "2023-07-07T20:32:25.264563"}, 
    {"card_xid": "a67288c1694ab180f19cc2ab658f45c43eb5fd819b3a526b8dbcdc7157a8f8c3", "card_link": "https://developer.marvel.com", "card_title": "developer.marvel.com", "card_theme": null, "card_type": "image", "card_description": "Marvel Developer Portal", "icon_img_src": "https://developer.marvel.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-14T11:12:11.934055", "updated_at": "2023-07-07T20:32:25.267577"}, 
    {"card_xid": "6f8c0ccc09579051f54ca52f6efd9000e95b135bc2b79cbd627b1aa47cb0a662", "card_link": "https://api.mcsrvstat.us", "card_title": "api.mcsrvstat.us", "card_theme": null, "card_type": "image", "card_description": "Minecraft Server Status API", "icon_img_src": "https://mcsrvstat.us/img/minecraft.png", "link_img_src": null, "added_at": "2023-04-26T09:01:07.614650", "updated_at": "2023-07-07T20:32:25.311911"}, 
    {"card_xid": "5944d538bd3d39da86607669ab33dc6fb05ef512fc394de43cdcd9ac4ab28503", "card_link": "https://www.mmobomb.com/api", "card_title": "www.mmobomb.com", "card_theme": null, "card_type": "image", "card_description": "MMO Games & MMORPG API - MMOBomb", "icon_img_src": "https://www.mmobomb.com/favicon/apple-touch-icon.png", "link_img_src": "https://www.mmobomb.com/assets/images/card.jpg", "added_at": "2023-05-26T08:58:53.491880", "updated_at": "2023-07-07T20:32:25.344829"}, 
    {"card_xid": "56fa1f511092dcd308a85190a9c223256702f263e28f5cc342f8ffb597f5ccbd", "card_link": "https://docs.mod.io", "card_title": "docs.mod.io", "card_theme": null, "card_type": "image", "card_description": "Getting Started \u2013 mod.io API v1", "icon_img_src": "https://docs.mod.io/images/favicon-7f14945d.ico", "link_img_src": "https://docs.mod.io/images/splash-8d288fe6.png", "added_at": "2023-05-20T15:41:52.091942", "updated_at": "2023-07-07T20:32:25.366512"}, 
    {"card_xid": "1b97dc97b03754dee07e0e31352080348bcc9057c19685e1e105a640748eb411", "card_link": "https://wiki.vg/Mojang_API", "card_title": "wiki.vg", "card_theme": null, "card_type": "image", "card_description": "Mojang API - wiki.vg", "icon_img_src": "https://wiki.vg/favicon.ico", "link_img_src": null, "added_at": "2023-04-25T01:24:41.229213", "updated_at": "2023-07-07T20:32:28.930987"}, 
    {"card_xid": "327eb2f288bf64301204a358d8632b3190de7516f43d84f099304d88a954c2ac", "card_link": "https://docs.mhw-db.com/", "card_title": "docs.mhw-db.com", "card_theme": null, "card_type": "image", "card_description": "Introduction \u2013 Monster Hunter World API", "icon_img_src": "", "link_img_src": null, "added_at": "2023-04-13T08:22:58.289354", "updated_at": "2023-07-07T20:32:29.042107"}, 
    {"card_xid": "a1e13daa806ff3175f2c77245c4629bbcd744437b9bdb189e3f4372178eed686", "card_link": "https://opentdb.com/api_config.php", "card_title": "opentdb.com", "card_theme": null, "card_type": "image", "card_description": "Open Trivia DB: Free to use, user-contributed trivia question database.", "icon_img_src": "https://opentdb.com/favicon.ico", "link_img_src": "https://opentdb.com/images/share.png", "added_at": "2023-06-13T03:48:12.096182", "updated_at": "2023-07-07T20:32:29.581154"}, 
    {"card_xid": "1276dc1e027120ab17a5b0c6ed8ae3faec21ecafbb11c437e73921a476413a09", "card_link": "https://developers.pandascore.co/", "card_title": "developers.pandascore.co", "card_theme": null, "card_type": "image", "card_description": "Introduction", "icon_img_src": "https://files.readme.io/4bcd187-favicon.ico", "link_img_src": null, "added_at": "2023-05-12T20:22:32.915916", "updated_at": "2023-07-07T20:32:29.595573"}, 
    {"card_xid": "a842913f0f1a7f33b3bd9de84b65c8c5d160a0f634c582068be91c1e3261dd05", "card_link": "https://www.pathofexile.com/developer/docs", "card_title": "www.pathofexile.com", "card_theme": null, "card_type": "image", "card_description": "Developer Docs - Path of Exile", "icon_img_src": "https://web.poecdn.com/protected/image/favicon/favicon.ico?v=1&key=Hd0k46o9F-3yqJ3wD8x8gg", "link_img_src": null, "added_at": "2023-04-12T11:48:24.320085", "updated_at": "2023-07-07T20:32:29.677376"}, 
    {"card_xid": "2d709e921b8fb5afe04d2837d8019ab978556751bc1d6f02f9d5c1cf66d980bb", "card_link": "https://pokeapi.co", "card_title": "pokeapi.co", "card_theme": null, "card_type": "image", "card_description": "Pok\u00e9API", "icon_img_src": "https://pokeapi.co/pokeapi_192_square.png", "link_img_src": "https://pokeapi.co/static/pokeapi_256.3fa72200.png", "added_at": "2023-06-04T04:21:50.540441", "updated_at": "2023-07-07T20:32:29.695591"}, 
    {"card_xid": "ea6fc343a9a4f2e18580be3bc0ea47feb5e7a0626875a2fac64e8c4e85ecfd2a", "card_link": "https://github.com/mazipan/graphql-pokeapi", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - mazipan/graphql-pokeapi: \ud83d\udd34  The Unofficial GraphQL for PokeAPI", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/019aefc4ca935a4b23f3be69f0875344a0c21a30a5fc1412cb48a3a5c6a3b284/mazipan/graphql-pokeapi", "added_at": "2023-04-30T03:04:29.249528", "updated_at": "2023-07-07T20:32:29.703489"}, 
    {"card_xid": "b292bfb447eaebc2994cc51df8c96179ea1a76c8264b6c9a1c058ee340ab3a3d", "card_link": "https://pokemontcg.io", "card_title": "pokemontcg.io", "card_theme": null, "card_type": "image", "card_description": "Pok\u00e9mon TCG Developers", "icon_img_src": "https://pokemontcg.io/images/favicon.ico", "link_img_src": null, "added_at": "2023-04-26T13:43:56.052043", "updated_at": "2023-07-07T20:32:29.832373"}, 
    {"card_xid": "2bb24c45720e7f35275c9b06318e777add4d31619f86cde29fd2c7f46dc55a3b", "card_link": "https://psychonauts-api.netlify.app/", "card_title": "psychonauts-api.netlify.app", "card_theme": null, "card_type": "image", "card_description": "Psychonauts API", "icon_img_src": "https://psychonauts-api.netlify.app/img/brain.png", "link_img_src": null, "added_at": "2023-05-19T00:48:45.752499", "updated_at": "2023-07-07T20:32:29.840659"}, 
    {"card_xid": "ef69d5fc00ec4047cc3a40bb8a2fa2596a1d466e7625007086a219b88d028f09", "card_link": "https://developer.pubg.com/", "card_title": "developer.pubg.com", "card_theme": null, "card_type": "image", "card_description": "PUBG Developer Portal", "icon_img_src": "https://d2s2kb1tq1008e.cloudfront.net/assets/pubg/favicon-d690c6bc4b10434525fdf6976f184a3456f3885a3c6033925e6b3259a3a48090.png", "link_img_src": "https://d2s2kb1tq1008e.cloudfront.net/assets/pubg/logo_large-a7a4f3e0242846f9d0d722ef21ad7729defdf2bac2d04ce82255a2b7b9ec4741.png", "added_at": "2023-04-19T00:42:52.560502", "updated_at": "2023-07-07T20:32:29.844473"}, 
    {"card_xid": "6e6e4806e0a52e5232931ea42414d4cbffa6a631bd22db678cb4f19e97a8858e", "card_link": "https://github.com/deltadex7/puyodb-api-deno", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - deltadex7/puyodb-api-deno: Puyo Nexus (unofficial) database scraper API.", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/855d7ea8043988118a5a1ef55c88f1d709d3700831b7afb6f3c9ee8f37e72821/deltadex7/puyodb-api-deno", "added_at": "2023-06-02T10:19:18.883569", "updated_at": "2023-07-07T20:32:29.850100"}, 
    {"card_xid": "08c9fbb9a83739ad9c3a88750f3090b76dcafb345f24b203fb4276a58a24d1e6", "card_link": "https://quizapi.io/", "card_title": "quizapi.io", "card_theme": null, "card_type": "image", "card_description": "Quiz API - A JSON API for Technical Quizzes", "icon_img_src": "https://quizapi.io/settings/March2020/H8dOZWtQD0IND4pqOJTT.png", "link_img_src": "https://quizapi.io/storage/QuziApi_Logo_Dark.png", "added_at": "2023-06-11T21:39:59.233318", "updated_at": "2023-07-07T20:32:29.941608"}, 
    {"card_xid": "9467c80f02c057c808c210ddfa59b514644a61bc0a854cc010bc0ea433c7b763", "card_link": "https://raider.io/api", "card_title": "raider.io", "card_theme": null, "card_type": "image", "card_description": "Raider.IO Developer API", "icon_img_src": "https://cdnassets.raider.io/images/favicon-16x16.png", "link_img_src": null, "added_at": "2023-05-18T15:55:34.528712", "updated_at": "2023-07-07T20:32:29.975652"}, 
    {"card_xid": "ba001f3c29188a74404a1d76c64f0f1057f99a0218dfc17aaf11eaa67f597904", "card_link": "https://rawg.io/apidocs", "card_title": "rawg.io", "card_theme": null, "card_type": "image", "card_description": "Explore RAWG Video Games Database API \u2022 RAWG", "icon_img_src": "https://rawg.io/assets/en/apple-icon-152x152.png?v=4", "link_img_src": "https://rawg.io/assets/en/share-fb.jpg?v=4", "added_at": "2023-04-18T04:59:38.656891", "updated_at": "2023-07-07T20:32:29.980119"}, 
    {"card_xid": "9a85d21db3081ff74f081c50a48879a9904b97a830dc0b14043a63166c6ca23d", "card_link": "https://rickandmortyapi.com", "card_title": "rickandmortyapi.com", "card_theme": null, "card_type": "image", "card_description": "The Rick and Morty API", "icon_img_src": "https://rickandmortyapi.com/icons/icon-144x144.png?v=1538abef51e33ef514e8fe1ab9aeab4e", "link_img_src": "https://rickandmortyapi.com/images/site.jpeg", "added_at": "2023-05-15T15:19:57.720162", "updated_at": "2023-07-07T20:32:30.023963"}, 
    {"card_xid": "2a8cba36e80e64497aca0dd57abd1c1cb3ef40cebc9638264b019e1b2308db47", "card_link": "https://developer.riotgames.com/", "card_title": "developer.riotgames.com", "card_theme": null, "card_type": "image", "card_description": "Riot Developer Portal", "icon_img_src": "https://static.developer.riotgames.com/img/favicon.png", "link_img_src": null, "added_at": "2023-05-07T03:05:16.157518", "updated_at": "2023-07-07T20:32:30.031134"}, 
    {"card_xid": "c022cfb7274cd83ede84df67df4a93017a23a9ed272f0bca3c72f0b7297c6712", "card_link": "https://rps101.pythonanywhere.com/api", "card_title": "rps101.pythonanywhere.com", "card_theme": null, "card_type": "image", "card_description": "Coming Soon: PythonAnywhere", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-04T17:08:12.217144", "updated_at": "2023-07-07T20:32:30.047587"}, 
    {"card_xid": "6534b86a74aebe4aab885c5ca1a69a8b741f9ecef27deb0a873ed6777b807bde", "card_link": "https://runescape.wiki/w/Application_programming_interface", "card_title": "runescape.wiki", "card_theme": null, "card_type": "image", "card_description": "Application programming interface - The RuneScape Wiki", "icon_img_src": "https://runescape.wiki/favicon.ico", "link_img_src": "https://runescape.wiki/images/Wiki.png", "added_at": "2023-05-09T18:15:32.516038", "updated_at": "2023-07-07T20:32:30.049903"}, 
    {"card_xid": "141d4e4d984ea97594ec9f7236474cd7dc19f82943478b0a4edfcb82ec529efe", "card_link": "https://github.com/JessVel/sakura-card-captor-api", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "GitHub - JessVel/sakura-card-captor-api", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://opengraph.githubassets.com/425a6d0eb094f1b7c0dd076585730e915562ecd00250d3f2297b4c00acc19f29/JessVel/sakura-card-captor-api", "added_at": "2023-05-19T15:08:32.650672", "updated_at": "2023-07-07T20:32:30.225814"}, 
    {"card_xid": "a610a51ffc47019d7633c877db4955ddd6482d59d7bd003d8b553edd1be69553", "card_link": "https://scryfall.com/docs/api", "card_title": "scryfall.com", "card_theme": null, "card_type": "image", "card_description": "REST API Documentation \u00b7 Scryfall Magic: The Gathering Search", "icon_img_src": "https://scryfall.com/apple-touch-icon.png?v=4679d72739c6c53eea14ebbedce662bdf69fbd0d6000b33f3db42519363fc88c", "link_img_src": null, "added_at": "2023-05-29T03:10:57.087060", "updated_at": "2023-07-07T20:32:30.352898"}, 
    {"card_xid": "c4563e91da23044c1e53a697a1cc9886cd304ee7873d6726c4b254962c79aacc", "card_link": "https://spacetraders.io?rel=pub-apis", "card_title": "spacetraders.io", "card_theme": null, "card_type": "image", "card_description": "SpaceTraders API", "icon_img_src": "https://spacetraders.io/apple-touch-icon.png", "link_img_src": "https://spacetraders.io/og-image.jpg", "added_at": "2023-05-31T15:51:34.538796", "updated_at": "2023-07-07T20:32:30.373982"}, 
    {"card_xid": "3dc91de5f18485cb3bbc553bb0d832d7d3986d63d5bc19c99005615f797f7acc", "card_link": "https://steamapi.xpaw.me/", "card_title": "steamapi.xpaw.me", "card_theme": null, "card_type": "image", "card_description": "Steam Web API Documentation", "icon_img_src": "https://steamapi.xpaw.me/favicon.ico", "link_img_src": null, "added_at": "2023-06-16T00:59:29.096065", "updated_at": "2023-07-07T20:32:30.396562"}, 
    {"card_xid": "2dede57af234bde47dcff68feb7bab37a27a6a448d639b9c66247b3fa7a3d9ca", "card_link": "https://github.com/Revadike/InternalSteamWebAPI/wiki", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "Home \u00b7 Revadike/InternalSteamWebAPI Wiki \u00b7 GitHub", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://repository-images.githubusercontent.com/365224400/bfd91a80-df31-11eb-8b0e-9b13e8be2358", "added_at": "2023-05-03T11:42:34.716471", "updated_at": "2023-07-07T20:32:30.537576"}, 
    {"card_xid": "ef65319cab49bedf39e4da330fd8b4fd4446ee77a9e6c54c4ffd1768b34893cf", "card_link": "https://superheroapi.com", "card_title": "superheroapi.com", "card_theme": null, "card_type": "image", "card_description": "SuperHero API", "icon_img_src": "https://thetheme.io/apple-touch-icon.png", "link_img_src": null, "added_at": "2023-04-05T02:17:18.116418", "updated_at": "2023-07-07T20:32:30.716575"}, 
    {"card_xid": "74fe602e8adc300430c472cd85debee6e1a46359b3c7a8f6bc65bdebeb214aad", "card_link": "https://www.tcgdex.net/docs", "card_title": "www.tcgdex.net", "card_theme": null, "card_type": "image", "card_description": "Homepage - TCGdex Documentation", "icon_img_src": "https://www.tcgdex.net/favicon.ico", "link_img_src": null, "added_at": "2023-05-23T22:49:16.369048", "updated_at": "2023-07-07T20:32:30.787995"}, 
    {"card_xid": "770f4ab6354c6419db147d5b2aab554def528a333180754cc87db440ac101b0d", "card_link": "https://docs.tebex.io/plugin/", "card_title": "docs.tebex.io", "card_theme": null, "card_type": "image", "card_description": "Welcome - Plugin API", "icon_img_src": "https://app.gitbook.com/public/emojis/1f5a5.png?v=6.0.0", "link_img_src": "https://app.gitbook.com/share/space/thumbnail/-Lu7EHa8uU8ZbYDKqVZF.png?color=%23164494&logo=&theme=bold", "added_at": "2023-04-24T17:42:27.191734", "updated_at": "2023-07-07T20:32:30.801457"}, 
    {"card_xid": "c237e8be0e7f79c29c720a70b9fd24f185e89399e8945b7fac3c05564d15cdcf", "card_link": "https://tetr.io/about/api/", "card_title": "tetr.io", "card_theme": null, "card_type": "image", "card_description": "API - TETR.IO", "icon_img_src": "https://tetr.io/favicon.ico", "link_img_src": null, "added_at": "2023-06-02T23:10:18.051060", "updated_at": "2023-07-07T20:32:30.824043"}, 
    {"card_xid": "3c260364c65b0f8a08eeb5cdd37b480989218a91e083ac1203442785c1d19c16", "card_link": "https://www.tronalddump.io/", "card_title": "www.tronalddump.io", "card_theme": null, "card_type": "image", "card_description": "Tronald Dump", "icon_img_src": "https://www.tronalddump.io/img/favicon.ico", "link_img_src": null, "added_at": "2023-05-31T11:00:54.128320", "updated_at": "2023-07-07T20:32:30.881869"}, 
    {"card_xid": "5c36fe9a8c921a86c515047270ffab2a591d21931bd721af610042762fbd2d78", "card_link": "https://valorant-api.com", "card_title": "valorant-api.com", "card_theme": null, "card_type": "image", "card_description": "Valorant-API", "icon_img_src": "https://valorant-api.com/assets/img/logo_128.png?v=1", "link_img_src": "https://valorant-api.com/assets/img/logo.png?v=1", "added_at": "2023-05-01T21:28:56.916715", "updated_at": "2023-07-07T20:32:30.890037"}, 
    {"card_xid": "118f23586284785df7b4e1cf977369a5705142888c5504b927e40be71e170f47", "card_link": "https://api.wfstats.cf", "card_title": "api.wfstats.cf", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-05T04:33:44.322594", "updated_at": "2023-07-07T20:32:30.903356"}, 
    {"card_xid": "f7adb0e52ad97faa014070d4e0433436ff49be3f3d001a3ed97483c44215eae2", "card_link": "https://developers.wargaming.net/", "card_title": "developers.wargaming.net", "card_theme": null, "card_type": "image", "card_description": "Developer Room", "icon_img_src": "https://developers.wargaming.net/static/1.14.0/assets/img/favicon.ico", "link_img_src": null, "added_at": "2023-05-07T15:28:55.736761", "updated_at": "2023-07-07T20:32:30.905110"}, 
    {"card_xid": "c34d42179390dbbfd67c42d3b3444ef8849d7c230c6352c1424dc1fc0eca806b", "card_link": "https://github.com/DiljotSG/MCU-Countdown/blob/develop/docs/API.md", "card_title": "github.com", "card_theme": null, "card_type": "image", "card_description": "MCU-Countdown/docs/API.md at develop \u00b7 DiljotSG/MCU-Countdown \u00b7 GitHub", "icon_img_src": "https://github.githubassets.com/favicons/favicon.svg", "link_img_src": "https://repository-images.githubusercontent.com/259080904/e4758c00-8be2-11ea-9041-dd540795212c", "added_at": "2023-04-30T14:52:48.381096", "updated_at": "2023-07-07T20:32:30.960765"}, 
    {"card_xid": "1e481a629ea0c1f6fb8da4075fcfe5746e34bfff3fd37505e2055607296fdc8d", "card_link": "https://xkcd.com/json.html", "card_title": "xkcd.com", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://xkcd.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-12T11:42:58.119076", "updated_at": "2023-07-07T20:32:31.066687"}, 
    {"card_xid": "9ec1d4e3e462d712e3065c8fb0ea2ac35c0d0b59922c884988c6288ad3e6d6ea", "card_link": "https://db.ygoprodeck.com/api-guide/", "card_title": "db.ygoprodeck.com", "card_theme": null, "card_type": "image", "card_description": "Yu-Gi-Oh! API Guide - YGOPRODeck", "icon_img_src": "https://images.ygoprodeck.com/images/assets/cropped-applogo_high-1-192x192.png", "link_img_src": "https://images.ygoprodeck.com/images/assets/OpenGraph_Summary.png", "added_at": "2023-06-14T09:34:02.265171", "updated_at": "2023-07-07T20:32:31.069734"}, 
]
const profile = {
    xid: 'AD55_5673_BF34',
    name_key: 'api_games_comics',
    theme_color: '#a921f0', 
    title: 'API: Games & Comics',
    img_src: 'https://img.freepik.com/free-vector/gaming-disorder-abstract-concept-vector-illustration-video-game-addict-decreased-attention-span-gaming-addiction-behavioral-disorder-mental-health-medical-condition-abstract-metaphor_335657-2264.jpg?w=1060&t=st=1687440773~exp=1687441373~hmac=419ab45ce31fbe996a4f645a6003f08cf0bbad9c888ceaf23845576036ff3ab1',
    description: [
        "APIs for games and comics",
    ],
}
const author = {
    key: 'api_store',
    name: 'All APIs',
    img_src: 'https://img.freepik.com/free-vector/application-programming-interface-concept-illustration_114360-9135.jpg?w=1060&t=st=1687440423~exp=1687441023~hmac=12830d60883b3aac174f89be583e4fec8d45286c3dfe6b3f6988f8288e0d6ce1',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
