import React from 'react';
// import Slide from 'react-reveal/Slide';
import {Parallax} from 'react-scroll-parallax';

import {Title} from 'component/global/title';


  const feature_manage = {
    "title": "Manage bookmarks", 
    "description": "All-in-one bookmark manager", 
    "points": [
      "Manage your links",
      "Build collections",
      "Highlight your bio link page",
    ], 
    "img": {"src": "/static/images/features/collection.png", "alt": "", "size": "lg"}
  }
  const feature_kb = {
    "title": "Build knowledge base", 
    "description": "Enable visitors to enrich your collections", 
    "points": [
      "Build public or private collections",
      "Discover publicly shared collections",
      "Get suggestions based on your collections",
    ], 
    "img": {"src": "/static/images/features/discover.png", "alt": "", "size": "md"}
  }
  const feature_owe = {
    "title": "Easy organization", 
    "description": "Keep your favorite links untangled", 
    "points": [
      "Duplicate notifications, redirect management",
      "Notifications on page error",
      "Import and export all your data",
    ], 
    "img": {"src": "/static/images/features/controls.png", "alt": "", "size": "md"}
  }
  const feature_company = {
    "title": "Built for teams", 
    "description": "Extension of your team's knowledge base", 
    "points": [
      "Hierarchical dashboards for company level overview",
      "Advanced analytics",
      "Batch operation support",
    ], 
    "img": {"src": "/static/images/features/collection_mobile.png", "alt": "", "size": "sm"}
  }
  const feature_analytics = {
    "title": "See the full picture", 
    "description": "Information at one place", 
    "points": [
      "Analytics on collections",
      "Subscription support and analysis tools",
      "Notifications on ",
    ], 
    "img": {"src": "/static/images/features/user_landing.png", "alt": "", "size": "lg"}
  }


const CONTENT = {
  'default': [feature_manage, feature_kb, feature_owe, ],
  'personal': [feature_manage, feature_kb, feature_owe, ],
  'creator': [feature_manage, feature_kb, feature_analytics, ],
  'enterprise': [feature_kb, feature_analytics, feature_company, ],
}


export default class Features extends React.Component {
  render (){
    const profile_key = this.props.profile_key || 'default';

    const _features = CONTENT[profile_key];

    return (
      <section id="subscribe" className="content-section flex-center ">
        <div className="container-fluid container" >
          {/* <SectionTitle first="What" second="you get" background="primary" /> */}

          {_features.map(function(feature, idx){
            return(
              <FeatureSection key={""+idx} item={feature} flip={(idx%2 === 0)}/>
            )
          })}
          
        </div>
      </section>
    )
  }
}


class FeatureSection extends React.Component {
  render (){
    const is_mobile = window.innerWidth < 1200;
    const {item, flip } = this.props;

    const img_width = {
      "sm": 4,
      "md": 6,
      "lg": 8,
    }[item.img.size]

    return (
      // <div className="my-5 py-1">
      <div className="row  rounded ">

      {(flip)? '': <FeatureImg item={item} img_width={img_width} flip={flip} />}
          {/* <Slide bottom> */}
        <div className={" d-flex align-items-center col-md-" + (12 - img_width) + ((is_mobile && flip)? ' order-2 my-3': ' my-5')}>
          <div className="flex-left black-text-1 mx-2">
            <h6 className=" primary-text-1 text-wide-1 text-size-8 " style={{    fontFamily: 'Caveat'}}>{item.title}</h6>
            {/* <Title type="section" className="" >{item.title}</Title> */}
            <div className="black-text-1 text-size-12 my-3">{item.description}</div>
            <ul className="my-3 black-text-4">
              {item.points.map(function(point, idx){
                return(
                  <li key={"_"+idx} className="checkmark mx-3 px-3 text-size-3 my-1">{point}</li>
                )
              })}
            </ul>
          </div>
        </div>
          {/* </Slide> */}
        {(flip)? <FeatureImg item={item} img_width={img_width} flip={flip} />: ""}

      </div>
      
      // </div>
    )
  }
}

function FeatureImg(props){
  const is_mobile = window.innerWidth < 1200;
  const {item, img_width, flip} = props;

  const deg = Math.floor((Math.random() * 360) + 1);
  const c1 = 'hsl('+deg+', 78%, 91%)';
  const c2 = 'hsl('+deg+', 78%, 98%)';
  const gradient_string = 'linear-gradient('+deg+'deg, '+c1+' 0%, '+c2+' 100%)';

  return(
    <div 
      className={"dark-highlight-1  align-self-center col-md-"+ (img_width) + ((is_mobile && flip)? ' order-1 my-3': ' my-5')}
      style={{borderRadius: '2em', backgroundImage: gradient_string, backdropFilter: 'blur(25px)',}}
    >
      <Parallax
        scale={[0.9, 1.0]}
      >
      {/* <Slide left={!flip} right={flip}> */}
        <img className={"scroll rounded img img-fluid rounded m-3" + (is_mobile? ' mx-auto d-block': ' ')} src={item.img.src} alt={item.img.alt} />
      {/* </Slide> */}
      </Parallax>
    </div>
  )
}