import React from "react";

import LightSpeed from 'react-reveal/LightSpeed';
import Pulse from 'react-reveal/Pulse';

import {SectionCTA as SubscribeSectionCTA} from 'component/content/subscribe';
import {detectDeviceType} from 'utils/tracker';


export default class HeroSection extends React.Component {

  render (){
    const CONTENT = {
      'default': {
        // One place for all your links
        // Organize bookmarks, share your knowledge base,  build your audience
        title: [
          [{style:{}, class: 'black-text-1 ', text: "Organize bookmarks, ",}, ], 
          [{style:{}, class: 'primary-text-1', text: "Share collections, ",}, ], 
          [{style:{}, class: 'black-text-1 ', text: "Grow your audience!",},],
        ],
        // subtitle: "It's free, and takes less than a minute",
        subtitle: [
          [
            {'class': 'text-size-6 black-text-1', "text": "Organize links ", }, 
            {'class': "text-size-4 black-text-6", "text": " that help you manage your slice of the web!", }, 
          ], [
            {'class': "text-size-4 black-text-6", "text": " Build and ", }, 
            {'class': "text-size-6 black-text-1", "text": " share collections ", }, 
            {'class': "text-size-4 black-text-6", "text": " to ", }, 
            {'class': 'text-size-6 black-text-1', "text": " engage your audience", }, 
          ],
        ],
        image: {
          path: '/static/images/hero.svg',
          alt: '"Try it free. No credit card required. Instant setup."',
        },
      },
      'personal': {
        title: [
          [{style:{}, class: 'primary-bg-1 white-text-1 p-2 rounded  ', text: "Smart ",}, ], 
          [{style:{}, class: 'black-text-1', text: "Bookmark Manager",}, ], 
        ],
        subtitle: [
          [
            {'class': 'text-size-6 black-text-1', "text": "Organize links ", }, 
            {'class': "text-size-4 black-text-6", "text": " that help you manage your slice of the web!", }, 
          ],[
            {'class': 'text-size-6 black-text-1', "text": "Create collections  ", }, 
            {'class': "text-size-4 black-text-6", "text": " and get ", }, 
            {'class': 'text-size-6 black-text-1', "text": " notified on broken links", }, 
          ],[
            {'class': 'text-size-6 black-text-1', "text": "Add notes  ", }, 
            {'class': "text-size-4 black-text-6", "text": " to your bookmarks and easily ", }, 
            {'class': 'text-size-6 black-text-1', "text": " search your content", }, 
          ],
        ],
        image: {
          path: '/static/images/splits/personal.svg',
          alt: '"Try it free. No credit card required. Instant setup."',
        },
      },
      'creator': {
        title: [
          [
            {style:{}, class: 'primary-bg-1 white-text-1 p-2 rounded ', text: "Grow",}, 
            {style:{}, class: 'black-text-1', text: " your audience,",}, 
          ], [
            {style:{}, class: 'black-text-1 ', text: " by ",}, 
            {style:{}, class: 'primary-text-1 ', text: " managing bookmarks",}, 
            {style:{}, class: 'black-text-1 ', text: " !",}, 
          ], 
        ],
        subtitle: [
          [
            {'class': 'text-size-6 black-text-1', "text": "Organize links ", }, 
            {'class': "text-size-4 black-text-6", "text": " and ", }, 
            {'class': "text-size-6 black-text-1", "text": " get suggestions ", }, 
            {'class': "text-size-4 black-text-6", "text": " about similar topics!", }, 
          ],[
            {'class': 'text-size-6 black-text-1', "text": "Create collections ", }, 
            {'class': "text-size-4 black-text-6", "text": " and let your ", }, 
            {'class': 'text-size-6 black-text-1', "text": " visitiors subsribe ", }, 
            {'class': "text-size-4 black-text-6", "text": " to increase visibility", }, 
          ],[
            {'class': "text-size-4 black-text-6", "text": " Add", }, 
            {'class': 'text-size-6 black-text-1', "text": " sync'd sources ", }, 
            {'class': "text-size-4 black-text-6", "text": " to monitor new links to add to your collections", }, 
          ],
        ],
        image: {
          path: '/static/images/splits/creator.svg',
          alt: '"Try it free. No credit card required. Instant setup."',
        },
      },
      'enterprise': {
        title: [
          [
            {style:{}, class: 'black-text-1 ', text: "Automate building ",}, 
            {style:{}, class: ' primary-bg-1 white-text-1 p-2 rounded ', text: "team's ",}, 
          ], [
            {style:{}, class: ' primary-text-1', text: "knowledge base",}, 
          ], 
        ],
        subtitle: [
          [
            {'class': "text-size-6 black-text-1", "text": " Create collections  ", }, 
            {'class': 'text-size-4 black-text-6', "text": " and  ", }, 
            {'class': 'text-size-6 black-text-1', "text": " update team", }, 
            {'class': 'text-size-4 black-text-6', "text": " with company news", }, 
          ],[
            {'class': 'text-size-6 black-text-1', "text": "View dashboards ", }, 
            {'class': "text-size-4 black-text-6", "text": " to see how ", }, 
            {'class': 'text-size-6 black-text-1', "text": " engaged your team ", }, 
            {'class': "text-size-4 black-text-6", "text": " is ", }, 
          ],[
            {'class': 'text-size-6 black-text-1', "text": " Compile content  ", }, 
            {'class': "text-size-4 black-text-6", "text": " that can be shared by your team", }, 
          ],
        ],
        image: {
          path: '/static/images/splits/enterprise.svg',
          alt: '"Try it free. No credit card required. Instant setup."',
        },
      },
    }

    const profile_key = this.props.profile_key || 'default';

    const content = CONTENT[profile_key];
    return (
      // <section className="  " style={{backgroundImage: "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)" }}>
      <div className=""> 
      {/* // DO NOT REMOVE: the wrapping div */}
        <HeroTitleSubFeaturesImageComponent hero={content} />
      </div>
      // </section>
    )
  }
}

function HeroTitleSubFeaturesImageComponent(props){
  const hero = props.hero;

  const is_mobile = window.innerWidth < 1200;
  // const deviceType = detectDeviceType();

  const constructed_title = hero.title.map(function(line, idx){
    return (
      <div className="my-3" key={"" + idx}>
        {line.map(function(subline, sidx){
          return (<span key={''+sidx} className={" " + subline.class} style={subline.style} >{subline.text}</span>)
        })}
      </div>
    )
  })

  return (
    <div className={"  " + ((is_mobile)? "  ": "  ")} style={{height: '100%'}}>

      <div className="py-5">

          <h1 className={" text-center text-left  " + ((is_mobile)? " text-size-10  my-3 ": " text-size-15 mt-1 mb-3 pb-3 px-5 ")}>{constructed_title}</h1>

      <div className={"row " + ((is_mobile)? "my-3": " ")}>
        
        <div className={" col-md-6 col-sm-12 p-0 img img-fluid text-center align-self-center  px-0 " + ((is_mobile)? '  ': ' ')}>
          <Pulse>
            <img className="hero" src={hero.image.path} alt={hero.image.alt} />
          </Pulse>
        </div> 
        
        <div className={" col-md-6 col-sm-12 text-center align-self-center " + ((is_mobile)? "  ": " pl-5 ")}>

          <div className={((is_mobile)? " py-1 my-5 ": " my-5 ")}>
            <LightSpeed left>
              <SubscribeSectionCTA category="early_access"  />
            </LightSpeed>
          </div>

          <div className={" my-3  p-1  " + ((is_mobile)? " text-center ": " text-left my-5 ")}>
            {hero.subtitle.map(function(sub, idx){
              return(<p key={"_"+idx} className=" m-0 p-0 my-3 ">
                {sub.map(function(sub_section, sidx){
                  return(<span key={"_"+sidx} className={" "+sub_section.class}>{sub_section.text}</span>)
                })}
              </p>)
            })}
          </div>

        </div> 

      </div>
      </div>


    </div>
  )
}