const cards = [
    {"card_xid": "bef31a6eb384b56c9c20f4283fc294decda9e3c7b3b1081b2d13c4e97f4b1547", "card_link": "https://www.deepswap.ai/", "card_title": "www.deepswap.ai", "card_theme": null, "card_type": "image", "card_description": "Deepswap - AI Face Swap Online App for Video, Photo & GIF", "icon_img_src": "https://www.deepswap.ai/favicon.ico", "link_img_src": "https://www.faceplay.me/static/main/_next/static/media/share_logo.98e3d05d.png", "added_at": "2023-05-27T22:06:41.321917", "updated_at": "2023-07-07T20:33:20.085805"}, 
    {"card_xid": "f8716515aabddfe59e72ee958a22c7bb8fcff1e59f01881edf63d71e0956f402", "card_link": "https://www.gnoosic.com/", "card_title": "www.gnoosic.com", "card_theme": null, "card_type": "image", "card_description": "Gnoosic - Discover new Music", "icon_img_src": "https://cdn.gnoosic.com/elements/objects/og_logo.png", "link_img_src": "https://www.gnoosic.com/elements/objects/og_logo.png", "added_at": "2023-06-14T00:43:35.642715", "updated_at": "2023-07-07T20:33:20.127061"}, 
    {"card_xid": "cf7f8e756d3624862378f1458083ef7563fd57dcc09259cf52fbdea7e473682b", "card_link": "https://chat.openai.com/cha", "card_title": "chat.openai.com", "card_theme": null, "card_type": "image", "card_description": "404: This page could not be found", "icon_img_src": "https://chat.openai.com/apple-touch-icon.png", "link_img_src": "https://chat.openai.com/images/chatgpt-share-og.png", "added_at": "2023-06-14T17:49:22.539347", "updated_at": "2023-07-07T20:33:20.132315"}, 
    {"card_xid": "dc4734a5c20462d9775dc7eb78c9f3a9a6e75461e0fc29cb908b0fcd7b4d5c36", "card_link": "https://www.patatap.com/", "card_title": "www.patatap.com", "card_theme": null, "card_type": "image", "card_description": "Patatap", "icon_img_src": "https://www.patatap.com/images/apple-touch-icon.png", "link_img_src": "http://www.patatap.com/images/thumbnail.png", "added_at": "2023-04-12T05:15:17.409976", "updated_at": "2023-07-07T20:33:20.135453"}, 
    {"card_xid": "2cdc628f3fb3db49a8f08c9245842ddf63462b52660927a9a398c3d84b736b6a", "card_link": "https://notalwaysright.com/", "card_title": "notalwaysright.com", "card_theme": null, "card_type": "image", "card_description": "Trending - Funny & True Stories | NotAlwaysRight.com ", "icon_img_src": "https://notalwaysright.com/favicon_nar.png", "link_img_src": "https://149366108.v2.pressablecdn.com/wp-content/uploads/2019/08/nar-social-media-logo.jpg", "added_at": "2023-05-23T07:28:30.527627", "updated_at": "2023-07-07T20:33:20.143563"}, 
    {"card_xid": "a5bb4d1a5298e798b6d67a11a7d16ad1c5108fe0c92e8641f765ef9118844724", "card_link": "http://www.flashbynight.com/drench/", "card_title": "www.flashbynight.com", "card_theme": null, "card_type": "image", "card_description": "Drench - The World's Simplest Web Game", "icon_img_src": "", "link_img_src": "http://flashbynight.com/promo/drench200.png", "added_at": "2023-04-11T11:52:52.140278", "updated_at": "2023-07-07T20:33:20.248050"}, 
    {"card_xid": "313eca641e4d0841cc7bfd374ffdd95b08d33661b4393b72606872410e0068fd", "card_link": "https://riverstyx.com/", "card_title": "riverstyx.com", "card_theme": null, "card_type": "image", "card_description": "RiverStyx", "icon_img_src": "https://riverstyx.com/favicon.ico", "link_img_src": "https://riverstyx.com/wp-content/uploads/2019/07/logo.png", "added_at": "2023-06-18T12:05:45.791022", "updated_at": "2023-07-07T20:33:20.253807"}, 
    {"card_xid": "02eb10cb45929d63bf8a0df2616ab230c0c31c334f50ddd781d1ffbfbf739187", "card_link": "http://www.noiys.com/", "card_title": "www.noiys.com", "card_theme": null, "card_type": "image", "card_description": "NOIYS - Post, read, forget", "icon_img_src": "http://www.noiys.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-02T11:52:16.485515", "updated_at": "2023-07-07T20:33:20.263460"}, 
    {"card_xid": "7228ba099d8facf3aa40fe887e5715de225802b53831a8232cd6f682b832f29c", "card_link": "https://www.coolthings.com/", "card_title": "www.coolthings.com", "card_theme": null, "card_type": "image", "card_description": "CoolThings.com | Cool Stuff, Cool Gadgets, Cool Gifts & Things", "icon_img_src": "https://netdna.coolthings.com/wp-content/uploads/2014/12/cropped-coolthings-favicon-512-180x180.png", "link_img_src": null, "added_at": "2023-06-18T18:02:49.004138", "updated_at": "2023-07-07T20:33:20.270311"}, 
    {"card_xid": "04215096bf1a3b68f320bd84b5ec4e7498ac384fcf067a8e6d77f14bc961d735", "card_link": "https://postsecret.com/", "card_title": "postsecret.com", "card_theme": null, "card_type": "image", "card_description": "PostSecret -", "icon_img_src": "https://i0.wp.com/postsecret.com/wp-content/uploads/2020/08/ps_favicon.jpg?fit=85%2C85&ssl=1", "link_img_src": null, "added_at": "2023-04-15T17:18:47.497338", "updated_at": "2023-07-07T20:33:20.302800"}, 
    {"card_xid": "324ced55a6f7a9b9a8aaf0b8e9b19336177801beb7328ba7356862c0b9854377", "card_link": "https://littlealchemy2.com/", "card_title": "littlealchemy2.com", "card_theme": null, "card_type": "image", "card_description": "Little Alchemy 2", "icon_img_src": "https://littlealchemy2.com/static/img/icon-60@2x.png", "link_img_src": "https://littlealchemy2.com/static/img/little-alchemy-2-fb-thumbnail.jpg", "added_at": "2023-06-02T05:19:58.074385", "updated_at": "2023-07-07T20:33:20.356094"}, 
    {"card_xid": "fc52b2ee48dc99ddf7a839ac1b7ae1d20ce7cdddc16a0ee0bbef297efb91b948", "card_link": "http://www.vsauce.com/#/", "card_title": "www.vsauce.com", "card_theme": null, "card_type": "image", "card_description": "\n      Vsauce\n    ", "icon_img_src": "http://www.vsauce.com/apple-touch-icon-60x60.png", "link_img_src": null, "added_at": "2023-04-12T20:29:32.094339", "updated_at": "2023-07-07T20:33:20.367359"}, 
    {"card_xid": "ee4ef573229f3623cce99f11709b118d43768f5efc4379f86d7519f0d0f5ef89", "card_link": "https://agoodmovietowatch.com/", "card_title": "agoodmovietowatch.com", "card_theme": null, "card_type": "image", "card_description": "What To Watch On Streaming - A Good Movie To Watch", "icon_img_src": "https://agoodmovietowatch.com/favicon-apple.pmg", "link_img_src": "https://agoodmovietowatch.com/assets/agmtw_logo.png", "added_at": "2023-04-12T12:41:52.189366", "updated_at": "2023-07-07T20:33:20.383766"}, 
    {"card_xid": "d4a1e6021cb7bccfb8813906b92dfab11abe085bf160991ed402e5788ae4a103", "card_link": "https://mubi.com/showing", "card_title": "mubi.com", "card_theme": null, "card_type": "image", "card_description": "Films Now Showing on MUBI", "icon_img_src": "https://mubi.com/apple-touch-icon.png?ver=2", "link_img_src": null, "added_at": "2023-05-03T18:21:41.023703", "updated_at": "2023-07-07T20:33:20.458518"}, 
    {"card_xid": "64c70026e9c57c7f0d116da6d099a516e60652fa4400af0f67aea9cec8d8cc12", "card_link": "https://www.themoth.org/", "card_title": "www.themoth.org", "card_theme": null, "card_type": "image", "card_description": "The Moth | The Art and Craft of Storytelling", "icon_img_src": "https://www.themoth.org/images/apple-touch-icon.png", "link_img_src": "https://s3.amazonaws.com/themoth-story-images/Alistair-Bane.png", "added_at": "2023-04-02T11:22:21.653283", "updated_at": "2023-07-07T20:33:20.567949"}, 
    {"card_xid": "8c48d746f5a80b3c50d850aba9606a878333fb835d9b49a7344de66427fe9455", "card_link": "http://www.dontevenreply.com/", "card_title": "www.dontevenreply.com", "card_theme": null, "card_type": "image", "card_description": "E-mails from an Asshole", "icon_img_src": "", "link_img_src": null, "added_at": "2023-06-14T15:45:42.239370", "updated_at": "2023-07-07T20:33:20.597999"}, 
    {"card_xid": "acb330e098ca121ce059253162e7418edc33249aff77824253742af1b372bae3", "card_link": "https://waitbutwhy.com/", "card_title": "waitbutwhy.com", "card_theme": null, "card_type": "image", "card_description": "Wait But Why", "icon_img_src": "https://149909199.v2.pressablecdn.com/wp-content/themes/waitbutwhy/images/favicon.ico", "link_img_src": null, "added_at": "2023-05-09T07:49:35.109314", "updated_at": "2023-07-07T20:33:20.644789"}, 
    {"card_xid": "f1813bf74b3d76de197c19eb791300c0c08e076fcfb4c8e1f931c5fbb59cba93", "card_link": "http://www.thisismywebsitenow.com/", "card_title": "www.thisismywebsitenow.com", "card_theme": null, "card_type": "image", "card_description": "This is My Website Now", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-24T15:14:52.080373", "updated_at": "2023-07-07T20:33:20.720683"}, 
    {"card_xid": "1098eab0e8cb62f4d2e9484f8d9e8ce23311c8f3071b9fefeec5e6a9c772d8a3", "card_link": "http://theoatmeal.com/", "card_title": "theoatmeal.com", "card_theme": null, "card_type": "image", "card_description": "The Oatmeal - Comics by Matthew Inman ", "icon_img_src": "https://s3.amazonaws.com/theoatmeal-img/favicons/apple-icon-57x57.png", "link_img_src": null, "added_at": "2023-06-02T22:50:43.957701", "updated_at": "2023-07-07T20:33:20.724093"}, 
    {"card_xid": "8cb09c4cb8d382163b5023783db9d0939414101454d3f8b218c9502db31966d9", "card_link": "http://www.theonion.com/", "card_title": "www.theonion.com", "card_theme": null, "card_type": "image", "card_description": "The Onion | America's Finest News Source.", "icon_img_src": "https://i.kinja-img.com/gawker-media/image/upload/c_fill,f_auto,fl_progressive,g_center,h_200,q_80,w_200/eti2h1r4wg0bqxctxenl.png", "link_img_src": "https://i.kinja-img.com/gawker-media/image/upload/c_fill,f_auto,fl_progressive,g_center,h_200,q_80,w_200/eti2h1r4wg0bqxctxenl.png", "added_at": "2023-04-11T14:37:37.327593", "updated_at": "2023-07-07T20:33:20.734076"}, 
    {"card_xid": "d4f905e687a21f965b6a7ce87c5aeef6dbf33fe0f63c0f0bb17998488e29828a", "card_link": "https://giphy.com/", "card_title": "giphy.com", "card_theme": null, "card_type": "image", "card_description": "GIPHY - Be Animated", "icon_img_src": "https://giphy.com/static/img/icons/apple-touch-icon-167px.png", "link_img_src": "https://giphy.com/static/img/giphy-be-animated-logo.gif", "added_at": "2023-04-12T15:35:37.661528", "updated_at": "2023-07-07T20:33:20.804788"}, 
    {"card_xid": "d1f478b2c991998892c6023af71743a3e2ff71b1174a40c726d82947849863ce", "card_link": "https://iwastesomuchtime.com/", "card_title": "iwastesomuchtime.com", "card_theme": null, "card_type": "image", "card_description": "I Waste So Much Time", "icon_img_src": "https://www.iwastesomuchtime.com/wp-content/themes/iwsmt/assets/img/favicon.ico", "link_img_src": null, "added_at": "2023-06-10T20:22:47.429461", "updated_at": "2023-07-07T20:33:20.852721"}, 
    {"card_xid": "ddfe1c4690d91c0d834d1481c42a8b992709b3c6dea41023a929d36cc079f659", "card_link": "http://www.oddee.com/", "card_title": "www.oddee.com", "card_theme": null, "card_type": "image", "card_description": "Oddee - Odd News, Weird Stuff, Strange Things in Our World", "icon_img_src": "https://www.oddee.com/wp-content/uploads/2019/03/Oddee.jpg", "link_img_src": null, "added_at": "2023-06-14T15:54:09.127732", "updated_at": "2023-07-07T20:33:20.901898"}, 
    {"card_xid": "ddfae1792fe78d74a1cfc0018670dcb659b18adc3ac26aff8e3be769b9de06f7", "card_link": "http://www.zergnet.com/", "card_title": "www.zergnet.com", "card_theme": null, "card_type": "image", "card_description": "ZergNet - Discover Interesting Articles", "icon_img_src": "https://www.zergnet.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-26T19:13:56.184976", "updated_at": "2023-07-07T20:33:20.994718"}, 
    {"card_xid": "87e446b9427a9b84229720169e7fe5b83f9ebf76dea1cfcdebb99f7d2248207e", "card_link": "http://uncrate.com/", "card_title": "uncrate.com", "card_theme": null, "card_type": "image", "card_description": "Uncrate", "icon_img_src": "https://uncrate.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-04T11:04:35.428607", "updated_at": "2023-07-07T20:33:21.023842"}, 
    {"card_xid": "4360f952d1cf83af7faf23a36993ed55a487cb96f1ed3a45c892aa4c2f2552f9", "card_link": "https://www.thisiswhyimbroke.com/", "card_title": "www.thisiswhyimbroke.com", "card_theme": null, "card_type": "image", "card_description": "ThisIsWhyImBroke :: The Internet's Mall", "icon_img_src": "https://www.thisiswhyimbroke.com/favicon.ico", "link_img_src": null, "added_at": "2023-06-06T20:00:45.952915", "updated_at": "2023-07-07T20:33:21.165444"}, 
    {"card_xid": "bb8b14d2be50fcf643f81936bc8a7c2f9b5dc597f7bb5ac1c734a0022d33f6c6", "card_link": "http://forgotify.com/", "card_title": "forgotify.com", "card_theme": null, "card_type": "image", "card_description": "Forgotify | Discover a previously unheard Spotify track", "icon_img_src": "http://forgotify.com/img/forgotify-bug.png", "link_img_src": "http://forgotify.com/img/forgotify-logo-share.jpg", "added_at": "2023-04-26T19:50:58.194418", "updated_at": "2023-07-07T20:33:21.220353"}, 
    {"card_xid": "bb8b14d2be50fcf643f81936bc8a7c2f9b5dc597f7bb5ac1c734a0022d33f6c6", "card_link": "http://forgotify.com/", "card_title": "forgotify.com", "card_theme": null, "card_type": "image", "card_description": "Forgotify | Discover a previously unheard Spotify track", "icon_img_src": "http://forgotify.com/img/forgotify-bug.png", "link_img_src": "http://forgotify.com/img/forgotify-logo-share.jpg", "added_at": "2023-04-26T19:50:58.194418", "updated_at": "2023-07-07T20:33:21.228795"}, 
    {"card_xid": "6e88d41d6890190b941ba32adfc4cf68683fa89a8543938c132655edab6562c0", "card_link": "https://everynoise.com/", "card_title": "everynoise.com", "card_theme": null, "card_type": "image", "card_description": "Every Noise at Once", "icon_img_src": "https://everynoise.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-29T02:10:33.916794", "updated_at": "2023-07-07T20:33:21.237198"}, 
    {"card_xid": "03ca9c56cfcb7c67359492b81ca5d8437e72d52ccc2cadaad0ec06e6837bcaa3", "card_link": "https://openpuppies.com/", "card_title": "openpuppies.com", "card_theme": null, "card_type": "image", "card_description": "OpenPuppies", "icon_img_src": "", "link_img_src": "http://openpuppies.com/assets/openpuppies-og.jpg", "added_at": "2023-05-19T22:02:09.457025", "updated_at": "2023-07-07T20:33:22.380496"}, 
    {"card_xid": "ac2c0b7453bd916b3d5c12a13f559ce6f27bc6a5daedb4a2d2d410b5904fce8d", "card_link": "https://en.akinator.com/", "card_title": "en.akinator.com", "card_theme": null, "card_type": "image", "card_description": "    Akinator, the mind reading genie\n", "icon_img_src": "https://en.akinator.com/apple-touch-icon.png?v95", "link_img_src": null, "added_at": "2023-05-17T09:50:03.668267", "updated_at": "2023-07-07T20:33:22.384744"}, 
    {"card_xid": "8403941f3e727fa7c269efb8a3c0945d4bb6394efe3ee89fabc87707c2f7ac52", "card_link": "http://www.pixelthoughts.co/#", "card_title": "www.pixelthoughts.co", "card_theme": null, "card_type": "image", "card_description": "A 60-second meditation tool to help clear your mind", "icon_img_src": "http://www.pixelthoughts.co/static/images/favicon.ico", "link_img_src": null, "added_at": "2023-04-23T11:09:15.513726", "updated_at": "2023-07-07T20:33:22.414104"}, 
    {"card_xid": "5e1ce18d69dc9ce8101ffe099b92feab96d19414fefee19b5c90588d6f09ab8c", "card_link": "http://asoftmurmur.com/", "card_title": "asoftmurmur.com", "card_theme": null, "card_type": "image", "card_description": "A Soft Murmur", "icon_img_src": "https://asoftmurmur.com/assets/icons/apple-touch-icon.png", "link_img_src": "https://asoftmurmur.com/img/meta.jpg", "added_at": "2023-04-19T23:28:25.837569", "updated_at": "2023-07-07T20:33:22.422195"}, 
    {"card_xid": "ba71372ee7dbb79fab10af68e3bd54cfb721c78558a800f1b5bf885cd5527cc7", "card_link": "https://nandgame.com/", "card_title": "nandgame.com", "card_theme": null, "card_type": "image", "card_description": "NandGame - Build a computer from scratch.", "icon_img_src": "https://nandgame.com/favicon.png", "link_img_src": null, "added_at": "2023-06-02T09:11:29.479413", "updated_at": "2023-07-07T20:33:22.435309"}, 
    {"card_xid": "8e4792e40832b455ae621368cd44849e4cb5237aa50b9ed473cd8f37a5c0afb2", "card_link": "https://thispersondoesnotexist.com/", "card_title": "thispersondoesnotexist.com", "card_theme": null, "card_type": "image", "card_description": "thispersondoesnotexist.com (1024\u00d71024)", "icon_img_src": "https://thispersondoesnotexist.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-30T01:28:46.148036", "updated_at": "2023-07-07T20:33:22.450984"}, 
    {"card_xid": "573cecd93ca4a0de908fbb88607238fc6590d2ec6b9cad51bcca4e479c31d17f", "card_link": "https://thiscatdoesnotexist.com/", "card_title": "thiscatdoesnotexist.com", "card_theme": null, "card_type": "image", "card_description": "Privacy error", "icon_img_src": "", "link_img_src": null, "added_at": "2023-05-20T14:46:21.207443", "updated_at": "2023-07-07T20:33:22.452838"}, 
    {"card_xid": "ca43049490b807ae89792f954940d067747ec90fb2e724bb7071bc7cfcb5fd6d", "card_link": "https://ncase.me/trust/", "card_title": "ncase.me", "card_theme": null, "card_type": "image", "card_description": "The Evolution of Trust", "icon_img_src": "https://ncase.me/trust/favicon.png", "link_img_src": "http://ncase.me/trust/social/thumbnail.png", "added_at": "2023-04-04T13:26:12.034112", "updated_at": "2023-07-07T20:33:22.464068"}, 
    {"card_xid": "ebd5236ed1015ba8499888296b41bdb500ed03b4872496ef39a449303cd3c639", "card_link": "http://stars.chromeexperiments.com/", "card_title": "stars.chromeexperiments.com", "card_theme": null, "card_type": "image", "card_description": "100,000 Stars", "icon_img_src": "http://stars.chromeexperiments.com/favicon.ico", "link_img_src": null, "added_at": "2023-05-14T13:43:31.043456", "updated_at": "2023-07-07T20:33:22.473398"}, 
    {"card_xid": "a3fee8b1539786c8a9da2d300b472685a622084d2cc27039786d155c96247b1d", "card_link": "http://www.smithsonianmag.com/", "card_title": "www.smithsonianmag.com", "card_theme": null, "card_type": "image", "card_description": "History, Travel, Arts, Science, People, Places Smithsonian Magazine", "icon_img_src": "https://www.smithsonianmag.com/static/smithsonianmag/img/Smithsonianmagazine_apple_touch_icon.png", "link_img_src": null, "added_at": "2023-05-15T07:33:14.945486", "updated_at": "2023-07-07T20:33:22.486837"}, 
    {"card_xid": "52456198d0ae67db3f7a61a9517bcd2c073fda9d4bb31228683bf5d905a11548", "card_link": "http://the-toast.net/", "card_title": "the-toast.net", "card_theme": null, "card_type": "image", "card_description": null, "icon_img_src": "https://thetoast.wpenginepowered.com/wp-content/themes/toaster/ico/apple-touch-icon-144x144.png", "link_img_src": null, "added_at": "2023-05-27T13:00:10.571328", "updated_at": "2023-07-07T20:33:22.554688"}, 
    {"card_xid": "20c181c3ed975c9f6d93f3cea4136d2945f623f068ed6d641fa4c5129770d97e", "card_link": "http://www.howstuffworks.com/", "card_title": "www.howstuffworks.com", "card_theme": null, "card_type": "image", "card_description": "HowStuffWorks - Learn How Everything Works!", "icon_img_src": "https://cdn.hswstatic.com/en-us/hsw/img/icons/apple-touch.png", "link_img_src": "https://cdn.hswstatic.com/gif/hsw-logo-120x90.jpg", "added_at": "2023-06-06T16:43:29.120044", "updated_at": "2023-07-07T20:33:22.594119"}, 
    {"card_xid": "fa7c8e5a22eedaf1609e76f5fc1233f5fee3da89e6b9578ba5854bf5381ad3e8", "card_link": "http://www.wikihow.com/Main-Page", "card_title": "www.wikihow.com", "card_theme": null, "card_type": "image", "card_description": "wikiHow: How-to instructions you can trust.", "icon_img_src": "https://www.wikihow.com/skins/WikiHow/wH-initials_152x152.png", "link_img_src": null, "added_at": "2023-06-08T05:43:52.662398", "updated_at": "2023-07-07T20:33:22.656518"}, 
    {"card_xid": "4d31799e893bec2b166317b8ccb5916b6de09428d781832d2796d807567d77e5", "card_link": "http://www.ancient.eu/", "card_title": "www.ancient.eu", "card_theme": null, "card_type": "image", "card_description": "World History Encyclopedia", "icon_img_src": "https://www.worldhistory.org/template/images/whe-favicon-152px.png", "link_img_src": "https://www.worldhistory.org/images/ahe-og-preview.jpg", "added_at": "2023-05-17T07:07:19.932219", "updated_at": "2023-07-07T20:33:22.812633"}, 
    {"card_xid": "585aa1573ccbc0bba8983e2a034cc21e316015ede940f77409981a073e6bf81a", "card_link": "https://www.justwatch.com/", "card_title": "www.justwatch.com", "card_theme": null, "card_type": "image", "card_description": "JustWatch - The Streaming Guide", "icon_img_src": "https://www.justwatch.com/appassets/favicon.ico", "link_img_src": "https://www.justwatch.com/appassets/img/JustWatch_logo_with_claim.png", "added_at": "2023-05-04T05:50:39.947011", "updated_at": "2023-07-07T20:33:22.848591"}, 
    {"card_xid": "bd23ad32870eb7fd2c3d1d17db1385c5d9759ffac62c33c8b0b7b7296ced47c9", "card_link": "https://www.thisamericanlife.org/", "card_title": "www.thisamericanlife.org", "card_theme": null, "card_type": "image", "card_description": "This American Life", "icon_img_src": "https://www.thisamericanlife.org/sites/all/themes/thislife/favicons/apple-touch-icon.png?v=2", "link_img_src": "https://www.thisamericanlife.org/sites/all/themes/thislife/img/social-blue.png", "added_at": "2023-04-02T03:16:32.320740", "updated_at": "2023-07-07T20:33:22.914368"}, 
    {"card_xid": "2070e7eeca148381105ba63751b64306263b2054c2500781049081b83ab151fc", "card_link": "http://nautil.us/", "card_title": "nautil.us", "card_theme": null, "card_type": "image", "card_description": "Nautilus | Science Connected", "icon_img_src": "https://nautil.us/wp-content/themes/nautilus-block-theme/images/favicon.png", "link_img_src": "https://assets.nautil.us/sites/3/nautilus/nautilus-vert-large-H-RGB_twitter.png?auto=compress&fm=png&ixlib=php-3.3.1", "added_at": "2023-04-23T02:41:50.981362", "updated_at": "2023-07-07T20:33:22.943838"}, 
    {"card_xid": "1a46305906a76831cb53ab8bdd9aa0d21c30223a09fe25a99d637adfb1bcd0c8", "card_link": "http://bigthink.com/", "card_title": "bigthink.com", "card_theme": null, "card_type": "image", "card_description": "Big Think - Smarter, Faster", "icon_img_src": "https://bigthink.com/wp-content/uploads/2023/06/cropped-bt-icon-512x512-1-1.png?w=180", "link_img_src": "https://bigthink.com/wp-content/uploads/2021/09/BigThink_Logo_Black.jpg?resize=1200,630", "added_at": "2023-04-18T18:10:48.115503", "updated_at": "2023-07-07T20:33:23.032197"}, 
    {"card_xid": "459c182800fbc34643c6ba426e599cf3acc5a2c99a2f6f856ce9faf82b55846b", "card_link": "https://www.hotspot3d.com/#compare/samsung-galaxy-s20-black/apple-iphone-11-purple", "card_title": "www.hotspot3d.com", "card_theme": null, "card_type": "image", "card_description": "Hotspot 3D", "icon_img_src": "https://www.hotspot3d.com/apple-touch-icon.png", "link_img_src": "https://hotspot3d.blob.core.windows.net/catalogs/XhdtfMTm/esm83yoo/social.jpg", "added_at": "2023-05-17T18:21:26.870459", "updated_at": "2023-07-07T20:33:23.109904"}, 
    {"card_xid": "40368fb1d6f44127ee844ff31ac0dd886ab15dd59f2476620c3b53c10eed1803", "card_link": "https://orb.farm/", "card_title": "orb.farm", "card_theme": null, "card_type": "image", "card_description": "Orb.Farm", "icon_img_src": "https://orb.farm/assets/favicon.ico", "link_img_src": "http://orb.farm/assets/thumbnail.png", "added_at": "2023-04-05T14:23:03.135589", "updated_at": "2023-07-07T20:33:23.117981"}, 
    {"card_xid": "6cf49d12d57510da0812626d4b41cb41709974ab7aeb81629317564bfbbc45df", "card_link": "https://www.apartmenttherapy.com/", "card_title": "www.apartmenttherapy.com", "card_theme": null, "card_type": "image", "card_description": "Apartment Therapy | Saving the world, one room at a time", "icon_img_src": "https://www.apartmenttherapy.com/static/icons/apple-touch-icon.png", "link_img_src": "https://cdn.apartmenttherapy.info/image/fetch/f_auto,q_auto:eco,c_fill,g_auto,w_1500,ar_3:2/https%3A%2F%2Fs3.amazonaws.com%2Fpixtruder%2Foriginal_images%2Fb27c3c4c25f0038644ed1615071b00d52bf2025f", "added_at": "2023-05-20T03:00:08.761967", "updated_at": "2023-07-07T20:33:23.126373"}, 
    {"card_xid": "bda1d2959d39e06d6d7c002cb7e76bb513ca1c70f521b1d43fcb6fa382da0156", "card_link": "https://www.supercook.com/#/recipes", "card_title": "www.supercook.com", "card_theme": null, "card_type": "image", "card_description": "SuperCook - Zero Waste Recipe Generator", "icon_img_src": "https://www.supercook.com/favicon.ico", "link_img_src": "https://www.supercook.com/images/002.3cc1abc7.png", "added_at": "2023-05-21T19:08:41.874723", "updated_at": "2023-07-07T20:33:23.186749"}, 
    {"card_xid": "0be1acca9dc5ca95d1d5ff3aae681d70a530ded3b51094eb3273f39c766f715b", "card_link": "https://weirdorconfusing.com/", "card_title": "weirdorconfusing.com", "card_theme": null, "card_type": "image", "card_description": "Weird or Confusing", "icon_img_src": "", "link_img_src": "https://weirdorconfusing.com/assets/icons/share.png", "added_at": "2023-05-18T22:28:56.231121", "updated_at": "2023-07-07T20:33:23.200956"}, 
    {"card_xid": "d9fcba2d416b1d32eb40a985d19b709b9603a3ed67666c1949e93dc90cfda9d2", "card_link": "https://www.ocearch.org/tracker/?list", "card_title": "www.ocearch.org", "card_theme": null, "card_type": "image", "card_description": "OCEARCH Shark Tracker", "icon_img_src": "https://www.ocearch.org/tracker/assets/favicon/apple-touch-icon.png", "link_img_src": "https://www.ocearch.org/tracker/assets/images/og/ocearch_shark2.jpg", "added_at": "2023-04-05T14:19:48.954813", "updated_at": "2023-07-07T20:33:23.225353"}, 
    {"card_xid": "5055b268edb6b6c41c94832f08d242dcf80f2d8e9f5cc3a2a77796a0fa27c56d", "card_link": "https://www.lego.com/en-us/kids", "card_title": "www.lego.com", "card_theme": null, "card_type": "image", "card_description": "LEGO.com for kids", "icon_img_src": "https://www.lego.com/kids/static/favicon.png", "link_img_src": "https://www.lego.com/kids/static/images/sharing/lego-kids.jpg", "added_at": "2023-04-24T02:47:50.251091", "updated_at": "2023-07-07T20:33:23.240309"}, 
    {"card_xid": "0f63a2a5a5620b745938e6a248b49704e580e3c8d9d24a4fb7ec68461f1edd41", "card_link": "https://news.ycombinator.com/", "card_title": "news.ycombinator.com", "card_theme": null, "card_type": "image", "card_description": "Hacker News", "icon_img_src": "https://news.ycombinator.com/favicon.ico", "link_img_src": null, "added_at": "2023-04-06T19:58:31.003842", "updated_at": "2023-07-07T20:33:23.362623"}, 
    {"card_xid": "bf9594a58f86cc545676f806df3a3ddb66cc2b20963d9831613e556a4b11d28a", "card_link": "https://www.producthunt.com/", "card_title": "www.producthunt.com", "card_theme": null, "card_type": "image", "card_description": " Product Hunt \u2013 The best new products in tech. ", "icon_img_src": "https://ph-static.imgix.net/ph-ios-icon.png?format=auto&compress=auto", "link_img_src": "https://ph-static.imgix.net/ph-logo-1.png", "added_at": "2023-04-16T21:55:13.483058", "updated_at": "2023-07-07T20:33:23.402006"}, 
    {"card_xid": "a7b59a97a5b918f32b7024f5a311ea39b8ab2a9c98e2398fe6750472f05d9534", "card_link": "https://keepa.com/", "card_title": "keepa.com", "card_theme": null, "card_type": "image", "card_description": "Keepa - Amazon Price Tracker", "icon_img_src": "https://cdn.keepa.com/apple-touch-icon-152x152.png", "link_img_src": null, "added_at": "2023-06-04T07:44:37.603258", "updated_at": "2023-07-07T20:33:23.491013"}, 
]
const profile = {
    xid: '6739_7132_D29B',
    name_key: 'kill_time_websites',
    theme_color: '#7feb3b', 
    title: 'Kill time Websites',
    img_src: 'https://img.freepik.com/free-vector/deadline-concept-illustration_114360-6003.jpg?w=1060&t=st=1687057919~exp=1687058519~hmac=c12283f3178bcb33f2cbbdbd56d43c6687162bd9155b80133c1be83691ca5672',
    description: [
        "Interesting Websites to Kill Time",
    ],
}
const author = {
    key: 'jared_feroldi',
    name: 'Jared Feroldi',
    img_src: 'https://img.freepik.com/free-vector/illustrator-designer-man-holding-digital-tablet_107791-12062.jpg?w=1060&t=st=1686919549~exp=1686920149~hmac=dcd3fa17081d31ea69e01cad76e468d5152040978fd7c32ac5f9379ed577fd74',
}
export const DATA = {
    lisk: [
        {title: 'Default', cards: cards, },
    ],
    profile: profile,
    author: author,
}
